import React, {useState, useCallback, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import logo from "../../assets/img/logo.jpg";

import '../css/Sidebar.css';

import {ReactComponent as IconDb} from "../../assets/svg/Icon db.svg";

import {ReactComponent as IconSpeaker} from "../../assets/svg/Icon feather-speaker.svg";
import {ReactComponent as IconUsers} from "../../assets/svg/Icon feather-users.svg";
import {ReactComponent as IconUser} from "../../assets/svg/Icon feather-user.svg";

import Divider from "./Divider";
import {PAGES} from "../../App";
import {
    loadAllUserGroups,
    setActiveBar,
    setActiveMenu, setCreateMenuActive,
    setCredentials,
    setIsCreatingMenu, setMenuLastCreated,
    setSessionUser,
    setSessionUserToken,
    setUserData,
    setUserToken
} from "../../store/actions/actions";
import {findItem, isUserAdmin, isUserSharepointAdmin, parseMapCaretChars, parseUserRole} from "../../helpers/helpers";
import {USER_GROUP_TYPE, USER_ROLES} from "../../constants/enums";
import {Redirect} from "react-router";


const isUserMemberOfAnySharepointAdminUserGroups = (usersUserGroupIds, allUserGroups) => {
    if (!usersUserGroupIds || usersUserGroupIds === '' || usersUserGroupIds.length === 0) {
        usersUserGroupIds = [];
    } else {
        usersUserGroupIds = usersUserGroupIds.trim();
        usersUserGroupIds = usersUserGroupIds.split(',');
    }

    let exists = false;
    for (let i = 0; i < usersUserGroupIds.length; i++) {
        if (usersUserGroupIds[i]) {
            let ug = findItem('user_group_id', usersUserGroupIds[i], allUserGroups);
            if (ug.type === USER_GROUP_TYPE.SHAREPOINT_ADMIN || ug.name === "SHAREPOINT-ADMIN") {
                return true;
            }
        }
    }
    if (!exists) {
        return false;
    }
}

function SidebarSharepoint(props) {
    let dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);

    const [_isUserSharepointAdmin, set_isUserSharepointAdmin] = useState(false)
    const [displaySidebar, setDisplaySidebar] = useState(props.display)
    const [selectedTab, setSelectedTab] = useState(!window.sharepointSelectedTab ? PAGES.SHAREPOINT_UPLOAD : window.sharepointSelectedTab)

    const onLogout = () => {
        dispatch(setUserData(null));
        dispatch(setUserToken(null));

        setSessionUser(null);
        setSessionUserToken(null);
        dispatch(setCredentials('', ''));
    };

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onNavigateAction = (path) => {
        window.sharepointSelectedTab = path;
        setSelectedTab(path);
        dispatch(setIsCreatingMenu(-1));
        onNavigateTo(path)
    };

    useEffect(() => {
        setDisplaySidebar(props.display)
    }, [props.display])

    useEffect(() => {
        dispatch(loadAllUserGroups(user.user_id, (res) => {
            console.log("User groups: ", res);
            set_isUserSharepointAdmin(user.username === 'plu.ceniki@gmail.com' || isUserAdmin(user) || isUserSharepointAdmin(user) || isUserMemberOfAnySharepointAdminUserGroups(user.user_groups, res));

        }, (err) => {
            console.warn("Error obtaining user groups..", err)
        }));
    }, [user])

    let classNameLi = selectedTab;

    const renderAdminSidebar = () => {
        if (!_isUserSharepointAdmin) {
            return <></>;
        }
        return (
            <div className={'sharepoint sidebar'}>
                <div className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" width={'220px'}/>
                </div>
                <Divider style={{marginBottom: '25px'}}/>
                <p className={'sidebar-name'}>Portal za predstavnike on trade</p>
                <ul>
                    <li className={selectedTab === PAGES.SHAREPOINT_UPLOAD ? 'tab selected' : ''}>
                        <a onClick={() => onNavigateAction(PAGES.SHAREPOINT_UPLOAD)}>
                            <IconSpeaker className={'icon'} width={'28px'} height={'28px'} title={'icon add'}/>BAZA
                            PRODAJNIH MATERIALOV</a>
                    </li>
                    <li className={selectedTab === PAGES.SHAREPOINT_USER_GROUPS ? 'tab selected' : ''}>
                    <a onClick={() => onNavigateAction(PAGES.SHAREPOINT_USER_GROUPS)}>
                            <IconUsers style={{margin: '0 16px'}} className={'icon'} width={'27px'}
                                       title={'icon users'}/>PREGLED UPORABNIŠKIH SKUPIN</a>
                    </li>
                    <li className={selectedTab === PAGES.SHAREPOINT_USERS ? 'tab selected' : ''}>
                    <a onClick={() => onNavigateAction(PAGES.SHAREPOINT_USERS)}>
                            <IconUser style={{margin: '0 18px'}} className={'icon'} width={'24px'}
                                      title={'icon users'}/>PREGLED UPORABNIKOV</a>
                    </li>
                </ul>
                <div className={'btn-logout'} onClick={onLogout}><p>ODJAVA</p></div>
            </div>
        );
    };

    const renderDefaultUserSidebar = () => {
        if (_isUserSharepointAdmin) {
            return <></>;
        }
        return (
            <div className={'sharepoint sidebar'}>
                <div onClick={() => onNavigateAction(PAGES.HOME)} className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" width={'220px'}/>
                </div>
                <Divider style={{marginBottom: '24px'}}/>
                <p className={'sidebar-name'}>Portal za potnike on trade</p>
                <ul>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.SHAREPOINT_UPLOAD)}>
                            <IconSpeaker className={'icon'} width={'28px'} height={'28px'} title={'icon add'}/>BAZA
                            PRODAJNIH MATERIALOV</a>
                    </li>
                </ul>
                <div className={'btn-logout'} onClick={onLogout}><p>ODJAVA</p></div>
            </div>
        );
    };

    const renderSidebar = () => {
        return <>
            {renderDefaultUserSidebar()}
            {renderAdminSidebar()}
        </>
    }

    if (!displaySidebar) {
        return <></>;
    }

    return <>
        {renderSidebar()}
    </>;
}

export default SidebarSharepoint;