import * as React from "react";
import Button from "./Button";

const FileUpload = props => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile && props.handleFile(fileUploaded);

        setTimeout(() => {
            hiddenFileInput.current.value = ""
        }, 250)
    };
    return (
        <>
            <Button style={props.buttonStyle} text={props.button} comboOffer={props.comboOffer} upload action={handleClick}/>
            <input type="file"
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{...props.style, display: 'none'}}
            />
        </>
    );
};
export default FileUpload;