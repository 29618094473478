import React, {useState, useCallback, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {Redirect} from 'react-router'
import Loader from "react-loader-spinner";

import '../css/Login.css';

import {useDispatch, useSelector} from "react-redux";
import * as FaIcons from "react-icons/fa";
import logo from "../assets/img/logo.jpg";
import background from "../assets/img/background-cp.jpg"

import Input from "../components/js/Input";
import Button from "../components/js/Button";
import {
    createNewUser,
    isRememberMeEnabled,
    loadCredentials,
    setRememberMe,
    setSignInCredentials,
    userLogin
} from "../store/actions/actions";
import Message, {ErrorType} from "../components/js/Message";
import {PAGES} from "../App";
import {isSessionValid, validateUserInput} from "../helpers/helpers";
import colors from "../constants/colors";


function Login(props) {
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);

    const isRememberMeSet = useSelector(state => state.reducer.rememberMe);
    const savedCredentials = useSelector(state => state.reducer.savedCredentials);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [_rememberMe, _setRememberMe] = useState();

    const [loginSuccessful, setLoginSuccessful] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        dispatch(loadCredentials());
        dispatch(isRememberMeEnabled());
    }, []);


    useEffect(() => {
        setEmail(savedCredentials.email);
        setPassword(savedCredentials.password)
    }, [savedCredentials])


    useEffect(() => {
        if (isRememberMeSet) {
            _setRememberMe(isRememberMeSet);
        }
    }, [isRememberMeSet])

    useEffect(() => {
        if (loginSuccessful) {
            if (_rememberMe) {
                dispatch(setSignInCredentials(email, password, true));
            } else {
                dispatch(setSignInCredentials('', '', true));
            }
            dispatch(setRememberMe(_rememberMe));
        }
    }, [loginSuccessful]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const onLogin = () => {
        //_sudo_createNewAdminAccount(); //SUDO TESTING ONLY! -> sway away :)

        setLoadingSpinner(true);
        const onSuccessLogin = () => {
            console.log('redirect success');
            setLoginSuccessful(true);
            setLoadingSpinner(false);
        };
        const UserAccessNotGranted = () => {
            console.log("Error fetching data");
            setError(ErrorType.USER_ACCESS_NOT_GRANTED);
            setLoadingSpinner(false);
        };
        const onErrorUnauthorized = () => {
            console.log("Unauthorized");
            setError(ErrorType.UNAUTHORIZED);
            setLoadingSpinner(false);
        };

        dispatch(userLogin(email, password, onSuccessLogin, UserAccessNotGranted, onErrorUnauthorized));

    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const renderRememberMeCheckBox = () => {
        return (
            <div className={'checkbox-container'}>
                <label>
                    <input type="checkbox"
                           checked={_rememberMe}
                           onChange={() => {
                               console.log(!_rememberMe);
                               _setRememberMe(!_rememberMe)
                           }}
                    />
                    Zapomni si me
                </label>
            </div>
        );
    };

    if (loginSuccessful) {
        console.log('redirect');
        return <Redirect to={PAGES.HOME} push={false}/>
    }


    return (
        <div className={'page login'} style={{backgroundImage: `url(${background})`}}>
            <div className={'login-container'}>
                <div className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" height={'110px'}/>
                </div>
                <div className={'form-container'}>
                    <Input login email
                           loadLoginCredentials
                           onChange={(value) => onInputChange(value, setEmail)}
                           onSubmit={onLogin}
                           placeholder={'Username'} label={'Vpišite uporabniško ime'}
                           style={{marginBottom: '36px'}}/>
                    <Input login password
                           loadLoginCredentials
                           onChange={(value) => onInputChange(value, setPassword)} onSubmit={onLogin}
                           placeholder={'Password'} label={'Vpišite geslo'}
                           style={{marginBottom: '0px'}}/>
                    {renderErrorMessage()}
                    {renderRememberMeCheckBox()}
                    {loadingSpinner ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={36}
                            width={36}
                            style={{paddingTop: 52, paddingBottom: 45}}
                        /> :
                        <Button text={'VPIŠI SE'} login action={onLogin}/>
                    }
                    <p className={'p-info'}>Če potrebujete pomoč se nam javite na dean@optibar.si</p>
                </div>
            </div>
        </div>
    )

}

export default Login;