export const USER_ROLES = {
    ADMIN: 0,
    BAR_REPRESENTATIVE: 1,
    CARETAKER: 2,
    OWNER: 3,
    SHAREPOINT: 10
};

export const USER_ROLES_OPTIONS = [
    {
        label: "ADMIN", value: "ADMIN"
    },
    {
        label: "POTNIK", value:  "POTNIK"
    },
    {
        label: "LASTNIK", value: "LASTNIK"
    },
    {
        label: "VODJA", value: "VODJA"
    },
    {
        label: "SHAREPOINT", value: "SHAREPOINT"
    }];

export const USER_GROUP_TYPE = {
    ALL: 'ALL',
    ADMIN: 'ADMIN',
    SUBSCRIBER: 'SUBSCRIBER',
    SHAREPOINT_ADMIN: 'SHAREPOINT ADMIN',
    SHAREPOINT: 'SHAREPOINT',
    CARETAKER: 'CARETAKER',
};

export const SESSION_STORAGE_KEYS = {
    USER: '_user',
    USER_CARETAKER: '_user_caretaker',
    USER_TOKEN: '_user_token',
    BAR_OWNER: '_bar_owner',
    BAR_REPRESENTATIVE: '_bar_user',
    BAR_CARETAKER: '_bar_caretaker',
    ACTIVE_EDIT_USER: '_user_edit',
    ACTIVE_BAR: '_bar_active',
    ACTIVE_CATEGORY: '_category_active',
    ACTIVE_PRODUCT: '_product_active',
    ACTIVE_MENU: '_menu_active',
    LIVE_MENU: '_menu_live',
    USERS: '_users',
    CARETAKERS: '_caretakers',
    BARS: '_bars',
    CATEGORIES: '_categories',
    PRODUCTS: '_products',
    COMBO_PRODUCTS: '_combo_products',
    ACTIVE_AD: '_ad_active'
};

/* EXCEL SHEET NAMES & ORDER, DO NOT CHANGE INDEX ORDERING!*/
export const XLSX_SHEET_NAMES = {
    BARS: 'Bars',
    USERS: 'Users',
    CATEGORIES: 'Categories',
    PRODUCTS: 'Products',
}

export const XLSX_BARS_FIELDS = {
    EXTERNAL_ID: 0,
    NAME: 1,
    ADDRESS_STREET: 2,
    ADDRESS_STREET_NMB: 3,
    ADDRESS_POST_NUMBER: 4,
    ADDRESS_POST: 5,
    ADDRESS_REGION: 6,
    TAX_NUMBER: 7,
    EMAIL_PERSONNEL: 8,
    EMAIL_CARETAKER: 9,
    EMAIL_OWNER: 10,
    LEGAL_ENTITY: 11
}
export const XLSX_USERS_FIELDS = {
    EXTERNAL_ID: 0,
    FULL_NAME: 1,
    ADDRESS: 2,
    TELEPHONE: 3,
    EMAIL: 4,
    USER_ROLE: 5
}
export const XLSX_CATEGORIES_FIELDS = {
    NAME: 0,
    POSITION: 1,
    EN_NAME: 2,
    DE_NAME: 3,
    IT_NAME: 4,
    HR_NAME: 5,
}
export const XLSX_PRODUCTS_FIELDS = {
    EXTERNAL_ID: 0,
    NAME: 1,
    CATEGORY_LABEL: 2,
    PACKAGING: 3,
    MEASURE_UNIT: 4,
    ALERGENS: 5,
    ALCO_PERCENTAGE: 6,
    COMBO_OFFER: 7,
    SLO_DESC: 8,
    EN_NAME: 9,
    EN_DESC: 10,
    DE_NAME: 11,
    DE_DESC: 12,
    IT_NAME: 13,
    IT_DESC: 14,
    HR_NAME: 15,
    HR_DESC: 16,
    OWN_PRODUCT: 17
}

export const NOTIFICATION_STATUS = {
    PENDING: "PENDING",
    CONFIRMED: "CONFIRMED",
    REJECTED: "REJECTED"
}

export const PRINT_EMAIL_RECIPIENTS = {
    PRINT_ADDRESS: 'hiti.tadej@gmail.com'
}

export const LOCAL_STORAGE_KEYS = {
    LOCAL_MENUS_IN_EDITING: 'LOCAL_MENUS_IN_EDITING'
}

export const ALERGEN_OPTIONS = [
    {label: '1. MLEKO IN MLEČNI IZDELKI', value: '1'},
    {label: '2. JAJCA IN JAJČNI IZDELKI', value: '2'},
    {label: '3. RAKI IN IZDELKI IZ NJIH', value: '3'},
    {label: '4. RIBE IN IZDELKI IZ NJIH', value: '4'},
    {label: '5. OREŠČKI / NUTS', value: '5'},
    {label: '6. ŽITO, KI VSEBUJE GLUTEN', value: '6'},
    {label: '7. ARAŠIDI', value: '7'},
    {label: '8. ZRNJE SOJE IN IZDELKI IZ NJEGA', value: '8'},
    {label: '9. LISTNATA ZELENA IN IZDELKI IZ NJE', value: '9'},
    {label: '10. GORČIČNO SEME IN IZDELKI IZ NJEGA', value: '10'},
    {label: '11. SEZAMOVO SEME IN IZDELKI IZ NJEGA', value: '11'},
    {label: '12. ŽVEPLOV DIOKSID IN SULFITI', value: '12'},
    {label: '13. VOLČJI BOB', value: '13'},
    {label: '14. MEHKUŽCI', value: '14'},
]

export const COUNTRY_OPTIONS = [
    {label: 'Slovenija', value: 'SI'},
    // {label: 'Hrvaška', value: 'HR'}
]

export const L10N_MENU = {
    si: {label: 'Slovenščina', value: 'SI'},
    hr: {label: 'Hrvaščina', value: 'HR'},
    it: {label: 'Italijanščina', value: 'IT'},
    de: {label: 'Nemščina', value: 'DE'},
    en: {label: 'Angleščina', value: 'EN'},
}

export const L10N_FIELDS = {
    name: {value: 'name'},
    description: {value: 'descr'},
}

export const MIME_OPTIONS = {
    FOTO: 'foto',
    VIDEO: 'video',
    PDF: 'pdf',
    URL: 'url'
};

//TODO: remove a4(beer&cider) when looping & generating standard menus!
export const FORMAT = [
    "a5",
    "a3",
    "a4",
    "b2",
    "qr"
]