import React, {useState, useEffect, createRef, useCallback} from "react";
import {ReactComponent as IconPalm} from "../assets/svg/icon-phone-palm.svg";

import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import "../css/Ads.css"
import '../components/css/Tables.css'
import '../css/Statistics.css'


import {useDispatch, useSelector} from "react-redux";
import {
    hasItem,
    isSessionValid, isUserBarRepresentative, isUserOwner,
    mapCaretChars,
    parseLeadingBrand,
    parseMapCaretChars
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    loadBars, setActiveBar,
    setCreateMenuActive, setSidebarWidth,

} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {ReactComponent as IconNotification} from "../assets/svg/Icon material-notifications-none.svg";
import {ReactComponent as IconList} from "../assets/svg/Icon awesome-list-ul.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import Button from "../components/js/Button";

// in pixels as integer
export const BANNER_WIDTH = 350;

const BARS_PER_PAGE = 30;
const TIMEOUT_INTERVAL = 1;

export const sumAllQrBarsScans = (bars, action) => {
    let c = 0;

    let b = [];
    for (let i = 0; i < bars.length; i++) {
        let bar = bars[i];
        if (bar?.scans_counter) {
            c += bar.scans_counter;
            b.push(bars[i]);
        }
    }
    let u = b.sort((a, b) => b.scans_counter - a.scans_counter);
    if (action) {
        action(u);
    }
    return c;
}

function Statistics(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);
    let bars = useSelector(state => state.reducer.bars);

    const [barChosen, setBarChosen] = useState(null);

    const [qrCodesScanned, setQrCodesScanned] = useState(null);

    const [allScannedBars, setAllScannedBars] = useState(bars);
    const [filteredBars, setFilteredBars] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        window.statistics_page_open = true;
        window.active_submenu_item_stats = 0;
        dispatch(setCreateMenuActive(null));

        return () => {
            window.statistics_page_open = false;
        };
    }, []);


    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (user) {
                await dispatch(loadBars(user.user_id, () => {
                    console.log("Bars successfully loaded..");
                    done = true;
                }, () => {
                    console.warn("Error loading bars..")
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [user]);

    useEffect(() => {
        if (bars) {
            console.log("Bars loaded");

            let allScans = sumAllQrBarsScans(bars, setAllScannedBars);

            setQrCodesScanned(allScans);
        }
    }, [bars]);

    useEffect(() => {
        if (allScannedBars) {
            setFilteredBars(allScannedBars);
        }
    }, [allScannedBars]);


    useEffect(() => {
        if (barChosen) {
            //dispatch(setActiveBar(barChosen));
            console.log("Bar chosen", barChosen);

        }
    }, [barChosen]);

    useEffect(() => {
        if (allScannedBars) {
            if (allScannedBars.length < BARS_PER_PAGE) {
                setFilteredBars(allScannedBars)
            } else {
                setFilteredBars(allScannedBars.slice((currentPage - 1) * BARS_PER_PAGE, (currentPage - 1) * BARS_PER_PAGE + BARS_PER_PAGE));
            }
        }

    }, [currentPage, allScannedBars]);

    useEffect(() => {
        if (filteredBars) {
            console.log("list re-draw", filteredBars);
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.clientHeight}

            let h = documentSize.y;
            if (filteredBars.length * 45 > h) {
                h = filteredBars.length * 45;
                setMainHeight(h);
            }
        }
    }, [filteredBars]);

    function Item(props) {
        // console.log(props.value);
        const item = props.value;
        /*
        if (!item.scans_counter) {
            return <></>;
        }

         */
        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-bar'}>{parseMapCaretChars(item.name)}</div>
                <div className={'row-address'}>{parseMapCaretChars(item.address)}</div>
                <div className={'row-qr-scans'}>{item.scans_counter}</div>
            </li>
        );
    }

    const pages = (className) => {
        return (
            <div className={className}>
                <Button disabled={currentPage === 1}
                        text={'Prejšnja stran'}
                        action={() => {
                            setCurrentPage(currentPage - 1)
                        }}/>
                <Button disabled={currentPage === Math.ceil(allScannedBars.length / BARS_PER_PAGE)}
                        text={'Naslednja stran'}
                        action={() => {
                            setCurrentPage(currentPage + 1)
                        }}/>
                <p>{currentPage}/{Math.ceil(allScannedBars.length / BARS_PER_PAGE)}</p>
            </div>
        );
    };

    function renderTable(items) {


        const header = () => {
            return (
                <>
                    <div className={'dynamic-header'}>
                        <div className={'row-bar'}>Lokal</div>
                        <div className={'row-address'}>Naslov</div>
                        <div className={'row-qr-scans'}>QR skeniranj</div>
                    </div>
                </>
            );
        };
        // console.log(items);
        if (!items || items === {}) {
            return header();
        } else {
            const list = (items) => {
                if (!items) {
                    return;
                }
                return (
                    <ul className={'item-list dynamic'}>
                        {items.map((item, index) => <Item key={index} value={item}/>)}
                    </ul>
                );
            }
            return (
                <>

                    {header()}
                    {list(items)}

                </>
            );
        }
    }

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token &&
        <div><Page relative ad className={'main'} style={{height: `${mainHeight + 10}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <div className={'body body-has-pagination'} style={{
                paddingLeft: `${sidebarWidth + 50}px`,
                marginTop: filteredBars.length >= BARS_PER_PAGE ? '210px' : '120px'
            }}>
                <Header left={`${sidebarWidth + 50}px`} title={`STATISTIKA / OSNOVNA`}/>
                <div className={'container-column-2'}>
                    <div className={'column-2'} style={{width: '40%'}}>
                        <p className={'p-title-small'}>{'Število vseh skeniranih QR kod'}</p>
                        <div className={'stats-card'}>
                            <IconPalm className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                            <p className={'p-stats'}>{!qrCodesScanned ? '-' : qrCodesScanned}</p>
                        </div>

                    </div>
                    <div className={'column-2'} style={{width: '60%', paddingRight: '48px'}}>
                        <p className={'p-title-small'}>{'Število skeniranih QR kod glede na lokal'}</p>
                        <div className={'stats-card'}>
                            <IconPalm className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                            <Dropdown loadBars label={'Ime lokala'} onChange={setBarChosen}
                                      style={{margin: 0}}
                                      grey
                                      placeholder={'Izberi ali vpiši lokal'}/>
                            <p className={'p-stats'}>{!barChosen ? '-' : barChosen?.scans_counter}</p>
                        </div>
                    </div>
                </div>

                <div className={'column-container'} style={{marginTop: '60px', width: '100%'}}>

                    <div className={'row-container'}>
                        <p className={'p-title-small'}>Pregled skeniranih QR kod po lokalih</p>
                        {pages('pagination-container pagination-container-bottom stats-pagination-dynamic')}
                    </div>
                    {renderTable(filteredBars)}
                </div>

            </div>
        </Page>
        </div>
}

export default Statistics;