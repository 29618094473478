import React, {useState, useEffect, createRef, useCallback} from "react";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import FileUpload from "../components/js/FileUpload";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import "../css/Ads.css"
import * as CgIcons from "react-icons/cg";

import {useDispatch, useSelector} from "react-redux";
import {displayAlert, hasItem, isSessionValid, mapCaretChars, parseMapCaretChars} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    addAllBarsToAd,
    addBarToAd,
    createNewAd,
    loadAds,
    loadBarsForAd, removeAllBarsFromAd,
    removeBarFromAd,
    setCreateMenuActive, setSidebarWidth,
    updateAd,
    updateAdActiveStatus,

} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import Masonry from "react-responsive-masonry";
import * as AiIcons from "react-icons/ai";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {LEADING_BRAND} from "../components/js/ButtonGroupSelection";
import Loader from "react-loader-spinner";
import colors from "../constants/colors";
import Button from "../components/js/Button";
import ModalSharepointUserGroup from "../components/js/ModalSharepointUserGroup";
import ModalInfo from "../components/js/ModalInfo";

// in pixels as integer
export const BANNER_WIDTH = 350;

function Ad(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let selectedAd = useSelector(state => state.reducer.selectedAd);
    let addedBars = useSelector(state => state.reducer.selectedAdAddedBars);
    let bars = useSelector(state => state.reducer.bars);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");

    const [image, setImage] = useState(selectedAd?.image);
    const [name, setName] = useState(null);
    const [link, setLink] = useState(null);

    const [addBar, setAddBar] = useState(null);
    const [removeBar, setRemoveBar] = useState(null);

    const [addAllBars, setAddAllBars] = useState(null);

    const [addAllUnionBars, setAddAllUnionBars] = useState(false);
    const [addAllLaskoBars, setAddAllLaskoBars] = useState(false);
    const [addAllHeinekenBars, setAddAllHeinekenBars] = useState(false);

    const [removeAllBars, setRemoveAllBars] = useState(null);

    const [loadingSpinnerHeineken, setLoadingSpinnerHeineken] = useState(false);
    const [loadingSpinnerLasko, setLoadingSpinnerLasko] = useState(false);
    const [loadingSpinnerUnion, setLoadingSpinnerUnion] = useState(false);

    let clientSize = {x: document.documentElement.clientWidth, y: document.documentElement.clientHeight}
    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 150)
    }, [documentSize.y])

    useEffect(() => {
        if (addAllBars) {
            const asyncEffect = async () => {
                let postData = [];
                for (let i = 0; i < bars.length; i++) {
                    postData.push({ad_id: selectedAd?.ad_id, bar_id: bars[i].bar_id});
                }
                await dispatch(addAllBarsToAd(postData), () => {
                    console.log("All bars added to ad!");
                });
            }
            asyncEffect().then(() => setAddAllBars(false));
        }
    }, [addAllBars]);

    useEffect(() => {
        if (removeAllBars) {
            dispatch(removeAllBarsFromAd(selectedAd?.ad_id), () => {
                console.log("All bars removed from ad:");
            });
            setRemoveAllBars(false);
        }

    }, [removeAllBars]);

    useEffect(() => {
        if (addBar) {
            let postData = {ad_id: selectedAd?.ad_id, bar_id: addBar.bar_id}
            dispatch(addBarToAd(postData), () => {
                console.log("Bar added to ad:", addBar);
            });
        }
    }, [addBar]);

    useEffect(() => {
        if (removeBar) {
            let postData = {ad_id: selectedAd?.ad_id, bar_id: removeBar.bar_id}
            dispatch(removeBarFromAd(postData), () => {
                console.log("Bar removed from ad:", removeBar);
            });
        }
    }, [removeBar]);

    useEffect(() => {
        if (addAllUnionBars) {
            const asyncEffect = async () => {
                let postData = [];
                for (let i = 0; i < bars.length; i++) {
                    if (bars[i].main_brand === LEADING_BRAND.UNION) {
                        postData.push({ad_id: selectedAd?.ad_id, bar_id: bars[i].bar_id});
                    }
                }
                setLoadingSpinnerUnion(true)
                await dispatch(addAllBarsToAd(postData), () => {
                    console.log("All union bars added to ad!");
                });
            }
            asyncEffect().then(() => setLoadingSpinnerUnion(false));
        }
    }, [addAllUnionBars]);

    useEffect(() => {
        if (addAllLaskoBars) {
            const asyncEffect = async () => {
                let postData = [];
                for (let i = 0; i < bars.length; i++) {
                    if (bars[i].main_brand === LEADING_BRAND.LASKO) {
                        postData.push({ad_id: selectedAd?.ad_id, bar_id: bars[i].bar_id});
                    }
                }
                setLoadingSpinnerLasko(true);
                await dispatch(addAllBarsToAd(postData), () => {
                    console.log("All lasko bars added to ad!");
                });
            }
            asyncEffect().then(() => setLoadingSpinnerLasko(false));
        }
    }, [addAllLaskoBars]);

    useEffect(() => {
        if (addAllHeinekenBars) {
            const asyncEffect = async () => {
                let postData = [];
                for (let i = 0; i < bars.length; i++) {
                    if (bars[i].main_brand === LEADING_BRAND.HEINEKEN) {
                        postData.push({ad_id: selectedAd?.ad_id, bar_id: bars[i].bar_id});
                    }
                }
                setLoadingSpinnerHeineken(true);
                await dispatch(addAllBarsToAd(postData), () => {
                    console.log("All heineken bars added to ad!");
                });
            }
            asyncEffect().then(() => setLoadingSpinnerHeineken(false));
        }
    }, [addAllHeinekenBars]);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = () => {
        setDisplayModalInfo(true);
        setModalInfoText("Oglas uspešno deaktiviran!");
        setModalInfoAction(() => {
            return () => {
                setDisplayModalInfo(false);
                onNavigateTo(PAGES.ADS_LIST);

                console.log("Ad successfully deactivated!");
                console.log('display ModalInfo, false');
            }
        })
    };



    const onDeactivateAd = () => {
        console.log("DEACTIVATE AD!");
        selectedAd.active = false;
        dispatch(updateAdActiveStatus(selectedAd, () => {
            return setModalValues();
        }, () => {
            console.warn("Error deactivating ad..")
        }));
    };

    useEffect(() => {
        dispatch(loadBarsForAd(selectedAd?.ad_id));
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        if (selectedAd && image) {
            if (image === 'REMOVE') {
                selectedAd.image = null;
                setImage(null);
            } else {
                selectedAd.image = image;
            }
            console.log("AD DATA LOADED..:", selectedAd);
            updateAdDispatch(selectedAd);
        }
    }, [image]);

    const updateAdDispatch = (ad) => {
        dispatch(updateAd(ad, () => {
            console.log("Ad successfully updated!");
        }, () => {
            console.warn("Error updating ad..")
        }));
    }

    const handleBannerUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log("load");
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        return <div className={'img-banner-container'}
                    style={{position: "inherit", marginTop: '18px', display: 'flex', flexDirection: 'row'}}>

            <img className={'img-banner-upload'} src={`${base64Data}`} alt="banner"
                 width={`${BANNER_WIDTH}px`}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE");
            }} className={'icon-close'} style={{position: 'relative', right: '0px'}}/>
        </div>
    };

    const renderFileUpload = (title) => {
        return <><p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                        handleFile={(file) => handleBannerUpload(file)}/>
            {image && previewImage(image)}
        </>
    };

    const renderUnionCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginTop: '12px'}}>
                <label>
                    {loadingSpinnerUnion ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={17}
                            width={17}
                            style={{paddingLeft: 12, paddingRight: 10}}
                        /> :
                        <input type="checkbox"
                               checked={addAllUnionBars}
                               onChange={() => {
                                   console.log(!addAllUnionBars);
                                   setAddAllUnionBars(!addAllUnionBars)
                               }}
                        />
                    }
                    Dodaj 'Union' lokale
                </label>
            </div>
        );
    };

    const renderHeinekenCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginTop: '12px'}}>
                <label>
                    {loadingSpinnerHeineken ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={17}
                            width={17}
                            style={{paddingLeft: 12, paddingRight: 10}}
                        /> :
                        <input type="checkbox"
                               checked={addAllHeinekenBars}
                               onChange={() => {
                                   console.log(!addAllHeinekenBars);
                                   setAddAllHeinekenBars(!addAllHeinekenBars)
                               }}
                        />
                    }
                    Dodaj 'Heineken' lokale
                </label>
            </div>
        );
    };

    const renderLaskoCheckbox = () => {
        return (
            <div className={'checkbox-container'} style={{marginTop: '12px'}}>
                <label>
                    {loadingSpinnerLasko ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={17}
                            width={17}
                            style={{paddingLeft: 12, paddingRight: 10}}
                        /> :
                        <input type="checkbox"
                               checked={addAllLaskoBars}
                               onChange={() => {
                                   console.log(!addAllLaskoBars);
                                   setAddAllLaskoBars(!addAllLaskoBars)
                               }}
                        />
                    }
                    Dodaj 'Laško' lokale
                </label>
            </div>
        );
    };

    let inputStyle = {marginBottom: '48px'};

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token &&
        <div><Page relative ad className={'main'} style={{height: `${mainHeight}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page ad relative'}>*/}
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                {selectedAd?.active ?
                    <Header left={`${sidebarWidth + 50}px`} title={`PREGLED OGLASOV / ${name}`} interactive delete
                            button={'Deaktiviraj oglas'}
                            action={onDeactivateAd}/> :
                    <Header left={`${sidebarWidth + 50}px`} title={`PREGLED OGLASOV / ${name} (NEAKTIVEN)`}/>}

                <div className={'container-column-2'}>
                    <div className={'column-2'}>
                        <Input white shadow loadAd name onChange={setName} placeholder={''}
                               disabled
                               label={'Ime oglasa'}
                               style={inputStyle}/>
                        <Input white loadAd link onChange={setLink} placeholder={''} label={'Link (url)'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedAd && link) {
                                       selectedAd.link = link;
                                       updateAdDispatch(selectedAd);
                                   }
                               }}/>
                        {renderFileUpload('Slika oglasa')}

                    </div>
                    <div className={'column-2'} style={{paddingRight: '48px'}}>
                        <Dropdown loadBarsAd label={'Seznam lokalov'} onChange={setAddBar}
                                  placeholder={'Dodelite lokale ustvarjenemu oglasu'}/>
                        <div className={'add-bars-to-ad-container'}>
                            <a className={'add-bars'} onClick={() => setAddAllBars(true)}>dodaj vse</a>
                            <a className={'remove-bars'} onClick={() => setRemoveAllBars(true)}>odstrani vse</a>
                        </div>
                        <div className={'add-bars-to-ad-container'} style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            zoom: 0.96
                        }}>
                            {renderUnionCheckbox()}
                            {renderHeinekenCheckbox()}
                            {renderLaskoCheckbox()}
                        </div>


                        <div className={'ad-bars-list'}>
                            {addedBars.map((bar, b) => (
                                <div key={`${b}_${bar}`}
                                     className={'bar-list-item'}
                                     onDoubleClick={() => console.log("remove item", bar)}>
                                    <p className={'p-title-product'}>{parseMapCaretChars(bar.name)}</p>
                                    <div className={'container-icon-selected'} style={{marginLeft: '0px'}}>
                                        <div className={'remove-bar-icon'} onClick={() => {
                                            setRemoveBar(bar)
                                        }} style={{cursor: 'pointer'}}>
                                            <AiIcons.AiFillDelete/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Page>
            {renderModalInfo()}
        </div>
}

export default Ad;