import React, {useState, useEffect, useCallback, useRef} from "react";
import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import * as CgIcons from "react-icons/cg";

import Modal from "../components/js/Modal";
import {useDispatch, useSelector} from "react-redux";
import {displayAlert, isSessionValid, mapCaretChars} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    setCreateMenuActive, setIsCreatingMenu, setSidebarWidth, updateCategory
} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

import countrySI from "../assets/img/country_si.png";
import countryEN from "../assets/img/country_en.png";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";
import Footer from "../components/js/Footer";

function Category(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let selectedCategory = useSelector(state => state.reducer.selectedCategory);
    const user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    console.log("selectedCategory", selectedCategory);


    const [name, setName] = useState(null);
    const [nameEn, setNameEn] = useState(null);
    const [nameDe, setNameDe] = useState(null);
    const [nameIt, setNameIt] = useState(null);
    const [nameHr, setNameHr] = useState(null);

    console.log("selectedCategory", name, nameEn);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onNavigateAction = (path) => {
        onNavigateTo(path)
    };

    useEffect(() => {
       dispatch(setCreateMenuActive(null));
    }, []);

    const updateCategoryDispatch = (category, leavePage) => {
        /*
                if (user.type !== 'ADMIN') {
                    product.category_label = selectedProduct.category_label
                }

         */

        dispatch(updateCategory(category, () => {
            console.log("Category successfully updated!");

            if (leavePage) {
                onNavigateTo(PAGES.HOME);
            }
        }, () => {
            console.warn("Error updating product..")
        }));
    }

    const confirmUpdate = () => {

        if (selectedCategory && name) {
            selectedCategory.name = mapCaretChars(name);
        }
        if (selectedCategory && nameEn) {
            selectedCategory.eng_name = mapCaretChars(nameEn);
        }
        if (selectedCategory && nameDe) {
            selectedCategory.de_name = mapCaretChars(nameDe);
        }
        if (selectedCategory && nameIt) {
            selectedCategory.it_name = mapCaretChars(nameIt);
        }
        if (selectedCategory && nameHr) {
            selectedCategory.hr_name = mapCaretChars(nameHr);
        }
        updateCategoryDispatch(selectedCategory, true);
    };

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 350)
    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    let inputStyle = {marginBottom: '48px'};
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div ref={ref}><Page relative className={'main'} style={{height: `${mainHeight}px`}}
                                      onToggleSidebar={(shown) => {
                                          if (shown) {
                                              dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                                          } else {
                                              dispatch(setSidebarWidth(0))
                                          }
                                      }}>
            <div className={'body'} style={{marginTop: '220px', paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={`PREGLED KATEGORIJ / ${name}`}/>
                <div className={'container-column-2'}>
                    <div className={'column-2'}>
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countrySI} alt="SI" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory name onChange={setName} placeholder={'Slovenski naziv kategorije'}
                               label={'Slovenski naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && name) {
                                       selectedCategory.name = mapCaretChars(name);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryEN} alt="EN" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory engName onChange={setNameEn}
                               placeholder={'Angleški naziv kategorije'}
                               label={'Angleški naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameEn) {
                                       selectedCategory.eng_name = mapCaretChars(nameEn);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryDE} alt="DE" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory deName onChange={setNameDe} placeholder={'Nemški naziv kategorije'}
                               label={'Nemški naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameDe) {
                                       selectedCategory.de_name = mapCaretChars(nameDe);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryIT} alt="IT" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory itName onChange={setNameIt}
                               placeholder={'Italijanski naziv kategorije'}
                               label={'Italijanski naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameIt) {
                                       selectedCategory.it_name = mapCaretChars(nameIt);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />
                        <div
                            className={'country-label-container'}
                            style={{alignSelf: 'self-end', marginBottom: '-20px'}}>
                            <img src={countryHR} alt="HR" width={'25px'}/>
                        </div>
                        <Input white shadow loadCategory hrName onChange={setNameHr} placeholder={'Hrvaški naziv kategorije'}
                               label={'Hrvaški naziv kategorije'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (selectedCategory && nameHr) {
                                       selectedCategory.hr_name = mapCaretChars(nameHr);
                                       updateCategoryDispatch(selectedCategory);
                                   }
                               }}
                        />

                    </div>
                </div>
                <Footer
                    left={`${sidebarWidth}px`}
                    next={'SHRANI'}
                    urlNext={PAGES.HOME}
                    onAction={() => confirmUpdate()}
                    progress={0}/>
            </div>
        </Page>
        </div>
}

export default Category;