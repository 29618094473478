import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import {ReactComponent as IconPrinter} from "../assets/svg/Icon feather-printer.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    LOAD_COMBO_OFFERS,
    loadComboOffers, loadLastPageOpened,
    loadProducts, loadSearchTerm, saveLastPageOpened, saveSearchTerm,
    setActiveProduct, setCreateMenuActive, setSessionComboOffers, setSessionProducts, setSidebarWidth
} from "../store/actions/actions";
import {
    findItem,
    isSessionValid,
    isUserAdmin, isUserBarRepresentative, isUserCaretaker, isUserOwner,
    parseBeerCiderLabel,
    parseMapCaretChars,
    parseUserRole
} from "../helpers/helpers";
import {Redirect} from "react-router";
import Modal from "../components/js/Modal";
import {USER_ROLES} from "../constants/enums";
import Input from "../components/js/Input";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import Button from "../components/js/Button";
import Loader from "react-loader-spinner";
import colors from "../constants/colors";

const PRODUCTS_PER_PAGE = 100;
const TIMEOUT_INTERVAL = 50;
const SEARCH_BY_FIELDS = [
    'name',
    'external_id'
];

function ProductsList(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    let allProducts = useSelector(state => state.reducer.products);
    let comboOffers = useSelector(state => state.reducer.comboOffers);

    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let activeBar = useSelector(state => state.reducer.activeBar);

    const [modal, setModal] = useState(false);
    const [beerCiderModal, setBeerCiderModal] = useState(false);
    const [toggleFilter, setToggleFilter] = useState(!!window.toggle_beer_cider);

    const [inputTimeout, setInputTimeout] = useState(null);

    const [nameInput, setNameInput] = useState(null);

    const [products, setProducts] = useState(allProducts ? allProducts : []);

    const [filteredProducts, setFilteredProducts] = useState(allProducts);
    const [currentPage, setCurrentPage] = useState(1);
    // const [products, setProducts] = useState([]);

    const [loadingSpinner, setLoadingSpinner] = useState(false);


    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);

    const onSaveSearchTermToCache = (search) => {
        saveSearchTerm('product_search', search);
    }

    const onLoadSearchTermFromCache = () => {
        if (loadLastPageOpened() === PAGES.EDIT_PRODUCT) {
            console.log("LAST PAGE WAS PRODUCT!");

            loadSearchTerm('product_search', (res) => {
                setNameInput(res);
                console.log("LOAD SEARCH TERM", res);
            });
        }
    }

    const onToggleFilter = (state) => {
        setToggleFilter(state);
        window.toggle_beer_cider = state;
    }

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const navigateAndLoadSelectedProduct = (product) => {
        dispatch(setActiveProduct(product));
        onNavigateTo(PAGES.EDIT_PRODUCT);
    };

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
        setToggleFilter(!!window.toggle_beer_cider);

        // onLoadSearchTermFromCache();
        return function cleanup() {
            saveLastPageOpened(null);
        };
    }, []);

    useEffect(() => {
        if (allProducts) {
            setFilteredProducts(allProducts);
            console.log(document.documentElement.offsetHeight);
            setMainHeight(6200)
        }
    }, [allProducts]);


    const searchInputLogic = () => {
        if (!!inputTimeout) {
            clearTimeout(inputTimeout);
            setInputTimeout(null);
        }

        setInputTimeout(setTimeout(() => {
            if (!nameInput || nameInput === '' || nameInput.length <= 1) {
                //setProducts(allProducts ? allProducts : []);
                setFilteredProducts(allProducts ? allProducts : []);
            } else {


                let filtered = [];
                // console.log(allProducts)

                if (!allProducts) {
                    clearTimeout(inputTimeout);
                    return;
                }

                for (const product of allProducts) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (product.hasOwnProperty(fieldName) && !!product[fieldName] && parseMapCaretChars(product[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            filtered.push(product);
                            break;
                        }
                    }
                }
                //setProducts(filteredProducts);
                setFilteredProducts(filtered);

                onSaveSearchTermToCache(nameInput ? nameInput : '');
            }
            setCurrentPage(1);
        }, TIMEOUT_INTERVAL));
    }

    useEffect(() => {
        if (filteredProducts.length < PRODUCTS_PER_PAGE) {
            setProducts(filteredProducts)
        } else {
            setProducts(filteredProducts.slice((currentPage - 1) * PRODUCTS_PER_PAGE, (currentPage - 1) * PRODUCTS_PER_PAGE + PRODUCTS_PER_PAGE));
        }
    }, [currentPage, filteredProducts]);


    useEffect(() => {
        if (nameInput || nameInput === '') {
            onSaveSearchTermToCache(nameInput ? nameInput : '');
            if (nameInput.length > 1) {
                setLoadingSpinner(true);
            }
        }
        searchInputLogic();
    }, [nameInput]);

    useEffect(() => {
            let done = false;
            const asyncEffect = async () => {
                if (user) {
                    let barId = null;
                    if (parseUserRole(user.type) !== USER_ROLES.ADMIN) {
                        if (activeBar) {
                            barId = activeBar.bar_id;
                        }
                    }

                    await dispatch(loadProducts(barId, user, (products) => {
                        //setSessionProducts(JSON.stringify(products));
                        let p = [];
                        if (parseUserRole(user.type) === USER_ROLES.OWNER || parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE) {
                            for (let i = 0; i < products.length; i++) {
                                if (!products[i].bar_id) {
                                    continue;
                                }
                                p.push(products[i]);
                            }
                            setProducts(p);
                        } else {
                            setProducts(products);
                        }

                        console.log("Products successfully loaded..", p);
                    }, () => {
                        console.warn("Error loading products..")
                    }, () => {
                    }, true));

                    await dispatch(loadComboOffers(barId, (resData) => {

                        if (parseUserRole(user.type) === USER_ROLES.OWNER || parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE) {
                            let p = [];
                            for (let i = 0; i < resData.length; i++) {
                                if (!resData[i]?.bar_id) {
                                    continue;
                                }
                                p.push(resData[i]);
                            }
                            setSessionComboOffers(JSON.stringify(p));
                            dispatch({type: LOAD_COMBO_OFFERS, comboOffers: p});
                        }

                        console.log("Combo Offers successfully loaded..");
                    }, () => {
                        console.warn("Error loading combo offers..")
                    }));

                }
            }
            asyncEffect().then(() => {
                console.log(done && "done loading data.")
                onLoadSearchTermFromCache();


            });
        }, [user, activeBar]
    );


    useEffect(() => {
        setTimeout(() => {
            //   onLoadSearchTermFromCache();
            //    searchInputLogic();
            setLoadingSpinner(false);

        }, 50)
    }, [filteredProducts]);



    const renderProductTypeFilter = () => {
        return <div className={'button-selection-filter'}>
            <Button className={'button-selection'} text={'PIVSKA KARTA IZDELKI'} selected={toggleFilter}
                    action={() => onToggleFilter(!toggleFilter)}/>
            <Button className={'button-selection'} text={'VSI IZDELKI'} selected={!toggleFilter}
                    action={() => onToggleFilter(!toggleFilter)}/>
        </div>;
    }

    function Item(props) {
        // console.log(props.value);
        const getMeasureUnit = (item) => {
            if (!item.beer_cider_type) {
                return item.measure_unit;
            }
            let m = '';
            if (item.measure_unit) {
                m += item.measure_unit;
            }
            if (item.measure_unit_2) {
                m += ', ';
                m += item.measure_unit_2;
            }
            if (item.measure_unit_3) {
                m += ', ';
                m += item.measure_unit_3;
            }
            return m;
        }
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{
                left: `${sidebarWidth + 50}px`,
                display: item.beer_cider_type && toggleFilter || !toggleFilter ? 'flex' : 'none'
            }}>
                <div className={'row-name'}>{parseMapCaretChars(item.name)}</div>
                <div className={'row-beer-cider'}>{parseBeerCiderLabel(item.beer_cider_type)}</div>
                <div className={'row-unit'}>{getMeasureUnit(item)}</div>
                <div className={'row-package'}>{parseMapCaretChars(item.packaging)}</div>
                <div className={'row-alergens'}>{item.allergenes}</div>
                <div onClick={() => navigateAndLoadSelectedProduct(item)} className={'row-edit'}><a
                    className={'a-icon'}>
                    <IconEdit
                        className={'icon'}
                        width={'24px'} height={'24px'}
                        title={'icon list'}/></a></div>
            </li>
        );
    }

    function renderTable(items) {
        const filters = () => {
            return (
                <div className={'search-filters products-list'} style={{left: `${sidebarWidth + 50}px`}}>

                    <Loader
                        type="TailSpin"
                        color={colors.primary}
                        height={24}
                        width={24}
                        style={{
                            display: loadingSpinner ? 'flex' : 'none',
                            position: 'absolute',
                            paddingTop: 8,
                            marginBottom: '28px',
                            alignSelf: 'flex-end',
                            marginLeft: '-40px'
                        }}
                    />

                    <Input onSubmit={() => {
                    }}
                           search
                           value={nameInput}
                           onChange={setNameInput}
                           placeholder={'Naziv ali šifra izdelka'}
                           white
                    />
                    {renderProductTypeFilter()}
                </div>
            );
        };
        const pages = (className) => {
            return (
                <div className={className} style={{left: `${sidebarWidth + 50}px`}}>
                    <Button disabled={currentPage === 1}
                            text={'Prejšnja stran'}
                            action={() => {
                                setCurrentPage(currentPage - 1)
                            }}/>
                    <Button disabled={currentPage === Math.ceil(filteredProducts.length / PRODUCTS_PER_PAGE)}
                            text={'Naslednja stran'}
                            action={() => {
                                setCurrentPage(currentPage + 1)
                            }}/>
                    <p>{currentPage}/{Math.ceil(filteredProducts.length / PRODUCTS_PER_PAGE)}</p>
                </div>
            );
        };
        const header = () => {
            return (
                <div className={'row-header row-header-has-pagination row-header-has-filter'}
                     style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-name'}>Naziv</div>
                    <div className={'row-beer-cider'}>Tip</div>
                    <div className={'row-unit'}>Enota</div>
                    <div className={'row-package'}>Pakiranje</div>
                    <div className={'row-alergens'}>Alergeni</div>
                    <div className={'row-edit'}>Uredi</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                    {pages('pagination-container pagination-container-bottom')}
                </ul>
            );
        }
        return (
            <>
                {filters()}
                {pages('pagination-container pagination-container-has-filter')}
                {header()}
                {list(items)}
            </>
        );
    }

    const renderModal = () => {
        return (
            modal && <Modal product actionClose={() => {
                setModal(false)
            }}/>
        );
    };
    const renderBandCModal = () => {
        return (
            beerCiderModal && <Modal product beer_cider_type actionClose={() => {
                setBeerCiderModal(false)
            }}/>
        );
    };
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'}
                            style={{height: `${6700}px`}}
                            onToggleSidebar={(shown) => {
                                if (shown) {
                                    dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                                } else {
                                    dispatch(setSidebarWidth(0))
                                }
                            }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'PREGLED IZDELKOV'} interactive add button={'DODAJ NOV IZDELEK'}
                    action={() => {
                        setModal(true)
                    }}
                    button2={isUserCaretaker(user) || isUserAdmin(user) ? 'DODAJ NOV IZDELEK (PIVSKA KARTA)' : null}
                    action2={() => {
                        if (isUserBarRepresentative(user) || isUserOwner(user)) {
                            return;
                        }
                        setBeerCiderModal(true);
                    }}/>
            <div className={'body body-has-pagination body-has-filter'}>
                {renderTable([...products, ...comboOffers])}
            </div>
            {/*</div>*/}
        </Page>
            {renderModal()}
            {renderBandCModal()}
        </div>
}

export default ProductsList;

