import React, {useEffect} from "react";

import '../css/MenuSuccessfullyCreated.css';
import Sidebar from "../components/js/Sidebar";
import {ReactComponent as IconMailbox} from "../assets/svg/Icon mailbox.svg";
import {useDispatch, useSelector} from "react-redux";
import {isSessionValid, isUserCaretaker} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    setActiveBar,
    setActiveMenu,
    setCreateMenuActive,
    setMenuLastCreated,
    setSidebarWidth
} from "../store/actions/actions";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

function MenuSuccessfullyCreated(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);
    let token = useSelector(state => state.reducer.userToken);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let menuLastCreated = useSelector(state => state.reducer.menuLastCreated);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);


    useEffect(() => {
        if (menuLastCreated) {
            console.log("MENU LAST CREATED:", menuLastCreated)

            dispatch(setActiveMenu(null));
            //dispatch(setActiveBar(null));
            dispatch(setCreateMenuActive(null));
            dispatch(setMenuLastCreated(null));
        }
        return () => {
            dispatch(setActiveBar(null));
        }
    }, [menuLastCreated])
    useEffect(() => {
        if (activeBar) {
            console.log("ACTIVE BAR:", activeBar)
        }
    }, [activeBar])

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page centered className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page centered'}>*/}
                <div className={'body'} style={{paddingLeft: `${sidebarWidth}px`}}>
                    <IconMailbox className={'icon'} width={'320px'} height={'275px'} title={'icon menu created'}/>
                    <p className={'menu-created-message'}>{ isUserCaretaker(user) ? 'USPEŠNO POSLANO V TISK' : 'CENIK USPEŠNO USTVARJEN'}</p>
                    {
                        isUserCaretaker(user) && <>
                            <p className={'menu-created-note'}>{ isUserCaretaker(user) ? 'Poslanu adminu v potrditev..' : 'Ustvarjeni tiskani ceniki so bili poslani na vaš e-poštni naslov..'}</p>
                            <p className={'menu-created-note'}>{`Ceniki bodo dostavljeni na vaš naslov: ${activeBar ? activeBar.address : ''}`}</p>
                        </>
                    }
                </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default MenuSuccessfullyCreated;