import React, {useState, useEffect, useRef} from "react";
import Input from "../js/Input";

import "../css/Modal.css";

import * as IoIcons from "react-icons/io";
import Button from "../js/Button";
import {ALERGEN_OPTIONS, COUNTRY_OPTIONS} from "../../constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {
    createFolder,
    loadAllUsersFilesHistory,
    loadAllUsersLoginHistory,
    loadAllUsersWithAccessToFileInteracted, loadAllUsersWithAccessToFileNotInteracted,
    updateFileName,
    updateFolder,
    updateFoldersListUserGroup
} from "../../store/actions/actions";
import {
    displayAlert,
    displayDate,
    displayDateHour,
    isNewObjValidCheck,
    isUserAdmin,
    isUserBarRepresentative,
    isUserOwner, isUserPartOfSharepointAdminGroup,
    isUserSharepointAdmin,
    mapCaretChars,
    parseMapCaretChars,
    parsePathToParent
} from "../../helpers/helpers";

import * as CgIcons from "react-icons/cg";
import FileUpload from "./FileUpload";
import {getAllUserGroups, getAllUsersAdminUserGroupsInFolder} from "../../pages/Sharepoint";
import Loader from "react-loader-spinner";
import colors from "../../constants/colors";


function ModalSharepoint(props) {
    console.log("Open modal");

    let dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);

    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [fileId, setFileId] = useState(null);
    const [fileHistory, setFileHistory] = useState([]);
    const [usersInteracted, setUsersInteracted] = useState([]);

    const [usersNotInteracted, setUsersNotInteracted] = useState([]);

    const [dataLoaded, setDataLoaded] = useState(0);


    useEffect(() => {

    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);


    useEffect(() => {
        if (props.file_id) {
            console.log("File_ID:", props.file_id)
            setFileId(props.file_id);
        }
    }, [props.file_id])

    useEffect(() => {
        if (fileId) {
            setLoadingSpinner(true);

            loadUsersInteracted(fileId)
            loadFileHistoryInteracted(fileId)
            loadUsersNotInteracted(fileId)
        }
    }, [fileId])

    useEffect(() => {
        if (dataLoaded === 3) {
            setLoadingSpinner(false);
            setDataLoaded(0);
        }
    }, [dataLoaded])


    const loadUsersInteracted = (file_id) => {
        dispatch(loadAllUsersWithAccessToFileInteracted(file_id, true, (res) => {
            console.log("Users interacted  successfully obtained!", res);

            setUsersInteracted(res);
            setDataLoaded(dataLoaded + 1);
        }, () => {
            console.warn("Error obtaining users interacted data..")
        }));
    }


    const loadFileHistoryInteracted = (file_id) => {
        dispatch(loadAllUsersWithAccessToFileInteracted(file_id, false, (res) => {
            console.log("Users interacted  successfully obtained!", res);

            setFileHistory(res);
            setDataLoaded(dataLoaded + 1);
        }, () => {
            console.warn("Error obtaining users interacted data..")
        }));
    }

    const loadUsersNotInteracted = (user_id) => {
        dispatch(loadAllUsersWithAccessToFileNotInteracted(user_id, (res) => {
            console.log("Users not interacted successfully obtained!", res);

            setUsersNotInteracted(res);
            setDataLoaded(dataLoaded + 1);
        }, () => {
            //  userFileHistory([]);
            console.warn("Error obtaining users [not] interacted data..")
        }));
    }

    function UserInteractedItem(props) {
        // console.log(props.value);
        const item = props.value;
        if (!isUserSharepointAdmin(user) || isUserPartOfSharepointAdminGroup(user?.user_group_ids)) {
            return null;
        }

        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-date'}>{parseMapCaretChars(item.username)}</div>
            </li>
        );
    }

    function renderUsersInteracted(items) {
        const header = () => {
            return (
                <div className={'row dynamic-header'}>
                    <div className={'row-date'}>Uporabnik</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list dynamic'}>
                    {items.map((item, index) => <UserInteractedItem key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }


    function FileHistoryInteractedItem(props) {
        // console.log(props.value);
        const item = props.value;
        if (!isUserSharepointAdmin(user) || isUserPartOfSharepointAdminGroup(user?.user_group_ids)) {
            return null;
        }

        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-date-time'}>{displayDateHour(item.date_create)}</div>
                <div className={'row-user'}>{parseMapCaretChars(item.username)}</div>
                <div className={'row-dimension'}>{parseMapCaretChars(item.action_type)}</div>
            </li>
        );
    }

    function renderFileHistoryInteracted(items) {
        const header = () => {
            return (
                <div className={'row dynamic-header'}>
                    <div className={'row-date-time'}>Datum</div>
                    <div className={'row-user'}>Uporabnik</div>
                    <div className={'row-dimension'}>Interkacija</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list dynamic'}>
                    {items.map((item, index) => <FileHistoryInteractedItem key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    function UserNotInteractedItem(props) {
        // console.log(props.value);
        const item = props.value;
        if (!isUserSharepointAdmin(user) || isUserPartOfSharepointAdminGroup(user?.user_group_ids)) {
            return null;
        }

        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-date'}>{parseMapCaretChars(item.username)}</div>
            </li>
        );
    }

    function renderUsersNotInteracted(items) {
        const header = () => {
            return (
                <div className={'row dynamic-header'}>
                    <div className={'row-date'}>Uporabnik</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list dynamic'}>
                    {items.map((item, index) => <UserNotInteractedItem key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    const renderHeader = (title, actionClose) => {
        return (
            <div className={'modal-header'} style={{marginBottom: '16px'}}>
                <p>{title}</p>
                <div onClick={actionClose} style={{cursor: 'pointer'}}><IoIcons.IoMdClose
                    className={'icon-close'}/>
                </div>
            </div>
        );
    };

    const renderHistory = () => {
        return (
            <div className={'dynamic container-row-2'}>
                <div className={'row-2'}>
                    <div className={'container-row-2'} style={{width: '100%'}}>
                        <div className={'column-2'}>
                            {renderFileHistoryInteracted(fileHistory)}
                        </div>
                    </div>
                </div>
                <div className={'row-2'} style={{paddingTop: '36px'}}>
                    <div className={'row-2'} style={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div className={'column-2'} style={{minWidth: '49%'}}>
                            <p className={'p-title-small dashed-border-title'} style={{color: 'darkred'}}>Neaktivni uporabniki</p>
                            {renderUsersNotInteracted(usersNotInteracted)}
                        </div>
                        <div className={'column-2'} style={{paddingLeft: '36px', minWidth: '49%'}}>
                            <p className={'p-title-small dashed-border-title'} style={{color: 'green'}}>Aktivni uporabniki</p>
                            {renderUsersInteracted(usersInteracted)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const renderUserAccessHistory = () => {
        return (
            <div ref={ref} className={'modal-overlay'}>
                <div className={'modal modal-product modal-height modal-auto-height'}>
                    {renderHeader('ZGODOVINA INTERAKCIJ Z DATOTEKO', props.actionClose)}
                    <div className={'modal-body'} style={{overflowY: 'hidden', maxHeight: '348px'}}>
                        {
                            loadingSpinner ? renderHistory() : <></>
                        }
                    </div>
                </div>
            </div>
        );
    };


    if (props.history) {
        return renderUserAccessHistory();
    } else {
        return <></>
    }
}

export default ModalSharepoint;