import React, {useState, useEffect} from "react";
import Select from "react-select";

import '../css/Dropdown.css';
import {useSelector} from "react-redux";
import {parseMapCaretChars} from "../../helpers/helpers";


const parseAdsDataToOptionsObject = (data) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = { value: JSON.parse(JSON.stringify(data[i])), label: parseMapCaretChars(data[i].name) }
        obj.push(el);
    }
    console.log(obj);
    return obj
};

const parseDataToOptionsObject = (data) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = { value: JSON.parse(JSON.stringify(data[i])), label: data[i].external_id + ' | ' + parseMapCaretChars(data[i].name) }
        obj.push(el);
    }
    console.log(obj);
    return obj
};

const parseDataToOptionsObjectDefault = (data) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = { value: JSON.parse(JSON.stringify(data[i])), label: data[i].label ?  data[i].label : data[i] }
        obj.push(el);
    }
    //console.log(obj);
    return obj
};

const parseProductsToOptionsObject = (data, isBeerCiderMenu) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        if (isBeerCiderMenu && parseMapCaretChars(data[i].name) !== 'beer&cider') {
            continue;
        }
        let el = { value: JSON.parse(JSON.stringify(data[i].products)), label: parseMapCaretChars(data[i].name) }
        obj.push(el);
    }
    console.log(obj);
    return obj
};


function Dropdown(props) {
    let activeBar = useSelector(state => state.reducer.activeBar);
    let createMenuActive = useSelector(state => state.reducer.createMenuActive);
    let bars = useSelector(state => state.reducer.bars);
    let internalAds = useSelector(state => state.reducer.ads);

    let isEditMode = !!createMenuActive && !!activeBar && props.createMenu;
    // const [bars, setBars] = useState(loadedBars);
    let productCategories = useSelector(state => state.reducer.productCategoryLabels);
    let products = useSelector(state => state.reducer.products);

    const defaultSelectedOption = isEditMode ? { value: JSON.parse(JSON.stringify(activeBar)), label: activeBar.external_id + ' | ' + parseMapCaretChars(activeBar.name) } : null;

    const [selectedOption, setSelectedOption] = useState(props.loadAlergens ? props.value : defaultSelectedOption);
    const [options, setOptions] = useState( props.loadAlergens || props.country ? props.options : (isEditMode ? [defaultSelectedOption] : null));

    useEffect(() => {
        if (props.loadBars && bars && !createMenuActive) {
            //setOptions(parseDataToOptionsObject(bars));
            setOptions(parseDataToOptionsObject(bars ? bars.slice(0, bars.length >= 100 ? 100 : bars.length) : []));
        } else if (props.loadBarsAd && bars) {
            //setOptions(parseDataToOptionsObject(bars));
            setOptions(parseDataToOptionsObject(bars ? bars.slice(0, bars.length >= 100 ? 100 : bars.length) : []));
        }
    }, [bars]);

    useEffect(() => {
        if (!!createMenuActive && !!activeBar) {
            const defaultSelectedOption = { value: JSON.parse(JSON.stringify(activeBar)), label: activeBar.external_id + ' | ' + parseMapCaretChars(activeBar.name) };
            console.log('defaultSelectedOption: ', defaultSelectedOption);
            setOptions([defaultSelectedOption]);
            setSelectedOption(defaultSelectedOption);
        }
    }, [activeBar, createMenuActive]);


    useEffect(() => {
        if (props.loadInternalAds && internalAds && !createMenuActive) {
            //setOptions(parseDataToOptionsObject(bars));
            setOptions(parseAdsDataToOptionsObject(internalAds ? internalAds.slice(0, internalAds.length >= 100 ? 100 : internalAds.length) : [], true));
        }
    }, [internalAds]);


    useEffect(() => {
        if (props.loadBars && activeBar) {
            setOptions(parseDataToOptionsObject(bars));
            // onInputChange(activeBar);
            // onInputChange({ value: JSON.parse(JSON.stringify(activeBar)), label: activeBar.external_id + ' | ' + parseMapCaretChars(activeBar.name) });
        }
    }, [activeBar]);

    useEffect(() => {
        const parseProductsByCategoryLabel = (products) => {
            let mappedProductsByCategoryLabel = [];
            mappedProductsByCategoryLabel.push({name: 'Vsi izdelki', products: products});

            let found = false;
            for (let i = 0; i < products.length; i++) {
                found = false;
                for (let j = 0; j < mappedProductsByCategoryLabel.length; j++) {
                    if (mappedProductsByCategoryLabel[j].name === products[i].category_label) {
                        mappedProductsByCategoryLabel[j].products.push(products[i])
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    let e = {name: products[i].category_label, products: [products[i]]};
                    mappedProductsByCategoryLabel.push(e);
                }
            }
            console.log("PARSED PRODUCTS BY CATEGORY:", mappedProductsByCategoryLabel);
            return mappedProductsByCategoryLabel;
        };

        if (props.loadSearchProductsCategory && products) {
            let productsByCategoryLabel = parseProductsByCategoryLabel(products);

            setOptions(parseProductsToOptionsObject(productsByCategoryLabel, createMenuActive.beer_cider_menu));
            //dispatch(loadProductCategoryLabels(activeBar.bar_id, () => {}, () => {}));
        }
    }, [products]);


    useEffect(() => {
        if (props.userType) {
            setOptions(parseDataToOptionsObjectDefault(props.options));
            // onInputChange(activeBar);
            // onInputChange({ value: JSON.parse(JSON.stringify(activeBar)), label: activeBar.external_id + ' | ' + parseMapCaretChars(activeBar.name) });
        }
    }, [props.userType]);

    /*
    useEffect(() => {
        if (props.loadSearchProductsCategory && productCategories) {
            setOptions(parseDataToOptionsObject(productCategories));
            //onInputChange(activeBar);
        }
    }, [productCategories]);
     */

    useEffect(() => {
        if (props.loadAlergens) {
            setSelectedOption(props.value)
        }
    }, [props.value])

    useEffect(() => {
        if (props.loadAlergens) {
            setOptions(props.options)
        }
    }, [props.options])


    const onInputChange = (selectedOption) => {
        const {onChange} = props;

        if (!props.loadAlergens) {
            setSelectedOption(selectedOption);
        }
        onChange(selectedOption.value);

        console.log(selectedOption);
    }

    const onInputChangeText = (selectedOption) => {
        if (props.loadBars || props.loadBarsAd) {
            console.log(selectedOption);
            if (selectedOption?.length >= 3) {
                setOptions(parseDataToOptionsObject(bars));
            } else {
                setOptions(parseDataToOptionsObject(bars ? bars.slice(0, 100) : []));
            }
        }
    }

    let className = 'select-default';
    let classNamePrefix = 'select-default';

    if (props.grey) {
        className += ' grey';
    }
    if (props.loadAlergens) {
        className += ' alergens';
    }
    if (props.alergensEdit) {
        className += ' alergens-edit';
    }
    if (props.invalidInput) {
        className += ' invalid-input'
    }

    let inputContent = () => {
        return (
            <Select
                className={className}
                classNamePrefix={classNamePrefix}
                defaultValue={selectedOption}
                onChange={onInputChange}
                onInputChange={onInputChangeText}
                options={options}
                placeholder={props.createMenu && !!createMenuActive && !!activeBar ? activeBar.external_id + ' | ' + parseMapCaretChars(activeBar.name) : props.placeholder}
            />
        );
    };

    return (
        <form>
            <label className={'input-label'}>
                <p className={'p-title-small'}>{props.label}</p>
                {inputContent()}
            </label>
        </form>
    );
}

export default Dropdown;