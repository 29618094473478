import React, {useState, useEffect} from "react";
import Input from "../js/Input";

import "../css/Modal.css";

import * as IoIcons from "react-icons/io";
import Button from "../js/Button";
import {ALERGEN_OPTIONS, COUNTRY_OPTIONS} from "../../constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {
    createFolder, updateFileName,
    updateFolder, updateFoldersListUserGroup
} from "../../store/actions/actions";
import {
    isNewObjValidCheck,
    mapCaretChars, parsePathToParent
} from "../../helpers/helpers";

import * as CgIcons from "react-icons/cg";
import FileUpload from "./FileUpload";
import {getAllUserGroups, getAllUsersAdminUserGroupsInFolder} from "../../pages/Sharepoint";
import Loader from "react-loader-spinner";
import colors from "../../constants/colors";
import ModalInfo from "./ModalInfo";

const CREATE_ACTION = {
    CREATE_FOLDER: 0,
    UPDATE_FOLDER: 1,
    UPDATE_FILE: 2
}

const NEW_FOLDER_REQUIRED_FIELDS = {
    'path': 'Ime direktorija',
    //'description': 'Opis (poljubno)',
}

const UPDATE_FILE_REQUIRED_FIELDS = {
    'path_new': 'Ime datoteke',
    //'description': 'Opis (poljubno)',
}

function ModalSharepoint(props) {
    console.log("Open modal");

    let dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);

    const [name, setName] = useState(null);
    const [description, setDesc] = useState(null);
    const [image, setImage] = useState(null);
    const [parentFolderId, setParentFolderId] = useState(null);

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [invalidInputs, setInvalidInputs] = useState([]);

    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    useEffect(() => {
        if (props.bar) {
            document.body.style = 'overflow: hidden;'

            return () => {
                document.body.style = ''
            }
        }
        if (props.folder && props.parent_folder_id) {
            setParentFolderId(props.parent_folder_id);
        }
    }, [])

    useEffect(() => {
        console.log(name)
    }, [name])

    useEffect(() => {
        if (props.update && props.folder) {
            console.log("DIR:", props.parent_folder_path, props.parent_folder_picture)
            //setName(props.parent_folder_path);
            setImage(props.parent_folder_picture);
        }
    }, [props.folder, props.parent_folder_picture, props.parent_folder_path])

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.CREATE_FOLDER:
                    confirmCreateFolder();
                    break;
                case CREATE_ACTION.UPDATE_FOLDER:
                    confirmUpdateFolder();
                    break;
                case CREATE_ACTION.UPDATE_FILE:
                    confirmUpdateFileName();
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    useEffect(() => {
        if (props.folder && props.parent_folder_id && image) {
            if (image === 'REMOVE') {
                //selectedProduct.picture = null;
                setImage(null);
            } else {
                //selectedProduct.picture = image;
            }
            //console.log("PRODUCT DATA LOADED..:", selectedProduct);

        }
    }, [image]);

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
            }
        })
    };

    const handleImageUpload = (file) => {
        console.log(file);
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log("load");
                console.log(reader.result);
                setImage(null);
                setTimeout(() => {
                    console.log(reader.result);
                    setImage(reader.result);
                }, 100)
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        base64Data = base64Data === 'REMOVE' ? null : base64Data;
        return <div className={'container-img-upload'}>
            <img className={'img-upload'} src={base64Data} alt="banner" height={'111px'}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE")
            }} className={'icon-close'}/>
        </div>
    };

    const renderFileUpload = (title) => {
        return <><p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'} style={{maxHeight: '111px'}}
                        handleFile={(file) => handleImageUpload(file)}/>
            {image && previewImage(image)}
        </>
    };

    const confirmCreateFolder = () => {
        setLoadingSpinner(true);

        let n = mapCaretChars(name);
        n = n ? n.trim() : null
        if (!n) {
            setModalValues('Manjka vrednost v polju: ' + NEW_FOLDER_REQUIRED_FIELDS.path);
            setCreateTrigger(false);
            setInvalidInputs(['path']);
            setLoadingSpinner(false);
            return;
        }
        let folder = {
            path: props.parent_folder_path ? props.parent_folder_path + '/' + n : '/' + n,
            parent_folder_id: parentFolderId ? parentFolderId : null,
            description: mapCaretChars(description),
            picture: image
        }

        // check data validity
        let response = isNewObjValidCheck(folder, NEW_FOLDER_REQUIRED_FIELDS);

        if (!response.isValid) {
            setModalValues(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            setLoadingSpinner(false);
            return;
        }

        let insertFolder = {
            path: folder.path,
            parent_folder_id: folder.parent_folder_id,
            description: folder.description ? folder.description : 'poljuben opis..',
            picture: folder.picture,
            user_groups: props.content_user_groups
        }
        console.log(insertFolder);


        // save new folder to database
        dispatch(createFolder(insertFolder, (res) => {
            console.log("Folder successfully created..", res);

            // add current folder to all admin user groups that have access to parent folder
            let usersUserGroups = props.user_user_groups;
            let parentFolderUserGroups = props.content_user_groups;
            let allUserGroups = props.all_user_groups;
            let allUserAdminGroupsInParentFolder = getAllUsersAdminUserGroupsInFolder(usersUserGroups, parentFolderUserGroups, allUserGroups);
            let folderToAdd = [];
            folderToAdd.push(res?.folder_id);

            console.log(folderToAdd, res.folder_id);
            console.log("ADD USERGROUPS TO NEW GROUP");

            /*
            let folderUserGroups = getAllUserGroups(parentFolderUserGroups);

            for (let i = 0; i < folderUserGroups.length; i++) {

                let ug = folderUserGroups[i];

                setTimeout(() => {
                    dispatch(updateFoldersListUserGroup(folderToAdd, ug, "add", (res) => {
                        console.log("User group successfully added to newly created folder..", res);
                    }));
                }, 100)
            }

             */
            /*
            for (let i = 0; i < allUserAdminGroupsInParentFolder.length; i++) {
                let ug = allUserAdminGroupsInParentFolder[i]?.user_group_id;

                setTimeout(() => {
                    dispatch(updateFoldersListUserGroup(folderToAdd, ug, "add", (res) => {
                        console.log("User group successfully added to newly created folder..", res);
                    }));
                }, 100)
            }

             */

            setTimeout(() => {
                props.actionFolderCreatedSuccess(res);

                setCreateTrigger(false);
                props.actionClose();
                setModalValues('Nova mapa uspešno ustvarjena..')

                setLoadingSpinner(false);
            }, 800)
        }, (res) => {

            setModalValues('Napaka pri ustvarjanju direktorija..');
            props.actionClose();
            setCreateTrigger(false);
            setLoadingSpinner(false);
        }));
    }

    const confirmUpdateFolder = () => {
        let n;
        let updatedPath;
        if (!name) {
            updatedPath = props.parent_folder_path;
        } else {
            n = mapCaretChars(name);
            n = n ? n.trim() : null
            updatedPath = parsePathToParent(props.parent_folder_path) + '/' + n;
        }
        /*
        if (!n) {
            setModalValues('Manjka vrednost v polju: ' + NEW_FOLDER_REQUIRED_FIELDS.path);
            setCreateTrigger(false);
            setInvalidInputs(['path']);
            return;
        }
         */
        let folder = {
            folder_id: props.parent_folder_id,
            path: updatedPath,
            parent_folder_id: parentFolderId ? parentFolderId : null,
            description: mapCaretChars(description),
            picture: image
        }

        // check data validity
        let response = isNewObjValidCheck(folder, NEW_FOLDER_REQUIRED_FIELDS);

        if (!response.isValid) {
            setModalValues(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            return;
        }

        let insertFolder = {
            folder_id: folder.folder_id,
            path: folder.path,
            parent_folder_id: folder.parent_folder_id,
            description: folder.description ? folder.description : 'poljuben opis..',
            picture: folder.picture
        }
        console.log("UPDATE FOLDER", insertFolder);

        // save new folder to database
        dispatch(updateFolder(insertFolder, (res) => {
            console.log("Folder successfully updated..", res);
            props.actionFolderUpdatedSuccess(res);

            setCreateTrigger(false);
            props.actionClose();
            setModalValues('Direktorij uspešno posodobljen..')
        }));
    }

    const confirmUpdateFileName = () => {
        let file = props.selected_file;
        let n;
        let updatedPath;
        let newName = mapCaretChars(name);

        let data = {
            file_id: file.file_id,
            path_old: file.path,
            path_new: '',
            name: ''
        }

        if (!newName) {
            // setModalValues('Manjka vrednost v polju: Ime');
            //setCreateTrigger(false);
            //setInvalidInputs(['path_new']);
            return;
        } else {
            n = mapCaretChars(name);
            n = n ? (n.trim() + file.mime_type) : null;
            updatedPath = parsePathToParent(file.path) + '/' + n;
        }
        /*
        if (!n) {
            setModalValues('Manjka vrednost v polju: ' + NEW_FOLDER_REQUIRED_FIELDS.path);
            setCreateTrigger(false);
            setInvalidInputs(['path']);
            return;
        }
         */
        data.name = n;
        data.path_new = updatedPath

        // check data validity
        let response = isNewObjValidCheck(data, UPDATE_FILE_REQUIRED_FIELDS);

        if (!response.isValid) {
            setModalValues(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            return;
        }

        console.log("UPDATE FILE DATA", data);

        dispatch(updateFileName(data, (res) => {
            console.log("File name/path successfully updated..", res);
            props.actionFileUpdatedSuccess(res);

            setCreateTrigger(false);
            props.actionClose();
            setModalValues('Datoteka uspešno preimenovana..')
        }, (err) => {
            console.warn("Napaka pri posodabljanju imena datoteke..", err)
            setModalValues(err)
        }));
    }

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };


    const renderHeader = (title, actionClose) => {
        return (
            <div className={'modal-header'}>
                <p>{title}</p>
                <div onClick={actionClose} style={{cursor: 'pointer'}}><IoIcons.IoMdClose
                    className={'icon-close'}/>
                </div>
            </div>
        );
    };

    const renderFooter = (text, actionCreate) => {
        return (
            <Button text={text} confirm action={actionCreate} style={{
                alignSelf: 'flex-end',
                marginTop: '16px'
            }}/>
        );
    };

    const renderCreateFolder = () => {
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-product modal-height'}>
                    {renderHeader('USTVARI NOV DIREKTORIJ', props.actionClose)}
                    <div className={'modal-body'} style={{overflowY: 'hidden', maxHeight: '348px'}}>
                        <Input onSubmit={() => {
                        }} onChange={setName}
                               placeholder={'Ime direktorija'}
                               style={{
                                   marginBottom: '24px',
                                   border: invalidInputs.includes('path') ? "1px solid red" : "none"
                               }}/>
                        <Input onSubmit={() => {
                        }} onChange={setDesc}
                               placeholder={'Opis (poljubno)'}
                               style={{
                                   marginBottom: '24px',
                               }}/>
                        {renderFileUpload('Slika direktorija')}
                    </div>
                    {loadingSpinner ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={36}
                            width={36}
                            style={{paddingTop: 12, paddingBottom: 8, alignSelf: 'flex-end', marginRight: '105px'}}
                        /> : renderFooter('USTVARI', () => triggerAction(CREATE_ACTION.CREATE_FOLDER))
                    }
                </div>
            </div>
        );
    };

    const renderUpdateFolder = () => {
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-product modal-height'}>
                    {renderHeader('POSODOBI TRENUTNI DIREKTORIJ', props.actionClose)}
                    <div className={'modal-body'} style={{overflowY: 'hidden', maxHeight: '348px'}}>
                        <Input onSubmit={() => {
                        }} onChange={setName}
                               placeholder={'Ime direktorija'}
                               style={{
                                   marginBottom: '24px',
                                   border: invalidInputs.includes('path') ? "1px solid red" : "none"
                               }}/>
                        {renderFileUpload('Slika direktorija')}
                    </div>
                    {renderFooter('POSODOBI', () => triggerAction(CREATE_ACTION.UPDATE_FOLDER))}
                </div>
            </div>
        );
    };

    const renderUpdateFile = () => {
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-product modal-height'}>
                    {renderHeader('POSODOBI TRENUTNO IZBRANO DATOTEKO', props.actionClose)}
                    <div className={'modal-body'} style={{overflowY: 'hidden', maxHeight: '348px'}}>
                        <Input onSubmit={() => {
                        }} onChange={setName}
                               placeholder={'Ime datoteke'}
                               style={{
                                   marginBottom: '24px',
                                   border: invalidInputs.includes('path_new') ? "1px solid red" : "none"
                               }}/>
                    </div>
                    {renderFooter('POSODOBI', () => triggerAction(CREATE_ACTION.UPDATE_FILE))}
                </div>
            </div>
        );
    };


    const renderBody = () => {
        if (props.folder && props.create) {
            return renderCreateFolder();
        } else if (props.folder && props.update) {
            return renderUpdateFolder();
        } else if (props.file && props.update) {
            return renderUpdateFile();
        } else {
            return <></>
        }
    }

    return <>
        {renderBody()}
        {renderModalInfo()}
    </>
}

export default ModalSharepoint;