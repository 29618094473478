import React, {useEffect, useState} from "react";
import Masonry from "react-responsive-masonry"


import '../css/CreateMenuAddProducts.css';

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import Button from "../components/js/Button";
import Input from "../components/js/Input";
import {
    loadComboOffers,
    loadMenus,
    setCreateMenuActive,
    setIsCreatingMenu,
    setSidebarWidth
} from "../store/actions/actions";
import {PAGES} from "../App";
import {
    isMenuValidPriceCheck,
    isSessionValid,
    mapProductsFromStoreToDefaultCategories,
    parseMapCaretChars,
    parsePostDecimalValue,
    saveToLocalStorage,
    validatePriceUpdateAction,
    validateUserInput
} from "../helpers/helpers";
import {Redirect} from "react-router";
import Modal from "../components/js/Modal";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import colors from "../constants/colors";
import ModalInfo from "../components/js/ModalInfo";

function CreateMenuAddComboOffers(props) {
    const dispatch = useDispatch();

    let user = useSelector(state => state.reducer.user);
    let token = useSelector(state => state.reducer.userToken);
    const createMenuActive = useSelector(state => state.reducer.createMenuActive);
    const activeBar = useSelector(state => state.reducer.activeBar);
    const _comboOffers = useSelector(state => state.reducer.comboOffers);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [mappedProductsByCat, setMappedProductsByCat] = useState(mapProductsFromStoreToDefaultCategories(_comboOffers));
    const [productsPool, setProductsPool] = useState(mapProductsFromStoreToDefaultCategories(_comboOffers));
    const [categoriesFinal, setCategoriesFinal] = useState(createMenuActive.categories);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [saveTimeout, setSaveTimeout] = useState(null);
    const [hiddenCategories, setHiddenCategories] = useState([]);

    const [draggedItem, setDraggedItem] = useState(null);
    const [draggedItemCategory, setDraggedItemCategory] = useState(null);

    const [modal, setModal] = useState(false);

    const [isFirstTime, setIsFirstTime] = useState(true)

    const [tabletMode, setTabletMode] = useState(true);


    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    useEffect(() => {
        dispatch(loadComboOffers(activeBar['bar_id'], () => {
        }, () => setModalValues('Napaka pri pridobivanju seznama posebnih ponudb. Prosimo poskusite znova..')));
    }, []);
    useEffect(() => {
        // console.log(_comboOffers)
        let mappedProducts = mapProductsFromStoreToDefaultCategories(_comboOffers);
        // console.log("Product pool:", mappedProducts);
        setProductsPool(mappedProducts);
        setMappedProductsByCat(mappedProducts)
    }, [_comboOffers]);

    useEffect(() => {
        dispatch(setIsCreatingMenu(4));

        if (categoriesFinal && mappedProductsByCat) {
            let parsedCategoriesFinal = parseMenuComboOffers(categoriesFinal)
            setArrayAction(categoriesFinal, parsedCategoriesFinal)

            let updated = JSON.parse(JSON.stringify(mappedProductsByCat))
            // console.log(_comboOffers);
            // console.log(parsedCategoriesFinal);

            let idxToRemove = [];
            if (updated[0]) {
                for (let k = 0; k < updated[0].products.length; k++) {
                    let comboOffer = updated[0].products[k];
                    // console.log(comboOffer);

                    for (let i = 0; i < parsedCategoriesFinal.length; i++) {
                        for (let j = 0; j < parsedCategoriesFinal[i].products.length; j++) {
                            if (comboOffer.name === parsedCategoriesFinal[i].products[j].name) {
                                // console.log(k);
                                idxToRemove.push(k);
                            }
                        }
                    }
                }

                if (parsedCategoriesFinal.length > 0) {
                    for (let i = idxToRemove.length - 1; i >= 0; i--) {
                        updated[0].products.splice(idxToRemove[i], 1);
                    }
                    ;
                    // console.log("[COMBO] REFRESH POOL", JSON.stringify(updated, null, 4));
                    setArrayAction(productsPool, updated)
                }
            }
        }
    }, [/*categoriesFinal, */mappedProductsByCat]);

    useEffect(() => {
        if (!isFirstTime) {
            setLocalCreateMenuActive();
            saveToLocalStorage(createMenuActive)
        }
        if (isFirstTime) setIsFirstTime(false)
    }, [isFirstTime, categoriesFinal, mappedProductsByCat, _comboOffers]);

    const setLocalCreateMenuActive = () => {
        createMenuActive.categories = removeComboOffersFromMenu(createMenuActive.categories);
        addComboOffersToMenu(categoriesFinal, createMenuActive.categories);

        dispatch(setCreateMenuActive(createMenuActive));
    }

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
            }
        })
    };

    const onConfirmCurrentChanges = () => {
        const isUserInputValid = () => {
            if (!categoriesFinal) {
                return false;
            }
            /*
            for (let i = 0; i < categoriesFinal.length; i++) {
                if (categoriesFinal[i].products.length > 0) {
                    return true;
                }
            }
            return false;

             */
        };

        /*
        if (!isUserInputValid()) {
            return false;
        }

         */

        const isMenuValid = isMenuValidPriceCheck(categoriesFinal);
        if (!isMenuValid) {
            alert("Za nadaljevanje najprej vnesite željene cene vsem izdelkom..");
            return false;
        }

        setLocalCreateMenuActive()
        return true;
    };

    const initSelectedCategories = () => {
        let init = JSON.parse(JSON.stringify([...createMenuActive.categories]));
        for (let i = 0; i < init.length; i++) {
            init[i].products = [];
        }

        return [...init];
    };

    const parseMenuComboOffers = (categories) => {
        let updated = initSelectedCategories();

        for (let i = 0; i < categories.length; i++) {
            for (let j = categories[i].products.length - 1; j >= 0; j--) {
                if (!categories[i].products[j].combo_offer) {
                    continue;
                }
                updated[i].products.push(categories[i].products[j]);
            }
        }
        // console.log("PARSE COMBO", [...updated]);
        return [...updated]
    };

    const removeComboOffersFromMenu = (categories) => {

        /*
        let filtered;
        for (let i = 0; i < categoriesFinal.length; i++) {
            for (let j = categoriesFinal[i].products.length - 1; j >= 0; j--) {
                if (categoriesFinal[i].products[j]) {
                    filtered = removeProduct(categoriesFinal[i].products[j], categories);
                }
            }
        }

         */

        let filtered;
        filtered = categories.map((category) => {
            return {...category, products: category.products.filter((product) => !product.combo_offer)}
        })

        // console.log("COMBO OFFERS REMOVED", JSON.stringify(filtered, null, 4));
        return filtered;
    };

    const addComboOffersToMenu = (comboOffers, menu) => {
        if (!comboOffers || comboOffers.length === 0) {
            return;
        }
        for (let i = 0; i < menu.length; i++) {
            if (comboOffers[i].products && comboOffers[i].products.length > 0) {
                for (let j = 0; j < comboOffers[i].products.length; j++) {
                    menu[i].products.push(comboOffers[i].products[j]);
                }
            }
        }
        // console.log("ADDED BACK TO MENU", JSON.stringify(menu, null, 4));
    };


    const handleDragStart = (isReorderingProducts, product, category) => {
        // console.log("drag started..");

        if (isReorderingProducts) {
            // console.log(product);
            setDraggedItem(product);
            setDraggedItemCategory(category);
        }
    };

    const handleDragEnd = () => {
        // console.log("drag ended..");
    };

    const handleDragLeave = event => {
        event.stopPropagation()
        event.preventDefault()

        //console.log("drag left drop zone!");
    };
    const handleDragOver = event => {
        event.stopPropagation()
        event.preventDefault()

        //console.log("drag over drop zone!");
    };
    const handleDragEnter = event => {
        event.stopPropagation()
        event.preventDefault()

        //console.log("drag entered drop zone!");
    };

    const handleDropCategorySort = (event, idxDropZone, idxCatDropZone) => {
        if (!draggedItem || !draggedItemCategory) {
            // console.log("First you need to select a product to drop it on menu..");
            return;
        }

        event?.stopPropagation()
        event?.preventDefault()
        // console.log("drag dropped on name sort zone!");

        if (selectedProducts.length !== 0) {
            console.warn("Can not sort items on menu when items on right are selected! Deselect all the products on the left by double clicking on one of them..", selectedProducts);
            return;
        }

        let idx = -1;
        for (let i = 0; i < categoriesFinal.length; i++) {
            if (categoriesFinal[i].category.name === draggedItemCategory.name) {
                idx = i;
                break;
            }
        }
        let idxProduct = -1;
        for (let j = 0; j < categoriesFinal[idx].products.length; j++) {
            if (categoriesFinal[idx].products[j].name === draggedItem.name) {
                // console.log(categoriesFinal[idx].products[j].name);
                idxProduct = j;
                break;
            }
        }
        if (idx === idxCatDropZone && idxProduct === idxDropZone) {
            // console.log("Dropping on itself..")
            return;
        }

        // console.log("CATEGORIES FINAL", categoriesFinal);

        let updated = JSON.parse(JSON.stringify([...categoriesFinal]));

        // console.log(idx);
        // console.log(idxProduct);
        updated[idx].products.splice(idxProduct, 1);

        updated[idxCatDropZone].products.splice(idxDropZone, 0, draggedItem);

        // console.log("UPDATED", updated);
        setArrayAction(categoriesFinal, [...updated]);

        //setDraggedItem(null);
    };

    const handleDropOnMenu = (event, category, idxCatDropZone) => {
        event?.stopPropagation()
        event?.preventDefault()
        // console.log("drag dropped over name on menu or product to sort!");


        if (!selectedProducts || selectedProducts.length === 0) {
            // console.log("First you need to select a product to drop it on menu..");
            return;
        }

        if (selectedProducts && selectedProducts.length !== 0) {
            let finalUpdated = JSON.parse(JSON.stringify([...categoriesFinal]));
            for (let i = 0; i < finalUpdated.length; i++) {
                if (finalUpdated[i].category.name === category.name) {
                    for (let j = 0; j < selectedProducts.length; j++) {
                        /*
                                                let alreadyExists = false;
                                                for (let k = 0; k < finalUpdated.length; k++) {
                                                    for (let l = 0; l < finalUpdated[k].products; l++) {
                                                        console.log(selectedProducts[j]);
                                                        if (finalUpdated[i].products.name === selectedProducts[j].name) {
                                                            alreadyExists = true;
                                                            break;
                                                        }
                                                    }
                                                }
                                                if (!alreadyExists) {
                                                    finalUpdated[i].products.push(selectedProducts[j]);
                                                }
                         */
                        finalUpdated[i].products.push(selectedProducts[j]);
                    }
                    break;
                }
            }
            setArrayAction(categoriesFinal, [...finalUpdated])
            removeFromProductsPool(null, null, finalUpdated);
            setArrayAction(selectedProducts, [])
        } else {
            handleDropCategorySort(event, 0, idxCatDropZone);
        }
    };

    const setArrayAction = (array, content) => {
        switch (array) {
            case productsPool:
                setProductsPool(content);
                break;
            case selectedProducts:
                setSelectedProducts(content);
                break;
            case categoriesFinal:
                setCategoriesFinal(content);
                break;
        }
    };

    const clearSelection = (array) => {
        setArrayAction(array, []);
    };

    const isProductSelected = (selection, array) => {
        if (!array) {
            return false;
        }
        for (let p = 0; p < array.length; p++) {
            if (array[p].name === selection.name) {
                return true;
            }
        }
        return false;
    };

    const isCategoryFinalEmpty = (c) => {
        return categoriesFinal[c] && categoriesFinal[c].products.length <= 0;
    };

    const selectProduct = (obj, array) => {
        if (!isProductSelected(obj, array)) {
            setArrayAction(array, [...array, obj])
            // console.log("PRODUCT", obj.name, 'selected');
        } else {
            // console.log("PRODUCT", obj.name, 'remove');
            removeProduct(obj, array);
        }
    };

    const removeProduct = (obj, array) => {
        let filtered;
        if (array === selectedProducts) {
            filtered = array.filter(product => product.name !== obj.name);
        } else {
            filtered = array.map((category) => {
                return {...category, products: category.products.filter((product) => product.name !== obj.name)}
            })
        }
        // console.log(array, filtered);
        setArrayAction(array, filtered)

        // console.log("FILTERED", filtered);
        return [...filtered];
    };


    const handleDropOnProductsPool = event => {
        event?.stopPropagation()
        event?.preventDefault()
        // console.log("drag dropped on name pool zone!");

        if (!draggedItem) {
            return;
        }

        let updatedFinal = removeFromCategoriesFinal(draggedItemCategory, draggedItem);
        removeFromProductsPool(draggedItemCategory, draggedItem, updatedFinal);
    };

    const removeFromCategoriesFinal = (category, obj) => {
        let finalUpdated = [];

        // console.log('CAT/OBJ', category, obj);
        finalUpdated = removeProduct(obj, categoriesFinal);

        // console.log("UPDATED ", finalUpdated);
        return finalUpdated;
    };

    const removeFromProductsPool = (category, obj, finalUpdated) => {
        let filtered = JSON.parse(JSON.stringify([...mappedProductsByCat]));
        for (let i = 0; i < finalUpdated.length; i++) {
            for (let j = 0; j < finalUpdated[i].products.length; j++) {
                filtered = filtered.map((category) => {
                    return {
                        ...category,
                        products: category.products.filter((product) => product.name !== finalUpdated[i].products[j].name)
                    }
                })
            }
        }
        // console.log("FILTERED POOL", filtered);
        setArrayAction(productsPool, [...filtered]);
    };

    const updateProductPriceOnMenu = (categoryIndex, productIndex, product, price, categories) => {
        if (saveTimeout) {
            clearTimeout(saveTimeout)
        }
        setSaveTimeout(setTimeout(() => {
            let menu = [...categories]
            menu[categoryIndex].products[productIndex].initial_price = price;
            setCategoriesFinal(menu);
        }, 500))
        // console.log("MENU UPDATED:", menu);
    }

    const renderAllProducts = () => {
        const categoryItemName = 'create-menu-product-pool-item combo-offer-pool-item';
        return (
            productsPool.map((category, c) => (
                category.products.map((product, p) => (
                    <div key={`${p}_${product.name}`}
                         className={isProductSelected(product, selectedProducts) ? categoryItemName + ' selected' : categoryItemName}
                         onClick={() => selectProduct(product, selectedProducts)}
                         onDoubleClick={() => clearSelection(selectedProducts)}
                         draggable="true"
                         onDragStart={() => handleDragStart(false)}
                         onDragEnd={handleDragEnd}>

                        <p className={'p-title-product'}>{parseMapCaretChars(product.name)}</p>
                        <p className={'p-title-product desc'}>{parseMapCaretChars(product.packaging)}</p>
                        <div className={'container-icon-selected'}>
                            <AiIcons.AiFillCheckCircle/>
                        </div>
                    </div>
                ))
            ))
        );
    };
    /* <!-- img src={`data:image/jpeg;base64,${product.image}`} alt={'combo offer'}/ --> -> from combo offer a few lines above */

    const toggleCategory = (categoryName) => {
        let hiddenClone = [...hiddenCategories]
        if (hiddenClone.includes(categoryName)) {
            const index = hiddenClone.indexOf(categoryName);
            if (index > -1) {
                hiddenClone.splice(index, 1);
            }
        } else {
            hiddenClone.push(categoryName)
        }
        setHiddenCategories(hiddenClone)
    }

    let categoryClassName = 'create-menu-category-item combo-offer';
    const renderMenu = () => {
        return (
            categoriesFinal.map((category, c) => (
                <div key={`${c}_${category}`}
                     className={isCategoryFinalEmpty(c) ? categoryClassName : categoryClassName + ' active'}
                     draggable="false"
                     onClick={(event) => {
                         if (tabletMode) {
                             if (event.target === event.currentTarget) {
                                 event.stopPropagation()
                                 console.log("stopped propagation");
                             }
                         }
                     }}
                     onDragOver={handleDragOver}
                     onDragEnter={handleDragEnter}
                     onDragLeave={handleDragLeave}
                     onDrop={(event) => {
                         handleDropOnMenu(event, category.category, c)
                     }}>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <p className={'p-title-product category'}
                       style={{
                           margin: '20px 0',
                           padding: 0,
                           paddingLeft: '35px'
                       }}
                       onClick={() => {
                           toggleCategory(category.category.name)
                       }}
                    >{parseMapCaretChars(category.category.name)}</p>


                        {selectedProducts?.length > 0 && tabletMode &&
                        <div onClick={(event) => {
                            if (tabletMode) {
                                if (!draggedItem) {
                                    handleDropOnMenu(event, category.category, c)
                                    setDraggedItem(null);
                                    setDraggedItemCategory(null);
                                }
                            }
                        }}>
                            <BsIcons.BsPlusCircle
                                style={{
                                    position: 'relative',
                                    width: "25px",
                                    height: '25px',
                                    cursor: 'pointer',
                                    marginRight: '20px',
                                    fill: colors.primary
                                }}/>
                        </div>
                        }
                        {draggedItem && tabletMode &&
                        <div onClick={(event) => {
                            if (tabletMode) {
                                if (draggedItem) {
                                    handleDropCategorySort(event, 0, c)
                                    setDraggedItem(null);
                                    setDraggedItemCategory(null);
                                }
                            }
                        }}>
                            <BsIcons.BsPlusCircle
                                style={{
                                    position: 'relative',
                                    width: "25px",
                                    height: '25px',
                                    cursor: 'pointer',
                                    marginRight: '20px',
                                    fill: colors.primary
                                }}/>
                        </div>
                        }
                    </div>

                    <div className={'create-menu-category-item-content'}
                         style={{display: hiddenCategories.includes(category.category.name) ? 'none' : 'block'}}>
                        {category.products.map((product, p) => (
                            <div key={`${p}_${product}`}
                                 style={{
                                     border: draggedItem?.product_id === product.product_id ? '3px solid #008941' : 'none',
                                     borderRadius: '20px'
                                 }}
                                 className={'create-menu-category-item-product'}
                                 draggable={!tabletMode}
                                 onClick={(event) => {
                                     if (tabletMode) {
                                         if (event.target === event.currentTarget) {
                                             event.stopPropagation()
                                             console.log("stopped propagation");

                                             if (!draggedItem) {
                                                 handleDragStart(true, product, category.category)
                                             } else {
                                                 handleDropCategorySort(event, p, c)
                                                 setDraggedItem(null);
                                                 setDraggedItemCategory(null);
                                             }
                                         }

                                     }
                                 }}
                                 onDragStart={() => handleDragStart(true, product, category.category)}
                                 onDragEnd={handleDragEnd}
                                 onDrop={(event) => handleDropCategorySort(event, p, c)}>


                                <div className={'create-menu-category-item-product-left'}>
                                    <p className={'p-title-product black'}>{parseMapCaretChars(product.name)}</p>
                                    <div className={'create-menu-category-item-product-left-desc'}>
                                        <p className={'p-desc-product'}>{parseMapCaretChars(product.packaging)}</p>
                                        <p className={'p-desc-product'}>{product.unit}</p>
                                    </div>
                                </div>
                                <div className={'create-menu-category-item-product-right'}>
                                    <Input
                                        onSubmit={() => {
                                        }}
                                        onChange={(value) => {
                                            // console.log('Triggered because this input lost focus', e.target.value);
                                            // validatePriceUpdateAction(value, () => {
                                            updateProductPriceOnMenu(c, p, product, value, categoriesFinal)
                                            // })
                                        }}
                                        placeholder={!product.initial_price && product.initial_price === 0 ? 'Vpiši ceno (v EUR)' : product.initial_price}
                                        style={{}}
                                        onBlur={(e) => {
                                            // console.log('Triggered because this input lost focus', e.target.value);
                                            validatePriceUpdateAction(e.target.value, () => {
                                            //     updateProductPriceOnMenu(c, p, product, parsePostDecimalValue(e.target.value), categoriesFinal)
                                            })
                                        }}/>
                                    <div className={'container-icon-selected'}>
                                        {tabletMode ?
                                            !draggedItem ? <div onClick={() => {
                                                    if (tabletMode) {
                                                        removeFromCategoriesFinal(category, product);
                                                        let updatedFinal = removeFromCategoriesFinal(category, product);
                                                        removeFromProductsPool(category, product, updatedFinal);
                                                        setDraggedItem(null);
                                                        setDraggedItemCategory(null);
                                                    }
                                                }}><BsIcons.BsTrash
                                                    style={{cursor: 'pointer', width: "25px", height: '25px'}}/></div> :
                                                <BsIcons.BsArrowsMove style={{width: "25px", height: '25px'}}/> :
                                            <BsIcons.BsArrowsMove style={{width: "25px", height: '25px'}}/>}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            ))
        );
    };

    const renderModal = () => {
        return (
            modal && <Modal comboOffer actionClose={() => {
                // console.log('display modal, false');
                setModal(false)
            }}/>
        );
    };

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative editMenuAddProducts className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <Header left={`${sidebarWidth + 50}px`} title={'DODAJ POSEBNO PONUDBO'} step={'4. KORAK'}/>
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <div onClick={() => setTabletMode(!tabletMode)} className={'container-icon-selected'}
                     style={{paddingRight: 40}}>
                    <BsIcons.BsTablet size={20} fill={tabletMode ? colors.primary : ''} style={{cursor: 'pointer'}}/>
                </div>
                <div className={'container-column-2'}>
                    <div className={'column-2 selected-product-drop-zone'}
                         onDragOver={handleDragOver} onDragEnter={handleDragEnter}
                         onDragLeave={handleDragLeave} onDrop={handleDropOnProductsPool}>
                        <p className={'p-title-small'}>{'Izbiraj med prednastavljenimi combo ponudbami.'}</p>
                        <Masonry columnsCount={1} className={'masonry-create-menu-categories-pool'}
                                 gutter="8px">
                            <Button className={'button-add-category'} text={'USTVARI POSEBNO PONUDBO'}
                                    action={() => {
                                        setModal(true)
                                    }} style={{width: '50%', marginBottom: '12px'}}/>
                            {renderAllProducts()}
                        </Masonry>
                    </div>
                    <div className={'column-2 drop-zone-product-pool'}>
                        <p className={'p-title-small'}>{'Izbrane ponudbe potegnite v željene kategorije.'}</p>
                        {renderMenu()}
                    </div>
                </div>
            </div>
            <Footer back={'NAZAJ'}
                    left={`${sidebarWidth}px`}
                    urlBack={PAGES.CREATE_MENU_ADD_PRODUCTS}
                    next={'POTRDI IN NADALJUJ'}
                    urlNext={PAGES.CREATE_MENU_PREVIEW}
                    onAction={() => {
                        return onConfirmCurrentChanges()
                    }}
                    progress={4}/>
        </Page>
            {renderModal()}
            {renderModalInfo()}
        </div>

}

export default CreateMenuAddComboOffers;

