import React, {useState, useEffect, createRef, useCallback, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    createUserGroup,
    loadAllUserGroups,
    loadUserGroups,
    loadUsers,
    loadUsersSharepoint,
    removeUserGroup,
    setSidebarWidth,
    updateFileUserGroup,
    updateFoldersListUserGroup,
    updateUsersListUserGroup,
    updateUserGroup,
    updateUsersUserGroup, loadAllFolders, loadAllFiles, loadUser, loadUserByUserId
} from "../store/actions/actions";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {MIME_OPTIONS, USER_GROUP_TYPE, USER_ROLES} from "../constants/enums";
import Header from "../components/js/Header";
import "../css/Sharepoint.css"
import * as BiIcons from "react-icons/bi";
import {PAGES} from "../App";
import DropdownFilter from "../components/js/DropdownFilter";
import Modal from "../components/js/Modal";
import ModalSharepoint from "../components/js/ModalSharepoint";
import {
    findItem,
    isSessionValid,
    isUserBarRepresentative, isUserOwner,
    parseMapCaretChars,
    parseSelectedObjectValues
} from "../helpers/helpers";
import ModalSharepointUserGroup from "../components/js/ModalSharepointUserGroup";
import {Redirect} from "react-router";

import background from "../assets/img/blur-background.png"
import ModalInfo from "../components/js/ModalInfo";

function SharepointUserGroups(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    const user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedUserGroup, setSelectedUserGroup] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [allUserGroups, setAllUserGroups] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const [displayUserGroupModal, setDisplayUserGroupModal] = useState(false);
    const [displayUpdateUserGroupModal, setDisplayUpdateUserGroupModal] = useState(false);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    useEffect(() => {
        if (user) {
            onLoadUserGroups();
            onLoadUsers();
        }
    }, [user])

    useEffect(() => {
        console.log("filter", selectedFilter);
        if (!selectedFilter?.user_group_id) {
            onLoadUsers();
            setSelectedFilter(null)
        }

    }, [selectedFilter]);



    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
            }
        })
    };


    const onLoadUserGroups = () => {
        dispatch(loadAllUserGroups(user.user_id, (res) => {
            console.log("User groups: ", res);
            setAllUserGroups(res);
        }, (err) => {
            console.warn("Error obtaining user groups..", err)
        }));
    }

    const onLoadUser = () => {
        dispatch(loadUserByUserId(user.user_id, (res) => {
            console.log("User: ", res);
            //setAllUserGroups(res);
        }, (err) => {
            console.warn("Error obtaining user..", err)
        }));
    }

    const onLoadUsers = () => {
        dispatch(loadUsersSharepoint(user.user_id, (res) => {
            console.log("Users: ", res);
            setAllUsers(res);
        }, (err) => {
            console.warn("Error obtaining users..", err)
        }));
    }



    const onDeleteUserGroup = (selected) => {
        if (!selected) {
            return;
        }
        dispatch(removeUserGroup(selected.user_group_id, (res) => {
            console.log("User group successfully deleted..", res);
            setSelectedUserGroup(null);
            onLoadUserGroups();

            setModalValues(`Uporabniška skupina '${selected.name}' uspešno odstranjena!`)
        }, (err) => {
            setModalValues(`Napaka pri odstranjevanju uporabniške skupine '${selected.name}'.., ${err.error}`)
            console.warn("Error deleting user group..", err)
        }));
    };



    const onUpdateUsersUserGroup = (operation) => {

        let parsed = parseSelectedObjectValues(selectedUsers, 'user_id');
        if (!parsed) {
            setModalValues(`Napaka pri ${operation === 'add' ? 'dodajanju' : 'odstranjevanju'} uporabniške skupine '${selectedUserGroup?.name}' izbranim uporabnikom. Seznam izbranih uporabnikov je prazen!`)
            return;
        }
        dispatch(updateUsersListUserGroup(parsed, selectedUserGroup?.user_group_id, operation, (res) => {
            console.log("User group successfully added to list of objects..", res);

            onLoadUserGroups();
            onLoadUsers();
            onLoadUser()
            setSelectedUsers([]);

            setModalValues(`Uporabniška skupina '${selectedUserGroup?.name}' uspešno ${operation === 'add' ? 'dodana' : 'odstranjena'} ${selectedUsers.length} uporabnikom!`)
        }, (err) => {
            console.warn("Error adding user group to objects..", err)

            onLoadUserGroups();
            onLoadUsers();
            onLoadUser()
            setSelectedUsers([]);

            setModalValues(`Napaka pri ${operation === 'add' ? 'dodajanju' : 'odstranjevanju'} uporabniške skupine '${selectedUserGroup?.name}' izbranim uporabnikom.., ${err.error}`)
        }));
    }

    let clientSize = {x: document.documentElement.clientWidth, y: document.documentElement.clientHeight}
    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 350)
    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    const renderModal = () => {
        //console.log(folder);
        return (
            displayUserGroupModal &&
            <ModalSharepointUserGroup usergroup selected_user_group={selectedUserGroup} actionSuccess={() => {
                onLoadUserGroups();
                onLoadUsers();
            }} actionClose={() => {
                console.log('display modal, false');
                setDisplayUserGroupModal(false)
            }}/>
        );
    };

    const renderUpdateModal = () => {
        //console.log(folder);
        return (
            displayUpdateUserGroupModal &&
            <ModalSharepointUserGroup usergroupUpdate selected_user_group={selectedUserGroup} actionSuccess={() => {
                onLoadUserGroups();
                onLoadUsers();
            }} actionClose={() => {
                console.log('display modal, false');
                setDisplayUpdateUserGroupModal(false)
            }}/>
        );
    };

    const renderToolbar = () => {
        let className = 'sharepoint toolbar-button sharepoint-button';
        if (!selectedUserGroup) {
            className += ' disabled';
        }

        return (
            <div className={'sharepoint toolbar'}>
                <div className={'sharepoint container-column-2'}>
                    <div className={'column-2'} style={{paddingRight: '0'}}>
                        <div className={'container-row-2'}>
                            <div className={'row-2'}>
                                <div className={'toolbar-actions'}>
                                    <div className={'sharepoint toolbar-button sharepoint-button'}
                                         onClick={() => setDisplayUserGroupModal(true)}>
                                        <BiIcons.BiUserPlus className={'sharepoint icon toolbar-icon'}/>
                                    </div>
                                    <div className={'sharepoint toolbar-right'}>
                                        <div className={className}
                                             onClick={() => onUpdateUsersUserGroup('add')}>
                                            <BiIcons.BiListPlus className={'sharepoint icon toolbar-icon'}/>
                                        </div>
                                        <div className={className}
                                             onClick={() => onUpdateUsersUserGroup('remove')}>
                                            <BiIcons.BiListMinus className={'sharepoint icon toolbar-icon'}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={'column-2'} style={{paddingRight: '0px'}}>
                        <div className={'container-row-2'}>
                            <div className={'row-2'}>
                                <DropdownFilter onChange={setSelectedUserGroup}
                                                style={{marginTop: '-10px'}}
                                                options={allUserGroups}
                                                placeholder={'Uporabniške skupine'}/>

                                <div className={className + ' button-delete'}
                                     onClick={() => onDeleteUserGroup(selectedUserGroup)}>
                                    <BiIcons.BiTrash className={'sharepoint icon remove toolbar-icon'}/>
                                </div>
                                <div className={className}
                                     style={{position: 'relative', right: '-14px'}}
                                     onClick={() => setDisplayUpdateUserGroupModal(true)}>
                                    <BiIcons.BiEdit className={'sharepoint icon toolbar-icon'}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={'sharepoint container-column-2'} style={{padding: 0}}>

                    <div className={'column-2'}>

                    </div>
                    <div className={'column-2'} style={{paddingRight: '47px'}}>
                        <DropdownFilter onChange={setSelectedFilter}
                                        options={[{user_group_id: null, name: "VSE SKUPINE", type: USER_GROUP_TYPE.ALL}, ...allUserGroups]}
                                        placeholder={'Filtriraj'}/>
                    </div>
                </div>

            </div>
        );
    }
    useEffect(() => {
        console.log(selectedUsers);
    }, [selectedUsers]);


    function Item(props) {
        const toggleCheckbox = (user) => {
            let updated = [...selectedUsers];
            let removed = false;
            for (let i = 0; i < selectedUsers.length; i++) {
                if (selectedUsers[i].user_id === user.user_id) {
                    // remove
                    updated.splice(i, 1);
                    removed = true;
                    break;
                }
            }
            // else add
            if (!removed) {
                updated.push(user);
            }
            setSelectedUsers(updated);
        }


        const parseUserGroupIds = (user_group_ids, userGroups) => {
            if (!userGroups || !user_group_ids || user_group_ids === '') {
                return [];
            }
            user_group_ids = user_group_ids.split(',');
            console.log(user_group_ids);
            let ug = [];
            for (let i = 0; i < user_group_ids.length; i++) {
                for (let j = 0; j < userGroups.length; j++) {
                    if (user_group_ids[i] === userGroups[j].user_group_id) {
                        ug.push(userGroups[j].name);
                    }
                }
            }
            ug = ug.join(', ');
            console.log(ug);
            return ug;
        }

        // console.log(props.value);
        const item = props.value;
        const selected = findItem('user_id', item.user_id, selectedUsers)
        if (isUserBarRepresentative(item) || isUserOwner(item)) {
            return null;
        }
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`, display: !selectedFilter || item?.user_groups && item?.user_groups.includes(selectedFilter.user_group_id) ? 'flex' : 'none'}}>
                <div className={'row-box'} onClick={() => toggleCheckbox(item)}>
                    <div className={'row-box-check'} style={{opacity: selected ? 1 : 0}}>
                    </div>
                </div>
                <div className={'row-bar'}>{item.external_id}</div>
                <div className={'row-date'}>{parseMapCaretChars(item.full_name)}</div>
                <div className={'row-date'}>{item.username}</div>
                <div className={'row-dimension'}>{parseUserGroupIds(item.user_groups, allUserGroups)}</div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <div className={'sharepoint-users row-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-bar'}>External ID</div>
                    <div className={'row-date'}>Polno ime</div>
                    <div className={'row-date'}>E-mail</div>
                    <div className={'row-dimension'}>Uporabniške skupine</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'sharepoint-users item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }


    return !isSessionValid(token) ? <Redirect to={PAGES.SHAREPOINT_LOGIN} push={false}/> :
        token && <div ref={ref}>
            <div className={'background-blurred'} style={{backgroundImage: `url(${background})`}}/>
            <Page relative sharepoint className={'sharepoint main'} style={{height: `${mainHeight}px`}} onToggleSidebar={(shown) => {
                if (shown) {
                    dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                } else {
                    dispatch(setSidebarWidth(0))
                }
            }}>
                <div className={'body'} style={{marginTop: '100px', paddingLeft: `${sidebarWidth}px`}}>
                    <Header sharepoint left={`${sidebarWidth + 50}px`}
                            title={`Uporabniške skupine`}/>
                    {renderToolbar()}
                    {renderTable(allUsers)}
                </div>
            </Page>
            {renderModal()}
            {renderUpdateModal()}
            {renderModalInfo()}
        </div>
}

export default SharepointUserGroups;