import React, {useState, useEffect} from "react";

import '../css/Home.css';

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import ButtonGroupSelection, {LEADING_BRAND, LEADING_BRAND_STRING} from "../components/js/ButtonGroupSelection";
import * as CgIcons from "react-icons/cg";
import FileUpload from "../components/js/FileUpload";
import Dropdown from "../components/js/Dropdown";
import DateInput from "../components/js/DateInput";
import {
    loadBars,
    loadBarsForBarRepresentative,
    loadBarsForOwner, loadProducts,
    setActiveBar,
    setCreateMenuActive,
    setIsCreatingMenu,
    setSidebarWidth,
    updateBar
} from "../store/actions/actions";
import {PAGES} from "../App";
import {
    getLocalStorageObject,
    getUniqueLocalId,
    isSessionValid,
    isUserBarRepresentative,
    isUserCaretaker,
    isUserOwner,
    parseDateForDateInput, parseUserRole,
    saveToLocalStorage,
    setLocalStorageObject,
    validateUserInput
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {LOCAL_STORAGE_KEYS, USER_ROLES} from "../constants/enums";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

function CreateMenu(props) {
    const dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let createMenuActive = useSelector(state => state.reducer.createMenuActive);
    let bars = useSelector(state => state.reducer.bars);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [image, setImage] = useState(activeBar ? activeBar.logo : null);
    const [barChosen, setBarChosen] = useState(activeBar);
    const [leadingBrand, setLeadingBrand] = useState(!createMenuActive ? (activeBar ? activeBar.main_brand : null) : createMenuActive.leading_brand);
    const [dateValidFrom, setDateValidFrom] = useState(createMenuActive?.hasOwnProperty('dateValidFrom') ? createMenuActive.dateValidFrom : createMenuActive?.valid_from);

    const [isBeerAndCiderMenu, setIsBeerAndCiderMenu] = useState(false);

    console.log("IS BEER AND CIDER:", isBeerAndCiderMenu);
    console.log("LEADING BRAND ACTIVE:", leadingBrand);

    useEffect(() => {
        dispatch(setIsCreatingMenu(0));

        setIsBeerAndCiderMenu(!createMenuActive ? !!window.beer_cider_menu : createMenuActive.beer_cider_menu);
        console.log(createMenuActive);
    }, []);

    useEffect(() => {
        setIsBeerAndCiderMenu(!createMenuActive ? !!window.beer_cider_menu : createMenuActive.beer_cider_menu);
    }, [window.beer_cider_menu]);

    useEffect(() => {
        if (isBeerAndCiderMenu) {
            setLeadingBrand(LEADING_BRAND.BEER_CIDER_1);
        }
    }, [isBeerAndCiderMenu]);

    useEffect(() => {
        if (!!createMenuActive) {
            setDateValidFrom(createMenuActive?.hasOwnProperty('dateValidFrom') ? createMenuActive.dateValidFrom : createMenuActive?.valid_from);
        }
    }, [createMenuActive]);

    useEffect(() => {
        if (bars) {
            console.log("Bars loaded");
        }
    }, [bars]);

    useEffect(() => {
        /*
        let done = false;
        const asyncEffect = async () => {
            if (user) {
                await dispatch(loadBars(user.user_id, () => {
                    console.log("Bars successfully loaded..");
                    done = true;
                }, () => {
                    console.warn("Error loading bars..")
                }));
            }
        }

                 */

        let done = false;
        const asyncEffect = async () => {
            if (user) {
                if (isUserOwner(user)) {
                    await dispatch(loadBarsForOwner(user.user_id, () => {
                        console.log("Bars successfully loaded..");
                        done = true;
                    }, () => {
                        console.warn("Error loading bars..")
                    }));
                } else if (isUserBarRepresentative(user)) {
                    await dispatch(loadBarsForBarRepresentative(user.user_id, () => {
                        console.log("Bars successfully loaded..");
                        done = true;
                    }, () => {
                        console.warn("Error loading bars..")
                    }));

                } else if (isUserCaretaker(user)) {
                    await dispatch(loadBars(user.user_id, () => {
                        console.log("Bars successfully loaded..");
                        done = true;
                    }, () => {
                        console.warn("Error loading bars..")
                    }));
                }
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));

    }, [user]);

    useEffect(() => {
        if (barChosen) {
            dispatch(setActiveBar(barChosen));

            dispatch(loadProducts(barChosen?.bar_id, user, (products) => {
                console.log("Products successfully loaded..");
            }, () => {
                console.warn("Error loading products..")
            }), true);
        }
    }, [barChosen]);

    useEffect(() => {
        if (activeBar && image) {
            if (image === 'REMOVE') {
                activeBar.logo = null;
                setImage(null);
            } else {
                activeBar.logo = image;
            }
            console.log("PRODUCT DATA LOADED..:", activeBar);
            updateBarDispatch(activeBar);
        }
    }, [image]);

    const updateBarDispatch = (bar) => {
        dispatch(updateBar(bar, () => {
            console.log("Bar successfully updated!");
        }, () => {
            console.warn("Error updating bar..")
        }));
    }

    useEffect(() => {
        if (activeBar) {
            console.log("BAR DATA LOADED..:", activeBar);
            setImage(activeBar.logo);
        }
    }, [activeBar]);



    useEffect(() => {
        if (activeBar || (leadingBrand !== undefined && leadingBrand !== null) || dateValidFrom) {
            setLocalCreateMenuActive()
            saveToLocalStorage(createMenuActive)
        }
    }, [activeBar, leadingBrand, dateValidFrom, isBeerAndCiderMenu])

    const onConfirmCurrentChanges = () => {
        console.log(dateValidFrom);
        const isUserInputValid = validateUserInput([activeBar, leadingBrand, dateValidFrom]);

        if (!isUserInputValid) {
            alert("Za nadaljevanje vnesite vse zahtevane podatke..");
            return false;
        }

        setLocalCreateMenuActive()
        return true;
    };

    const setLocalCreateMenuActive = () => {
        if (!createMenuActive) {
            createMenuActive = {
                bar: null,
                logo: null,
                leading_brand: null,
                main_brand: null,
                menuBackground: null,
                dateValidFrom: null,
                categories: [],
                menuType: null,
                printQuantities: {
                    qr: null, a4: null, a5: null, a3: null, b2: null
                },
                comment: null,
                beer_cider_menu: null,
                localId: getUniqueLocalId()
            }
        }
        if (!createMenuActive.localId) createMenuActive.localId = getUniqueLocalId()
        createMenuActive.bar = activeBar;
        createMenuActive.createdDate = !createMenuActive.createdDate ? Date.now() : createMenuActive.createdDate;
        createMenuActive.leading_brand = leadingBrand;
        createMenuActive.main_brand = (createMenuActive.main_brand === null || createMenuActive.main_brand === undefined) ? createMenuActive.menuBackground : createMenuActive.main_brand;
        console.log("CREATE MENU ACTIVE main_brand", createMenuActive?.main_brand);

        createMenuActive.dateValidFrom = dateValidFrom ? new Date(dateValidFrom).toISOString() : null;
        createMenuActive.beer_cider_menu = isBeerAndCiderMenu;

        dispatch(setCreateMenuActive(createMenuActive));
        dateValidFrom && console.log("VALID FROM: ", new Date(dateValidFrom).toISOString(), new Date(dateValidFrom), new Date(dateValidFrom).toISOString())
    }

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log("load");
                console.log(reader.result);
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        base64Data = base64Data === 'REMOVE' ? null : base64Data;
        return <div className={'container-img-upload'}
                    style={{marginLeft: '370px', marginTop: '-150px', marginBottom: '35px', position: 'unset'}}>
            <img className={'img-upload'} src={base64Data} alt="banner" height={'111px'}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE")
            }} className={'icon-close'}/>
        </div>
    };

    const renderFileUpload = (title) => {
        if (!isUserCaretaker(user)) {
            return <></>
        }

        return <>
            <p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                        handleFile={(file) => handleImageUpload(file)}/>

            {image && previewImage(image)}
        </>
    };

    let inputStyle = {marginBottom: '48px'};

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> : token &&
        <div><Page relative className={'main'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative'}>*/}
            <Header left={`${sidebarWidth + 50}px`} title={'OSNOVNI PODATKI'} step={'1. KORAK'}/>
            <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <div className={'container-column-2'}>
                    <div className={'column-2'}>
                        <Dropdown loadBars label={'Ime lokala'} onChange={setBarChosen}
                                  createMenu
                                  placeholder={'Izberite lokal'}/>
                        {!isBeerAndCiderMenu && (isUserCaretaker(user)) &&
                            <ButtonGroupSelection loadBar loadCreateMenu value={leadingBrand} leadingBrand
                                                  onChange={setLeadingBrand}/>}
                    </div>
                    <div className={'column-2'} style={{paddingRight: '50px', marginTop: !isUserCaretaker(user) ? '-60px': '0px'}}>
                        {renderFileUpload('Dodajte logotip lokala')}
                        <DateInput label={'Določite veljavnost cenika'} value={dateValidFrom}
                                   onChange={(value) => setDateValidFrom(new Date(value).getTime() + 3600000 * 2)}
                                   style={{marginTop: '60px'}}/>
                    </div>
                </div>
                <Footer
                    left={`${sidebarWidth}px`}
                    next={'POTRDI IN NADALJUJ'}
                    urlNext={PAGES.CREATE_MENU_CHOOSE_BACKGROUND}
                    onAction={() => onConfirmCurrentChanges()}
                    progress={1}/>
            </div>
            {/*</div>*/}
        </Page>
        </div>

}

export default CreateMenu;

