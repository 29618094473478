import React, {useState, useCallback, useEffect} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {Redirect} from 'react-router'
import Loader from "react-loader-spinner";

import '../css/Login.css';

import {useDispatch, useSelector} from "react-redux";
import * as FaIcons from "react-icons/fa";
import logo from "../assets/img/logo.jpg";
import background from "../assets/img/background-cp.jpg"

import Input from "../components/js/Input";
import Button from "../components/js/Button";
import {
    createNewUser,
    isRememberMeEnabled,
    loadCredentials, loadLiveMenu,
    setRememberMe,
    setSignInCredentials, updateUserPassword,
    userLogin
} from "../store/actions/actions";
import Message, {ErrorType} from "../components/js/Message";
import {PAGES} from "../App";
import {
    isPasswordValid,
    isSessionValid,
    mapCaretChars,
    parseMapCaretChars,
    validateUserInput
} from "../helpers/helpers";
import colors from "../constants/colors";
import ModalInfo from "../components/js/ModalInfo";


function ResetPassword(props) {
    let dispatch = useDispatch();

    const {id} = useParams();

    const [password, setPassword] = useState();

    const [resetSuccessful, setResetSuccessful] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    useEffect(() => {
        dispatch(loadCredentials());
        dispatch(isRememberMeEnabled());
    }, []);

    useEffect(() => {
        if (id) {
           dispatchLoadResetLink()
        }
    }, [id]);

    const dispatchLoadResetLink = () => {

    }

    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const onResetPassword = () => {

        setLoadingSpinner(true);
        const onSuccessLogin = () => {

            setModalValues("Geslo uspešno ponastavljeno", () => {
                console.log('redirect success');
                setResetSuccessful(true);
                setLoadingSpinner(false);
            })
        };
        const UserAccessNotGranted = () => {
            setModalValues("Povezava za ponastavitev gesla je potekla. Prosimo zahtevajte novo povezavo.", () => {
                console.log("Error fetching data");
                setLoadingSpinner(false);
            })
        };
        const onErrorUnauthorized = () => {
            setModalValues("Povezava za ponastavitev gesla je potekla. Prosimo zahtevajte novo povezavo.", () => {
                console.log("Unauthorized");
             //   setError(ErrorType.UNAUTHORIZED);
                setLoadingSpinner(false);
            })
        };

        let data = {
            reset_request_id: id,
            password: mapCaretChars(password)
        }

        if (!isPasswordValid(mapCaretChars(password))) {
            const invalidPasswordAlert = "Geslo ni veljavno. Prosimo, vnesite samo črke in številke, brez šumnikov.";
            setModalValues(invalidPasswordAlert, () => {
                console.log("Unauthorized");
                //   setError(ErrorType.UNAUTHORIZED);
                setLoadingSpinner(false);
            })
            return;
        }

        dispatch(updateUserPassword(data, onSuccessLogin, UserAccessNotGranted, onErrorUnauthorized));
    };

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text, action) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
                if (action) {
                    action()
                }
            }
        })
    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    if (resetSuccessful) {
        console.log('redirect');
        return <Redirect to={PAGES.LOGIN} push={false}/>
    }

    return (
        <div className={'page login'} style={{backgroundImage: `url(${background})`}}>
            <div className={'login-container'}>
                <div className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" height={'110px'}/>
                </div>
                <div className={'form-container'}>
                    <Input login password
                           onChange={(value) => onInputChange(value, setPassword)} onSubmit={onResetPassword}
                           placeholder={'Novo geslo'} label={'Vpišite novo geslo'}
                           style={{marginBottom: '0px'}}/>
                    {renderErrorMessage()}
                    {loadingSpinner ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={36}
                            width={36}
                            style={{paddingTop: 52, paddingBottom: 45}}
                        /> :
                        <Button text={'PONASTAVI GESLO'} login action={onResetPassword}/>
                    }
                    <p className={'p-info'}>Če potrebujete pomoč se nam javite na dean@optibar.si</p>
                </div>
            </div>
            {renderModalInfo()}
        </div>
    )

}

export default ResetPassword;