import React, {useState, useEffect} from "react";

import '../css/LiveDigitalMenu.css';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {
    createNewStat,
    loadBar,
    loadBarAds,
    loadBarL10NSupport,
    loadLiveMenu,
    updateAdClickedCounter,
    updateBarQrCodeScanned
} from "../store/actions/actions";
import {
    displayPrice,
    displayValidFromMenuFormat,
    getL10NAlergens, getL10NMenuNonTaxDisclaimer,
    getL10NMenuPricesDisclaimer,
    getL10NMenuValidFrom,
    getL10NVat,
    hasItem,
    mapCaretChars,
    parseMapCaretChars,
} from "../helpers/helpers";

import '../assets/fonts/HeinekenSerif18-Bold.ttf';
import '../assets/fonts/HeinekenSerif18-Medium.ttf';
import '../assets/fonts/HeinekenSerif18-Regular.ttf';
import '../assets/fonts/FranklinGothicMedium.ttf';
import '../assets/fonts/FranklinGothicURWCom-Boo.ttf';
import '../assets/fonts/FranklinGothicURWCom-Dem.ttf';
import '../assets/fonts/Kanit-Medium.ttf';
import '../assets/fonts/Kanit-Regular.ttf';
import '../assets/fonts/Kanit-ExtraBold.ttf';

import countrySI from "../assets/img/country_si.png";
import countryEN from "../assets/img/country_en.png";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";

import unionLogo from "../assets/img/PUlogo-red@2x.png";
import unionLogoDark from "../assets/img/logo-union-black.png";
import unionBcg from "../assets/img/bcg-union@2x.png";
import unionBcgDark from "../assets/img/bcg-union@2x-TEMNO.png";
import laskoBcg from "../assets/img/lasko-back.jpg";
import laskoBcgDark from "../assets/img/lasko_mobile_temna3.jpg";
import laskoLogo from "../assets/img/logo-lasko.png";
import heinekenBcg from "../assets/img/heineken-back-black.jpg";
import heinekenBcgWhite from "../assets/img/heineken-back-white.jpg";
import heinekenLogo from "../assets/img/heineken-logo.png";
import heinekenLogoWhite from "../assets/img/heineken-logo-white.png";

import Collapsible from "react-collapsible";
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import {LEADING_BRAND} from "../components/js/ButtonGroupSelection";
import {PAGES} from "../App";
import logo from "../assets/img/logo.jpg";
import {L10N_FIELDS, L10N_MENU} from "../constants/enums";

const DISPLAY_AD_DELAY = 1000;
const DISPLAY_AD_SHOWN = 7000;
const DISPLAY_AD_HIDE_AFTER = DISPLAY_AD_DELAY + DISPLAY_AD_SHOWN;

const shouldDisplayL10NOption = (l10nKey, availableLanguages, override) => {
    /*
    if (!override && !availableLanguages || availableLanguages?.length === 0 || (availableLanguages?.length === 1 && (!availableLanguages[0]?.l10n) || availableLanguages[0]?.l10n === L10N_MENU.si.value)) {
        console.log("SHOULD DISPLAY", false, l10nKey, availableLanguages)
        return false;
    }

     */
    if (!override && !availableLanguages.l10n_en && !availableLanguages.l10n_de && !availableLanguages.l10n_it && !availableLanguages.l10n_hr) {
       // console.log("SHOULD DISPLAY", false, l10nKey, availableLanguages)
        return false;
    }

    let shouldDisplay = l10nKey === L10N_MENU.si.value
    if (shouldDisplay) {
        return true;
    }
    //shouldDisplay = !override ? !!hasItem('l10n', 'l10n', l10nKey, availableLanguages) : true;

    if (l10nKey === L10N_MENU.en.value) {
        shouldDisplay = !override ? !!availableLanguages.l10n_en : true;
    } else if (l10nKey === L10N_MENU.de.value) {
        shouldDisplay = !override ? !!availableLanguages.l10n_de : true;
    }
    if (l10nKey === L10N_MENU.it.value) {
        shouldDisplay = !override ? !!availableLanguages.l10n_it : true;
    }
    if (l10nKey === L10N_MENU.hr.value) {
        shouldDisplay = !override ? !!availableLanguages.l10n_hr : true;
    }

//    console.log("SHOULD DISPLAY", shouldDisplay, l10nKey, availableLanguages)
    return shouldDisplay;
}


const parseSelectedCountryLabel = (country, selectAction, onActionCollapseL10NOptions, availableLanguages, override) => {
    const action = () => {
        selectAction(country)
        onActionCollapseL10NOptions()
    }
    if (!shouldDisplayL10NOption(country, availableLanguages, override)) {
        return <></>
    }

    switch (country) {
        case L10N_MENU.si.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countrySI} alt="SI" width={'25px'}/>
            </div>
        case L10N_MENU.en.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryEN} alt="EN" width={'25px'}/>
            </div>

        case L10N_MENU.de.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryDE} alt="DE" width={'25px'}/>
            </div>
        case L10N_MENU.it.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryIT} alt="IT" width={'25px'}/>
            </div>
        case L10N_MENU.hr.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryHR} alt="HR" width={'25px'}/>
            </div>
    }
}

function LiveDigitalMenu(props) {
    let dispatch = useDispatch();

    const {id} = useParams();
 //   console.log(id);
    let menu = useSelector(state => state.reducer.onlinePriceList);
    let bar = useSelector(state => state.reducer.onlineBar);
    let adBanners = useSelector(state => state.reducer.onlineBarAds);

    //TESTING MAIN MENU BRAND STYLES
    /*
    if (menu) {
        menu.main_brand = 5;
    }

     */

    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(L10N_MENU.si.value);
    const [availableLanguages, setAvailableLanguages] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState([]);
    const [displayAdBanner, setDisplayAdBanner] = useState(false);

    const [barScanned, setBarScanned] = useState(false);
    const [adShown, setAdShown] = useState(false);

    const dispatchBarL10NSupport = (barId) => {
        dispatch(loadBarL10NSupport(barId, (l10n) => {
       //     console.log("Live menu l10n loaded..", l10n);
            setAvailableLanguages(l10n);
        }, () => console.warn("Error loading live menu l10n languages support...")));
    };

    const dispatchLoadLiveMenu = () => {
        dispatch(loadLiveMenu(id, () => {
            console.log("Live menu loaded..");
        }, () => console.warn("Error loading live menu...")));
    };

    const dispatchLoadBarData = (barId) => {
        dispatchBarL10NSupport(barId)
        dispatch(loadBar(barId, () => {
            console.log("Bar loaded..");
        }, () => console.warn("Error loading bar...")));
    };

    const dispatchLoadBarAds = (barId) => {
        dispatch(loadBarAds(barId, () => {
            console.log("Bar ads loaded..");
        }, () => console.warn("Error loading bar ads...")));
    };

    useEffect(() => {
        dispatchLoadLiveMenu();
    }, [])
    useEffect(() => {
        if (menu) {
   //         console.log("ONLINE MENU:", menu)
            dispatchLoadBarData(menu.bar_id);

            if (!barScanned) {
                setBarScanned(true);

                dispatch(createNewStat(menu.bar_id, null, "QR_SCAN", (res) => {
                   // console.log("New stat qr scan created", res);
                }, () => console.warn("Bar qr code scan update counter failed..., res")));


                dispatch(updateBarQrCodeScanned(menu.bar_id, (res) => {
                   // console.log("Bar qr code scanned updated counter..", res);
                }, () => console.warn("Bar qr code scan update counter failed..., res")));
            }
        }
    }, [menu])
    useEffect(() => {
        if (bar) {
   //         console.log("BAR:", bar)
            dispatchLoadBarAds(bar.bar_id);
        }
    }, [bar]);
    useEffect(() => {
        if (adBanners) {
    //        console.log("ADS:", adBanners)
            AdBannersPopupLogic();
        }
    }, [adBanners]);
    useEffect(() => {
  //      console.log("SELECTED LANGUAGE:", selectedLanguage)
    }, [selectedLanguage])

    useEffect(() => {
        if (adShown) {
            dispatch(createNewStat(menu.bar_id, adBanners[0]?.ad_id, "AD_SHOWN", (res) => {
               // console.log("New stat ad click impression created", res);

            }, (e) => console.warn("Bar New stat ad click impression created failed...", e)));
        }
    }, [adShown])

    const AdBannersPopupLogic = () => {
        setTimeout(() => setDisplayAdBanner(true), DISPLAY_AD_DELAY);
        setTimeout(() => setDisplayAdBanner(false), DISPLAY_AD_HIDE_AFTER);
    };

    const displayCollapsibleArrow = (name, c, status) => {
        let arrowStyle = {width: '24px', height: '24px', color: parseSecondaryColor(menu.main_brand)};
        return (
            <div className={'collapsible-header'}>
                <p className={'online-menu-title-category'}
                   style={{
                       letterSpacing: parseLetterSpacingRegular(menu.main_brand),
                       color: parseSecondaryColor(menu.main_brand),
                       fontFamily: parseFontTitle(menu.main_brand),
                   }}>{parseMapCaretChars(name).toUpperCase()}</p>
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapse = (c, state) => {
        let updated = isCategoryOpen;
        updated[c] = state
   //     console.log(updated)
        setIsCategoryOpen(updated);
    }

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: parseSecondaryColor(menu.main_brand)};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false), availableLanguages, true)}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const openInNewTab = (ad, closeAd) => {
        let url = ad.link;

        dispatch(createNewStat(menu.bar_id, ad.ad_id, "AD_CLICK", (res) => {
            //console.log("New stat ad click impression created", res);
        }, (e) => console.warn("Bar New stat ad click impression created failed...", e)));

        dispatch(updateAdClickedCounter(ad.ad_id, (res) => {
            //console.log("Internal Ad counter updated..", res);
        }, () => console.warn("Internal Ad counter updated..., res")));

        if (closeAd) {
            setDisplayAdBanner(false);
        }
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const renderAdBanner = () => {
        if (!adBanners || adBanners.length === 0) {
            return null;
        }
        if (!adShown) {
            setAdShown(true);
        }

        return (
            <Collapsible classParentString={'collapsible-ad-banner'} transitionTime={400}
                         onClosing={() => {
                         }}
                         onOpening={() => {
                         }}
                         open={displayAdBanner}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}>

                <div className={'ad-container'} onClick={() => {
                }}>
                    <div onClick={() => openInNewTab(adBanners[0], true)}><img className={'banner'}
                                                                                    src={adBanners[0].image} alt="ad"
                                                                                    width={'100%'}/></div>
                </div>
            </Collapsible>
        );
    };

    const renderHeader = () => {
        return (
            <div className={'logo'} style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                <img src={parseLogo(menu.main_brand)} alt="logo" height={'40px'}/>
            </div>
        );
    };

    const renderDDVFooter = (ddv) => {
        if (!ddv || ddv === '' || ddv === '/' || (ddv && !ddv.includes("SI"))) {
            return (
                `${parseMapCaretChars(bar.legal_entity) ? parseMapCaretChars(bar.legal_entity) : " "} | ${parseMapCaretChars(bar.address)} | ${getL10NMenuValidFrom(selectedLanguage)} ${displayValidFromMenuFormat(menu.valid_from)} | ${getL10NMenuNonTaxDisclaimer(selectedLanguage)}`
            );
        } else {
            return (
                `${parseMapCaretChars(bar.legal_entity) ? parseMapCaretChars(bar.legal_entity) : " "} | ${parseMapCaretChars(bar.address)} | ${getL10NVat(selectedLanguage)} ${parseMapCaretChars(bar.tax_number)} | ${getL10NMenuPricesDisclaimer(selectedLanguage)} ${getL10NMenuValidFrom(selectedLanguage)} ${displayValidFromMenuFormat(menu.valid_from)}`
            );
        }
    }

    const renderFooter = () => {
        if (!bar) {
            return null;
        }
        return (
            <>
                <div style={{width: '100%'}}>
                    <p className={'online-menu-text-footer alergens'}
                       style={{
                           fontFamily: parseFontLight(menu.main_brand),
                           color: parsePrimaryColor(menu.main_brand)
                       }}>{getL10NAlergens(selectedLanguage)}</p>
                    <p className={'online-menu-text-footer bar-info'}
                       style={{fontFamily: parseFontLight(menu.main_brand)}}>
                        {renderDDVFooter(bar?.tax_number)}</p>

                </div>
            </>
        );
    };

    const parseBackgroundColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
                return 'transparent';
            case LEADING_BRAND.HEINEKEN_WHITE:
                return '#fff';
            case LEADING_BRAND.UNION:
                return '#E11835';
            case LEADING_BRAND.UNION_DARK:
                return '#231F20';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return '#015A2C';
        }
    };
    const parsePrimaryColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN_WHITE:
                return '#07843B';
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return '#fff';
        }
    };
    const parseSecondaryColor = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
                return '#fff';
            case LEADING_BRAND.HEINEKEN_WHITE:
                return '#07843B';
            case LEADING_BRAND.UNION:
                return '#0A0A0A';
            case LEADING_BRAND.UNION_DARK:
                return '#E11835';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return '#F2CB13';
        }
    };
    const parseBackground = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
                return heinekenBcg;
            case LEADING_BRAND.HEINEKEN_WHITE:
                return heinekenBcgWhite;
            case LEADING_BRAND.UNION:
                return unionBcg;
            case LEADING_BRAND.UNION_DARK:
                return unionBcgDark;
            case LEADING_BRAND.LASKO:
                return laskoBcg;
            case LEADING_BRAND.LASKO_DARK:
                return laskoBcgDark;
        }
    };
    const parseLogo = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN_WHITE:
                return heinekenLogoWhite;
            case LEADING_BRAND.HEINEKEN:
                return heinekenLogo;
            case LEADING_BRAND.UNION:
                return unionLogo;
            case LEADING_BRAND.UNION_DARK:
                return unionLogoDark;
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return laskoLogo;
        }
    };
    const parseFontTitle = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return 'HeinekenSerifBold';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return 'Kanit-ExtraBold';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return 'FranklinGothicDemi';
        }
    };
    const parseFontTitleProd = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return 'HeinekenSerifRegular';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return 'Kanit-Medium';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return 'FranklinGothicRegular';
        }
    };
    const parseFontLight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return 'HeinekenSerif';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return 'Kanit-Regular';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return 'FranklinGothicRegular';
        }
    };
    const parseFontPrice = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return 'HeinekenSerifRegular';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return 'Kanit-Regular';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return 'FranklinGothicRegular';
        }
    };
    const parseFontUnit = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return 'HeinekenSerifRegular';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return 'Kanit-Regular';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return 'FranklinGothicRegular';
        }
    };
    const parseFontSizeRegular = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return '14px';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return '14px';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return '16px';
        }
    };
    const parseLetterSpacingRegular = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return '1.2px';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return '1.2px';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return '1.2px';
        }
    };
    const parseLetterSpacingTight = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.HEINEKEN_WHITE:
                return '0.85px';
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return '0.85px';
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return '0.85px';
        }
    };

    const parseBodyPadding = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN_WHITE:
                return '50px';
            case LEADING_BRAND.HEINEKEN:
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return '24px';
        }
    }

    const parseBackgroundStyle = (brand) => {
        switch (brand) {
            case LEADING_BRAND.HEINEKEN_WHITE:
                return {
                    backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'top',
                    backgroundPositionX: 'right'
                };
            case LEADING_BRAND.HEINEKEN:
                return {
                    backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'center',
                    backgroundPositionX: 'center'
                };
            case LEADING_BRAND.UNION:
            case LEADING_BRAND.UNION_DARK:
                return {
                    backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'bottom',
                    backgroundPositionX: 'center'
                };
            case LEADING_BRAND.LASKO:
            case LEADING_BRAND.LASKO_DARK:
                return {
                    backgroundColor: parseBackgroundColor(brand),
                    backgroundImage: `url(${parseBackground(brand)})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'top',
                    backgroundPositionX: 'center'
                };
        }
    };

    const isProductFood = (name) => {
        let n = parseMapCaretChars(name);
        n = n.toLowerCase();
        if (n === 'hrana' || n === 'prigrizki' || n === 'sladice' || n === 'jedi') {
            return true;
        }
    }

    const parseProductField = (l10n, field, product) => {
        let l10nKey = l10n.toLowerCase() + '_' + field;
        if (l10n === L10N_MENU.si.value && field === L10N_FIELDS.name.value) {
            l10nKey = 'name';
        } else if (l10n === L10N_MENU.si.value && field === L10N_FIELDS.description.value) {
            l10nKey = 'slo' + '_' + field;
        } else if (l10n === L10N_MENU.en.value) {
            l10nKey = 'eng' + '_' + field;
        } else if (l10n === L10N_MENU.de.value) {
            l10nKey = 'de' + '_' + field;
        } else if (l10n === L10N_MENU.it.value) {
            l10nKey = 'it' + '_' + field;
        } else if (l10n === L10N_MENU.hr.value) {
            l10nKey = 'hr' + '_' + field;
        } else {
            console.warn("No l10n key found:", l10n, field, product)
            return null;
        }
        return product[l10nKey];
    }

    const renderL10NOption = (l10nKey, imgData) => {
        if (!shouldDisplayL10NOption(l10nKey, availableLanguages, false)) {
            return <></>;
        }
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'25px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        console.log(availableLanguages)

        if (!availableLanguages.l10n_en && !availableLanguages.l10n_de && !availableLanguages.l10n_it && !availableLanguages.l10n_hr) {
            return <></>;
        }

        /*
        if (!availableLanguages || (availableLanguages?.length === 1 && (!availableLanguages[0]?.l10n || availableLanguages[0]?.l10n === 'SI')) || availableLanguages.length === 2 && (availableLanguages[0]?.l10n === 'SI' && !availableLanguages[1]?.l10n)) {
            return <></>;
        }

         */

        return (
            <Collapsible className={'collapsible-menu-listing l10-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {renderL10NOption(L10N_MENU.si.value, countrySI)}
                    {renderL10NOption(L10N_MENU.en.value, countryEN)}
                    {renderL10NOption(L10N_MENU.de.value, countryDE)}
                    {renderL10NOption(L10N_MENU.it.value, countryIT)}
                    {renderL10NOption(L10N_MENU.hr.value, countryHR)}
                </div>
            </Collapsible>
        );
    }

    const renderMenu = () => {
        return (
            menu.categories.map((category, c) => (
                <Collapsible key={`${c}_${category}`} className={'collapsible-menu-listing'} transitionTime={400}
                             onClosing={() => onActionCollapse(c, false)}
                             onOpening={() => onActionCollapse(c, true)}
                             open={Number(c) < 3}
                             easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                             trigger={displayCollapsibleArrow(parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) ? parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) : 'MANJKA PREVOD', c, false)}
                             triggerWhenOpen={displayCollapsibleArrow(parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) ? parseProductField(selectedLanguage, L10N_FIELDS.name.value, category.category) : 'MANJKA PREVOD', c, true)}>
                    <div className={'online-menu-category'}>

                        <div className={'online-menu-item-category'}>
                            {category.products.map((product, p) => (

                                parseProductField(selectedLanguage, L10N_FIELDS.name.value, product) &&
                                <div key={`${p}_${product}`}
                                     className={'online-menu-item-product'}>
                                    <div className={'online-menu-item-product-left'}>
                                        <div className={'online-menu-item-product-left-row'}>
                                            <p className={'online-menu-title-product'} style={{
                                                letterSpacing: parseLetterSpacingRegular(menu.main_brand),
                                                fontSize: parseFontSizeRegular(menu.main_brand),
                                                fontFamily: parseFontTitleProd(menu.main_brand),
                                                color: parsePrimaryColor(menu.main_brand)
                                            }}>{parseMapCaretChars(parseProductField(selectedLanguage, L10N_FIELDS.name.value, product) ? parseProductField(selectedLanguage, L10N_FIELDS.name.value, product).toUpperCase() : 'MANJKA PREVOD')}</p>
                                            <p className={'online-menu-alergens-product'} style={{
                                                letterSpacing: parseLetterSpacingTight(menu.main_brand),
                                                fontFamily: parseFontLight(menu.main_brand),
                                                color: parsePrimaryColor(menu.main_brand)
                                            }}>{product.allergenes}</p>
                                        </div>
                                        <p className={'online-menu-unit-product'} style={{
                                            letterSpacing: parseLetterSpacingTight(menu.main_brand),
                                            fontFamily: parseFontLight(menu.main_brand),
                                            color: parsePrimaryColor(menu.main_brand)
                                        }}>{!isProductFood(category.category.name) ? product.measure_unit : parseMapCaretChars(parseProductField(selectedLanguage, L10N_FIELDS.description.value, product))}</p>
                                    </div>
                                    <div className={'online-menu-item-product-right'}>
                                        <p className={'online-menu-price-product'} style={{
                                            letterSpacing: parseLetterSpacingRegular(menu.main_brand),
                                            fontSize: parseFontSizeRegular(menu.main_brand),
                                            fontFamily: parseFontPrice(menu.main_brand),
                                            color: parsePrimaryColor(menu.main_brand)
                                        }}>{displayPrice(product.initial_price)} €</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Collapsible>
            ))
        );
    };
    if (!menu || !menu.categories) {
        return <></>;
    }

    return <div className={'main'}>
        <div className={'background fixed'} style={parseBackgroundStyle(menu.main_brand)}/>
        <div className={'page digital-menu'}>
            <div className={'body'} style={{
                paddingLeft: parseBodyPadding(menu.main_brand),
                paddingRight: parseBodyPadding(menu.main_brand)
            }}>
                {renderL10NOptions()}
                {renderAdBanner()}
                {renderHeader()}
                {renderMenu()}
                {renderFooter()}
            </div>
        </div>
    </div>
}

export default LiveDigitalMenu;