import {
    LOAD_ALL_USER_DATA,
    SET_APP_FIRST_OPEN,
    SET_CREDENTIALS,
    SET_USER_DATA,
    REMEMBER_ME,
    REMEMBER_ME_CREDENTIALS,
    REMEMBER_ME_SHAREPOINT,
    REMEMBER_ME_CREDENTIALS_SHAREPOINT,
    SET_WALLET_CONNECTED,
    SET_CREATING_MENU_PROGRESS,
    SET_CREATE_MENU,
    LOAD_BARS,
    LOAD_COMBO_OFFERS,
    LOAD_PRODUCTS,
    LOAD_MENUS_HISTORY,
    loadSessionUser,
    SET_ACTIVE_BAR,
    setCreateMenuActive,
    LOAD_CATEGORIES,
    LOAD_MENU_LIVE,
    LOAD_BARS_FOR_OWNER,
    SET_CREATE_MENU_LAST_CREATED,
    LOAD_NOTIFICATIONS,
    ADD_TO_USERS_LIST,
    LOAD_USERS,
    loadSessionUsers,
    loadSessionProducts,
    loadSessionCategories,
    loadSessionBars,
    loadSessionComboOffers,
    SET_ACTIVE_BAR_REPRESENTATIVE,
    SET_ACTIVE_BAR_CARETAKER,
    SET_SELECTED_PRODUCT,
    SET_USER_CARETAKER,
    SET_USER_TOKEN,
    loadSessionUserToken,
    loadSessionCaretakers,
    LOAD_CARETAKERS,
    loadSessionActiveBarCaretaker,
    loadSessionActiveBarUser,
    loadSessionActiveBar,
    loadSessionCaretaker,
    loadSessionActiveMenu,
    SET_ACTIVE_MENU,
    loadSessionLiveMenu,
    loadSessionActiveBarOwner,
    SET_ACTIVE_BAR_OWNER,
    LOAD_ONLINE_BAR,
    LOAD_ONLINE_MENU,
    LOAD_ADS,
    LOAD_BAR_ADS,
    loadSessionActiveAd,
    loadSessionActiveProduct,
    SET_SELECTED_AD,
    LOAD_SELECTED_AD_ADDED_BARS,
    SET_NOTIFICATIONS_AVAILABLE,
    LOAD_PRODUCTS_BY_CATEGORY,
    LOAD_PRODUCT_CATEGORY_LABELS,
    SET_LAST_CARETAKER_CREATED,
    SHOW_SIDEBAR,
    SET_SIDEBAR_WIDTH,
    UPDATE_IMPORT_ERRORS,
    SET_IMPORT_ERRORS,
    loadSessionActiveEditUser,
    SET_ACTIVE_EDIT_USER,
    SET_CREDENTIALS_SHAREPOINT,
    SET_SELECTED_CATEGORY, loadSessionActiveCategory, SET_DOWNLOAD_PROGRESS, SET_DOWNLOAD_STATUS
} from '../actions/actions';
import {SESSION_STORAGE_KEYS} from "../../constants/enums";
import {SIDEBAR_WIDTH} from "../../components/js/Page";
import {findItem, getIndexOf} from "../../helpers/helpers";

const initialState = {
    appFirstOpen: true,
    savedCredentials: {
        email: "",
        password: ""
    },
    loginCredentials: {
        email: "",
        password: ""
    },
    rememberMe: false,
    savedCredentialsSharepoint: {
        email: "",
        password: ""
    },
    loginCredentialsSharepoint: {
        email: "",
        password: ""
    },
    rememberMeSharepoint: false,
    userToken: loadSessionUserToken(SESSION_STORAGE_KEYS.USER_TOKEN),
    user: loadSessionUser(SESSION_STORAGE_KEYS.USER),
    userCaretaker: loadSessionCaretaker(SESSION_STORAGE_KEYS.USER_CARETAKER),
    notifications: null,
    isCreatingMenu: -1,
    selectedAd: loadSessionActiveAd(SESSION_STORAGE_KEYS.ACTIVE_AD),
    selectedCategory: loadSessionActiveCategory(SESSION_STORAGE_KEYS.ACTIVE_CATEGORY),
    selectedProduct: loadSessionActiveProduct(SESSION_STORAGE_KEYS.ACTIVE_PRODUCT),
    activeBar: loadSessionActiveBar(SESSION_STORAGE_KEYS.ACTIVE_BAR),
    activeEditUser: loadSessionActiveEditUser(SESSION_STORAGE_KEYS.ACTIVE_EDIT_USER),
    activeBarRepresentative: loadSessionActiveBarUser(SESSION_STORAGE_KEYS.BAR_REPRESENTATIVE),
    activeBarCaretaker: loadSessionActiveBarCaretaker(SESSION_STORAGE_KEYS.BAR_CARETAKER),
    activeMenu: loadSessionActiveMenu(SESSION_STORAGE_KEYS.ACTIVE_MENU),
    activeBarOwner: loadSessionActiveBarOwner(SESSION_STORAGE_KEYS.BAR_OWNER),
    menuLive: loadSessionLiveMenu(SESSION_STORAGE_KEYS.LIVE_MENU),
    menuLastCreated: null,
    showSidebar: true,
    sidebarWidth: SIDEBAR_WIDTH,
    createMenuActive: {
        bar: null,
        leading_brand: null,
        menuBackground: null,
        dateValidFrom: null,
        categories: [],
        menuType: null,
        printQuantities: {
            qr: null,
            a4: null,
            a5: null,
            a3: null,
            b2: null
        },
        comment: null
    },
    menusHistory: [],
    bars: [], //loadSessionBars(SESSION_STORAGE_KEYS.BARS),
    categories: loadSessionCategories(SESSION_STORAGE_KEYS.CATEGORIES),
    products: loadSessionProducts(SESSION_STORAGE_KEYS.PRODUCTS) ? loadSessionProducts(SESSION_STORAGE_KEYS.PRODUCTS) : [],
    comboOffers: loadSessionComboOffers(SESSION_STORAGE_KEYS.COMBO_PRODUCTS) ? loadSessionComboOffers(SESSION_STORAGE_KEYS.COMBO_PRODUCTS) : [],
    productsByCategory: [],
    productCategoryLabels: [],
    allUsers: loadSessionUsers(SESSION_STORAGE_KEYS.USERS),
    allCaretakers: loadSessionCaretakers(SESSION_STORAGE_KEYS.CARETAKERS),
    createdUsers: [],
    lastCaretakerCreated: null,
    onlinePriceList: null,
    onlineBar: null,
    onlineBarAds: [],
    ads: [],
    selectedAdAddedBars: [],
    notificationsAvailableBool: false,
    importErrors: [],
    downloadProgress: []
};

const reducer = (state = initialState, action) => {
    let updated;
    let dl_task;
    let index;

    switch (action.type) {
        case SET_APP_FIRST_OPEN:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.appFirstOpen}`);
            return {...state, appFirstOpen: action.appFirstOpen};
        case REMEMBER_ME:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.rememberMe}`);
            return {...state, rememberMe: action.rememberMe};
        case REMEMBER_ME_CREDENTIALS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, saved email: ${JSON.stringify(action.email, null, 4)}, saved password: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, savedCredentials: {email: action.email, password: action.password}};
        case REMEMBER_ME_SHAREPOINT:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.rememberMe}`);
            return {...state, rememberMeSharepoint: action.rememberMe};
        case REMEMBER_ME_CREDENTIALS_SHAREPOINT:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, saved email: ${JSON.stringify(action.email, null, 4)}, saved password: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, savedCredentialsSharepoint: {email: action.email, password: action.password}};
        case SET_USER_TOKEN:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, token: ${JSON.stringify(action.token, null, 4)}`);
            return {...state, userToken: action.token};
        case SET_USER_DATA:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, user: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, user: action.user};
        case SET_USER_CARETAKER:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, caretaker: ${JSON.stringify(action.caretaker, null, 4)}`);
            return {...state, userCaretaker: action.caretaker};
        case SET_ACTIVE_BAR_OWNER:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, owner: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, activeBarOwner: action.user};
        case SET_ACTIVE_BAR:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, active bar: ${JSON.stringify(action.bar, null, 4)}`);
            return {...state, activeBar: action.bar};
        case LOAD_ONLINE_BAR:
        //    console.log(`Action ${JSON.stringify(action.type, null, 4)}, online bar: ${JSON.stringify(action.bar, null, 4)}`);
            return {...state, onlineBar: action.bar};
        case LOAD_ONLINE_MENU:
           // console.log(`Action ${JSON.stringify(action.type, null, 4)}, online menu: ${JSON.stringify(action.menu, null, 4)}`);
            return {...state, onlinePriceList: action.menu};
        case SET_SELECTED_CATEGORY:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, selected category: ${JSON.stringify(action.category, null, 4)}`);
            return {...state, selectedCategory: action.category};
        case SET_SELECTED_PRODUCT:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, selected product: ${JSON.stringify(action.product, null, 4)}`);
            return {...state, selectedProduct: action.product};
        case SET_SELECTED_AD:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, selected ad: ${JSON.stringify(action.ad, null, 4)}`);
            return {...state, selectedAd: action.ad};
        case SET_ACTIVE_MENU:
       //     console.log(`Action ${JSON.stringify(action.type, null, 4)}, active menu: ${JSON.stringify(action.menu, null, 4)}`);
            return {...state, activeMenu: action.menu};
        case SET_ACTIVE_EDIT_USER:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, active edit user: ${JSON.stringify(action.activeEditUser, null, 4)}`);
            return {...state, activeEditUser: action.activeEditUser};
        case SET_ACTIVE_BAR_REPRESENTATIVE:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, active bar representative: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, activeBarRepresentative: action.user};
        case SET_ACTIVE_BAR_CARETAKER:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, active bar caretaker: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, activeBarCaretaker: action.user};
        case SET_LAST_CARETAKER_CREATED:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, last caretaker created: ${JSON.stringify(action.caretaker, null, 4)}`);
            return {...state, lastCaretakerCreated: action.caretaker};
        case SET_CREDENTIALS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, email: ${JSON.stringify(action.email, null, 4)}, password: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, loginCredentials: {email: action.email, password: action.password}};
        case SET_CREDENTIALS_SHAREPOINT:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, email: ${JSON.stringify(action.email, null, 4)}, password: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, loginCredentialsSharepoint: {email: action.email, password: action.password}};
        case SET_CREATING_MENU_PROGRESS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, create menu progress: ${JSON.stringify(action.isCreatingMenu, null, 4)}`);
            return {...state, isCreatingMenu: action.isCreatingMenu};
        case SET_CREATE_MENU:
          //  console.log(`Action ${JSON.stringify(action.type, null, 4)}, menu: ${JSON.stringify(action.createMenuActive, null, 4)}`);
            return {...state, createMenuActive: action.createMenuActive};
        case SET_CREATE_MENU_LAST_CREATED:
          //  console.log(`Action ${JSON.stringify(action.type, null, 4)}, last created menu: ${JSON.stringify(action.menu, null, 4)}`);
            return {...state, menuLastCreated: action.menu};
        case LOAD_MENUS_HISTORY:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, all menus: ${JSON.stringify(action.menusHistory, null, 4)}`);
            return {...state, menusHistory: action.menusHistory};
        case LOAD_BARS:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, bars: ${JSON.stringify(action.bars, null, 4)}`);
            return {...state, bars: action.bars};
        case SHOW_SIDEBAR:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, showSidebar: ${JSON.stringify(action.showSidebar, null, 4)}`);
            return {...state, showSidebar: action.showSidebar};
        case SET_SIDEBAR_WIDTH:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, sidebarWidth: ${JSON.stringify(action.sidebarWidth, null, 4)}`);
            return {...state, sidebarWidth: action.sidebarWidth};
        case LOAD_PRODUCTS:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, products: ${JSON.stringify(action.products, null, 4)}`);
            return {...state, products: action.products};
        case LOAD_COMBO_OFFERS:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, combo offers: ${JSON.stringify(action.comboOffers, null, 4)}`);
            return {...state, comboOffers: action.comboOffers};
        case LOAD_CATEGORIES:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, categories: ${JSON.stringify(action.categories, null, 4)}`);
            return {...state, categories: action.categories};
        case LOAD_MENU_LIVE:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, live menu: ${JSON.stringify(action.menuLive, null, 4)}`);
            return {...state, menuLive: action.menu};
        case LOAD_BARS_FOR_OWNER:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, bars: ${JSON.stringify(action.bars, null, 4)}`);
            return {...state, bars: action.bars};
        case LOAD_NOTIFICATIONS:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, notifications: ${JSON.stringify(action.notifications, null, 4)}`);
            return {...state, notifications: action.notifications};
        case ADD_TO_USERS_LIST:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, created user: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, createdUsers: [...state.createdUsers, action.user]};
        case LOAD_USERS:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, loaded all users: ${JSON.stringify(action.users, null, 4)}`);
            return {...state, allUsers: action.users};
        case LOAD_CARETAKERS:
            //console.log(`Action ${JSON.stringify(action.type, null, 4)}, loaded all caretakers: ${JSON.stringify(action.users, null, 4)}`);
            return {...state, allCaretakers: action.users};
        case LOAD_ADS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, loaded all ads: ${JSON.stringify(action.ads, null, 4)}`);
            return {...state, ads: action.ads};
        case LOAD_BAR_ADS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, loaded all ads for a bar: ${JSON.stringify(action.ads, null, 4)}`);
            return {...state, onlineBarAds: action.ads};
        case LOAD_SELECTED_AD_ADDED_BARS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, loaded all bars for selected ad: ${JSON.stringify(action.bars, null, 4)}`);
            return {...state, selectedAdAddedBars: action.bars};
        case SET_NOTIFICATIONS_AVAILABLE:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, loaded all bars for selected ad: ${JSON.stringify(action.state, null, 4)}`);
            return {...state, notificationsAvailableBool: action.state};
        case LOAD_PRODUCTS_BY_CATEGORY:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, products by category: ${JSON.stringify(action.products, null, 4)}`);
            return {...state, productsByCategory: action.products};
        case LOAD_PRODUCT_CATEGORY_LABELS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, products category labels: ${JSON.stringify(action.categoryLabels, null, 4)}`);
            return {...state, productCategoryLabels: action.categoryLabels};
        case UPDATE_IMPORT_ERRORS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, add error: ${JSON.stringify(action.error, null, 4)}`);
            let errorsUpdated = [...state.importErrors];
            errorsUpdated.push(action.error);
            return {...state, importErrors: errorsUpdated};
        case SET_IMPORT_ERRORS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, set import errors: ${JSON.stringify(action.importErrors, null, 4)}`);
            return {...state, importErrors: action.importErrors};
        case SET_DOWNLOAD_PROGRESS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, set download progress: ${JSON.stringify(action.downloadProgress, null, 4)}`);

            updated = [...state.downloadProgress]
            dl_task = {file_id: action.file_id, progress: action.downloadProgress};

            index = getIndexOf("file_id", action.file_id, state.downloadProgress);
            if (index >= 0) {
                if (dl_task.progress === 0 || dl_task.progress === '0') {
                    updated.splice(index, 1)
                } else {
                    let tmp = updated[index];
                    tmp.status = "Nalaganje datoteke..";
                    tmp.progress = action.downloadProgress;
                    updated[index] = tmp;
                }
            } else {
                updated = [...state.downloadProgress, dl_task]
            }

            return {...state, downloadProgress: updated};
        case SET_DOWNLOAD_STATUS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, set download progress: ${JSON.stringify(action.downloadProgress, null, 4)}`);

            updated = [...state.downloadProgress]
            dl_task = {file_id: action.file_id, progress: 0, status: action.status};

            index = getIndexOf("file_id", action.file_id, state.downloadProgress);
            if (index >= 0) {
                updated[index].status = action.status;
            } else {
                updated = [...state.downloadProgress, dl_task]
            }

            return {...state, downloadProgress: updated};
        default:
            console.log(`Undefined fire action: ${JSON.stringify(action.type, null, 4)}, value: ${JSON.stringify(action, null, 4)}`);
            return state;
    }
};

export default reducer;