import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Header from "../components/js/Header";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadCategories,
    setActiveCategory, setCreateMenuActive, setSessionCategories, setSidebarWidth
} from "../store/actions/actions";
import {isSessionValid, parseMapCaretChars, parseUserRole} from "../helpers/helpers";
import {Redirect} from "react-router";
import Modal from "../components/js/Modal";
import {USER_ROLES} from "../constants/enums";
import Input from "../components/js/Input";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

const TIMEOUT_INTERVAL = 1;
const SEARCH_BY_FIELDS = [
    'name',
    'external_id'
];

function CategoriesList(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    let allCategories = useSelector(state => state.reducer.categories);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let activeBar = useSelector(state => state.reducer.activeBar);

    const [modal, setModal] = useState(false);

    const [inputTimeout, setInputTimeout] = useState(null);

    const [nameInput, setNameInput] = useState(null);

    const [categories, setCategories] = useState(allCategories);


    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const navigateAndLoadSelectedCategory = (category) => {
        if (parseUserRole(user.type) === USER_ROLES.OWNER || parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE) {
            if (!activeBar || !category?.bar_id) {
                return;
            }
        }

        dispatch(setActiveCategory(category));
        onNavigateTo(PAGES.EDIT_CATEGORY);
    };

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        if (!!inputTimeout) {
            clearTimeout(inputTimeout);
            setInputTimeout(null);
        }
        setInputTimeout(setTimeout(() => {
            if (!nameInput || nameInput === '') {
                setCategories(allCategories);
            } else {
                let filteredObj = [];
                for (const obj of allCategories) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (obj.hasOwnProperty(fieldName) && !!obj[fieldName] && parseMapCaretChars(obj[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            filteredObj.push(obj);
                            break;
                        }
                    }
                }
                setCategories(filteredObj);
            }
        }, TIMEOUT_INTERVAL));
    }, [nameInput]);

    useEffect(() => {
            let done = false;
            const asyncEffect = async () => {
                if (user) {
                    let barId = null;
                    if (parseUserRole(user.type) !== USER_ROLES.ADMIN) {
                        if (activeBar) {
                            barId = activeBar.bar_id;
                        }
                    }
                    await dispatch(loadCategories(barId, (categories) => {
                        setSessionCategories(JSON.stringify(categories));
                        setCategories(categories)
                        console.log("Categories successfully loaded..");
                    }, () => {
                        console.warn("Error loading categories..")
                    }));
                }
            }
            asyncEffect().then(() =>  console.log(done && "done loading data."));
        }, [user, activeBar]
    );

    function Item(props) {
        // console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-name'}>{parseMapCaretChars(item.name)}</div>
                <div onClick={() => navigateAndLoadSelectedCategory(item)} className={'row-edit'}><a className={'a-icon'}
                ><IconEdit
                    className={'icon'}
                    width={'24px'} height={'24px'}
                    title={'icon list'}/></a></div>
            </li>
        );
    }

    function renderTable(items) {

        // Check if the user is not an admin, then filter out items with category?.bar_id === null
        if (parseUserRole(user.type) !== USER_ROLES.ADMIN) {
            items = items.filter(item => item?.bar_id !== null);
        }

        const filters = () => {
            return (
                <div className={'search-filters'} style={{left: `${sidebarWidth + 50}px`}}>
                    <Input onSubmit={() => {}}
                           onChange={setNameInput}
                           placeholder={'Naziv ali šifra kategorije'}
                           white
                    />
                </div>
            );
        };
        const header = () => {
            return (
                <div className={'row-header row-header-has-filter'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-name'}>Naziv</div>
                    <div className={'row-edit'}>Uredi</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {filters()}
                {header()}
                {list(items)}
            </>
        );
    }

    const renderModal = () => {
        return (
            modal && <Modal product actionClose={() => {
                console.log('display modal, false');
                setModal(false)
            }}/>
        );
    };
    let windowSize = {x: 0, y: document.documentElement.scrollHeight}
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} style={{height: `${windowSize.y}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
                    <Header left={`${sidebarWidth + 50}px`} title={'PREGLED KATEGORIJ'}/>
                    <div className={'body body-has-filter'}>
                        {renderTable([...categories])}
                    </div>
            </Page>
            {renderModal()}
        </div>
}

export default CategoriesList;

