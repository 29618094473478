import React, {useState, useEffect, createRef, useCallback} from "react";
import {ReactComponent as IconPalm} from "../assets/svg/icon-phone-palm.svg";
import {ReactComponent as IconPercentage} from "../assets/svg/icon-percentage.svg";
import {ReactComponent as IconScale} from "../assets/svg/icon-scale.svg";

import Header from "../components/js/Header";

import "../css/ProductBarEdit.css"
import "../css/Ads.css"

import {useDispatch, useSelector} from "react-redux";
import {getIndexOf, isSessionValid, parseBeerCiderLabel, parseMapCaretChars} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    loadAvgPriceProducts, loadOwnProductsMarketPresence,
    loadProducts,
    setCreateMenuActive, setSidebarWidth,

} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import DropdownFilter from "../components/js/DropdownFilter";
import Input from "../components/js/Input";
import Loader from "react-loader-spinner";
import colors from "../constants/colors";

// in pixels as integer
export const BANNER_WIDTH = 350;
export const STATS_MODE = {
    price: 'PO CENI',
    percentage: 'PO VKLJUČENOSTI'
}

function StatisticsProducts(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let products = useSelector(state => state.reducer.products);

    const [ownProductChosen, setOwnProductChosen] = useState(null);
    const [competitionProductChosen, setCompetitionProductChosen] = useState(null);
    const [productChosen, setProductChosen] = useState(null);
    const [statsMode, setStatsMode] = useState(STATS_MODE.price);

    const [ownProducts, setOwnProducts] = useState([]);
    const [otherProducts, setOtherProducts] = useState([]);


    const [ownProductChosenList, setOwnProductChosenList] = useState([]);
    const [competitionProductChosenList, setCompetitionProductChosenList] = useState([]);

    const [avgPriceAll, setAvgPriceAll] = useState(null);
    const [avgPriceProduct, setAvgPriceProduct] = useState(null);

    const [ownedProductsMarketShare, setOwnedProductsMarketShare] = useState(null);
    const [percentageProduct, setPercentageProduct] = useState(null);

    const [loadingDataOwn, setLoadingDataOwn] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingDataPrice, setLoadingDataPrice] = useState(false);
    const [loadingDataMarketShare, setLoadingDataMarketShare] = useState(false);

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        window.statistics_page_open = true;
        window.active_submenu_item_stats = 2;
        dispatch(setCreateMenuActive(null));

        return () => {
            window.statistics_page_open = false;
        };
    }, []);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (user) {
                setLoadingDataPrice(true);
                setLoadingDataMarketShare(true);
                await dispatch(loadOwnProductsMarketPresence(user.user_id, (resData) => {
                    console.log("Products owned % stats successfully loaded..");
                    setLoadingDataMarketShare(false);
                    setOwnedProductsMarketShare(resData?.percentage_all);
                }, () => {
                    console.warn("Error loading products owned % stats ..")
                }));
                await dispatch(loadProducts(null, user, () => {
                    console.log("Products successfully loaded..");
                    done = true;
                }, () => {
                    console.warn("Error loading products..")
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [user]);

    useEffect(() => {
        if (products) {
            filterProducts(products)
        }
    }, [products]);

    useEffect(() => {
        if (products) {
            console.log("own/competition list re-draw");
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}

            let h = documentSize.y;
            if (competitionProductChosenList.length <= 5 && ownProductChosenList.length <= 5) {
                h += 300;
            }
            if (competitionProductChosenList.length > ownProductChosenList.length) {
                h += competitionProductChosenList.length * (50 + 10);
            } else {
                h += ownProductChosenList.length * (50 + 10);
            }
            setMainHeight(h)
        }
    }, [competitionProductChosenList, ownProductChosenList]);

    useEffect(() => {
        if (productChosen) {
            console.log("Product chosen", productChosen);

            dispatch(loadAvgPriceProducts(productChosen.product_id, (res) => {
                console.log("Products avg price successfully loaded..", res);
                setAvgPriceAll(res.avg_price_all);
                setAvgPriceProduct(res.avg_price_product);
                setPercentageProduct(res.percentage_product);
            }, (res) => {
                console.warn("Error loading products..", res)
            }));
        }
    }, [productChosen]);

    useEffect(() => {
        if (ownProductChosen) {
            console.log("Own Product chosen", ownProductChosen);
            setLoadingDataOwn(true);

            dispatch(loadAvgPriceProducts(ownProductChosen.product_id, (res) => {
                let l = [...ownProductChosenList];

                console.log("Products avg price successfully loaded..", res);
                setAvgPriceAll(res.avg_price_all);

                ownProductChosen.avg_price_product = res.avg_price_product;
                ownProductChosen.percentage_product = res.percentage_product;

                l.push(ownProductChosen);
                setOwnProductChosenList(l);

                setLoadingDataOwn(false);
            }, (res) => {
                setLoadingDataOwn(false);
                console.warn("Error loading products..", res)
            }));
        }
    }, [ownProductChosen]);

    useEffect(() => {
        if (competitionProductChosen) {
            console.log("Competition Product chosen", competitionProductChosen);
            setLoadingData(true);

            dispatch(loadAvgPriceProducts(competitionProductChosen.product_id, (res) => {
                let l = [...competitionProductChosenList];

                console.log("Products avg price successfully loaded..", res);
                setAvgPriceAll(res.avg_price_all);

                competitionProductChosen.avg_price_product = res.avg_price_product;
                competitionProductChosen.percentage_product = res.percentage_product;

                l.push(competitionProductChosen);
                setCompetitionProductChosenList(l);

                setLoadingData(false);
            }, (res) => {
                setLoadingData(false);
                console.warn("Error loading products..", res)
            }));
        }
    }, [competitionProductChosen]);

    useEffect(() => {
        if (statsMode) {
            console.log("Stats mode", statsMode);
        }
    }, [statsMode]);

    useEffect(() => {
        if (products) {
            dispatch(loadAvgPriceProducts(null, (res) => {
                console.log("Products avg price successfully loaded..", res);
                setAvgPriceAll(res.avg_price_all);
                setLoadingDataPrice(false);
            }, (res) => {
                setLoadingDataPrice(false);
                console.warn("Error loading products..", res)
            }));
        }
    }, [products]);

    const filterProducts = (allProducts) => {
        if (!products) {
            return {ownProducts: [], otherProducts: []};
        }

        const ownProducts = products.filter(product => allProducts.own_product === true);
        const otherProducts = products.filter(product => !ownProducts.includes(product));

        setOwnProducts(ownProducts);
        setOtherProducts(otherProducts);
    };


    if (!products) {
        return <></>;
    }

    const renderPriceStats = () => {
        return (
            <div className={'container-column-2'}>
                <div className={'column-2'} style={{width: '40%'}}>
                    <p className={'p-title-small'}>{'Povprečna cena prednastavljenih artiklov v bazi'}</p>
                    <div className={'stats-card'}>
                        <IconPalm className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                        {loadingDataPrice ?
                            <Loader
                                type="TailSpin"
                                color={colors.primary}
                                height={36}
                                width={36}
                                style={{paddingBottom: 8, alignSelf: 'center', marginRight: '36px'}}
                            />
                            : <p className={'p-stats'}>{!avgPriceAll ? '-' : avgPriceAll + ' €'}</p>
                        }
                    </div>
                </div>
                <div className={'column-2'} style={{width: '60%', paddingRight: '60px'}}>
                    <p className={'p-title-small'}>{'Primerjava povprečne cene lastnih izdelkov z konkurenčnimi'}</p>
                    <div className={'stats-card'}>
                        <IconPalm className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                        <DropdownFilter loadProducts label={'Ime artikla'} onChange={setProductChosen}
                                        options={ownProducts}
                                        style={{margin: 0, height: 'auto'}}
                                        grey
                                        placeholder={'Izberi ali vpiši lasten izdelek'}/>
                        <p className={'p-stats'}>{!avgPriceProduct ? '-' : avgPriceProduct + ' €'}</p>
                    </div>
                </div>
            </div>

        );
    }

    const renderPercentageStats = () => {
        return (
            <div className={'container-column-2'}>
                <div className={'column-2'} style={{width: '40%'}}>
                    <p className={'p-title-small'}>{'Procentualna vključenost lastnih artiklov v cenikih'}</p>
                    <div className={'stats-card'}>
                        <IconPercentage className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>

                        {loadingDataMarketShare ?
                            <Loader
                                type="TailSpin"
                                color={colors.primary}
                                height={36}
                                width={36}
                                style={{paddingBottom: 8, alignSelf: 'center', marginRight: '36px'}}
                            />
                            : <p className={'p-stats'}>{ownedProductsMarketShare === null ? '-' : Number(ownedProductsMarketShare) * 100 + ' %'}</p>
                        }

                    </div>

                </div>
                <div className={'column-2'} style={{width: '60%', paddingRight: '48px'}}>
                    <p className={'p-title-small'}>{'Procentualna vključenost posameznega artikla v cenikih'}</p>
                    <div className={'stats-card'}>
                        <IconPercentage className={'icon icon-stats'} width={'20px'} height={'22px'} title={'icon'}/>
                        <DropdownFilter loadProducts label={'Ime artikla'} onChange={setProductChosen}
                                        options={ownProducts}
                                        style={{margin: 0, height: 'auto'}}
                                        grey
                                        placeholder={'Izberi ali vpiši lasten izdelek'}/>
                        <p className={'p-stats'}>{!percentageProduct ? '-' : Number(percentageProduct) * 100 + ' %'}</p>
                    </div>
                </div>
            </div>

        );
    }

    const renderSubHeader = () => {
        return (
            <>
                <div className={'stats-subheader'} style={{left: `${sidebarWidth + 50}px`, marginBottom: '20px'}}>
                    <div className={`stats-selection ${statsMode === STATS_MODE.price ? 'selected' : ''}`}
                         onClick={() => setStatsMode(STATS_MODE.price)}>
                        {STATS_MODE.price}
                    </div>
                    <div className={`stats-selection ${statsMode === STATS_MODE.percentage ? 'selected' : ''}`}
                         onClick={() => setStatsMode(STATS_MODE.percentage)}>
                        {STATS_MODE.percentage}
                    </div>
                </div>
            </>

        );
    }

    const resetComparisonTables = () => {
        setCompetitionProductChosenList([]);
        setOwnProductChosenList([]);
    }

    function Item(props) {

        const removeFromList = (item, list, action) => {
            let l = [...list];
            let i = getIndexOf("product_id", item.product_id, list);
            l.splice(i, 1);
            action(l);
        }
        // console.log(props.value);
        const getMeasureUnit = (item) => {
            if (!item.beer_cider_type) {
                return item.measure_unit;
            }
            let m = '';
            if (item.measure_unit) {
                m += item.measure_unit;
            }
            if (item.measure_unit_2) {
                m += ', ';
                m += item.measure_unit_2;
            }
            if (item.measure_unit_3) {
                m += ', ';
                m += item.measure_unit_3;
            }
            return m;
        }
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'stats-row'} style={{
                left: `${sidebarWidth + 50}px`,
            }}>
                <div className={'row-name'}>{parseMapCaretChars(item.name)}</div>
                <div className={'stats-inner-row'}>
                    <div className={'row-package'}>{parseMapCaretChars(item.packaging)}</div>
                    <div className={'row-unit'}>{getMeasureUnit(item)}</div>
                </div>
                <div
                    className={'row-value'}>{statsMode === STATS_MODE.price ? Number(item?.avg_price_product) + ' €' : Number(item?.percentage_product) * 100 + ' %'}</div>
                <div className={'row-remove'} onClick={() => {
                    console.log("clicked remove product from list");
                    removeFromList(item, props.items, props.own ? setOwnProductChosenList : setCompetitionProductChosenList);
                }}>{'X'}</div>
            </li>
        );
    }

    function renderTable(items, ownProducts) {
        const list = (items) => {
            if (!items || items?.length <= 0) {
                return;
            }
            return (
                <ul className={'stats-item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} own={ownProducts} items={items} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {list(items)}
            </>
        );
    }

    const renderContent = () => {
        return (
            <div className={'stats-body-container'}>
                {statsMode && statsMode === STATS_MODE.price ? renderPriceStats() : renderPercentageStats()}
                <p className={'p-title-small'}
                   style={{paddingTop: '50px'}}>{'Procentualna vključenost posameznega artiklov v cenike'}</p>

                <div className={'container-column-2 stats'} style={{
                    background: 'white',
                    borderRadius: '5px',
                    padding: '50px',
                    marginRight: '60px',
                    paddingBottom: '50px',
                    width: 'auto'
                }}>
                    <div className={'column-2'}>
                        <DropdownFilter loadProducts label={'Ime lastnega artikla'} onChange={setOwnProductChosen}
                                        options={ownProducts}
                                        style={{margin: 0, paddingRight: '100px', height: 'auto'}}
                                        grey
                                        placeholder={'Izberi ali vpiši lasten izdelek'}/>
                        {renderTable(ownProductChosenList, true)}

                        {loadingDataOwn ?
                            <Loader
                                type="TailSpin"
                                color={colors.primary}
                                height={36}
                                width={36}
                                style={{paddingTop: 12, paddingBottom: 8, alignSelf: 'flex-end', marginRight: '105px'}}
                            />
                            : <></>
                        }

                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <IconScale className={'icon icon-stats icon-stats-scale'} width={'50px'} height={'60px'} title={'icon'}/>
                        <div onClick={resetComparisonTables}>
                            <p className={'p-title-small stats-reset'}
                               style={{paddingTop: '40px'}}>{'Začni znova'}</p>
                        </div>
                    </div>
                    <div className={'column-2'}>
                        <DropdownFilter loadProducts label={'Ime konkurenčnega artikla'}
                                        onChange={setCompetitionProductChosen}
                                        options={otherProducts}
                                        style={{
                                            width: '90%',
                                            alignSelf: 'flex-end',
                                            paddingRight: '50px',
                                            height: 'auto'
                                        }}
                                        grey
                                        placeholder={'Izberi ali vpiši konkurenčen izdelek'}/>
                        {renderTable(competitionProductChosenList)}

                        {loadingData ?
                            <Loader
                                type="TailSpin"
                                color={colors.primary}
                                height={36}
                                width={36}
                                style={{paddingTop: 12, paddingBottom: 8, alignSelf: 'flex-end', marginRight: '105px'}}
                            />
                            : <></>
                        }
                    </div>
                </div>
            </div>
        );
    }

    if (!statsMode) {
        return <></>
    }

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token &&
        <div><Page relative ad className={'main'} style={{height: `${mainHeight + 10}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <div className={'body'}
                 style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={`STATISTIKA / ARTIKLI`}/>
                {renderSubHeader()}
                {renderContent()}
            </div>
        </Page>
        </div>
}

export default StatisticsProducts;