import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import {ReactComponent as IconPrinter} from "../assets/svg/Icon feather-printer.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadUsers, loadUsersExternal, setActiveEditUser,
    setSidebarWidth
} from "../store/actions/actions";
import {
    displayAlert,
    displayDate,
    isSessionValid, isUserAdmin, isUserBarRepresentative, isUserCaretaker, isUserOwner, isUserSharepointAdmin,
    parseMapCaretChars,
    printDimensions
} from "../helpers/helpers";
import {Redirect} from "react-router";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import * as BiIcons from "react-icons/bi";
import ModalCreateUser from "../components/js/ModalCreateUser";
import ModalInfo from "../components/js/ModalInfo";
import Button from "../components/js/Button";

function UsersList(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);
    let users = useSelector(state => state.reducer.allUsers);
    let activeEditUser = useSelector(state => state.reducer.activeEditUser);

    const [shouldNavigate, setShouldNavigate] = useState(false);

    const [createDisplayModal, setCreateDisplayModal] = useState(false);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    const [usersExternal, setUsersExternal] = useState([]);
    const [toggleFilter, setToggleFilter] = useState(!!window.toggle_users_external);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onLoadUsers = () => {
        dispatch(loadUsers(user.user_id, () => {
            console.log("Users successfully loaded..");
        }, () => {
            setModalValues(false)
        }));
    }

    const onLoadUsersExternal = () => {
        dispatch(loadUsersExternal(user.user_id, (users) => {
            setUsersExternal(users);
            console.log("External users successfully loaded..");
        }, () => {
            setModalValues(false)
        }));
    }

    useEffect(() => {
        if (user) {
            onLoadUsers();
            onLoadUsersExternal();
        }
    }, [user]);


    useEffect(() => {
        if (activeEditUser && shouldNavigate) {
            onNavigateTo(PAGES.EDIT_USER);
        }

    }, [activeEditUser, shouldNavigate]);

    const navigateAndLoadEditUser = async (user) => {
        await dispatch(setActiveEditUser(user));
        setTimeout(() => setShouldNavigate(true), 100);
    };

    useEffect(() => {
        console.log("USERS:", users)
    }, [users]);

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (success) => {
        setDisplayModalInfo(true);

        if (success) {
        } else {
            setModalInfoTitle(`Napaka`);
            setModalInfoText(`Napaka pri pridobivanju seznama uporabnikov. Prosimo poskusite znova..`);
            console.warn("Error loading users..");
            setModalInfoAction(() => {
                return () => {
                    setDisplayModalInfo(false);
                }
            })
        }
    };

    function Item(props) {
       // console.log(props.value);
        const item = props.value;
        if (isUserOwner(item) || isUserBarRepresentative(item)) {
            return <></>
        }
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-bar'}>{item.external_id}</div>
                <div className={'row-date'}>{parseMapCaretChars(item.full_name)}</div>
                <div className={'row-date'}>{item.username}</div>
                <div className={'row-dimension'}>{parseMapCaretChars(item.type)}</div>

                <div className={'row-edit'} style={{visibility: isUserAdmin(user) ? 'visible' : 'hidden'}}><a
                    className={'a-icon'}
                    onClick={async () => await navigateAndLoadEditUser(item)}><IconEdit
                    className={'icon'}
                    width={'24px'} height={'24px'}
                    title={'icon list'}/></a>
                </div>
            </li>
        );
    }

    function renderTable(items) {
        if (toggleFilter) {
            return <></>
        }
        const header = () => {
            return (
                <div className={'row-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-bar'}>External ID</div>
                    <div className={'row-date'}>Polno ime</div>
                    <div className={'row-date'}>E-mail</div>
                    <div className={'row-dimension'}>Tip</div>
                    <div className={'row-edit'} style={{visibility: isUserAdmin(user) ? 'visible' : 'hidden'}}>Uredi
                    </div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    function ItemExternal(props) {
        // console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-bar'}>{parseMapCaretChars(item.bar_name)}</div>
                <div className={'row-date'}>{parseMapCaretChars(item.full_name)}</div>
                <div className={'row-date'}>{item.username}</div>
                <div className={'row-dimension'}>{parseMapCaretChars(item.type)}</div>

                <div className={'row-edit'} style={{visibility: isUserAdmin(user) ? 'visible' : 'hidden'}}><a
                    className={'a-icon'}
                    onClick={async () => await navigateAndLoadEditUser(item)}><IconEdit
                    className={'icon'}
                    width={'24px'} height={'24px'}
                    title={'icon list'}/></a>
                </div>
            </li>
        );
    }

    function renderTableExternal(items) {
        if (!toggleFilter) {
            return <></>
        }
        const header = () => {
            return (
                <div className={'row-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-bar'}>Bar</div>
                    <div className={'row-date'}>Polno ime</div>
                    <div className={'row-date'}>E-mail</div>
                    <div className={'row-dimension'}>Tip</div>
                    <div className={'row-edit'} style={{visibility: isUserAdmin(user) ? 'visible' : 'hidden'}}>Uredi
                    </div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <ItemExternal key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    const renderCreateUserButton = () => {
        return <>
            <div className={'sharepoint toolbar-button sharepoint-button'}
                 style={{display: isUserAdmin(user) ? 'flex' : 'none', zIndex: 99}}
                 onClick={() => setCreateDisplayModal(true)}>
                <BiIcons.BiUserPlus className={'sharepoint icon toolbar-icon'} style={{color: 'gray'}}/>
            </div>
        </>
    }

    const renderModal = () => {
        return (
            createDisplayModal ?
                <ModalCreateUser user create
                                 actionClose={() => {
                                     console.log('display modal, false');
                                     setCreateDisplayModal(false)
                                 }} actionUserCreatedSuccess={() => {
                    onLoadUsers();
                    onLoadUsersExternal();
                }}/> : <></>
        );
    };

    const onToggleFilter = (state) => {
        setToggleFilter(state);
        window.toggle_users_external = state;
    }

    const renderUsersFilter = () => {
        return <div className={'button-selection-filter button-selection-users'}>
            <Button className={'button-selection'} text={'INTERNI'} selected={!toggleFilter}
                    action={() => onToggleFilter(!toggleFilter)}/>
            <Button className={'button-selection'} text={'ZUNANJI'} selected={toggleFilter}
                    action={() => onToggleFilter(!toggleFilter)}/>
        </div>;
    }

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 250)
    }, [])

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page className={'main'} style={{height: `${mainHeight}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <Header left={`${sidebarWidth + 50}px`} title={'UPORABNIŠKI RAČUNI'} createAction={renderCreateUserButton}/>

            {renderUsersFilter()}

            <div className={'body'}>
                {renderTable(users)}
                {renderTableExternal(usersExternal)}
            </div>
        </Page>
            {renderModal()}
            {renderModalInfo()}
        </div>

}

export default UsersList;

