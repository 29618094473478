import React, {useState, useEffect, useCallback} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import '../css/Home.css';

import {ReactComponent as IconAddPrimary} from '../assets/svg/Icon ionic-ios-add-circle-outline-primary.svg';
import {ReactComponent as IconFeather} from '../assets/svg/Icon feather-edit.svg';
import {ReactComponent as IconNotification} from '../assets/svg/Icon feather-material-notifications-none.svg';
import {ReactComponent as IconUpload} from '../assets/svg/Icon feather-upload-primary.svg';
import {ReactComponent as IconBar} from '../assets/svg/Icon material-local-bar-primary.svg';
import {ReactComponent as IconDrink} from '../assets/svg/Icon material-local-drink-primary.svg';

import Sidebar from "../components/js/Sidebar";
import Card from "../components/js/Card";
import Header from "../components/js/Header";
import {PAGES} from "../App";
import {
    loadAdminAllNotifications,
    loadNotifications,
    loadSessionUser, setActiveBar,
    setActiveMenu,
    setCreateMenuActive, setMenuLastCreated, setNotificationsAvailable, setSidebarWidth
} from "../store/actions/actions";
import {NOTIFICATION_STATUS, SESSION_STORAGE_KEYS, USER_ROLES} from "../constants/enums";
import {Redirect} from "react-router";
import {
    isSessionValid,
    isUserAdmin,
    isUserCaretaker,
    parseFirstName,
    parseMapCaretChars,
    parseUserRole
} from "../helpers/helpers";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";

export const TABLET_ZOOM = 0.8;

function Home(props) {
    let dispatch = useDispatch();

    let user = useSelector(state => state.reducer.user);
    let token = useSelector(state => state.reducer.userToken);
    const userCaretaker = useSelector(state => state.reducer.userCaretaker);
    const notifications = useSelector(state => state.reducer.notifications);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        console.log("USER + TOKEN:", user, token)
    }, [user, token])

    useEffect(() => {
        if (userCaretaker) {
            if (parseUserRole(user.type) === USER_ROLES.ADMIN) {
                dispatch(loadAdminAllNotifications(userCaretaker['caretaker_id'], () => {
                    console.log("Notifications successfully loaded..");
                }, () => {
                    console.warn("Error loading notifications..")
                }));
            } else if (parseUserRole(user.type) === USER_ROLES.CARETAKER) {
                dispatch(loadNotifications(userCaretaker['caretaker_id'], () => {
                    console.log("Notifications successfully loaded..");
                }, () => {
                    console.warn("Error loading notifications..")
                }));
            }
        }
    }, [userCaretaker]);

    useEffect(() => {
        if (notifications) {
            for (let i = 0; i < notifications.length; i++) {
                if (notifications[i].status === NOTIFICATION_STATUS.PENDING) {
                    dispatch(setNotificationsAvailable(true));
                    return;
                }
            }
            dispatch(setNotificationsAvailable(false));
        }
    }, [notifications]);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onNavigateToState = useCallback(((path, state) => {
        history.push(path, state);
    }), [history]);

    const renderWelcomeHeader = () => {
        if (!user) {
            return '';
        }
        let firstName = parseFirstName(user?.full_name ? user.full_name : user?.username);
        let s = `Pozdravljeni, `;
        s += isUserAdmin(user) ? `admin ${parseMapCaretChars(firstName)}` : isUserCaretaker(user) ? `potnik ${parseMapCaretChars(firstName)}` : `vodja ${parseMapCaretChars(firstName)}`;
        return s;
    };

    const onNavigateActionReset = (path, beer_cider) => {
        window.beer_cider_menu = beer_cider;

        dispatch(setActiveMenu(null));
        dispatch(setActiveBar(null));
        dispatch(setCreateMenuActive(null));
        dispatch(setMenuLastCreated(null));
        onNavigateTo(path);
    };

    const renderBarRepresentativeView = () => {
        return <>
            <Card style={{zoom: TABLET_ZOOM}}
                onClick={() => onNavigateTo(PAGES.EDIT_PUBLISHED_MENU)} title={"UREDI CENE CENIKA"}
                icon={<IconBar className={'icon primary'} width={'85px'} height={'85px'}
                                      title={'icon add'}/>}/>
            <Card style={{zoom: TABLET_ZOOM}} onClick={() => onNavigateToState(PAGES.BARS_LIST, {search: 'quickLiveEdit'})} title={"UREDI TRENUTNI CENIK"}
                  icon={<IconDrink className={'icon primary'} width={'85px'} height={'85px'}
                                   title={'icon edit'}/>}/>
            <Card style={{zoom: TABLET_ZOOM}} onClick={() => onNavigateTo(PAGES.BARS_LIST)} title={"UREDI BAR"}
                  icon={<IconDrink className={'icon primary'} width={'85px'} height={'85px'}
                                     title={'icon edit'}/>}/>

        </>
    }

    const renderCaretakerView = () => {
        return <>
            <div className={'home-cards-container caretaker'}>
                <Card
                    onClick={() => onNavigateActionReset(PAGES.CREATE_MENU, false)} title={"USTVARI NOV CENIK"}
                    icon={<IconAddPrimary className={'icon primary'} width={'85px'} height={'85px'}
                                          title={'icon add'}/>}/>
                <Card
                    onClick={() => onNavigateActionReset(PAGES.CREATE_MENU, true)} title={"USTVARI PIVSKO KARTO"}
                    icon={<IconAddPrimary className={'icon primary'} width={'85px'} height={'85px'}
                                          title={'icon add'}/>}/>
            </div>
            <Card onClick={() => onNavigateTo(PAGES.MENUS_LIST)} title={"UREDI CENIK"}
                  icon={<IconFeather className={'icon primary'} width={'75px'} height={'75px'}
                                     title={'icon edit'}/>}/>
        </>
    }

    const renderAdminView = () => {
        return <>
            <Card
                onClick={() => onNavigateTo(PAGES.IMPORT_FILE)} title={"UVOZI DATOTEKO"}
                icon={<IconUpload className={'icon primary'} width={'85px'} height={'85px'}
                                      title={'icon add'}/>}/>
            <Card onClick={() => onNavigateTo(PAGES.NOTIFICATIONS)} title={"OBVESTILA"}
                  icon={<IconNotification className={'icon primary'} width={'75px'} height={'75px'}
                                     title={'icon edit'}/>}/>
        </>
    }

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && user && <div><Page relative className={'main before'} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
         {/*<div className={'main before'}>*/}
         {/*   <Sidebar/>*/}
         {/*   <div className={'page relative'}>*/}
                <Header left={`${sidebarWidth + 50}px`} welcome={renderWelcomeHeader()}/>
                <div className={'body'} style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                    <div className={'home'}>
                        {isUserAdmin(user) ? renderAdminView() : isUserCaretaker(user) ? renderCaretakerView() : renderBarRepresentativeView()}
                    </div>
                </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default Home;