import React, {useState, useCallback, useEffect} from "react";

import '../components/css/Tables.css'

import {useDispatch, useSelector} from "react-redux";

import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import {ReactComponent as IconPrinter} from "../assets/svg/Icon feather-printer.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useHistory} from "react-router-dom";
import {PAGES} from "../App";
import {
    loadAds,
    loadMenu,
    loadMenus,
    setCreateMenuActive, setSidebarWidth
} from "../store/actions/actions";
import {
    displayAlert,
    displayDate,
    findItem,
    isSessionValid, isUserBarRepresentative, isUserCaretaker, isUserOwner,
    parseLeadingBrand, parseMapCaretChars,
    printDimensions
} from "../helpers/helpers";
import {USER_ROLES} from "../constants/enums";
import {Redirect} from "react-router";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import Loader from "react-loader-spinner";
import colors from "../constants/colors";
import ModalInfo from "../components/js/ModalInfo";

function MenusList(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let menus = useSelector(state => state.reducer.menusHistory);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);

    const [state, setState] = useState(props.location.state);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    console.log(mainHeight);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    console.log("STATE:", state);

    const navigateAndLoadEditMenu = async (path, menu) => {

        await dispatch(loadMenu(menu, () => {
            console.log("Menu loaded successfully!");
            onNavigateTo(path);
        }, (e) => {
            console.log(e)
        }));
    };

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        if (activeBar) {
            console.log(activeBar)
            dispatch(loadMenus(activeBar['bar_id'], () => {
            }, () => setModalValues(false)));
        }
    }, [activeBar]);

    useEffect(() => {
        if (menus) {
            console.log(menus)
        }
    }, [menus]);
    useEffect(() => {
        console.log("STATE:", state)
        if (state && menus && menus.length > 0) {
            navigateAndLoadEditMenu(PAGES.CREATE_MENU, menus[0]).then(r => console.log("done"))
        }

    }, [menus]);

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (success) => {
        setDisplayModalInfo(true);

        if (success) {
        } else {
            setModalInfoTitle(`Napaka`);
            setModalInfoText(`Napaka pri pridobivanju seznama cenikov. Prosimo poskusite znova..`);
            setModalInfoAction(() => {
                return () => {
                    setDisplayModalInfo(false);
                }
            })
        }

    };


    function Item(props) {
        // console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={'row-menu-active absolute'}>{item.active ? '●' : ''}</div>
                <div className={'row-bar'}>{parseMapCaretChars(activeBar.name)}</div>
                <div className={'row-date'}>{displayDate(item.date_create)}</div>
                <div className={'row-date'}>{displayDate(item.valid_from)}</div>
                <div className={'row-dimension'}>{printDimensions(item)}</div>
                <div className={'row-leading-brand'}>{parseLeadingBrand(item.main_brand)}</div>

                <div className={'row-edit'}
                     style={{visibility: isUserCaretaker(user) || isUserBarRepresentative(user) || isUserOwner(user) ? 'visible' : 'hidden'}}>
                    <a
                        className={'a-icon'}
                        onClick={async () => await navigateAndLoadEditMenu(PAGES.CREATE_MENU, item)}><IconEdit
                        className={'icon'}
                        width={'24px'} height={'24px'}
                        title={'icon list'}/></a></div>
                <div className={'row-print'}
                     style={{visibility: isUserCaretaker(user) || isUserBarRepresentative(user) || isUserOwner(user) ? 'visible' : 'hidden'}}>
                    <a
                        className={'a-icon'}
                        onClick={async () => await navigateAndLoadEditMenu(PAGES.CREATE_MENU_PREVIEW, item)}><IconPrinter
                        className={'icon'}
                        width={'24px'}
                        height={'24px'}
                        title={'icon printer'}/></a>
                </div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <div className={'row-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <p className={'active-menu label'}><span>●</span> trenutno aktiven cenik</p>
                    <div className={'row-bar'}>Lokal</div>
                    <div className={'row-date'}>Datum izdelave</div>
                    <div className={'row-date'}>Datum veljavnosti</div>
                    <div className={'row-dimension'}>Dimenzija</div>
                    <div className={'row-leading-brand'}>Glavna znamka</div>
                    <div className={'row-edit'}
                         style={{visibility: isUserCaretaker(user) ? 'visible' : 'hidden'}}>Uredi
                    </div>
                    <div className={'row-print'}></div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token &&
        <div><Page className={'main'} style={{height: `${documentSize.y + 10}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <Header left={`${sidebarWidth + 50}px`} title={'OBSTOJEČI CENIKI'}/>
            <div className={'body'}>
                {renderTable(menus)}
            </div>
            {state ?
                <Loader
                    type="TailSpin"
                    color={colors.primary}
                    height={36}
                    width={36}
                    style={{paddingTop: 12, paddingBottom: 8, alignSelf: 'flex-end', marginRight: '105px'}}
                />
                : <></>
            }
        </Page>
            {renderModalInfo()}
        </div>

}

export default MenusList;

