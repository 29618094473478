import React, {useState, useEffect, useRef} from "react";
import Input from "../js/Input";

import "../css/Modal.css";

import * as IoIcons from "react-icons/io";
import Button from "../js/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    createNewUser
} from "../../store/actions/actions";
import {
    isNewObjValidCheck,
    mapCaretChars
} from "../../helpers/helpers";

import Loader from "react-loader-spinner";
import colors from "../../constants/colors";
import Dropdown from "./Dropdown";
import {USER_ROLES, USER_ROLES_OPTIONS} from "../../constants/enums";
import ModalInfo from "./ModalInfo";

const CREATE_ACTION = {
    CREATE_USER: 0,
}

const NEW_USER_REQUIRED_FIELDS = {
    'username': 'E-pošta'
}

function ModalCreateUser(props) {
    console.log("Open modal");

    let dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);
    const userCaretaker = useSelector(state => state.reducer.userCaretaker);

    const [name, setName] = useState("");
    const [externalId, setExternalId] = useState(null);
    const [email, setEmail] = useState(null);
    const [address, setAddress] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [userType, setUserType] = useState(null);

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [invalidInputs, setInvalidInputs] = useState([]);

    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    useEffect(() => {
    }, [])


    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    useEffect(() => {
        console.log(name);
        console.log(email);
        console.log(address);
        console.log(telephone);
        console.log(userType);


    }, [name, email, address, telephone, userType])


    useEffect(() => {
        if (props.create && props.user) {
            console.log(props.create)
        }
    }, [props.user])

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.CREATE_USER:
                    confirmCreateNewUser();
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text, action) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
                if (action) {
                    action()
                }
            }
        })
    };


    const confirmCreateNewUser = () => {
        setLoadingSpinner(true);

        if (!email) {
            setModalValues('Manjka vrednost v polju: ' + NEW_USER_REQUIRED_FIELDS.username);
            setCreateTrigger(false);
            setInvalidInputs(['username']);
            setLoadingSpinner(false);
            return;
        }
        let newUser = {
            external_id: externalId,
            full_name: mapCaretChars(name) ? mapCaretChars(name) : "Uporabnik",
            username: mapCaretChars(email),
            address: mapCaretChars(address),
            telephone: telephone,
            type: userType.value
        }

        // check data validity
        let response = isNewObjValidCheck(newUser, NEW_USER_REQUIRED_FIELDS);

        if (!response.isValid) {
            setModalValues(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            setLoadingSpinner(false);
            return;
        }

        // save new user to database
        dispatch(createNewUser(userCaretaker.caretaker_id, newUser, (res) => {
            console.log("User successfully created..", res);

            setTimeout(() => {

                setModalValues('Nov uporabnik uspešno ustvarjen..', () => {
                    props.actionUserCreatedSuccess(res);
                    setCreateTrigger(false);

                    props.actionClose();
                    setLoadingSpinner(false);
                })
            }, 700)
        }, (res) => {

            setModalValues('Napaka pri kreaciji novega uporabnika..', () => {
                props.actionClose();

                setCreateTrigger(false);
                setLoadingSpinner(false);
            });
        }));
    }


    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };


    const renderHeader = (title, actionClose) => {
        return (
            <div className={'modal-header'}>
                <p>{title}</p>
                <div onClick={actionClose} style={{cursor: 'pointer'}}><IoIcons.IoMdClose
                    className={'icon-close'}/>
                </div>
            </div>
        );
    };

    const renderFooter = (text, actionCreate) => {
        return (
            <Button text={text} confirm action={actionCreate} style={{
                alignSelf: 'flex-end',
                marginRight: 0
            }}/>
        );
    };

    const renderCreateUser = () => {
        return (
            <div ref={ref} className={'modal-overlay'}>
                <div className={'modal modal-product modal-height modal-auto-height modal-user-create'}>
                    {renderHeader('USTVARI NOVEGA UPORABNIKA', props.actionClose)}
                    <div className={'modal-body'} style={{overflowY: 'visible'}}>


                        <div className={'custom-row'}>
                            <Input onSubmit={() => {
                            }} onChange={setExternalId}
                                   label={'External ID'}
                                   placeholder={'Zunanji ID uporabnika'}
                                   style={{
                                       marginBottom: '24px',
                                   }}
                            />
                            <Input onSubmit={() => {
                            }} onChange={setName}
                                   label={'Polno ime'}
                                   placeholder={'Ime uporabnika'}
                                   style={{
                                       marginBottom: '24px',
                                   }}
                            />
                            <Input onSubmit={() => {
                            }} onChange={setEmail}
                                   label={'E-pošta'}
                                   placeholder={'E-pošta uporabnika'}
                                   style={{
                                       marginBottom: '24px',
                                       border: invalidInputs.includes('username') ? "1px solid red" : "none"
                                   }}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       setInvalidInputs([]);
                                   }}/>
                        </div>

                        <div className={'custom-row'}>
                            <Input onSubmit={() => {
                            }} onChange={setAddress}
                                   label={'Naslov'}
                                   placeholder={'Naslov'}
                                   style={{
                                       marginBottom: '24px',
                                   }}/>
                            <Input onSubmit={() => {
                            }} onChange={setTelephone}
                                   label={'Telefonska številka'}
                                   placeholder={'Telefonska številka'}
                                   style={{
                                       marginBottom: '24px',
                                   }}/>

                        </div>
                        <Dropdown userType label={'Tip uporabnika'} onChange={setUserType}
                                  placeholder={'Tip'} options={USER_ROLES_OPTIONS} value={userType}
                                  grey/>


                    </div>
                    {loadingSpinner ?
                        <Loader
                            type="TailSpin"
                            color={colors.primary}
                            height={36}
                            width={36}
                            style={{paddingTop: 12, paddingBottom: 8, alignSelf: 'flex-end', marginRight: '105px'}}
                        /> : userType ? renderFooter('USTVARI', () => triggerAction(CREATE_ACTION.CREATE_USER)) : <></>
                    }
                </div>
            </div>
        );
    };

    const renderBody = () => {
        if (props.user && props.create) {
            return renderCreateUser();
        } else {
            return <></>
        }
    }

    return <>
        {renderBody()}
        {renderModalInfo()}
    </>

}

export default ModalCreateUser;