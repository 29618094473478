import React, { useState, useEffect } from "react";
import Select from "react-select";
import {parseMapCaretChars} from "../../helpers/helpers";

function BarQrScansDropdown({ onChange, placeholder, options }) {
    const [selectedOption, setSelectedOption] = useState(null);
    const [localOptions, setLocalOptions] = useState([]);

    useEffect(() => {
        if (options) {
            const parsedBars = options.map((bar) => {
                return { value: JSON.parse(JSON.stringify(bar)), label: parseMapCaretChars(bar.name)};
            });
            setLocalOptions(parsedBars);
        }
    }, [options]);

    const onInputChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption.value);
    };

    return (
        <form>
            <label className={"input-label"} style={{paddingTop: '24px'}}>
                <p className={"p-title-small"}>{"Izberite bar za prikaz QR skeniranj po urah"}</p>
                <Select
                    className={"select-default small-height"}
                    classNamePrefix={"select-default"}
                    defaultValue={selectedOption}
                    onChange={onInputChange}
                    options={localOptions}
                    placeholder={placeholder}
                />
            </label>
        </form>
    );
}

export default BarQrScansDropdown;
