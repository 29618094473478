import React, {useState, useEffect, createRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import FileUpload from "../components/js/FileUpload";
import Input from "../components/js/Input";

import "../css/CreateComboOffer.css"
import * as CgIcons from "react-icons/cg";

import Modal from "../components/js/Modal";
import {
    createNewProduct,
    loadComboOffers,
    setCreateMenuActive,
    setSidebarWidth,
    updateProduct
} from "../store/actions/actions";
import Button from "../components/js/Button";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {hasItem, isSessionValid, mapCaretChars, parseMapCaretChars} from "../helpers/helpers";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";
import countryEN from "../assets/img/country_en.png";

function CreateComboOffer(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let comboOffers = useSelector(state => state.reducer.comboOffers);
    const [name, setName] = useState(null);
    const [descSlo, setDescSlo] = useState(null);
    const [descEng, setDescEng] = useState(null);
    const [nameEn, setNameEn] = useState(null);
    const [nameDe, setNameDe] = useState(null);
    const [nameIt, setNameIt] = useState(null);
    const [nameHr, setNameHr] = useState(null);
    const [descIt, setDescIt] = useState(null);
    const [descDe, setDescDe] = useState(null);
    const [descHr, setDescHr] = useState(null);

    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [createTrigger, setCreateTrigger] = useState(false);

    useEffect(() => {
        dispatch(setCreateMenuActive(null));
    }, []);

    useEffect(() => {
        dispatch(loadComboOffers(null, () => {
            console.log("Combo Offers successfully loaded..");
        }, () => {
            console.warn("Error loading combo offers..")
        }));

    }, []);

    useEffect(() => {
        console.log("Combo offers:", comboOffers);

        if (createTrigger && name) {
            const asyncEffect = async () => {
                let product = {
                    name: mapCaretChars(name),
                    initial_price: 0,
                    bar_id: null,
                    picture: null,
                    slo_descr: mapCaretChars(descSlo),
                    eng_descr: mapCaretChars(descEng),
                    eng_name: mapCaretChars(nameEn),
                    de_descr: mapCaretChars(descDe),
                    de_name: mapCaretChars(nameDe),
                    it_descr: mapCaretChars(descIt),
                    it_name: mapCaretChars(nameIt),
                    hr_descr: mapCaretChars(descHr),
                    hr_name: mapCaretChars(nameHr),
                    packaging: null,
                    measure_unit: null,
                    allergenes: null,
                    alcohol_percent: null,
                    combo_offer: true
                };
                if (!hasItem('name', 'name', product.name, comboOffers)) {
                    await dispatch(createNewProduct(product, true, () => {
                        alert(`Izdelek ${name} uspešno dodan.`);
                    }, () => {
                        console.warn("Error creating combo offer!")
                        alert("Napaka pri dodajanju izdelka...");
                        setCreateTrigger(false);
                    }));
                } else {
                    alert(`Izdelek z imenom "${parseMapCaretChars(product.name)}" že obstaja v bazi!`);
                    setCreateTrigger(false);
                }
            }
            asyncEffect().then(() => console.log("Combo offer post done.."));
        }
    }, [createTrigger, comboOffers]);

    let windowSize = {x: 0, y: document.documentElement.scrollHeight}
    let inputStyle = {marginBottom: '48px'};
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token &&
        <div><Page relative className={'main'} style={{height: `${windowSize.y + 48}px`}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative'}>*/}
            <div className={'body combo-offer-create-container'}
                 style={{paddingLeft: `${sidebarWidth + 50}px`, paddingTop: '24px'}}>
                <Header left={`${sidebarWidth + 50}px`} title={'IZDELAVA COMBO PONUDBE'}/>

                <Input white onChange={setName} placeholder={''} label={'Ime combo ponudbe'}
                       style={{marginBottom: '0px', width: '100%'}}/>

                <Input onSubmit={() => {
                }} textarea
                       style={{background: '#F0F0F0', minHeight: '115px', height: '115px', maxHeight: '115px'}}
                       onChange={setDescSlo} placeholder={'Opis artikla (maksimalno število znakov 140)'}
                />

                <div
                    className={'country-label-container'}
                    style={{alignSelf: 'self-end', marginBottom: '-20px', paddingRight: '60px'}}>
                    <img src={countryEN} alt="EN" width={'25px'}/>
                </div>
                <Input white onChange={setNameEn} placeholder={'Angleški naziv izdelka'}
                       label={'Angleški naziv izdelka'}
                       style={inputStyle}
                />
                <Input white onChange={setDescEng} placeholder={'Angleški opis'}
                       label={'Angleški opis'}
                       style={inputStyle}
                />
                <div
                    className={'country-label-container'}
                    style={{alignSelf: 'self-end', marginBottom: '-20px', paddingRight: '60px'}}>
                    <img src={countryDE} alt="DE" width={'25px'}/>
                </div>
                <Input white onChange={setNameDe} placeholder={'Nemški naziv izdelka'}
                       label={'Nemški naziv izdelka'}
                       style={inputStyle}
                />
                <Input white onChange={setDescDe} placeholder={'Nemški opis'}
                       label={'Nemški opis'}
                       style={inputStyle}
                />
                <div
                    className={'country-label-container'}
                    style={{alignSelf: 'self-end', marginBottom: '-20px', paddingRight: '60px'}}>
                    <img src={countryIT} alt="IT" width={'25px'}/>
                </div>
                <Input white onChange={setNameIt} placeholder={'Italijanski naziv izdelka'}
                       label={'Italijanski naziv izdelka'}
                       style={inputStyle}
                />
                <Input white onChange={setDescIt} placeholder={'Italijanski opis'}
                       label={'Italijanski opis'}
                       style={inputStyle}
                />
                <div
                    className={'country-label-container'}
                    style={{alignSelf: 'self-end', marginBottom: '-20px', paddingRight: '60px'}}>
                    <img src={countryHR} alt="HR" width={'25px'}/>
                </div>
                <Input white onChange={setNameHr} placeholder={'Hrvaški naziv izdelka'}
                       label={'Hrvaški naziv izdelka'}
                       style={inputStyle}
                />
                <Input white onChange={setDescHr} placeholder={'Hrvaški opis'}
                       label={'Hrvaški opis'}
                       style={inputStyle}
                />


                <Button text={'USTVARI POSEBNO PONUDBO'} confirm action={() => {
                    setCreateTrigger(true)
                }} style={{
                    margin: 0, marginTop: '36px', width: '350px'
                }}/>
            </div>
            {/*</div>*/}
        </Page>
        </div>
}

export default CreateComboOffer;