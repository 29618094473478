import {useHistory} from "react-router-dom";
import React, {useCallback, useEffect} from "react";

import '../css/Card.css'
import '../css/Footer.css';

import * as FaIcons from "react-icons/fa";

import {PAGES} from "../../App";
import Loader from "react-loader-spinner";
import colors from "../../constants/colors";
import Page from "./Page";
import {isRememberMeEnabled, loadCredentials} from "../../store/actions/actions";

function Footer(props) {

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        if (props.onLoading) {
        }
        console.log("PROPS LOADING", props.onLoading)

    }, [props.onLoading]);
    console.log("PROPS LOADING", props.onLoading)

    const onAction = (url, isNext) => {
        if (props.onActionNext && isNext) {
            if (!props.onActionNext()) {
                return;
            }
        } else if (props.onActionBack && !isNext) {
            if (!props.onActionBack()) {
                return;
            }
        } else {
            if (props.onAction) {
                if (!props.onAction()) {
                    return;
                }
            }
        }
        onNavigateTo(url);
    };


    return (
        <div className={'footer'} style={{left: props.left}}>
            <a onClick={() => onAction(props.urlBack, false)} style={{opacity: props.back ? 1 : 0}}><FaIcons.FaArrowLeft
                style={{}}/>{props.back}</a>
            <div className={'progress'} style={{opacity: props.progress ? 1 : 0}}>
                <div className={props.progress === 1 ? 'dot active' : 'dot'}/>
                <div className={props.progress === 2 ? 'dot active' : 'dot'}/>
                <div className={props.progress === 3 ? 'dot active' : 'dot'}/>
                <div className={props.progress === 4 ? 'dot active' : 'dot'}/>
                <div className={props.progress === 5 ? 'dot active' : 'dot'}/>
            </div>
            {props.onLoading ?
                <Loader
                    type="TailSpin"
                    color={colors.white}
                    height={36}
                    width={36}
                    style={{paddingRight: '52px'}}
                /> :
                <a onClick={() => onAction(props.urlNext, true)}
                   style={{opacity: props.next ? 1 : 0}}>{props.next}<FaIcons.FaArrowRight/></a>
            }
        </div>
    );
}

export default Footer;

