import React, {useState, useEffect, useCallback} from "react";

import {useDispatch, useSelector} from "react-redux";

import Input from "../components/js/Input";
import Button from "../components/js/Button";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import Footer from "../components/js/Footer";
import ButtonGroupSelection, {GenerateMenuType} from "../components/js/ButtonGroupSelection";
import {
    autoPublishMenu,
    createNewMenu,
    createNewMenuCategoryProductRow,
    createNewMenuDirect,
    createNewMenuProductPriceRow,
    previewMenu,
    previewMenuParseOnBE,
    setCreateMenuActive,
    setIsCreatingMenu,
    setSidebarWidth
} from "../store/actions/actions";
import {PAGES} from "../App";
import {
    deleteLocalMenu,
    displayAlert,
    isSessionValid, isUserBarRepresentative, isUserCaretaker, isUserOwner,
    mapCaretChars,
    parseMapCaretChars,
    saveToLocalStorage,
    validateUserInput
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {useHistory} from "react-router-dom";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {FORMAT, L10N_FIELDS, L10N_MENU} from "../constants/enums";
import countryDE from "../assets/img/country_de.png";
import countryIT from "../assets/img/country_it.png";
import countryHR from "../assets/img/country_hr.png";
import countryEN from "../assets/img/country_en.png";
import countrySI from "../assets/img/country_si.png";
import Loader from "react-loader-spinner";
import colors from "../constants/colors";
import ModalInfo from "../components/js/ModalInfo";

function CreateMenuPreview(props) {
    const dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);

    const createMenuActive = useSelector(state => state.reducer.createMenuActive);
    const user = useSelector(state => state.reducer.user);
    const caretaker = useSelector(state => state.reducer.userCaretaker);
    const menuLastCreated = useSelector(state => state.reducer.menuLastCreated)
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [languageSelected, setLanguageSelected] = useState(createMenuActive && createMenuActive.l10n ? createMenuActive.l10n : L10N_MENU.si.value)

    const [languageSelectedEn, setLanguageSelectedEn] = useState(createMenuActive && createMenuActive.l10n_en ? createMenuActive.l10n_en : false)
    const [languageSelectedDe, setLanguageSelectedDe] = useState(createMenuActive && createMenuActive.l10n_de ? createMenuActive.l10n_de : false)
    const [languageSelectedIt, setLanguageSelectedIt] = useState(createMenuActive && createMenuActive.l10n_it ? createMenuActive.l10n_it : false)
    const [languageSelectedHr, setLanguageSelectedHr] = useState(createMenuActive && createMenuActive.l10n_hr ? createMenuActive.l10n_hr : false)

    console.log("LANG SEL", languageSelected);

    const [qrType, setQrType] = useState(createMenuActive ? createMenuActive.qr_type : null);
    const [quantityQRCode, setQuantityQRCode] = useState(createMenuActive ? createMenuActive.quantity_qr : null);
    const [quantityA5, setQuantityA5] = useState(createMenuActive ? createMenuActive.quantity_a5 : null);
    const [quantityA3, setQuantityA3] = useState(createMenuActive ? createMenuActive.quantity_a3 : null);
    const [quantityA4, setQuantityA4] = useState(createMenuActive ? createMenuActive.quantity_a4 : null);
    const [quantityB2, setQuantityB2] = useState(createMenuActive ? createMenuActive.quantity_b2 : null);
    const [note, setNote] = useState('');

    const [generateMenuType, setGenerateMenuType] = useState(createMenuActive.beer_cider_menu ? GenerateMenuType.PAPER : createMenuActive?.menu_type ? createMenuActive.menu_type : (createMenuActive?.menuType ? createMenuActive.menuType : null));
    console.log("GEN MEN", generateMenuType)
    console.log("QR TYPE", qrType)

    const [triggerMenuCreate, setTriggerMenuCreate] = useState(false);

    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        dispatch(setIsCreatingMenu(5));
    }, []);

    useEffect(() => {
        if (createMenuActive?.beer_cider_menu) {
            setGenerateMenuType(GenerateMenuType.PAPER);
        }
    }, [createMenuActive]);

    useEffect(() => {
        if (triggerMenuCreate) {
            onConfirmCurrentChanges();
        }
    }, [triggerMenuCreate]);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (menuLastCreated && triggerMenuCreate) {
                console.log("Menu successfully created!", menuLastCreated);

                try {
                    await dispatchMenuCategoryProducts();
                    await dispatchMenuProductsPrices();
                    done = true;
                    setTriggerMenuCreate(false);

                    onNavigateTo(PAGES.CREATE_MENU_SUCCESSFUL);
                } catch (e) {
                    console.warn(e);
                    setModalValues("Napaka pri kreiranju seznama: " + e);
                    setTriggerMenuCreate(false);
                }
            }
        }
        asyncEffect().then(() => {
                if (done) {
                    console.log("Menu finished inserting all category/product rows.")

                    if (isUserBarRepresentative(user) || isUserOwner(user)) {
                        dispatch(autoPublishMenu(menuLastCreated, caretaker.caretaker_id, (menuPublished) => {
                            console.log("Menu successfully (auto) published!", menuLastCreated?.menu_id)
                            setLoadingSpinner(false);

                            onNavigateTo(PAGES.CREATE_MENU_SUCCESSFUL);

                            // remove from local storage
                            //deleteLocalMenu(createMenuActive.localId)
                        }, (menuPublished) => {
                            setLoadingSpinner(false);
                            setTriggerMenuCreate(false);
                            console.log("Error creating menu!");

                            alert("Napaka pri ustvarjanju cenika! Prosimo poskusite znova.")
                            onNavigateTo(PAGES.MENUS_LIST_LOCAL);
                            return false;
                        }));
                    }

                }
            }
        ).catch((e) => console.warn(e));
    }, [menuLastCreated, triggerMenuCreate]);

    useEffect(() => {
        saveCurrentChanges()
        saveToLocalStorage(createMenuActive);

    }, [quantityQRCode, quantityA5, quantityA3, quantityA4, quantityB2, note, generateMenuType, languageSelected, qrType]);


    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
            }
        })
    };

    const onConfirmCurrentChanges = () => {
        setLoadingSpinner(true);

        let isUserInputValid = true;
        if (generateMenuType !== GenerateMenuType.DIGITAL) {
            if (!createMenuActive?.beer_cider_menu) {
                isUserInputValid = validateUserInput([quantityQRCode, quantityA5, quantityA3, quantityB2])
            } else {
                isUserInputValid = validateUserInput([quantityQRCode, quantityA4])
            }
        } else {
            isUserInputValid = validateUserInput([quantityQRCode])

            if (isUserBarRepresentative(user) || isUserOwner(user)) {
                isUserInputValid = true;
            }
        }
        if (isUserInputValid === false) {
            alert("Za zaključek ustvarjanja cenika prosimo izberite željene količine predlog cenikov za tisk..");
            setTriggerMenuCreate(false);
            setLoadingSpinner(false);
            return false;
        }

        let menuPostData = parseMenuPostData(saveCurrentChanges());


        if (isUserBarRepresentative(user) || isUserOwner(user)) {
            dispatch(createNewMenuDirect(menuPostData, (menuCreated) => {
                console.log("Menu successfully (auto) created!")

                // remove from local storage
                //deleteLocalMenu(createMenuActive.localId)
            }, (menuCreated) => {
                setLoadingSpinner(false);
                setTriggerMenuCreate(false);
                console.log("Error creating menu!");
                return false;
            }));
        } else {
            dispatch(createNewMenu(user, caretaker, menuPostData, () => {
                console.log("Menu successfully created!")
                setLoadingSpinner(false);
                // remove from local storage
                //deleteLocalMenu(createMenuActive.localId)
            }, () => {
                setLoadingSpinner(false);
                setTriggerMenuCreate(false);
                console.log("Error creating menu!");
                return false;
            }));
        }
        return true;
    };

    const parseMenuPostData = (createMenuActive) => {
        let menu = {
            bar_id: createMenuActive.bar.bar_id,
            name: '',
            description: '',
            active: false,
            valid_from: createMenuActive.dateValidFrom,
            main_brand: createMenuActive.menuBackground,
            menu_type: createMenuActive.menuType,
            comment: createMenuActive.comment,
            quantity_a3: createMenuActive.printQuantities.a3,
            quantity_a4: createMenuActive.printQuantities.a4,
            quantity_a5: createMenuActive.printQuantities.a5,
            quantity_b2: createMenuActive.printQuantities.b2,
            quantity_qr: createMenuActive.printQuantities.qr,
            qr_type: createMenuActive.qr_type,
            l10n: createMenuActive.l10n,
            l10n_en: createMenuActive.l10n_en,
            l10n_de: createMenuActive.l10n_de,
            l10n_it: createMenuActive.l10n_it,
            l10n_hr: createMenuActive.l10n_hr,
            beer_cider_menu: createMenuActive.beer_cider_menu
        }
        return menu;
    };

    const parseMenuPostDataPreview = (createMenuActive) => {
        let menu = {
            bar_id: createMenuActive.bar.bar_id,
            name: '',
            description: '',
            active: false,
            valid_from: createMenuActive.dateValidFrom,
            main_brand: createMenuActive.menuBackground,
            menu_type: generateMenuType,
            comment: note,
            quantity_a3: quantityA3,
            quantity_a4: createMenuActive.beer_cider_menu ? quantityA4 : 0,
            quantity_a5: quantityA5,
            quantity_b2: quantityB2,
            quantity_qr: quantityQRCode,
            qr_type: qrType,
            categories: createMenuActive.categories,
            preview: true,
            l10n: languageSelected,
            l10n_en: languageSelectedEn,
            l10n_de: languageSelectedDe,
            l10n_it: languageSelectedIt,
            l10n_hr: languageSelectedHr,
            beer_cider_menu: createMenuActive.beer_cider_menu
        }

        if (languageSelected === L10N_MENU.si.value) {
            menu.l10n = null;
        } else {
            menu.l10n = languageSelected
        }

        return menu;
    };


    const parseProductCategoryMenuPostData = (categories) => {
        let array = [];
        let c = 0;
        for (let i = 0; i < categories.length; i++) {
            for (let j = 0; j < categories[i].products.length; j++) {
                let element = {
                    menu_group_id: categories[i].category.menu_group_id,
                    menu_id: menuLastCreated.menu_id,
                    product_id: categories[i].products[j].product_id,
                    index: c
                }
                array.push(element);
                c++;
            }
        }
        return array;
    };

    const parseProductPriceMenuPostData = (categories) => {
        let array = [];
        let c = 0;
        for (let i = 0; i < categories.length; i++) {
            for (let j = 0; j < categories[i].products.length; j++) {
                let element = {
                    product_id: categories[i].products[j].product_id,
                    menu_id: menuLastCreated.menu_id,
                    price: categories[i].products[j].initial_price,
                    price_2: categories[i].products[j].initial_price_2 ? categories[i].products[j].initial_price_2 : null,
                    price_3: categories[i].products[j].initial_price_3 ? categories[i].products[j].initial_price_3 : null,
                    quantity: 1,
                    index: c,
                }
                array.push(element);
                c++;
            }
        }
        return array;
    };

    const dispatchMenuCategoryProducts = async () => {
        let postData = parseProductCategoryMenuPostData(createMenuActive.categories)
        for (let i = 0; i < postData.length; i++) {
            await dispatch(createNewMenuCategoryProductRow(postData[i]), () => {
                console.log("Category/Product menu row successfully created!")
            }, (e) => {
                throw new Error("Error creating Category/Product menu row! " + e);
            });
        }
    };

    const dispatchMenuProductsPrices = async () => {
        let postData = parseProductPriceMenuPostData(createMenuActive.categories)
        for (let i = 0; i < postData.length; i++) {
            await dispatch(createNewMenuProductPriceRow(postData[i]), () => {
                console.log("Price/Product menu row successfully created!")
            }, (e) => {
                throw new Error("Error creating Category/Product menu row! " + e);
            });
        }
    };

    const onPreviewMenu = async () => {
        let menuPostData = parseMenuPostDataPreview(createMenuActive);
        console.log("PREVIEW MENU", menuPostData);

        for (let i = 0; i < FORMAT.length - 1; i++) {
            let format = FORMAT[i];
            // skip preview of a4 beer & cider card on normal menus
            if (!createMenuActive.beer_cider_menu && format === "a4") {
                continue;
            }
            if (createMenuActive.beer_cider_menu) {
                format = "a4";
            }
            await dispatch(previewMenu(menuPostData, format, () => {
                console.log("Menu successfully generated!")
            }, (e) => {
                throw new Error("Error previewing menu! " + e);
            }));
            if (createMenuActive.beer_cider_menu) {
                break;
            }
        }
    };

    const isInputDisabled = () => {
        return generateMenuType === GenerateMenuType.DIGITAL;
    }

    const saveCurrentChanges = () => {
        let printQuantities = !!createMenuActive?.printQuantities ? createMenuActive?.printQuantities : {};
        printQuantities.qr = quantityQRCode;
        printQuantities.a5 = quantityA5;
        printQuantities.a3 = quantityA3;
        printQuantities.a4 = quantityA4;
        printQuantities.b2 = quantityB2;
        createMenuActive.quantity_a3 = quantityA3
        createMenuActive.quantity_a4 = quantityA4
        createMenuActive.quantity_a5 = quantityA5
        createMenuActive.quantity_b2 = quantityB2
        createMenuActive.quantity_qr = quantityQRCode
        createMenuActive.qr_type = qrType

        createMenuActive.printQuantities = printQuantities;
        createMenuActive.menuType = generateMenuType;
        createMenuActive.menu_type = generateMenuType;
        createMenuActive.comment = mapCaretChars(note);

        if (languageSelected === L10N_MENU.si.value) {
            createMenuActive.l10n = null;
        } else {
            createMenuActive.l10n = languageSelected
        }
        createMenuActive.l10n_en = languageSelectedEn
        createMenuActive.l10n_de = languageSelectedDe
        createMenuActive.l10n_it = languageSelectedIt
        createMenuActive.l10n_hr = languageSelectedHr

        dispatch(setCreateMenuActive(createMenuActive));

        return createMenuActive
    }

    let inputStyle = {marginBottom: '32px'};
    const placeholderText = 'Vpiši količino';
    const className = 'button-container button-default button-selection';
    const classNameSelected = 'button-container button-default button-selection button-selected';
    const classNameDisabled = 'button-container button-default button-selection button-disabled';

    const renderBeerCiderMenuPreview = () => {
        return <div className={'row-2'}>
            <div className={'row'}>
                <p className={'p-input-label'}>plakat A4</p>
                <Input loadMenu quantity_a4 disabled={isInputDisabled()} white onChange={setQuantityA4}
                       placeholder={placeholderText}
                       style={inputStyle}/>
            </div>
        </div>
    }

    const renderStandardMenuPreview = () => {
        return <>
            <div className={'row-2'}>
                <div className={'row'}>
                    <p className={'p-input-label'}>QR kode</p>
                    <Input white loadMenu quantity_qr onChange={setQuantityQRCode}
                           placeholder={placeholderText}
                           style={inputStyle}/>
                </div>
                <div className={'row'}>
                    <p className={'p-input-label'}>letak A5</p>
                    <Input loadMenu quantity_a5 disabled={isInputDisabled()} white onChange={setQuantityA5}
                           placeholder={placeholderText}
                           style={inputStyle}/>
                </div>
            </div>
            <div className={'row-2'}>
                <div className={'row'}>
                    <p className={'p-input-label'}>plakat A3</p>
                    <Input loadMenu quantity_a3 disabled={isInputDisabled()} white onChange={setQuantityA3}
                           placeholder={placeholderText}
                           style={inputStyle}/>
                </div>
                <div className={'row'}>
                    <p className={'p-input-label'}>plakat B2</p>
                    <Input loadMenu quantity_b2 disabled={isInputDisabled()} white onChange={setQuantityB2}
                           placeholder={placeholderText}
                           style={inputStyle}/>
                </div>
            </div>
        </>
    }

    const renderQrTypeFilter = () => {
        if (generateMenuType === 0) {
            return null;
        }
        return (
            <>
                <p className={'p-title-small'}>Izberite željeno podobo printanih QR kod:</p>
                <div className={'button-selection-filter'} style={{
                    paddingBottom: '20px',
                    display: 'flex',
                }}>
                    <Button style={{
                        marginRight: '6%'
                    }} className={'button-selection'} text={'QR (z ozadjem)'} selected={qrType === "0"}
                            action={() => setQrType("0")}/>
                    <Button className={'button-selection'} text={'QR (brez ozadja)'} selected={qrType === "1"}
                            action={() => setQrType("1")}/>
                </div>
            </>
        );
    }

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token &&
        <div><Page relative className={'main'} pageStyle={{height: documentSize.y + 'px'}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            <div className={`body ${createMenuActive.beer_cider_menu ? 'beer-cider-page' : ''}`}
                 style={{paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={'PREDOGLED IN POTRDITEV CENIKA'} step={'5. KORAK'}/>
                <div className={'container-column-2'} style={{paddingBottom: '100px'}}>
                    <div className={'column-2'}>
                        <p className={'p-title-small'}>Izberite jezik cenika</p>
                        <div className={'l10n-button-group'}>
                            <div className={languageSelected === L10N_MENU.si.value ? classNameSelected : className}
                                 onClick={() => setLanguageSelected(L10N_MENU.si.value)}>
                                <img src={countrySI} alt="SI" width={'25px'}/>
                            </div>
                            <div
                                className={languageSelectedEn || languageSelected === L10N_MENU.en.value ? classNameSelected : className}
                                onClick={() => {
                                    if (generateMenuType === 1) {
                                        setLanguageSelectedEn(false);
                                        setLanguageSelected(L10N_MENU.en.value);
                                    } else {
                                        setLanguageSelectedEn(!languageSelectedEn);
                                        setLanguageSelected(null);
                                    }
                                }
                                }>
                                <img src={countryEN} alt="EN" width={'25px'}/>
                            </div>
                            <div
                                className={languageSelectedDe || languageSelected === L10N_MENU.de.value ? classNameSelected : className}
                                onClick={() => {
                                    if (generateMenuType === 1) {
                                        setLanguageSelected(L10N_MENU.de.value);
                                        setLanguageSelectedDe(false);
                                    } else {
                                        setLanguageSelected(null);
                                        setLanguageSelectedDe(!languageSelectedDe);
                                    }

                                }}>
                                <img src={countryDE} alt="DE" width={'25px'}/>
                            </div>
                            <div
                                className={languageSelectedIt || languageSelected === L10N_MENU.it.value ? classNameSelected : className}
                                onClick={() => {
                                    if (generateMenuType === 1) {
                                        setLanguageSelectedIt(false);
                                        setLanguageSelected(L10N_MENU.it.value);
                                    } else {
                                        setLanguageSelected(null);
                                        setLanguageSelectedIt(!languageSelectedIt);
                                    }
                                }}>
                                <img src={countryIT} alt="IT" width={'25px'}/>
                            </div>
                            <div
                                className={languageSelectedHr || languageSelected === L10N_MENU.hr.value ? classNameSelected : className}
                                onClick={() => {
                                    if (generateMenuType === 1) {
                                        setLanguageSelectedHr(false);
                                        setLanguageSelected(L10N_MENU.hr.value);
                                    } else {
                                        setLanguageSelected(null);
                                        setLanguageSelectedHr(!languageSelectedHr);
                                    }
                                }}>
                                <img src={countryHR} alt="HR" width={'25px'}/>
                            </div>
                        </div>
                        {renderQrTypeFilter()}


                        <ButtonGroupSelection disabled={createMenuActive.beer_cider_menu} menuOption loadMenu menu_type
                                              digital={isUserOwner(user) || isUserBarRepresentative(user)}
                                              value={generateMenuType}
                                              onChange={setGenerateMenuType}
                                              style={{marginBottom: '60px'}}/>
                        <div className={'container-row-2'}>
                            {isUserCaretaker(user) && <p className={'p-title-small'}>Izberite količino</p>}
                            {!isUserCaretaker(user) ? <></> : !createMenuActive?.beer_cider_menu ? renderStandardMenuPreview() : renderBeerCiderMenuPreview()}
                        </div>
                    </div>
                    <div className={'column-2'} style={{paddingRight: '50px'}}>
                        <Input loadMenu comment textarea white label={'Tukaj lahko oddaš opombe'} onChange={setNote}
                               placeholder={'Bi želel cenik poslati na drug naslov ipd?'}
                               style={inputStyle}/>

                        {generateMenuType !== 0 ? <Button text={'PREDOGLED CENIKA'} action={() => {
                            dispatch(onPreviewMenu)
                        }} style={{width: '100%', marginTop: '30px'}}/> : <></>}
                    </div>
                </div>
            </div>
            <Footer
                onLoading={loadingSpinner}
                back={'NAZAJ'}
                left={`${sidebarWidth}px`}
                urlBack={isUserCaretaker(user) ? PAGES.CREATE_MENU_CHOOSE_COMBO_OFFER : PAGES.CREATE_MENU_ADD_PRODUCTS}
                onActionBack={() => {
                    saveCurrentChanges();
                    return true
                }}
                next={'POTRDI IN POŠLJI V TISK'}
                urlNext={PAGES.CREATE_MENU_SUCCESSFUL}
                onActionNext={() => {
                    setTriggerMenuCreate(true);
                    return false
                }}
                progress={5}/>
        </Page>
            {renderModalInfo()}
        </div>
}

export default CreateMenuPreview;