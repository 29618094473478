import React, {useState, useEffect} from "react";
import Input from "../js/Input";

import "../css/Modal.css";

import * as IoIcons from "react-icons/io";
import Button from "../js/Button";
import {USER_GROUP_TYPE} from "../../constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {
    createUserGroup, loadAllFolders,
    updateFoldersListUserGroup, updateUserGroup
} from "../../store/actions/actions";
import {
    displayAlert,
    findItem, getIndexForNthCharOccurrence,
    isNewObjValidCheck,
    mapCaretChars,
    parseMapCaretChars,
    parseSelectedObjectValues,
    parseSelectionInvertedObjectValues,

} from "../../helpers/helpers";
import colors from "../../constants/colors";
import ModalInfo from "./ModalInfo";


const CREATE_ACTION = {
    CREATE_USER_GROUP: 0,
    UPDATE_USER_GROUP: 1,
}

export const parseUserGroupType = (type) => {
    switch (type) {
        case USER_GROUP_TYPE.ADMIN:
            return 'ADMIN'
        case USER_GROUP_TYPE.SUBSCRIBER:
            return 'OPAZOVALEC'
        case USER_GROUP_TYPE.ALL:
            return 'VSI'
        case USER_GROUP_TYPE.SHAREPOINT_ADMIN:
            return 'SHAREPOINT ADMIN'
        case USER_GROUP_TYPE.CARETAKER:
            return 'POTNIK'
        default:
            return ''
    }
}


const NEW_FOLDER_REQUIRED_FIELDS = {
    'name': 'Ime uporabniške skupine',
    'type': 'Tip uporabniške skupine',
}

function ModalSharepointUserGroup(props) {
    console.log("Open modal");

    let dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);

    const [name, setName] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [selectedUserGroup, setSelectedUserGroup] = useState(null);

    const [allFolders, setAllFolders] = useState(null);
    const [selectedFolders, setSelectedFolders] = useState([]);


    const [invalidInputs, setInvalidInputs] = useState([]);


    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    useEffect(() => {
        if (props.bar) {
            document.body.style = 'overflow: hidden;'

            return () => {
                document.body.style = ''
            }
        }
        if (props.selected_user_group) {
            setSelectedUserGroup(props.selected_user_group);
        }
    }, [])

    useEffect(() => {
        if (user) {
            onLoadAllFolders();
        }
    }, [props.usergroupUpdate, user])


    useEffect(() => {
        if (user && props.usergroupUpdate && selectedUserGroup) {
            setName(selectedUserGroup.name);
            setSelectedType(selectedUserGroup.type);
        }
    }, [selectedUserGroup])


    useEffect(() => {
        if (user && props.usergroupUpdate && selectedUserGroup) {
            let selected = [];
            for (let i = 0; i < allFolders.length; i++) {
                let folder = allFolders[i];
                console.log(folder.user_groups);
                if (folder.user_groups && folder.user_groups.includes(selectedUserGroup.user_group_id)) {
                    selected.push(folder);
                }
            }
            setSelectedFolders(selected);
            console.log("Selected folders", selected);
        }
    }, [allFolders])


    const parseSelectedFolders = (user_group_ids, userGroup) => {
        if (!userGroup || !user_group_ids || !user_group_ids || user_group_ids === '') {
            return [];
        }
        user_group_ids = user_group_ids.split(',');
        console.log(user_group_ids);
        let ug = [];
        for (let i = 0; i < user_group_ids.length; i++) {
            if (user_group_ids[i] === userGroup[i].user_group_id) {
                ug.push(userGroup[i]);
            }
        }
        console.log(ug);
        return ug;
    }


    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.CREATE_USER_GROUP:
                    confirmCreateUserGroup();
                    break;
                case CREATE_ACTION.UPDATE_USER_GROUP:
                    confirmUpdateUserGroup();
                    break;
                default:
                    console.warn("Action not implemented..", action);
            }
        }
    }, [createTrigger]);


    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
            }
        })
    };


    const confirmCreateUserGroup = () => {
        let n = mapCaretChars(name);
        n = n ? n.trim() : null
        if (!n) {
            setModalValues('Manjka vrednost v polju: ' + NEW_FOLDER_REQUIRED_FIELDS.name);
            setCreateTrigger(false);
            setInvalidInputs(['name']);
            return;
        }
        let user_group = {
            name: n,
            type: selectedType,
        }

        // check data validity
        let response = isNewObjValidCheck(user_group, NEW_FOLDER_REQUIRED_FIELDS);

        if (!response.isValid) {
            setModalValues(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            return;
        }

        // save new user group to database
        dispatch(createUserGroup(user_group, (res) => {
            console.log("User group successfully created..", res);
            props.actionSuccess(res);

            setCreateTrigger(false);
            props.actionClose();
            setModalValues('Nova uporabniška skupina uspešno ustvarjena!')


        }, (e) => {
            setModalValues('Napaka pri ustvarjanju nove uporabniške skupine..', e)
        }));
    }

    const confirmUpdateUserGroup = () => {
        let n = mapCaretChars(name);
        n = n ? n.trim() : null
        if (!n) {
            setModalValues('Manjka vrednost v polju: ' + NEW_FOLDER_REQUIRED_FIELDS.name);
            setCreateTrigger(false);
            setInvalidInputs(['name']);
            return;
        }
        let user_group = {
            user_group_id: selectedUserGroup.user_group_id,
            name: n,
            type: selectedType,
        }

        let response = isNewObjValidCheck(user_group, NEW_FOLDER_REQUIRED_FIELDS);

        if (!response.isValid) {
            setModalValues(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            return;
        }

        dispatch(updateUserGroup(user_group, (resUpdateUG) => {
            console.log("User group successfully updated..", resUpdateUG);
            setModalValues('Nova uporabniška skupina uspešno posodobljena!');

            let parsed = parseSelectedObjectValues(selectedFolders, 'folder_id');
            let parsedNotSelected = parseSelectionInvertedObjectValues(allFolders, selectedFolders, 'folder_id');
            let operation = 'add';

            console.log(parsed, parsedNotSelected);

            if (!parsed && !parsedNotSelected) {
                setModalValues(`Napaka pri ${operation === 'add' ? 'dodajanju' : 'odstranjevanju'} uporabniške skupine '${selectedUserGroup?.name}' izbranim direktorijem. Seznam izbranih direktorijev je prazen!`)
                return;
            } else if (parsed) {
                dispatch(updateFoldersListUserGroup(parsed, selectedUserGroup?.user_group_id, operation, (res) => {
                    console.log("User group successfully added to list of objects..", res);

                    operation = 'remove';
                    dispatch(updateFoldersListUserGroup(parsedNotSelected, selectedUserGroup?.user_group_id, operation, (res) => {
                        console.log("User group successfully added to list of objects..", res);

                        onLoadAllFolders();
                        setSelectedFolders([]);

                        props.actionSuccess(resUpdateUG);
                        props.actionClose();
                        setCreateTrigger(false);


                        operation = 'add';
                        setModalValues(`Uporabniška skupina '${selectedUserGroup?.name}' uspešno ${operation === 'add' ? 'dodana' : 'odstranjena'} ${selectedFolders.length} direktorijem!`)
                        operation = 'remove';
                        setModalValues(`Uporabniška skupina '${selectedUserGroup?.name}' uspešno ${operation === 'add' ? 'dodana' : 'odstranjena'} ${parsedNotSelected.length} direktorijem!`)

                    }, (err) => {
                        console.warn("Error adding user group to objects..", err)

                        onLoadAllFolders();
                        setSelectedFolders([]);

                        props.actionClose();
                        setCreateTrigger(false);

                        setModalValues(`Napaka pri ${operation === 'add' ? 'dodajanju' : 'odstranjevanju'} uporabniške skupine '${selectedUserGroup?.name}' izbranim direktorijem.., ${err.error}`)
                    }));

                }, (err) => {
                    operation = 'add';
                    console.warn("Error adding user group to objects..", err)

                    onLoadAllFolders();
                    setSelectedFolders([]);

                    props.actionClose();
                    setCreateTrigger(false);

                    setModalValues(`Napaka pri ${operation === 'add' ? 'dodajanju' : 'odstranjevanju'} uporabniške skupine '${selectedUserGroup?.name}' izbranim direktorijem.., ${err.error}`)
                }));
            } else if (!parsed && parsedNotSelected) {
                operation = 'remove';
                dispatch(updateFoldersListUserGroup(parsedNotSelected, selectedUserGroup?.user_group_id, operation, (res) => {
                    console.log("User group successfully added to list of objects..", res);

                    onLoadAllFolders();
                    setSelectedFolders([]);

                    props.actionSuccess(resUpdateUG);
                    props.actionClose();
                    setCreateTrigger(false);

                    setModalValues(`Uporabniška skupina '${selectedUserGroup?.name}' uspešno ${operation === 'add' ? 'dodana' : 'odstranjena'} ${parsedNotSelected.length} direktorijem!`)

                }, (err) => {
                    console.warn("Error adding user group to objects..", err)

                    onLoadAllFolders();
                    setSelectedFolders([]);

                    props.actionClose();
                    setCreateTrigger(false);

                    setModalValues(`Napaka pri ${operation === 'add' ? 'dodajanju' : 'odstranjevanju'} uporabniške skupine '${parsedNotSelected?.name}' izbranim direktorijem.., ${err.error}`)
                }));
            }


        }, (e) => {
            setModalValues('Napaka pri posodabljanju nove uporabniške skupine..', e)
        }));
    }


    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };

    const onLoadAllFolders = () => {
        dispatch(loadAllFolders(user.user_id, (res) => {
            console.log("Folders: ", res);
            setAllFolders(res);
        }, (err) => {
            console.warn("Error obtaining folders..", err)
        }));
    }


    const renderHeader = (title, actionClose) => {
        return (
            <div className={'modal-header'}>
                <p>{parseMapCaretChars(title)}</p>
                <div onClick={actionClose} style={{cursor: 'pointer'}}><IoIcons.IoMdClose
                    className={'icon-close'}/>
                </div>
            </div>
        );
    };

    const renderFooter = (text, actionCreate) => {
        return (
            <Button text={text} confirm action={actionCreate} style={{
                alignSelf: 'flex-end',
                marginTop: '16px'
            }}/>
        );
    };

    const toggleCheckbox = (type) => {
        if (type === USER_GROUP_TYPE.ADMIN) {
            setSelectedType(USER_GROUP_TYPE.ADMIN);
        } else if (type === USER_GROUP_TYPE.SUBSCRIBER) {
            setSelectedType(USER_GROUP_TYPE.SUBSCRIBER);
        } else if (type === USER_GROUP_TYPE.SHAREPOINT_ADMIN) {
            setSelectedType(USER_GROUP_TYPE.SHAREPOINT_ADMIN);
        } else if (type === USER_GROUP_TYPE.CARETAKER) {
            setSelectedType(USER_GROUP_TYPE.CARETAKER);
        }
    }

    const renderCreateUserGroup = () => {
        console.log(selectedType);
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-product modal-height'}>
                    {renderHeader('USTVARI NOVO UPORABNIŠKO SKUPINO', props.actionClose)}
                    <div className={'modal-body'} style={{overflowY: 'auto'}}>
                        <Input onSubmit={() => {
                        }} onChange={setName}
                               placeholder={'Ime uporabniške skupine'}
                               style={{
                                   marginBottom: '24px',
                                   border: invalidInputs.includes('name') ? "1px solid red" : "none"
                               }}/>
                        <div className={'user-group-type-container'}>
                            <div className={'row-box'}
                                 style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                 onClick={() => toggleCheckbox(USER_GROUP_TYPE.SHAREPOINT_ADMIN)}>
                                <div className={'row-box-check'}
                                     style={{opacity: selectedType === USER_GROUP_TYPE.SHAREPOINT_ADMIN ? 1 : 0}}>
                                </div>
                            </div>
                            <p>{parseUserGroupType(USER_GROUP_TYPE.SHAREPOINT_ADMIN)} <p style={{paddingLeft: '4px', display:'inline', fontSize: '12px', fontWeight: 600}}> (lahko ureja uporabniške skupine)</p></p>
                        </div>
                        <div className={'user-group-type-container'}>
                            <div className={'row-box'}
                                 style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                 onClick={() => toggleCheckbox(USER_GROUP_TYPE.ADMIN)}>
                                <div className={'row-box-check'}
                                     style={{opacity: selectedType === USER_GROUP_TYPE.ADMIN ? 1 : 0}}>
                                </div>
                            </div>
                            <p>{parseUserGroupType(USER_GROUP_TYPE.ADMIN)}</p>
                        </div>
                        <div className={'user-group-type-container'}>
                            <div className={'row-box'}
                                 style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                 onClick={() => toggleCheckbox(USER_GROUP_TYPE.CARETAKER)}>
                                <div className={'row-box-check'}
                                     style={{opacity: selectedType === USER_GROUP_TYPE.CARETAKER ? 1 : 0}}>
                                </div>
                            </div>
                            <p>{parseUserGroupType(USER_GROUP_TYPE.CARETAKER)}</p>
                        </div>
                        <div className={'user-group-type-container'}>
                            <div className={'row-box'}
                                 style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                 onClick={() => toggleCheckbox(USER_GROUP_TYPE.SUBSCRIBER)}>
                                <div className={'row-box-check'}
                                     style={{opacity: selectedType === USER_GROUP_TYPE.SUBSCRIBER ? 1 : 0}}>
                                </div>
                            </div>
                            <p>{parseUserGroupType(USER_GROUP_TYPE.SUBSCRIBER)}</p>
                        </div>

                    </div>
                    {renderFooter('USTVARI', () => triggerAction(CREATE_ACTION.CREATE_USER_GROUP))}
                </div>
            </div>
        );
    };

    const renderUpdateUserGroup = () => {
        console.log(selectedType);
        return (
            <div className={'modal-overlay'}>
                <div className={'modal modal-product modal-height'}>
                    {renderHeader('POSODOBI UPORABNIŠKO SKUPINO', props.actionClose)}
                    <div className={'modal-body'} style={{overflowY: 'auto'}}>


                        <div className={'sharepoint container-column-2'} style={{padding: 0, paddingRight: '20px'}}>

                            <div className={'column-2'} style={{paddingRight: '30px'}}>
                                <div className={'container-row-2'}>
                                    <Input onSubmit={() => {
                                    }} onChange={setName}
                                           placeholder={!name ? 'Ime uporabniške skupine' : name}
                                           style={{
                                               marginBottom: '24px',
                                               border: invalidInputs.includes('name') ? "1px solid red" : "none"
                                           }}/>
                                    <div className={'user-group-type-container'}>
                                        <div className={'row-box'}
                                             style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                             onClick={() => toggleCheckbox(USER_GROUP_TYPE.SHAREPOINT_ADMIN)}>
                                            <div className={'row-box-check'}
                                                 style={{opacity: selectedType === USER_GROUP_TYPE.SHAREPOINT_ADMIN ? 1 : 0}}>
                                            </div>
                                        </div>
                                        <p>{parseUserGroupType(USER_GROUP_TYPE.SHAREPOINT_ADMIN)} <p style={{paddingLeft: '4px', display:'inline', fontSize: '12px', fontWeight: 600}}> (lahko ureja uporabniške skupine)</p></p>
                                    </div>
                                    <div className={'user-group-type-container'}>
                                        <div className={'row-box'}
                                             style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                             onClick={() => toggleCheckbox(USER_GROUP_TYPE.ADMIN)}>
                                            <div className={'row-box-check'}
                                                 style={{opacity: selectedType === USER_GROUP_TYPE.ADMIN ? 1 : 0}}>
                                            </div>
                                        </div>
                                        <p>{parseUserGroupType(USER_GROUP_TYPE.ADMIN)}</p>
                                    </div>
                                    <div className={'user-group-type-container'}>
                                        <div className={'row-box'}
                                             style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                             onClick={() => toggleCheckbox(USER_GROUP_TYPE.CARETAKER)}>
                                            <div className={'row-box-check'}
                                                 style={{opacity: selectedType === USER_GROUP_TYPE.CARETAKER ? 1 : 0}}>
                                            </div>
                                        </div>
                                        <p>{parseUserGroupType(USER_GROUP_TYPE.CARETAKER)}</p>
                                    </div>
                                    <div className={'user-group-type-container'}>
                                        <div className={'row-box'}
                                             style={{border: invalidInputs.includes('type') ? "2px solid red" : "2px black solid"}}
                                             onClick={() => toggleCheckbox(USER_GROUP_TYPE.SUBSCRIBER)}>
                                            <div className={'row-box-check'}
                                                 style={{opacity: selectedType === USER_GROUP_TYPE.SUBSCRIBER ? 1 : 0}}>
                                            </div>
                                        </div>
                                        <p>{parseUserGroupType(USER_GROUP_TYPE.SUBSCRIBER)}</p>
                                    </div>
                                </div>
                            </div>


                            <div className={'column-2'} style={{paddingRight: '0px'}}>
                                <div className={'container-row-2'}>
                                    <div className={'user-group-type-container'}
                                         style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                                        {renderTable(allFolders)}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {renderFooter('POSODOBI', () => triggerAction(CREATE_ACTION.UPDATE_USER_GROUP))}
                </div>
            </div>
        );
    };

    function Item(props) {
        const toggleCheckbox = (folder) => {
            let updated = [...selectedFolders];
            let removed = false;
            for (let i = 0; i < selectedFolders.length; i++) {
                if (selectedFolders[i].folder_id === folder.folder_id) {
                    // remove
                    updated.splice(i, 1);
                    removed = true;
                    break;
                }
            }
            // else add
            if (!removed) {
                updated.push(folder);
            }
            setSelectedFolders(updated);
            console.log(updated);
        }
        const toggleMultipleCheckbox = (folders) => {
            let updated = [...selectedFolders];
            let removed = false;
            for (let i = 0; i < folders.length; i++) {
                for (let j = 0; j < selectedFolders.length; j++) {
                    if (selectedFolders[j].folder_id === folders[i].folder_id) {
                        // remove
                        updated.splice(j, 1);
                        selectedFolders.splice(j, 1);
                        removed = true;
                    }
                }
            }

            // else add
            if (!removed) {
                updated.push(...folders);
            }
            setSelectedFolders(updated);
            console.log(updated);
        }

        let clickHoldTimer = null;

        const handleMouseDown = (item) => {
            clickHoldTimer = setTimeout(() => {
                //Action to be performed after holding down mouse
                console.log("LONG PRESS");

                let foldersToAdd = [];
                for (let i = 0; i < allFolders.length; i++) {
                    let f = allFolders[i];
                    if (`${f?.path + "/"}`.includes(`${item?.path + "/"}`)) {
                        console.log(f.path);
                        foldersToAdd.push(f);
                    }
                }
                toggleMultipleCheckbox(foldersToAdd);

            }, 900); //Change 1000 to number of milliseconds required for mouse hold
        }
        const handleMouseUp = (item) => {
            clearTimeout(clickHoldTimer);
        }

        // console.log(props.value);
        const item = props.value;
        let depth = 0;
        depth = (item?.path.match(/\//g)||[])?.length;
        let s = '';
        for (let i = 0; i < depth; i++) {
            s += '\xa0\xa0\xa0\xa0';
        }
        let displayPath = `${s}${parseMapCaretChars(item.path)}`;
        let idx = getIndexForNthCharOccurrence(displayPath, "/", depth);

        if (depth > 1) {
            displayPath = s + displayPath?.substring(idx);
        }
        console.log(displayPath)

        const selected = findItem('folder_id', item.folder_id, selectedFolders);
        return (
            <li onClick={() => {
            }} className={'row'} style={{left: `${0 + 50}px`}}>
                <div className={'row-box'} onClick={() => toggleCheckbox(item)} onMouseDown={() => handleMouseDown(item)} onMouseUp={() => handleMouseUp(item)}>
                    <div className={'row-box-check'} style={{opacity: selected ? 1 : 0}}>
                    </div>
                </div>
                <div className={'row-bar'}><p>{`${displayPath}`}</p></div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <div className={'sharepoint-folders-edit-header'} style={{position: 'relative'}}>
                    <div className={'row-bar'}
                         style={{maxWidth: '95%'}}>{`Izberite direktorije nad katerimi naj ima ${!selectedType ? 'uporabnik' : parseUserGroupType(selectedType).toLowerCase()} pregled:`}</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'sharepoint-folders-edit list'} style={{
                    position: 'relative',
                    paddingTop: '10px',
                    overflowY: 'scroll',
                    maxHeight: '200px',
                    width: '100%',
                    marginTop: '10px'
                }}>
                    {items.map((item, index) => <Item key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }


    const renderBody = () => {
        if (props.usergroup) {
            return renderCreateUserGroup();
        } else if (props.usergroupUpdate) {
            return renderUpdateUserGroup();
        } else {
            return <></>
        }
    }

    return <>
        {renderBody()}
        {renderModalInfo()}
    </>

}

export default ModalSharepointUserGroup;