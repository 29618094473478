import React, {useState, useEffect, useRef} from "react";

import "../css/Modal.css";

import * as IoIcons from "react-icons/io";
import Button from "../js/Button";
import {useDispatch, useSelector} from "react-redux";


function ModalInfo(props) {
    console.log("Open modal");

    let dispatch = useDispatch();

    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    useEffect(() => {
        if (props.bar) {
            document.body.style = 'overflow: hidden;'

            return () => {
                document.body.style = ''
            }
        }

    }, [])


    const renderHeader = (title, actionClose) => {
        return (
            <div className={'modal-header'}>
                <p>{title}</p>
                <div onClick={actionClose} style={{cursor: 'pointer'}}><IoIcons.IoMdClose
                    className={'icon-close'}/>
                </div>
            </div>
        );
    };

    const renderBody = (text) => {
        return (
            <div className={'modal-info-body'}>
                <p>{text}</p>
            </div>
        );
    };

    const renderFooter = (text, actionCreate) => {
        return (
            <Button text={text} confirm action={actionCreate} style={{
                alignSelf: 'flex-end',
                marginTop: '16px'
            }}/>
        );
    };

    const renderModal = () => {
        return (
            <div ref={ref} className={'modal-overlay modal-info'}>
                <div className={'modal'}>
                    {renderHeader(props.title ? props.title : "", props.actionClose)}
                    {renderBody(props.text)}
                    {renderFooter(props.buttonText ? props.buttonText : 'OK', props.actionClose)}
                </div>
            </div>
        );
    };

    if (props.info) {
        return renderModal();
    } else {
        return <></>
    }
}

export default ModalInfo;