import React, {useState, useEffect, createRef, useCallback} from "react";
import {ReactComponent as IconPalm} from "../assets/svg/icon-phone-palm.svg";

import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import "../css/Ads.css"
import '../components/css/Tables.css'
import '../css/Analytics.css'


import {useDispatch, useSelector} from "react-redux";
import {
    displayDate, displayDateUTC,
    hasItem,
    isSessionValid, isUserBarRepresentative, isUserOwner,
    mapCaretChars,
    parseLeadingBrand,
    parseMapCaretChars
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    loadAddImpressionsRegionally, loadAvgPrices,
    loadBars,
    loadDailyQRScans,
    loadHourlyQRScans, loadHourlyQRScansByBars,
    loadOwnProductsMarketPresence,
    loadProducts,
    loadRegionallyQRScans,
    setActiveBar,
    setCreateMenuActive,
    setSidebarWidth,

} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Dropdown from "../components/js/Dropdown";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {ReactComponent as IconNotification} from "../assets/svg/Icon material-notifications-none.svg";
import {ReactComponent as IconList} from "../assets/svg/Icon awesome-list-ul.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import Button from "../components/js/Button";
import {GenerateMenuType} from "../components/js/ButtonGroupSelection";
import {
    BarChart,
    Bar,
    Cell,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import BarQrScansDropdown from "../components/js/BarQrScansDropdown";

// in pixels as integer
export const BANNER_WIDTH = 350;

const ITEMS_PER_PAGE = 30;
const TIMEOUT_INTERVAL = 1;

// return all elements that are relevant for 7_DAYS, 30_DAYS, ALL_TIME
const DATA_QR_CODE_SCANS_DAILY = [
    {
        date: '2023-07-08T10:26:06.956Z',
        qr_scans: 10,
    },
    {
        date: '2023-07-09T11:26:06.956Z',
        qr_scans: 12,
    },
    {
        date: '2023-07-10T13:26:06.956Z',
        qr_scans: 25,
    },
    {
        date: '2023-07-11T16:26:06.956Z',
        qr_scans: 11,
    },
    {
        date: '2023-07-13T17:26:06.956Z',
        qr_scans: 25,
    },
    {
        date: '2023-07-14T17:26:06.956Z',
        qr_scans: 45,
    },
    {
        date: '2023-07-15T17:26:06.956Z',
        qr_scans: 35,
    },
    {
        date: '2023-07-16T17:26:06.956Z',
        qr_scans: 55,
    },
    {
        date: '2023-07-17T17:26:06.956Z',
        qr_scans: 25,
    },
    {
        date: '2023-07-18T17:26:06.956Z',
        qr_scans: 45,
    },
];

// return all elements that are relevant for 7_DAYS, 30_DAYS, ALL_TIME
const DATA_QR_CODE_SCANS_HOURLY = [
    {
        hour: '0',
        qr_scans: 22,
    },
    {
        hour: '1',
        qr_scans: 35,
    },
    {
        hour: '2',
        qr_scans: 48,
    },
    {
        hour: '3',
        qr_scans: 13,
    },
    {
        hour: '4',
        qr_scans: 78,
    },
    {
        hour: '5',
        qr_scans: 89,
    },
    {
        hour: '6',
        qr_scans: 56,
    },
    {
        hour: '7',
        qr_scans: 37,
    },
    {
        hour: '8',
        qr_scans: 92,
    },
    {
        hour: '9',
        qr_scans: 41,
    },
    {
        hour: '10',
        qr_scans: 67,
    },
    {
        hour: '11',
        qr_scans: 85,
    },
    {
        hour: '12',
        qr_scans: 72,
    },
    {
        hour: '13',
        qr_scans: 50,
    },
    {
        hour: '14',
        qr_scans: 33,
    },
    {
        hour: '15',
        qr_scans: 44,
    },
    {
        hour: '16',
        qr_scans: 28,
    },
    {
        hour: '17',
        qr_scans: 66,
    },
    {
        hour: '18',
        qr_scans: 53,
    },
    {
        hour: '19',
        qr_scans: 75,
    },
    {
        hour: '20',
        qr_scans: 87,
    },
    {
        hour: '21',
        qr_scans: 64,
    },
    {
        hour: '22',
        qr_scans: 49,
    },
    {
        hour: '23',
        qr_scans: 90,
    },
];

const DATA_QR_CODE_SCANS_REGIONAL = [
    {
        region: '1000', // Ljubljana
        qr_scans: 1378,
    },
    {
        region: '2000', // Maribor
        qr_scans: 521,
    },
    {
        region: '3000', // Celje
        qr_scans: 1593,
    },
    {
        region: '4000', // Kranj
        qr_scans: 871,
    },
    {
        region: '5000', // Nova Gorica
        qr_scans: 1227,
    },
    {
        region: '6000', // Koper
        qr_scans: 1867,
    },
    {
        region: '8000', // Novo Mesto
        qr_scans: 658,
    },
    {
        region: '9000', // Murska Sobota
        qr_scans: 1043,
    },
];

// just sum the lower array object where divided regionally
const DATA_AD_IMPRESSIONS = [
    {
        name: 'Test Oglas 1',
        ad_click: 827,
        ad_shown: 1378,
    },
    {
        name: 'Test Oglas 2',
        ad_click: 320,
        ad_shown: 521,
    },
    {
        name: 'Test Oglas 3',
        ad_click: 1103,
        ad_shown: 1593,
    },
    {
        name: 'Test Oglas 4',
        ad_click: 589,
        ad_shown: 871,
    },
    {
        name: 'Test Oglas 5',
        ad_click: 764,
        ad_shown: 1227,
    },
    {
        name: 'Test Oglas 6',
        ad_click: 1207,
        ad_shown: 1867,
    },
    {
        name: 'Test Oglas 7',
        ad_click: 462,
        ad_shown: 658,
    },
    {
        name: 'Test Oglas 8',
        ad_click: 692,
        ad_shown: 1043,
    },
];

// per each ad, analytics dissected into regions + then sum all ad stats regionally into one array
const DATA_ADS_IMPRESSIONS_REGIONAL = [
    {
        name: 'Test Oglas 1',
        ad_id: '232323fd34rf4-12347894323gd1',
        ad_click: 1212,
        ad_shown: 5444,
        regions: [
            {
                region: '1000', // Ljubljana
                ad_click: 827,
                ad_shown: 1378,
            },
            {
                region: '2000', // Maribor
                ad_click: 320,
                ad_shown: 521,
            },
            {
                region: '3000', // Celje
                ad_click: 1103,
                ad_shown: 1593,
            },
            {
                region: '4000', // Kranj
                ad_click: 589,
                ad_shown: 871,
            },
            {
                region: '5000', // Nova Gorica
                ad_click: 764,
                ad_shown: 1227,
            },
            {
                region: '6000', // Koper
                ad_click: 1207,
                ad_shown: 1867,
            },
            {
                region: '8000', // Novo Mesto
                ad_click: 462,
                ad_shown: 658,
            },
            {
                region: '9000', // Murska Sobota
                ad_click: 692,
                ad_shown: 1043,
            }]
    },
    {
        name: 'Test Oglas 2',
        ad_id: '232323fd32rf4-12347894323gd2',
        ad_click: 1212,
        ad_shown: 5444,
        regions: [
            {
                region: '1000', // Ljubljana
                ad_click: 827,
                ad_shown: 1378,
            },
            {
                region: '2000', // Maribor
                ad_click: 320,
                ad_shown: 521,
            },
            {
                region: '3000', // Celje
                ad_click: 1103,
                ad_shown: 1593,
            },
            {
                region: '4000', // Kranj
                ad_click: 589,
                ad_shown: 871,
            },
            {
                region: '5000', // Nova Gorica
                ad_click: 764,
                ad_shown: 1227,
            },
            {
                region: '6000', // Koper
                ad_click: 1207,
                ad_shown: 1867,
            },
            {
                region: '8000', // Novo Mesto
                ad_click: 462,
                ad_shown: 658,
            },
            {
                region: '9000', // Murska Sobota
                ad_click: 692,
                ad_shown: 1043,
            }]
    }
];

const DATA_CATEGORIES_AVG_PRICE = [
    {
        name: 'Test kategorija 1',
        avg_price: 6.43,
    },
    {
        name: 'Test kategorija 2',
        avg_price: 2.75,
    },
    {
        name: 'Test kategorija 3',
        avg_price: 8.12,
    },
    {
        name: 'Test kategorija 4',
        avg_price: 4.89,
    },
    {
        name: 'Test kategorija 5',
        avg_price: 1.98,
    },
    {
        name: 'Test kategorija 6',
        avg_price: 7.56,
    },
    {
        name: 'Test kategorija 7',
        avg_price: 5.27,
    },
    {
        name: 'Test kategorija 8',
        avg_price: 3.49,
    },
];

// per each product, analytics dissected into regions + then sum all ad stats regionally into one array
const DATA_PRODUCTS_AVG_PRICE_REGIONAL = [
    {
        name: 'Test Produkt 1',
        product_id: '4cfc7677-bc40-42a4-9135-418c2f3a6b23',
        regions: [
            {region: '1000', avg_price: 7.24},
            {region: '2000', avg_price: 3.76},
            {region: '3000', avg_price: 5.82},
            {region: '4000', avg_price: 1.58},
            {region: '5000', avg_price: 6.97},
            {region: '6000', avg_price: 4.19},
            {region: '8000', avg_price: 2.73},
            {region: '9000', avg_price: 7.59},
        ],
    },
    {
        name: 'Test Produkt 2',
        product_id: 'a1bc2428-83db-4c8b-8fdd-d577b4927b6f',
        regions: [
            {region: '1000', avg_price: 7.88},
            {region: '2000', avg_price: 3.45},
            {region: '3000', avg_price: 6.21},
            {region: '4000', avg_price: 2.37},
            {region: '5000', avg_price: 5.56},
            {region: '6000', avg_price: 4.90},
            {region: '8000', avg_price: 2.28},
            {region: '9000', avg_price: 7.35},
        ],
    },
    {
        name: 'Test Produkt 3',
        product_id: '29a5817e-4b79-4db1-a2b6-1d4e03e6b6c7',
        regions: [
            {region: '1000', avg_price: 6.98},
            {region: '2000', avg_price: 4.23},
            {region: '3000', avg_price: 5.07},
            {region: '4000', avg_price: 1.87},
            {region: '5000', avg_price: 6.49},
            {region: '6000', avg_price: 3.78},
            {region: '8000', avg_price: 2.97},
            {region: '9000', avg_price: 7.88},
        ],
    },
];

// per each category, analytics dissected into regions + then sum all ad stats regionally into one array
const DATA_CATEGORIES_AVG_PRICE_REGIONAL = [
    {
        name: 'Test Kategorija 1',
        category_id: '7a52d6f7-89b1-4a9e-94e9-77168274d133',
        regions: [
            {region: '1000', avg_price: 7.46},
            {region: '2000', avg_price: 3.92},
            {region: '3000', avg_price: 6.38},
            {region: '4000', avg_price: 2.14},
            {region: '5000', avg_price: 6.13},
            {region: '6000', avg_price: 4.45},
            {region: '8000', avg_price: 2.86},
            {region: '9000', avg_price: 7.18},
        ],
    },
    {
        name: 'Test Kategorija 2',
        category_id: '4e26d43f-6c6b-420a-9e49-6e030f6fb032',
        regions: [
            {region: '1000', avg_price: 7.02},
            {region: '2000', avg_price: 4.56},
            {region: '3000', avg_price: 5.76},
            {region: '4000', avg_price: 2.48},
            {region: '5000', avg_price: 5.96},
            {region: '6000', avg_price: 4.12},
            {region: '8000', avg_price: 3.19},
            {region: '9000', avg_price: 7.45},
        ],
    },
    {
        name: 'Test Kategorija 3',
        category_id: 'c924a094-8924-40b6-9c3e-35bdb75ee9b7',
        regions: [
            {region: '1000', avg_price: 6.54},
            {region: '2000', avg_price: 3.68},
            {region: '3000', avg_price: 5.97},
            {region: '4000', avg_price: 1.73},
            {region: '5000', avg_price: 6.36},
            {region: '6000', avg_price: 3.67},
            {region: '8000', avg_price: 2.54},
            {region: '9000', avg_price: 7.73},
        ],
    },
];

const SEARCH_BY_FIELDS = [
    'name',
    'external_id'
];


function Analytics(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);
    let ads = useSelector(state => state.reducer.ads);

    const [productSelected, setProductSelected] = useState(null);
    const [categorySelected, setCategorySelected] = useState(null);

    const [dataQrScansDaily, setDataQrScansDaily] = useState([]);
    const [dataQrScansHourly, setDataQrScansHourly] = useState([]);
    const [dataQrScansByBarsHourly, setDataQrScansByBarsHourly] = useState([]);

    const [selectedBarForHourlyQrScans, setSelectedBarForHourlyQrScans] = useState(null);


    const [dataQrScansRegionally, setDataQrScansRegionally] = useState([]);
    const [adsImpressions, setAdsImpressions] = useState([]);
    const [averageProductPrices, setAverageProductPrices] = useState([]);
    const [averageCategoryPrices, setAverageCategoryPrices] = useState([]);

    const [period, setPeriod] = useState("ALL_TIME");

    const [tab, setTab] = useState(false);
    const [tabPrices, setTabPrices] = useState(false);

    const [allProductPrices, setAllProductPrices] = useState(averageProductPrices);
    const [allCategoryPrices, setAllCategoryPrices] = useState(averageCategoryPrices);

    const [filteredAverageProductPrices, setFilteredAverageProductPrices] = useState(averageProductPrices);
    const [filteredAverageCategoryPrices, setFilteredAverageCategoryPrices] = useState(averageCategoryPrices);
    const [nameInput, setNameInput] = useState(null);

    const [inputTimeout, setInputTimeout] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [showStatsForSelectedBar, setShowStatsForSelectedBar] = useState(false);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        window.analytics_page_open = true;

        return () => {
            window.analytics_page_open = false;
        };
    }, []);

    useEffect(() => {
        if (!!inputTimeout) {
            clearTimeout(inputTimeout);
            setInputTimeout(null);
        }
        setInputTimeout(setTimeout(() => {
            if (!nameInput || nameInput === '' || (nameInput && nameInput?.length <= 2)) {
                dispatch(loadAvgPrices(user.user_id, (resData) => {
                    setFilteredAverageProductPrices(resData.products);
                    setFilteredAverageCategoryPrices(resData.categories);

                    setAllProductPrices(resData.products);
                    setAllCategoryPrices(resData.categories);

                    //  console.log(resData)

                }, () => {
                    console.warn("Error loading data..")
                }));
            } else {
                let searched = [];
                let searchedSecondArray = [];
                for (const s of allProductPrices) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (s.hasOwnProperty(fieldName) && !!s[fieldName] && parseMapCaretChars(s[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            searched.push(s);
                            break;
                        }
                    }
                }
                for (const s of allCategoryPrices) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (s.hasOwnProperty(fieldName) && !!s[fieldName] && parseMapCaretChars(s[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            searchedSecondArray.push(s);
                            break;
                        }
                    }
                }
                setFilteredAverageProductPrices(searched);
                setFilteredAverageCategoryPrices(searchedSecondArray);
            }
            //setCurrentPage(1);
        }, TIMEOUT_INTERVAL));
    }, [nameInput]);

    useEffect(() => {
        if (filteredAverageProductPrices.length < ITEMS_PER_PAGE) {
            setAverageProductPrices(filteredAverageProductPrices)
        } else {
            setAverageProductPrices(filteredAverageProductPrices.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE));
        }
    }, [currentPage, filteredAverageProductPrices, tabPrices]);

    useEffect(() => {
        if (filteredAverageCategoryPrices.length < ITEMS_PER_PAGE) {
            setAverageCategoryPrices(filteredAverageCategoryPrices)
        } else {
            setAverageCategoryPrices(filteredAverageCategoryPrices.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE));
        }
    }, [currentPage, filteredAverageCategoryPrices, tabPrices]);

    useEffect(() => {
        let done = false;
        const asyncEffect = async () => {
            if (user && period) {
                await dispatch(loadDailyQRScans(period, (resData) => {
                    setDataQrScansDaily(resData);

                    //    console.log(resData)
                }, () => {
                    console.warn("Error loading data..")
                }));
                await dispatch(loadHourlyQRScans(period, (resData) => {
                    setDataQrScansHourly(resData);
                    //    console.log(resData)

                }, () => {
                    console.warn("Error loading data..")
                }));
                await dispatch(loadHourlyQRScansByBars(period, (resData) => {
                    setDataQrScansByBarsHourly(resData);
                    //    console.log(resData)

                }, () => {
                    console.warn("Error loading data..")
                }));
                await dispatch(loadRegionallyQRScans(user.user_id, period, (resData) => {
                    setDataQrScansRegionally(resData);
                    //    console.log(resData)

                }, () => {
                    console.warn("Error loading data..")
                }));

                await dispatch(loadAddImpressionsRegionally(user.user_id, (resData) => {
                    setAdsImpressions(resData);
                    //  console.log(resData)

                }, () => {
                    console.warn("Error loading data..")
                }));

                await dispatch(loadAvgPrices(user.user_id, (resData) => {
                    setAverageProductPrices(resData.products);
                    setAverageCategoryPrices(resData.categories);

                    setFilteredAverageProductPrices(resData.products);
                    setFilteredAverageCategoryPrices(resData.categories);

                    setAllProductPrices(resData.products);
                    setAllCategoryPrices(resData.categories);
                    // console.log(resData)

                }, () => {
                    console.warn("Error loading data..")
                }));
            }
        }
        asyncEffect().then(() => console.log(done && "done loading data."));
    }, [user, period]);


    const formatDatesInData = (data) => {
        return data.map(item => {
            const formattedDate = displayDateUTC(item.date);
            return {...item, date: formattedDate};
        });
    };

    const renderScansDaily = () => {
        if (!dataQrScansDaily || dataQrScansDaily === []) {
            return <></>
        }
        let tmpData = formatDatesInData(dataQrScansDaily)
        console.log(tmpData);

        return (
            <div style={{height: '200px'}}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={tmpData}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="date"/>
                        <YAxis/>
                        <Tooltip/>
                        <Line type="monotone" name="QR SKENIRANJ" dataKey="qr_scans" stroke="#82ca9d"
                              activeDot={{r: 4}}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }

    const renderScansHourly = () => {
        const maxQrScans = Math.max(...dataQrScansHourly.map(item => item.qr_scans));

        return (
            <div style={{height: '200px'}}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={dataQrScansHourly}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="hour"/>
                        <YAxis domain={[0, maxQrScans]}/>
                        <Tooltip/>
                        <Bar name="QR SKENIRANJ" dataKey="qr_scans" fill="#82ca9d"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };

    const renderScansByBarHourly = () => {
        if (!selectedBarForHourlyQrScans) {
            return <div></div>;
        }

        if (!selectedBarForHourlyQrScans || !selectedBarForHourlyQrScans.hourly_stats) {
            return <div>Za izbrani bar ni na voljo podatkov.</div>;
        }

        const maxQrScans = Math.max(...selectedBarForHourlyQrScans.hourly_stats.map(item => item.qr_scans));

        return (
            <div style={{height: '200px'}}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={selectedBarForHourlyQrScans.hourly_stats}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="hour"/>
                        <YAxis domain={[0, maxQrScans]}/>
                        <Tooltip/>
                        <Bar name={`QR skeniranja za ${parseMapCaretChars(selectedBarForHourlyQrScans.name)}`} dataKey="qr_scans"
                             fill="#82ca9d"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };


    const renderScansRegionally = () => {
        return (
            <div style={{height: '160px'}}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={dataQrScansRegionally}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="region"/>
                        <YAxis/>
                        <Tooltip/>
                        <Bar name="QR SKENIRANJ" dataKey="qr_scans" fill="#82ca9d"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    const renderAdImpressionsRegionally = (regions) => {
        return (
            <div style={{height: '140px', width: '300px'}}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={regions}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 10,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="region"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar name="PRIKAZOV" dataKey="ad_shown" stackId="a" fill="#8884d8"/>
                        <Bar name="KLIKOV" dataKey="ad_click" stackId="a" fill="#82ca9d"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    const renderAvgPriceRegionally = (regions) => {
        return (
            <div style={{height: '140px', width: '300px'}}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={regions}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3"/>
                        <XAxis dataKey="region"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar name="POVP. CENA" dataKey="avg_price" fill="#82ca9d"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }

    const displayStatsForHourlyQrScansToggle = () => {
        return (
            <div className={'stats-toggle prices-toggle custom-row'}>
                <>
                    <Button id={'button-all-bars'} selection
                            selected={!showStatsForSelectedBar}
                            text={'VSI BARI'}
                            action={() => setShowStatsForSelectedBar(false)}/>
                    <Button id={'button-selected-bar'} selection
                            selected={showStatsForSelectedBar}
                            text={'ZA IZBRANI BAR'}
                            action={() => setShowStatsForSelectedBar(true)}/>
                </>
            </div>
        );
    };


    const displayStatsToggle = () => {
        return (
            <div className={'stats-toggle custom-row'}>
                <>
                    <Button id={'button-digital'} selection
                            selected={!tab}
                            text={'ANALITIKA QR IN OGLASI'}
                            action={() => setTab(false)}/>
                    <Button id={'button-paper'} selection
                            selected={tab}
                            text={'ANALITIKA POVP. CEN'}
                            action={() => setTab(true)}/>
                </>
            </div>
        );
    }

    const displayStatsToggleCategoryProduct = () => {
        if (!tab) {
            return <></>
        }

        return (
            <div className={'stats-toggle prices-toggle custom-row'}>
                <>
                    <Button id={'button-digital'} selection
                            selected={!tabPrices}
                            text={'KATEGORIJE'}
                            action={() => setTabPrices(false)}/>
                    <Button id={'button-paper'} selection
                            selected={tabPrices}
                            text={'IZDELKI'}
                            action={() => setTabPrices(true)}/>
                </>
            </div>
        );
    }

    const renderPeriodsToggle = () => {
        return (
            <div className={'toggle-period custom-row'}>
                <>
                    <Button id={'button-digital'} selection
                            disabled={false}
                            selected={period === "7_DAYS"}
                            text={'7 DNI'}
                            action={() => setPeriod("7_DAYS")}/>
                    <Button id={'button-paper'} selection
                            selected={period === "30_DAYS"}
                            disabled={false}
                            text={'30 DNI'}
                            action={() => setPeriod("30_DAYS")}/>
                    <Button id={'button-both'} selection
                            disabled={false}
                            selected={period === "ALL_TIME"}
                            text={'CELOTNO OBDOBJE'}
                            action={() => setPeriod("ALL_TIME")}/>
                </>
            </div>
        );
    }

    function Item(props) {
        console.log(props.value);
        const item = props.value;
        const regions = props.value.regions;
        /*
        if (!item.scans_counter) {
            return <></>;
        }

         */
        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-stat normal'}>{parseMapCaretChars(item.name)}</div>
                <div className={'row-stat normal'}>{item.ad_shown}</div>
                <div className={'row-stat normal'}>{item.ad_click}</div>
                <div className={'row-stat'}>{
                    renderAdImpressionsRegionally(regions)
                }</div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <>
                    <div className={'dynamic-header-stats'}>
                        <div className={'row-stat'}>Oglas</div>
                        <div className={'row-stat'}>Št. prikazov</div>
                        <div className={'row-stat'}>Št. klikov</div>
                        <div className={'row-stat'}>Regijska porazdelitev</div>
                    </div>
                </>
            );
        };
        // console.log(items);
        if (!items || items === {}) {
            return header();
        } else {
            const list = (items) => {
                if (!items) {
                    return;
                }
                return (
                    <ul className={'item-list dynamic stats-list'}>
                        {items.map((item, index) => <Item key={index} value={item}/>)}
                    </ul>
                );
            }
            return (
                <div className={'table-container-dynamic'}>
                    {header()}
                    {list(items)}
                </div>
            );
        }
    }

    function ItemAvgPrice(props) {
        // console.log(props.value);
        const item = props.value;
        const regions = props.value.regions;
        /*
        if (!item.scans_counter) {
            return <></>;
        }

         */
        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-stat normal'}>{parseMapCaretChars(item.name)}</div>
                <div className={'row-stat'}>{item?.avg_price} €</div>
                <div className={'row-stat'}>{
                    renderAvgPriceRegionally(regions)
                }</div>
            </li>
        );
    }

    function renderTableAvgPrices(items, title) {
        const filters = () => {
            return (
                <div className={'search-filters'} style={{left: `${sidebarWidth + 50}px`}}>
                    <Input onSubmit={() => {
                    }}
                           onChange={setNameInput}
                           placeholder={'Naziv ali šifra izdelka ali kategorije'}
                           white
                    />
                </div>
            );
        };
        const pages = (className) => {
            return (
                <div className={className} style={{left: `${sidebarWidth + 50}px`}}>
                    <Button disabled={currentPage === 1}
                            text={'Prejšnja stran'}
                            action={() => {
                                setCurrentPage(currentPage - 1)
                            }}/>
                    <Button disabled={currentPage === Math.ceil(filteredAverageProductPrices.length / ITEMS_PER_PAGE)}
                            text={'Naslednja stran'}
                            action={() => {
                                setCurrentPage(currentPage + 1)
                            }}/>
                    <p>{currentPage}/{Math.ceil(filteredAverageProductPrices.length / ITEMS_PER_PAGE)}</p>
                </div>
            );
        };
        const header = () => {
            return (
                <>
                    <div className={'dynamic-header-stats col3'}>
                        <div className={'row-stat'}>{title}</div>
                        <div className={'row-stat'}>Povprečna cena</div>
                        <div className={'row-stat'}>Regijska porazdelitev</div>
                    </div>
                </>
            );
        };
        // console.log(items);
        if (!items || items === {}) {
            return header();
        } else {
            const list = (items) => {
                if (!items) {
                    return;
                }
                return (
                    <ul className={'item-list dynamic stats-list col3'}>
                        {items.map((item, index) => <ItemAvgPrice key={index} value={item}/>)}
                    </ul>
                );
            }
            return (
                <div className={'table-container-dynamic'} style={{display: !tab ? 'none' : 'block'}}>
                    {filters()}
                    {pages('pagination-container pagination-container-has-filter')}
                    {header()}
                    {list(items)}
                </div>
            );
        }
    }

    const renderAnalyticsAveragePrices = () => {
        return <>
            <p className={'p-title-small'}
               style={{
                   paddingBottom: '48px',
                   marginTop: '150px'
               }}>{'Analitika povprečnih cen kategorij in izdelkov'}</p>

            {!tabPrices ? <div className={'container-column-2'} style={{marginBottom: '20px'}}>
                    <div className={'column-2'} style={{width: '80%'}}>
                        <p className={'p-title-small'}>{'Kategorije'}</p>
                        {renderTableAvgPrices(averageCategoryPrices, "Kategorija")}
                    </div>
                </div> :
                <div className={'container-column-2'}>
                    <div className={'column-2'} style={{width: '80%', marginBottom: '20px'}}>
                        <p className={'p-title-small'}>{'Izdelki'}</p>
                        {renderTableAvgPrices(averageProductPrices, "Izdelek")}
                    </div>
                </div>
            }


        </>
    }

    const renderAdsImpressionsList = () => {
        if (!adsImpressions) {
            return <></>
        }
        return (
            <>
                <p className={'p-title-small'}
                   style={{marginTop: '24px', marginBottom: '12px'}}>{'Analitika oglasov'}</p>
                <div className={'custom-row'} style={{justifyContent: 'flex-start'}}>
                    {renderTable(adsImpressions)}
                </div>
            </>
        );
    }

    const renderAnalyticsScansAndAds = () => {
        return <>
            <div className={'container-column-2'}>
                <div className={'column-2'} style={{width: '50%'}}>
                    <p className={'p-title-small'}>{'Prikaz skeniranih QR kod (po dnevih)'}</p>
                    {renderScansDaily()}

                </div>

                <div className={'column-2'} style={{width: '50%', paddingRight: '48px', paddingLeft: '36px'}}>


                    <p className={'p-title-small'}>{'Prikaz skeniranih QR kod (po urah)'}</p>
                    {renderScansHourly()}

                </div>
            </div>

            {renderPeriodsToggle()}

            <div className={'container-column-2'} style={{paddingTop: '48px'}}>
                <div className={'column-2'} style={{width: '50%'}}>
                    <p className={'p-title-small'}>{'Prikaz skeniranih QR kod (po regijah)'}</p>
                    {renderScansRegionally()}
                </div>
                <>
                    <div className={'column-2'} style={{width: '50%', paddingRight: '48px', paddingLeft: '36px'}}>
                        <p className={'p-title-small'}>{'Prikaz skeniranih QR kod (po urah) za izbrani bar'}</p>

                        <BarQrScansDropdown
                            options={dataQrScansByBarsHourly}
                            onChange={setSelectedBarForHourlyQrScans}
                            placeholder={"Izberite bar"}
                        />
                        {renderScansByBarHourly()}
                    </div>

                </>
            </div>

            {renderAdsImpressionsList()}
        </>
    }


    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y + 100);
        }, 250)
    }, [tab, tabPrices, selectedBarForHourlyQrScans])


    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token &&
        <div><Page relative ad className={'main analytics'} style={{height: `${mainHeight}px`}}
                   onToggleSidebar={(shown) => {
                       if (shown) {
                           dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                       } else {
                           dispatch(setSidebarWidth(0))
                       }
                   }}>
            <div className={'body'} style={{
                paddingLeft: `${sidebarWidth + 50}px`
            }}>
                <Header left={`${sidebarWidth + 50}px`} title={`ANALITIKA`}/>
                {displayStatsToggle()}
                {displayStatsToggleCategoryProduct()}

                {!tab ? renderAnalyticsScansAndAds() : renderAnalyticsAveragePrices()}
            </div>
        </Page>
        </div>
}

export default Analytics;