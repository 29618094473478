import React, {useEffect, useState} from "react";
import Button from "./Button";

import "../css/ButtonGroupSelection.css"
import {useSelector} from "react-redux";
import {isUserCaretaker} from "../../helpers/helpers";

export const LEADING_BRAND = {
    HEINEKEN: 0,
    UNION: 1,
    LASKO: 2,
    LASKO_DARK: 3,
    UNION_DARK: 4,
    HEINEKEN_WHITE: 5,
    BEER_CIDER_1: 101,
    BEER_CIDER_2: 102,
    BEER_CIDER_3: 103,
    BEER_CIDER_4: 104,
    BEER_CIDER_5: 105,
    BEER_CIDER_6: 106,
}

export const LEADING_BRAND_STRING = {
    HEINEKEN: 'HEINEKEN',
    HEINEKEN_WHITE: 'HEINEKEN',
    UNION: 'UNION',
    LASKO: 'LASKO',
    LASKO_DARK: 'LASKO',
    UNION_DARK: 'UNION',
    BEER_CIDER: 'BEER & CIDER'
}

export const GenerateMenuType = {
    DIGITAL: 0,
    PAPER: 1,
    BOTH: 2
};

const TRANSLATED_MAIN_TO_LEADING = {
    0: 0,
    1: 1,
    2: 2,
    3: 2,
    4: 1,
    5: 0
}

const translateMainBrandToLeading = (main_brand) => {
    return TRANSLATED_MAIN_TO_LEADING[main_brand];
}

function ButtonGroupSelection(props) {
    let user = useSelector(state => state.reducer.user);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let createMenuActive = useSelector(state => state.reducer.createMenuActive);


    let defaultValue = !!createMenuActive && props.loadBar ? (createMenuActive.leading_brand!==undefined ? createMenuActive.leading_brand : translateMainBrandToLeading(createMenuActive.main_brand)) : !!activeBar && props.loadBar ? activeBar.main_brand : props.value;
    const [selection, setSelection] = useState(defaultValue);
/*
    useEffect(() => {
        if (props.loadBar) {
            if (props.leadingBrand && activeBar) {
                onSelection(activeBar.main_brand);
            }
        }
    }, [activeBar]);


 */
    useEffect(() => {
        if (props.loadBar) {
            if (props.leadingBrand && activeBar) {
                onSelection(activeBar.main_brand);
            }
        }
        if (props.loadBar) {
            if (props.leadingBrand && props.loadCreateMenu && createMenuActive) {
                let value = createMenuActive.leading_brand!==undefined ? createMenuActive.leading_brand : translateMainBrandToLeading(createMenuActive.main_brand);
                onSelection(value)
            }
        }
        if (props.loadMenu) {
            if (props.menu_type && createMenuActive) {
                onSelection(Number(createMenuActive.menu_type!==undefined ? createMenuActive.menu_type : (!!createMenuActive.menuType!==undefined ? createMenuActive.menuType : null)))
            }
            if (props.menu_type && createMenuActive?.beer_cider_menu) {
                onSelection(GenerateMenuType.PAPER)
            }
            if (props.menu_type && !isUserCaretaker(user)) {
                onSelection(GenerateMenuType.DIGITAL)
            }
        }
    }, [activeBar, createMenuActive]);

    const onSelection = (brand) => {
        const {onChange} = props;

        setSelection(brand);
        onChange(brand);
    };

    const title = () => {
        if (props.leadingBrand) {
            return 'Izberite vodilno blagovno znamko v lokalu';
        } else if (props.menuOption) {
            return 'Izberite vrsto in količino cenika';
        }
    }

    const content = () => {
        if (props.leadingBrand) {
            return <>
                <Button id={'button-heineken'} loadBar={props.loadBar} selection
                        selected={selection === LEADING_BRAND.HEINEKEN}
                        text={'Heineken'} action={() => onSelection(LEADING_BRAND.HEINEKEN)}/>
                <Button id={'button-union'} loadBar={props.loadBar} selection
                        selected={selection === LEADING_BRAND.UNION} text={'Union'}
                        action={() => onSelection(LEADING_BRAND.UNION)}/>
                <Button id={'button-lasko'} loadBar={props.loadBar} selection
                        selected={selection === LEADING_BRAND.LASKO} text={'Laško'}
                        action={() => onSelection(LEADING_BRAND.LASKO)}/>
            </>
        } else if (props.menuOption && !props.digital) {
            return <>
                <Button id={'button-digital'} loadMenu={props.loadMenu} selection
                        disabled={props.disabled}
                        selected={selection === GenerateMenuType.DIGITAL}
                        text={'digitalni'}
                        action={() => onSelection(GenerateMenuType.DIGITAL)}/>
                <Button id={'button-paper'} loadMenu={props.loadMenu} selection
                        selected={selection === GenerateMenuType.PAPER}
                        text={'tiskan'}
                        action={() => onSelection(GenerateMenuType.PAPER)}/>
                <Button id={'button-both'} loadMenu={props.loadMenu} selection
                        disabled={props.disabled}
                        selected={selection === GenerateMenuType.BOTH}
                        text={'oboje'}
                        action={() => onSelection(GenerateMenuType.BOTH)}/>
            </>
        } else if (props.menuOption && props.digital) {
            return <>
                <Button id={'button-digital'} loadMenu={props.loadMenu} selection
                        disabled={false}
                        selected={selection === GenerateMenuType.DIGITAL}
                        text={'digitalni'}
                        action={() => onSelection(GenerateMenuType.DIGITAL)}/>
                <Button id={'button-paper'} loadMenu={props.loadMenu} selection
                        selected={selection === GenerateMenuType.PAPER}
                        disabled={true}
                        text={'tiskan'}
                        action={() => onSelection(GenerateMenuType.PAPER)}/>
                <Button id={'button-both'} loadMenu={props.loadMenu} selection
                        disabled={true}
                        selected={selection === GenerateMenuType.BOTH}
                        text={'oboje'}
                        action={() => onSelection(GenerateMenuType.BOTH)}/>
            </>
        }
    }

    return (
        <div className={'container-button-group-selection'} style={props.style}>
            <p className={'p-title-small'}>{title()}</p>
            <div className={'button-group-selection'}>
                {content()}
            </div>
        </div>
    );
}

export default ButtonGroupSelection;