import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {ErrorType} from "../../components/js/Message";
import {
    displayAlert, isUserAdmin, isUserBarRepresentative,
    isUserCaretaker, isUserOwner, isUserSharepointOnlyUser,
    mapCaretChars,
    parseFirstName, parseMapCaretChars,
    parseRestApiResponse,
    parseUserRole
} from "../../helpers/helpers";
import {NOTIFICATION_STATUS, PRINT_EMAIL_RECIPIENTS, SESSION_STORAGE_KEYS, USER_ROLES} from "../../constants/enums";
import * as fs from "fs";
import axios from "axios";
import {saveAs} from 'file-saver';

export const SET_WALLET_CONNECTED = 'SET_WALLET_CONNECTED';
export const SET_APP_FIRST_OPEN = 'SET_APP_FIRST_OPEN';
export const LOAD_ALL_USER_DATA = 'LOAD_ALL_USER_DATA';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_CREDENTIALS_SHAREPOINT = 'SET_CREDENTIALS_SHAREPOINT';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REMEMBER_ME = 'REMEMBER_ME';
export const REMEMBER_ME_CREDENTIALS = 'REMEMBER_ME_CREDENTIALS';
export const REMEMBER_ME_SHAREPOINT = 'REMEMBER_ME_SHAREPOINT';
export const REMEMBER_ME_CREDENTIALS_SHAREPOINT = 'REMEMBER_ME_CREDENTIALS_SHAREPOINT';
export const SET_CREATING_MENU_PROGRESS = 'SET_CREATING_MENU_PROGRESS';
export const SET_CREATE_MENU = 'SET_CREATE_MENU';
export const LOAD_MENUS_HISTORY = 'LOAD_MENUS_HISTORY';
export const LOAD_BARS = 'LOAD_BARS';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_BY_CATEGORY = 'LOAD_PRODUCTS_BY_CATEGORY';
export const LOAD_COMBO_OFFERS = 'LOAD_COMBO_OFFERS';
export const SET_ACTIVE_BAR = 'SET_ACTIVE_BAR';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_MENU_LIVE = 'LOAD_MENU_LIVE';
export const LOAD_BARS_FOR_OWNER = 'LOAD_BARS_FOR_OWNER';
export const SET_CREATE_MENU_LAST_CREATED = 'SET_CREATE_MENU_LAST_CREATED';
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const ADD_TO_USERS_LIST = 'ADD_TO_USERS_LIST';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_CARETAKERS = 'LOAD_CARETAKERS';
export const SET_ACTIVE_BAR_REPRESENTATIVE = 'SET_ACTIVE_BAR_REPRESENTATIVE';
export const SET_ACTIVE_BAR_CARETAKER = 'SET_ACTIVE_BAR_CARETAKER';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_USER_CARETAKER = 'SET_USER_CARETAKER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_ACTIVE_BAR_OWNER = 'SET_ACTIVE_BAR_OWNER';
export const SET_LAST_CARETAKER_CREATED = 'SET_LAST_CARETAKER_CREATED';
export const LOAD_ONLINE_BAR = 'LOAD_ONLINE_BAR';
export const LOAD_ONLINE_MENU = 'LOAD_ONLINE_MENU';
export const LOAD_ADS = 'LOAD_ADS';
export const LOAD_BAR_ADS = 'LOAD_BAR_ADS';
export const SET_SELECTED_AD = 'SET_SELECTED_AD';
export const LOAD_SELECTED_AD_ADDED_BARS = 'LOAD_SELECTED_AD_ADDED_BARS';
export const SET_NOTIFICATIONS_AVAILABLE = 'SET_NOTIFICATIONS_AVAILABLE';
export const LOAD_PRODUCT_CATEGORY_LABELS = 'LOAD_PRODUCT_CATEGORY_LABELS';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';
export const UPDATE_IMPORT_ERRORS = 'UPDATE_IMPORT_ERRORS';
export const SET_IMPORT_ERRORS = 'SET_IMPORT_ERRORS';
export const SET_ACTIVE_EDIT_USER = 'SET_ACTIVE_EDIT_USER';
export const SET_DOWNLOAD_PROGRESS = 'SET_DOWNLOAD_PROGRESS';
export const SET_DOWNLOAD_STATUS = 'SET_DOWNLOAD_STATUS';


//const URL_HOSTNAME = 'https://plu.serv.si/3001';
const URL_HOSTNAME = '';
const REST_API_ENDPOINT = '/rest'
export const BE_REST_API = URL_HOSTNAME + REST_API_ENDPOINT;

export const setDownloadProgress = (file_id, progress) => {
    saveDownloadProgress(file_id, progress);

    return {type: SET_DOWNLOAD_PROGRESS, file_id: file_id, downloadProgress: progress};
};

export const setDownloadStatusAction = (file_id, status) => {
    //saveDownloadProgress(file_id, status);

    return {type: SET_DOWNLOAD_STATUS, file_id: file_id, status: status};
};
export const setMenuLastCreated = (menu) => {
    return {type: SET_CREATE_MENU_LAST_CREATED, menu: menu}
};
export const setProductCategoryLabels = (categoryLabels) => {
    return {type: LOAD_PRODUCT_CATEGORY_LABELS, categoryLabels: categoryLabels}
};
export const setProductsByCategory = (products) => {
    return {type: LOAD_PRODUCTS_BY_CATEGORY, products: products}
};
export const setNotificationsAvailable = (state) => {
    return {type: SET_NOTIFICATIONS_AVAILABLE, state: state}
};
export const setSelectedAdAddedBars = (bars) => {
    return {type: LOAD_SELECTED_AD_ADDED_BARS, bars: bars}
};
export const setBarAds = (ads) => {
    return {type: LOAD_BAR_ADS, ads: ads}
};
export const setAds = (ads) => {
    return {type: LOAD_ADS, ads: ads}
};

export const loadOnlineMenu = (menu) => {
    return {type: LOAD_ONLINE_MENU, menu: menu}
};
export const loadOnlineBar = (bar) => {
    return {type: LOAD_ONLINE_BAR, bar: bar}
};

export const setCreateMenuActive = (menu) => {
    return {type: SET_CREATE_MENU, createMenuActive: menu}
};

export const setActiveMenu = (menu) => {
    setSessionActiveMenu(JSON.stringify(menu));
    return {type: SET_ACTIVE_MENU, menu: menu}
};

export const setLiveMenu = (menu) => {
    setSessionLiveMenu(JSON.stringify(menu));
    return {type: LOAD_MENU_LIVE, menu: menu}
};

export const setIsCreatingMenu = (state) => {
    return {type: SET_CREATING_MENU_PROGRESS, isCreatingMenu: state}
};

export const setWalletConnected = (state) => {
    return {type: SET_WALLET_CONNECTED, walletConnected: state};
};

export const setAppNotFirstOpen = (state) => {
    return {type: SET_APP_FIRST_OPEN, appFirstOpen: state};
};

export const setUserToken = (token) => {
    return {type: SET_USER_TOKEN, token: token};
};

export const setUserData = (user) => {
    return {type: SET_USER_DATA, user: user};
};

export const setCaretaker = (caretaker) => {
    setSessionCaretaker(JSON.stringify(caretaker));
    return {type: SET_USER_CARETAKER, caretaker: caretaker};
};

export const setCredentials = (email, password) => {
    return {type: SET_CREDENTIALS, email: email, password: password};
};

export const setCredentialsSharepoint = (email, password) => {
    return {type: SET_CREDENTIALS_SHAREPOINT, email: email, password: password};
};

export const addToUsersList = (user) => {
    return {type: ADD_TO_USERS_LIST, user: user};
};

export const setActiveBar = (bar) => {
    setSessionActiveBar(JSON.stringify(bar));
    return {type: SET_ACTIVE_BAR, bar: bar};
};

export const setActiveBarRepresentative = (user) => {
    setSessionActiveBarUser(JSON.stringify(user));
    return {type: SET_ACTIVE_BAR_REPRESENTATIVE, user: user};
};

export const setActiveBarCaretaker = (user) => {
    setSessionActiveBarCaretaker(JSON.stringify(user));
    return {type: SET_ACTIVE_BAR_CARETAKER, user: user};
};

export const setActiveBarOwner = (user) => {
    setSessionActiveBarOwner(JSON.stringify(user));
    return {type: SET_ACTIVE_BAR_OWNER, user: user};
};

export const setLastCaretakerCreated = (caretaker) => {
    //setSessionActiveBarOwner(JSON.stringify(user));
    return {type: SET_LAST_CARETAKER_CREATED, caretaker: caretaker};
};

export const setActiveProduct = (product) => {
    setSessionActiveProduct(JSON.stringify(product));
    return {type: SET_SELECTED_PRODUCT, product: product};
};

export const setActiveCategory = (category) => {
    setSessionActiveCategory(JSON.stringify(category));
    return {type: SET_SELECTED_CATEGORY, category: category};
};

export const setActiveAd = (ad) => {
    setSessionActiveAd(JSON.stringify(ad));
    return {type: SET_SELECTED_AD, ad: ad};
};

export const setNotifications = (notifications) => {
    return {type: LOAD_NOTIFICATIONS, notifications: notifications};
};

export const setUsers = (users) => {
    return {type: LOAD_USERS, users: users};
};

export const setActiveEditUser = (activeEditUser) => {
    return {type: SET_ACTIVE_EDIT_USER, activeEditUser: activeEditUser};
};

export const setCaretakers = (users) => {
    return {type: LOAD_CARETAKERS, users: users};
};

export const setBars = (bars) => {
    return {type: LOAD_BARS, bars: bars};
};

export const setShowSidebar = (showSidebar) => {
    return {type: SHOW_SIDEBAR, showSidebar: showSidebar};
};

export const setSidebarWidth = (sidebarWidth) => {
    return {type: SET_SIDEBAR_WIDTH, sidebarWidth: sidebarWidth};
};

export const setCategories = (categories) => {
    setSessionCategories(JSON.stringify(categories));
    return {type: LOAD_CATEGORIES, categories: categories};
};

export const setProducts = (products) => {
    return {type: LOAD_PRODUCTS, products: products};
};

export const setComboOffers = (comboOffers) => {
    return {type: LOAD_COMBO_OFFERS, comboOffers: comboOffers};
};
export const updateImportErrors = (error) => {
    return {type: UPDATE_IMPORT_ERRORS, error: error};
};
export const setImportErrors = (importErrors) => {
    return {type: SET_IMPORT_ERRORS, importErrors: importErrors};
};

export const setRememberMe = (state) => {
    saveRememberMe(state);
    return {type: REMEMBER_ME, rememberMe: state};
};

export const setSignInCredentials = (email, password, shouldWrite) => {
    if (shouldWrite) {
        saveCredentials(email, password);
    }
    return {type: REMEMBER_ME_CREDENTIALS, email: email, password: password};
};

export const loadCredentials = () => {
    return async dispatch => {
        try {
            const email = localStorage.getItem('saved_email');
            const pass = localStorage.getItem('saved_password');
            console.log("LOAD [STORAGE] REMEMBER ME CREDENTIALS:", email, pass);
            if (email && pass) {
                dispatch(setSignInCredentials(email, pass));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveCredentials = (email, password) => {
    try {
        localStorage.setItem('saved_email', email);
        localStorage.setItem('saved_password', password);
        console.log("SAVE [STORAGE] REMEMBER ME CREDENTIALS:", email, password);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const loadDownloadProgress = (file_id, action) => {
    return loadSessionStorageData('dl_progress_' + file_id, action)
};

export const saveDownloadProgress = (file_id, progress) => {
    try {
        sessionStorage.setItem('dl_progress_' + file_id, progress);
        console.log("SAVE [STORAGE] DOWNLOAD PROGRESS:", progress);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const loadSearchTerm = (search_input_field, action) => {
    return loadSessionStorageData('search_term' + search_input_field, action)
};

export const saveSearchTerm = (search_input_field, search_term) => {
    try {
        sessionStorage.setItem('search_term' + search_input_field, search_term);
        console.log("SAVE [STORAGE] SEARCH TERM:", search_term);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};


export const loadLastPageOpened = (action) => {
    return loadSessionStorageData('last_page', action)
};

export const saveLastPageOpened = (last_page) => {
    try {
        sessionStorage.setItem('last_page', last_page);
        console.log("SAVE [STORAGE] LAST PAGE VISITED:", last_page);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const isRememberMeEnabled = () => {
    return async dispatch => {
        try {
            const value = await localStorage.getItem('remember_me_sign_in');
            console.log("LOAD [STORAGE] REMEMBER ME:", value);
            if (!value || Number(value) === 0) {
                dispatch(setRememberMe(false));
            } else {
                dispatch(setRememberMe(true));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveRememberMe = (state) => {
    try {
        localStorage.setItem('remember_me_sign_in', state ? '1' : '0');
        console.log("SAVE [STORAGE]  REMEMBER ME:", state);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

/* SHAREPOINT REMEMBER ME */

export const setRememberMeSharepoint = (state) => {
    saveRememberMeSharepoint(state);
    return {type: REMEMBER_ME_SHAREPOINT, rememberMe: state};
};

export const setSignInCredentialsSharepoint = (email, password, shouldWrite) => {
    if (shouldWrite) {
        saveCredentialsSharepoint(email, password);
    }
    return {type: REMEMBER_ME_CREDENTIALS_SHAREPOINT, email: email, password: password};
};

export const loadCredentialsSharepoint = () => {
    return async dispatch => {
        try {
            const email = localStorage.getItem('saved_email_sharepoint');
            const pass = localStorage.getItem('saved_password_sharepoint');
            console.log("LOAD [STORAGE] REMEMBER ME CREDENTIALS:", email, pass);
            if (email && pass) {
                dispatch(setSignInCredentialsSharepoint(email, pass));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveCredentialsSharepoint = (email, password) => {
    try {
        localStorage.setItem('saved_email_sharepoint', email);
        localStorage.setItem('saved_password_sharepoint', password);
        console.log("SAVE [STORAGE] REMEMBER ME CREDENTIALS:", email, password);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const isRememberMeEnabledSharepoint = () => {
    return async dispatch => {
        try {
            const value = await localStorage.getItem('remember_me_sign_in_sharepoint');
            console.log("LOAD [STORAGE] REMEMBER ME:", value);
            if (!value || Number(value) === 0) {
                dispatch(setRememberMeSharepoint(false));
            } else {
                dispatch(setRememberMeSharepoint(true));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveRememberMeSharepoint = (state) => {
    try {
        localStorage.setItem('remember_me_sign_in_sharepoint', state ? '1' : '0');
        console.log("SAVE [STORAGE] REMEMBER ME:", state);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const setSessionStorageData = (onSetSessionAction) => {
    try {
        onSetSessionAction();
    } catch (error) {
        console.error("Error saving data!" + error);
    }
};

export const loadSessionStorageData = (key, onLoadSessionAction) => {
    try {
        const value = sessionStorage.getItem(key);
        console.log(`GET [SESSION STORAGE] ${key}:`, value);
        if (onLoadSessionAction) {
            onLoadSessionAction(value);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    } catch (error) {
        console.error("Error saving data!" + error);
        return null;
    }
};

export const setSessionUser = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER, user);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER}:`, JSON.parse(user));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserData(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionCaretaker = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_CARETAKER, user);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER_CARETAKER}:`, JSON.parse(user));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionCaretaker = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setCaretaker(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionUserToken = (token) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_TOKEN, token);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER_TOKEN}:`, token);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUserToken = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserToken(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionUsers = (users) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USERS, users);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USERS}:`, JSON.parse(users));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUsers = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUsers(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionCaretakers = (users) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.CARETAKERS, users);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.CARETAKERS}:`, JSON.parse(users));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionCaretakers = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setCaretakers(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionBars = (bars) => {
    const onSetSessionAction = () => {
        //sessionStorage.setItem(SESSION_STORAGE_KEYS.BARS, bars);
        //console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BARS}:`, JSON.parse(bars));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionBars = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setBars(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionProducts = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.PRODUCTS, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.PRODUCTS}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionProducts = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setProducts(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionComboOffers = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.COMBO_PRODUCTS, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.COMBO_PRODUCTS}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionComboOffers = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setComboOffers(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionCategories = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.CATEGORIES, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.CATEGORIES}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionCategories = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setCategories(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveBarUser = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.BAR_REPRESENTATIVE, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BAR_REPRESENTATIVE}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBarUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBarRepresentative(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveBarCaretaker = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.BAR_CARETAKER, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BAR_CARETAKER}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBarCaretaker = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBarCaretaker(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveEditUser = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_EDIT_USER, user);
        //console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_BAR}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveEditUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveEditUser(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};


export const setSessionActiveBar = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_BAR, products);
        //console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_BAR}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBar = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBar(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveProduct = (product) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_PRODUCT, product);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_PRODUCT}:`, JSON.parse(product));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveProduct = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveProduct(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveCategory = (category) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_CATEGORY, category);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_CATEGORY}:`, JSON.parse(category));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveCategory = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveCategory(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveAd = (ad) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_AD, ad);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_AD}:`, JSON.parse(ad));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveAd = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveAd(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveMenu = (menu) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_MENU, menu);
        // console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_MENU}:`, JSON.parse(menu));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveMenu = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveMenu(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionLiveMenu = (menu) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.LIVE_MENU, menu);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.LIVE_MENU}:`, JSON.parse(menu));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionLiveMenu = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setLiveMenu(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveBarOwner = (owner) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.BAR_OWNER, owner);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BAR_OWNER}:`, JSON.parse(owner));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBarOwner = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBarOwner(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

/* USER actions */
export const createNewUser = (admin_caretaker_id, user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user
        }
        postdata = JSON.stringify(postdata);
        if (!admin_caretaker_id) {
            return;
        }
        try {
            let uri = BE_REST_API + '/users/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("CREATE NEW USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionUser(JSON.stringify(resData));
                dispatch(addToUsersList(resData));
                if (isUserCaretaker(user) || isUserOwner(user) || isUserAdmin(user) || isUserBarRepresentative(user)) {
                    let caretaker = {
                        full_name: user.full_name,
                        user_id: resData.user_id,
                        parent_id: admin_caretaker_id,
                    }
                    await dispatch(createCaretaker(caretaker, () => {
                        console.log("Caretaker user successfully created!")
                    }, () => {
                        console.warn("Error creating new caretaker!")
                    }));
                }
                if (isUserBarRepresentative(user)) {
                    dispatch(setActiveBarRepresentative(resData)); //user
                } else if (isUserOwner(user)) {
                    dispatch(setActiveBarOwner(resData)); //user
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating user..', e);
        }
    };
};

export const createCaretaker = (caretaker, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            caretaker: caretaker
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/create/caretaker';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET CREATE CARETAKER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setLastCaretakerCreated(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};

export const updateUser = (user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveEditUser(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const resetUserPassword = (user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user/reset/password';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE ACTIVE EDIT USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setActiveEditUser(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const updateUserTelephone = (user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user/update/telephone';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE USER TEL.: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionUsers(JSON.stringify(resData));
                //dispatch({type: LOAD_USERS, users: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const updateUserPassword = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user/update/password';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER PASS.: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionUsers(JSON.stringify(resData));
                //dispatch({type: LOAD_USERS, users: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed resetting user password..', e);
        }
    };
};



export const loadUsers = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET USERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUsers(JSON.stringify(resData));
                dispatch(setUsers(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const loadUsersExternal = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/external';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET USERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
              //  setSessionUsers(JSON.stringify(resData));
              //  dispatch(setUsers(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData.error), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const loadUser = (user_id, userRole, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (userRole === USER_ROLES.BAR_REPRESENTATIVE) {
                    dispatch(setActiveBarRepresentative(resData));
                    //setSessionActiveBarUser(JSON.stringify(resData));
                } else if (userRole === USER_ROLES.OWNER) {
                    dispatch(setActiveBarOwner(resData));
                    //setSessionActiveBarUser(JSON.stringify(resData));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const loadCaretaker = (caretaker_id, userRole, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        try {
            let postdata;
            let uri;
            if (userRole === USER_ROLES.CARETAKER) {
                postdata = {
                    caretaker_id: caretaker_id,
                }
                uri = BE_REST_API + '/users/caretaker';
            } else if (userRole === USER_ROLES.OWNER) {
                postdata = {
                    caretaker_id: caretaker_id,
                }
                uri = BE_REST_API + '/users/caretaker';
            } else if (userRole === USER_ROLES.BAR_REPRESENTATIVE) {
                postdata = {
                    user_id: caretaker_id,
                }
                uri = BE_REST_API + '/users/user';
            }
            postdata = JSON.stringify(postdata);
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET CARETAKER BY CARETAKER ID: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (userRole === USER_ROLES.CARETAKER) {
                    dispatch(setActiveBarCaretaker(resData));
                    //setSessionActiveBarCaretaker(JSON.stringify(resData));
                } else if (userRole === USER_ROLES.OWNER) {
                    console.log("LOAD CARETAKER OWNER", resData);
                    dispatch(loadUser(resData.user_id, USER_ROLES.OWNER, () => {
                    }, () => {
                    })); //set owner as user entity
                } else if (userRole === USER_ROLES.BAR_REPRESENTATIVE) {
                    console.log("LOAD USER BAR REP", resData);

                    if (!caretaker_id) {
                        dispatch(setActiveBarRepresentative(null));
                    } else {
                        dispatch(loadUser(resData.user_id, USER_ROLES.BAR_REPRESENTATIVE, () => {
                        }, () => {
                        })); //set owner as user entity
                    }
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const loadCaretakerByUserId = (user_id, userRole, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/caretaker/user_id';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET CARETAKER BY USER ID: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (userRole === USER_ROLES.CARETAKER || userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.BAR_REPRESENTATIVE || userRole === USER_ROLES.OWNER) {
                    dispatch(setCaretaker(resData));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading caretaker..', e);
        }
    };
};

export const loadCaretakers = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/caretakers';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET CARETAKERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionCaretakers(JSON.stringify(resData));
                dispatch(setCaretakers(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading caretakers list..', e);
        }
    };
};


export const userLogin = (email, password, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            password: password
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/login';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET USER LOGIN: " + JSON.stringify(resData, null, 4));

            if (resData && resData.type && isUserSharepointOnlyUser(resData)) {
                onErrorErrorFetchingData();
            } else {
                const onDispatchData = async () => {
                    setSessionUser(JSON.stringify(resData));
                    setSessionUserToken(resData["user_id"]);
                    dispatch(setCredentials(email, password));
                    dispatch(setUserData(resData));
                    dispatch(setUserToken(resData["user_id"]));

                    let parsedUserRole = parseUserRole(resData["type"]);
                    if (parsedUserRole === USER_ROLES.ADMIN || parsedUserRole === USER_ROLES.CARETAKER || parsedUserRole === USER_ROLES.OWNER || parsedUserRole === USER_ROLES.BAR_REPRESENTATIVE) {
                        if (parsedUserRole === USER_ROLES.OWNER) {
                            await dispatch(loadBarsForOwner(resData['user_id'], (bars) => {
                                console.log(bars);
                                if (!bars || bars.length <= 0) {
                                    if (onErrorErrorFetchingData) {
                                        onErrorErrorFetchingData()
                                    }
                                    window.location.reload()
                                    return;
                                }

                                console.log("Bars successfully loaded..");
                                dispatch(setLiveMenu(null));
                                dispatch(setActiveMenu(null));

                                dispatch(setActiveBarOwner(resData));

                            }, () => {
                                console.warn("Error loading bars..")
                            }))
                        }
                        if (parsedUserRole === USER_ROLES.BAR_REPRESENTATIVE) {
                            await dispatch(loadBarsForBarRepresentative(resData['user_id'], (bars) => {
                                console.log(bars);
                                if (!bars || bars.length <= 0) {
                                    if (onErrorErrorFetchingData) {
                                        onErrorErrorFetchingData()
                                    }
                                    window.location.reload()
                                    return;
                                }

                                dispatch(setLiveMenu(null));
                                dispatch(setActiveMenu(null));

                                dispatch(setActiveBarRepresentative(resData));
                                console.log("Bars successfully loaded..");
                            }, () => {
                                console.warn("Error loading bars..")
                            }))
                        }


                        await dispatch(loadCaretakerByUserId(resData["user_id"], parsedUserRole, () => {
                            console.log("Caretaker successfully loaded..");
                        }, () => {

                            /*
                            if (parsedUserRole === USER_ROLES.OWNER || parsedUserRole === USER_ROLES.BAR_REPRESENTATIVE) {
                                if (onErrorErrorFetchingData) {
                                    onErrorErrorFetchingData()
                                 //   window.location.reload()
                                    return;
                                }
                            }
                             */


                            console.warn("Error loading caretaker user..")
                        }));
                        await dispatch(loadUsers(resData['user_id'], () => {
                            console.log("Users successfully loaded..");
                        }, () => {
                            console.warn("Error loading users..");
                        }));
                        await dispatch(loadCaretakers(resData['user_id'], () => {
                            console.log("Caretakers successfully loaded..");
                        }, () => {
                            console.warn("Error loading caretakers..");
                        }));
                        if (parsedUserRole === USER_ROLES.ADMIN) {
                            await dispatch(loadAllBars(resData['user_id'], () => {
                                console.log("Bars successfully loaded..");
                            }, () => {
                                console.warn("Error loading bars..")
                            }))
                            await dispatch(loadAds(() => {
                                console.log("Ads successfully loaded..");
                            }, () => {
                                console.warn("Error loading ads..")
                            }));
                        } else if (parsedUserRole === USER_ROLES.CARETAKER) {
                            await dispatch(loadBars(resData['user_id'], () => {
                                console.log("Bars successfully loaded..");
                            }, () => {
                                console.warn("Error loading bars..")
                            }))
                        }
                        await dispatch(loadCategories(null, () => {
                            console.log("Categories successfully loaded..");
                        }, () => {
                            console.warn("Error loading categories..")
                        }));

                        if (parsedUserRole === USER_ROLES.ADMIN || parsedUserRole === USER_ROLES.CARETAKER) {
                            await dispatch(loadProducts(null, resData, () => {
                                console.log("Products successfully loaded..");
                            }, () => {
                                console.warn("Error loading products..")
                            }));
                            await dispatch(loadComboOffers(null, () => {
                                console.log("Combo Offers successfully loaded..");
                            }, () => {
                                console.warn("Error loading combo offers..")
                            }));
                        }

                    }
                };
                parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);
            }

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};

/* BARS actions */

export const loadBars = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET BARS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionBars(JSON.stringify(resData));
                dispatch({type: LOAD_BARS, bars: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const loadBar = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/bar';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //   console.log("GET BAR DATA [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bar..', e);
        }
    };
};

export const loadActiveBar = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/bar';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //   console.log("GET BAR DATA [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bar..', e);
        }
    };
};

export const loadBarByMenuId = (menuId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu_id: menuId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/bar/menu/id';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET BAR DATA [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {

            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bar..', e);
        }
    };
};

export const loadAllBars = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/all';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET ALL BARS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionBars(JSON.stringify(resData));
                dispatch({type: LOAD_BARS, bars: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading all bars..', e);
        }
    };
};

export const loadBarsForBarRepresentative = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/representative';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET BARS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch({type: LOAD_BARS, bars: resData});
                if (resData && resData.length > 0) {
                    dispatch(setActiveBar(resData[0]));
                } else {
                    dispatch(setActiveBar(null));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const loadBarsForOwner = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/bars/owner';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET BARS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch({type: LOAD_BARS, bars: resData});

                console.log("LOAD BARS FOR OWNER: ", resData)

                if (resData && resData.length > 0) {
                    dispatch(setActiveBar(resData[0]));
                } else {
                    dispatch(setActiveBar(null));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};


export const createNewBar = (bar, admin_email, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar,
            admin_email: admin_email,
        }
        try {
            postdata = JSON.stringify(postdata);
            console.log(postdata)
        } catch (e) {
            console.log(e)
        }
        try {
            let uri = BE_REST_API + '/bars/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("CREATE BAR: " + JSON.stringify(resData.bar_id + ' ' + resData.external_id + ' ' + resData.name, null, 4));

            if (resData.error) {
                onErrorErrorFetchingData(resData.error);
            }
            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating bar..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                external_id: bar.external_id,
                name: bar.name
            }))
        }
    };
};

export const autoPublishMenu = (menu, caretaker_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu,
            caretaker_id: caretaker_id
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/publish';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("POST MENU DATA PUBLISH [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };

            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bar..', e);
        }
    };
};


export const updateBar = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar..', e);
        }
    };
};

export const updateBarExcel = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/excel';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            /*
            if (resData.error) {
                onErrorErrorFetchingData(resData.error);
            }
            */
            const onDispatchData = async () => {
                if (!resData.error) {
                    dispatch(setActiveBar(resData))
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData.error), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                external_id: bar.external_id,
                name: bar.name
            }))
        }
    };
};

export const updateCategoryExcel = (category, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            category: category
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories/category/update/excel';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            /*
            if (resData.error) {
                onErrorErrorFetchingData(resData.error);
            }
            */

            const onDispatchData = async () => {
                if (!resData.error) {
                    //dispatch(setActiveBar(resData))
                }
            };

            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData.error), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating category..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                name: category.name,
            }))
        }
    };
};

export const updateBarUser = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/user';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE BAR USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar user..', e);
        }
    };
};

export const updateBarOwner = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/owner';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE BAR OWNER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar owner..', e);
        }
    };
};


export const updateBarActiveStatus = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/active';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar..', e);
        }
    };
};

/* PRODUCT actions */
export const loadUniqueProducts = (onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        try {
            let uri = BE_REST_API + '/products/unique';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
            });
            const resData = await response.json();
            console.log("GET UNIQUE PRODUCTS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionProducts(JSON.stringify(resData));
                //dispatch({type: LOAD_PRODUCTS, products: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

/* PRODUCT actions */
export const loadProducts = (barId, user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized, shouldParse) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata)
        try {
            let uri = BE_REST_API + '/products';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET PRODUCTS: " + JSON.stringify(resData, null, 4));

            let p = [];
            let parseCondition = parseUserRole(user.type) === USER_ROLES.OWNER || parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE;
            shouldParse = shouldParse && parseCondition;
            const onDispatchData = async () => {

                if (!shouldParse) {
                    //setSessionProducts(JSON.stringify(resData));
                    dispatch({type: LOAD_PRODUCTS, products: resData});
                } else {
                    if (parseCondition) {
                        for (let i = 0; i < resData.length; i++) {
                            if (!resData[i].bar_id) {
                                continue;
                            }
                            p.push(resData[i]);
                        }
                        //setSessionProducts(JSON.stringify(p));
                        dispatch({type: LOAD_PRODUCTS, products: p});
                        console.log(p)

                    } else {
                        //setSessionProducts(JSON.stringify(resData));
                        dispatch({type: LOAD_PRODUCTS, products: resData});
                        console.log(resData)

                    }
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(!shouldParse ? resData : p), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading products..', e);
        }
    };
};

export const loadProductsByCategory = (barId, category_label, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
            category_label: category_label
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/products/by/category';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log(`GET PRODUCTS BY CATEGORY [${category_label}]:` + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionProducts(JSON.stringify(resData));
                dispatch(setProductsByCategory(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const createNewProduct = (product, isComboOffer, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let restCall = isComboOffer ? '/comboOffers/create' : '/products/create';
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata)
        try {
            let uri = BE_REST_API + restCall;
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE PRODUCT [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
                console.log("No need to write to app state, since get data is obtained after write to db operations");
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new product..', e);
        }
    };
};

export const updateProduct = (product, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata);

        console.log("PROD TO UPDATE", product);
        try {
            let uri = BE_REST_API + '/products/update';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE PRODUCT: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveProduct(resData));

                console.log("No need to write to app state, since get data is obtained after write to db operations");
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading product..', e);
        }
    };
};

export const updateProductExcel = (product, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/products/update/excel';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("UPDATE PRODUCT: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData.error), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading product..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                name: product.name,
                external_id: product.external_id,
            }))
        }
    };
};

export const updateProductActiveStatus = (product, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/products/update/active';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata

            });
            const resData = await response.json();
            console.log("UPDATE PRODUCT STATUS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
                if (!product.active) {
                    dispatch(setActiveProduct(null));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading product..', e);
        }
    };
};


/* MENU actions */
export const loadMenus = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET MENUS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionMenus(JSON.stringify(resData));
                dispatch({type: LOAD_MENUS_HISTORY, menusHistory: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading menus for specific bar..', e);
        }
    };
};

/* MENU actions */
export const loadMenusDirect = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/export';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET ALL BARS MENUS CATEGORIES AND PRODUCTS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionMenus(JSON.stringify(resData));
                // dispatch({type: LOAD_MENUS_HISTORY, menusHistory: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading BARS MENUS CATEGORIES AND PRODUCTS..', e);
        }
    };
};

export const loadLiveMenu = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/live';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //  console.log("GET LIVE MENU [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                await dispatch({type: LOAD_MENU_LIVE, menu: resData});
                await dispatch(loadMenu(resData));
                //await dispatch(loadBarAds(barId));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading live menu..', e);
        }
    };
};


export const loadBarL10NSupport = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/live/l10n';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET BAR L10N SUPPORT [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //await dispatch(loadBarAds(barId));
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading live menu. l10n.', e);
        }
    };
};

export const previewMenu = (menu, format, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu,
            format: format
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/menu/generate';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                responseType: "stream",
                body: postdata
            });
            console.log(response);
            let resData = '';
            let blob = await response.blob();
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `cenik-${format}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();

            console.log("MENU PREVIEW [RESPONSE]: " + JSON.stringify("Menu downloaded as pdf successfully..", null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed loading generated menu pdf..', e);
        }
    };
};

/* send only menu_id & parse menu on BE */
export const previewMenuParseOnBE = (menu, format, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu,
            format: format
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/menu/preview';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                responseType: "stream",
                body: postdata
            });
            console.log(response);
            let resData = '';
            let blob = await response.blob();
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `cenik-${format}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();

            console.log("MENU PREVIEW [RESPONSE]: " + JSON.stringify("Menu downloaded as pdf successfully..", null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed loading generated menu pdf..', e);
        }
    };
};

export const sendMenuToPrint = (menu, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        menu.email_print_recipient = PRINT_EMAIL_RECIPIENTS.PRINT_ADDRESS;
        let postdata = {
            menu: menu,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/menu/generate';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("MENU SEND TO PRINT [RESPONSE]: " + JSON.stringify("Menu sent to print successfully..", null, 4));

            const onDispatchData = async () => {

            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed loading generated menu pdf..', e);
        }
    };
};

export const loadMenu = (menu, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/menu/products';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            // console.log("LOAD MENU PRODUCTS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                await dispatch(loadOnlineMenu(resData))
                await dispatch(setActiveMenu(resData))
                await dispatch(setCreateMenuActive(resData))
                await dispatch(setMenuLastCreated(null));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading combo offers..', e);
        }
    };
};

export const loadMenuSendToPrint = (menu, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/menu/products';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("LOAD MENU PRODUCTS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                await dispatch(sendMenuToPrint(resData, () => {
                    console.log("Menu successfully generated!")
                }, (e) => {
                    throw new Error("Error previewing menu! " + e);
                }));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading combo offers..', e);
        }
    };
};

export const createNewMenuCategoryProductRow = (menu, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/create/product-category-menu';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("CREATE MENU CATEGORY PRODUCT [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const createNewMenuProductPriceRow = (menu, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/create/product-price-menu';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("CREATE MENU PRODUCT PRICE [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const createNewMenuDirect = (menu, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/menus/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATED NEW MENU [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //await dispatch({type: SET_CREATE_MENU_LAST_CREATED, menu: resData});
                await dispatch(setMenuLastCreated(resData));

                //let s = parseUserRole(user.type) === USER_ROLES.CARETAKER ? mapCaretChars(`Potnik ${parseFirstName(user.full_name)} je poslal prošnjo za tisk cenika`) : mapCaretChars(`Admin ${parseFirstName(user.full_name)} vam je poslal obvestilo`)
                //s = s.toString();

                /*
                let notification = {
                    name: s,
                    from_id: caretaker.caretaker_id,
                    to_id: caretaker.parent_id,
                    content: menu.comment,
                    status: NOTIFICATION_STATUS.PENDING,
                    menu_id: resData.menu_id
                }

                console.log("NOTIFICATION", notification);
                await dispatch(createNewNotification(user, notification, () => {
                    console.log("Notification successfully created..")
                }, () => {
                    console.log("Error creating notification!");
                }))
                */
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const createNewMenu = (user, caretaker, menu, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu: menu
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/menus/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATED NEW MENU [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //await dispatch({type: SET_CREATE_MENU_LAST_CREATED, menu: resData});
                await dispatch(setMenuLastCreated(resData));

                let s = parseUserRole(user.type) === USER_ROLES.CARETAKER ? mapCaretChars(`Potnik ${user.full_name} je poslal prošnjo za tisk cenika`) : mapCaretChars(`Admin ${user.full_name} vam je poslal obvestilo`)
                s = s.toString();

                let notification = {
                    name: s,
                    from_id: caretaker.caretaker_id,
                    to_id: caretaker.parent_id,
                    content: menu.comment,
                    status: NOTIFICATION_STATUS.PENDING,
                    menu_id: resData.menu_id
                }
                console.log("NOTIFICATION", notification);
                await dispatch(createNewNotification(user, notification, () => {
                    console.log("Notification successfully created..")
                }, () => {
                    console.log("Error creating notification!");
                }))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const updateProductPriceOnLiveMenu = (bar_id, menuId, productId, updatePrice, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu_id: menuId,
            product_id: productId,
            price: updatePrice
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/live/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE LIVE MENU PRODUCT PRICE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(loadLiveMenu(bar_id, () => {
                }, () => displayAlert('Napaka pri pridobivanju trenutnega cenika. Prosimo poskusite znova..')));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating live menu..', e);
        }
    };
};


/* COMBO OFFER actions */
export const loadComboOffers = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/comboOffers';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET COMBO OFFERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionComboOffers(JSON.stringify(resData));
                dispatch({type: LOAD_COMBO_OFFERS, comboOffers: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading combo offers..', e);
        }
    };
};


/* CATEGORIES actions*/

export const loadCategories = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET CATEGORIES: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionCategories(JSON.stringify(resData));
                dispatch({type: LOAD_CATEGORIES, categories: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading combo offers..', e);
        }
    };
};

export const createNewCategory = (barId, category, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
            name: category.name,
            position: category.position,
            eng_name: category.eng_name,
            de_name: category.de_name,
            it_name: category.it_name,
            hr_name: category.hr_name
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("CREATE CATEGORY [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new name..', e);
        }
    };
};


export const updateCategory = (category, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            category: category
        }
        postdata = JSON.stringify(postdata);

        console.log("CAT TO UPDATE", category);
        try {
            let uri = BE_REST_API + '/categories/category/update';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE CATEGORY: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveCategory(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading category..', e);
        }
    };
};


/* NOTIFICATION actions */
export const loadNotifications = (caretaker_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            caretaker_id: caretaker_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/notifications';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET NOTIFICATIONS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setNotifications(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading notifications..', e);
        }
    };
};

export const loadAdminAllNotifications = (caretaker_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            caretaker_id: caretaker_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/notifications/all';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET ALL NOTIFICATIONS (ADMIN) [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setNotifications(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading notifications..', e);
        }
    };
};

export const createNewNotification = (user, notification, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            notification: notification
        }
        postdata.notification.name = parseUserRole(user.type) === USER_ROLES.CARETAKER ? mapCaretChars(`Potnik ${user.full_name} je poslal prošnjo za tisk cenika`) : mapCaretChars(`Admin ${user.full_name} vam je poslal obvestilo`)
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/notifications/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("CREATE NOTIFICATION: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
                console.log("No need to write to app state, since get data is obtained after write to db operations");
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading notifications..', e);
        }
    };
};

export const respondToNotification = (notification, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            notification: notification
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/notifications/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE NOTIFICATION [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (notification.status === NOTIFICATION_STATUS.CONFIRMED) {
                    console.log("Notification confirmed...");
                } else if (notification.status === NOTIFICATION_STATUS.REJECTED) {
                    console.log("Notification rejected...");
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating notifications..', e);
        }
    };
};

/* ADS */

export const loadAds = (onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        try {
            let uri = BE_REST_API + '/ads';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
            });
            const resData = await response.json();
            console.log("ALL ADS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setAds(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining list of all ads..', e);
        }
    };
};

export const loadBarAds = (bar_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: bar_id
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/bar/ads';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("LOAD BAR ADS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setBarAds(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new ad..', e);
        }
    };
};

export const loadBarsForAd = (ad_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            ad_id: ad_id
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/ad/bars';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("LOAD BAR ADS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setSelectedAdAddedBars(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new ad..', e);
        }
    };
};

export const createNewAd = (ad, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            ad: ad
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE AD: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                console.log("No need to write to app state, since get data is obtained after write to db operations");
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new ad..', e);
        }
    };
};

export const addBarToAd = (adBarRow, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            adBarRow: adBarRow
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/create/bar-ad';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE BAR - AD ROW: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                await dispatch(loadBarsForAd(adBarRow.ad_id));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new ad..', e);
        }
    };
};

export const addAllBarsToAd = (adBarRows, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            adBarRows: adBarRows
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/ad/bars/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE ALL BAR - AD ROWS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                try {
                    await dispatch(loadBarsForAd(adBarRows[0].ad_id));
                } catch (e) {
                    console.warn(e)
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new ad..', e);
        }
    };
};


export const removeAllBarsFromAd = (ad_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            ad_id: ad_id
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/ad/bars/remove';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("REMOVE ALL BAR - AD ROWS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                await dispatch(loadBarsForAd(ad_id));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new ad..', e);
        }
    };
};

export const removeSelectedBarsFromAd = (adBarRows, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            adBarRows: adBarRows
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/ad/bars/remove/selected';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });

            const resData = await response.json();
            console.log("REMOVE SELECTED BAR - AD ROWS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                try {
                    await dispatch(loadBarsForAd(adBarRows[0].ad_id));
                } catch (e) {
                    console.warn(e)
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed removing ad from bar..', e);
        }
    };
};


export const removeBarFromAd = (adBarRow, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            adBarRow: adBarRow
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/remove/bar-ad';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("REMOVE BAR - AD ROW: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                await dispatch(loadBarsForAd(adBarRow.ad_id));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new ad..', e);
        }
    };
};

export const updateAd = (ad, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: ad
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/ads/update';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE AD: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveAd(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading ad..', e);
        }
    };
};

export const updateAdActiveStatus = (ad, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: ad
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/ads/update/active';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata

            });
            const resData = await response.json();
            console.log("UPDATE AD STATUS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (!ad.active) {
                    dispatch(setActiveAd(null));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed setting active status on ad..', e);
        }
    };
};

export const getMenuByMenuId = (menu_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            menu_id: menu_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/menus/menu/get';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("LOAD MENU [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading menu..', e);
        }
    };
};

export const getBarByBarId = (bar_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: bar_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/bar';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("LOAD BAR [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bar..', e);
        }
    };
}

//STATS
export const updateBarQrCodeScanned = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/counter';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE BAR QR SCAN COUNTER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (onSuccessAction) {
                    onSuccessAction(resData);
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () =>  onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bars qr code scan counter..', e);
        }
    };
};

export const updateAdClickedCounter = (product_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product_id: product_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/ads/update/counter';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE INTERNAL AD COUNTER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (onSuccessAction) {
                    onSuccessAction(resData);
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () =>  onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating internal ad counter..', e);
        }
    };
};

export const loadAvgPriceProducts = (product_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product_id: product_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/products/product/stats/price';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PRODUCTS STATS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (onSuccessAction) {
                    onSuccessAction(resData)
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading avg price products..', e);
        }
    };
};


export const loadOwnProductsMarketPresence = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/products/stats/percentage';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET OWNED PRODUCTS % STATS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                if (onSuccessAction) {
                    onSuccessAction(resData)
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading owned products % stats..', e);
        }
    };
};

/* SHAREPOINT */
export const updateFileName = (file, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            path_new: file.path_new,
            path_old: file.path_old,
            file_id: file.file_id,
            name: file.name
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/update/path';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE FILE NAME: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating file name/path..', e);
        }
    };
};


export const loadAllFolders = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folders/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET ALL FOLDERS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining all folders..', e);
        }
    };
};

export const loadAllFiles = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/files/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET ALL FILES [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining all files..', e);
        }
    };
};

export const loadFolderContent = (user_id, folder_id, order_by, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
            folder_id: folder_id,
            order_by: order_by
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/content';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLDER CONTENT [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining folder files and directories..', e);
        }
    };
};

export const loadAllUserGroups = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/usergroups/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining all user groups..', e);
        }
    };
};

export const createFolder = (folder, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            path: folder.path,
            parent_folder_id: folder.parent_folder_id,
            description: folder.description,
            picture: folder.picture,
            user_groups: folder.user_groups
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE FOLDER [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating folder..', e);
        }
    };
};


export const updateFolder = (folder, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            path: folder.path,
            folder_id: folder.folder_id,
            parent_folder_id: folder.parent_folder_id,
            description: folder.description,
            picture: folder.picture
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/update/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE FOLDER NAME & IMAGE [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating folder..', e);
        }
    };
};

export const updateFolderDescription = (folder_id, description, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            folder_id: folder_id,
            description: description,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE FOLDER [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating folder..', e);
        }
    };
};

export const uploadFile = (file, path, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("path", path);

        try {
            let uri = BE_REST_API + '/sharepoint/file/create';
            const response = await axios.post(
                uri,
                formData
            );
            //const resData = await response.json();
            console.log("FILE UPLOAD [RESPONSE]", JSON.stringify(response, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, response, onDispatchData, () => onSuccessAction(response), () => onErrorErrorFetchingData(response), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed uploading file..', e);
        }
    };
};

export const createUserGroup = (user_group, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            name: user_group.name,
            type: user_group.type
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/usergroup/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE USER GROUP [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating user group..', e);
        }
    };
};

export const removeFile = (file_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/delete';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("DELETE FILE [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed deleting file..', e);
        }
    };
};


export const removeFolder = (folder_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            folder_id: folder_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/delete';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("DELETE FOLDER [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed deleting folder..', e);
        }
    };
};

export const removeUserGroup = (user_group_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_group_id: user_group_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/usergroup/delete';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("DELETE USER GROUP [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed deleting user group..', e);
        }
    };
};

export const updateUserGroup = (user_group, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_group_id: user_group.user_group_id,
            name: user_group.name,
            type: user_group.type
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/usergroup/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER GROUP [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating user group..', e);
        }
    };
};

export const updateUsersUserGroup = (user_id, user_group_id, operation, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
            user_group_id: user_group_id,
            operation: operation
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/user/usergroup/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USERS USER GROUP [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users user group..', e);
        }
    };
};

export const updateUsersListUserGroup = (user_ids, user_group_id, operation, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_ids: user_ids,
            user_group_id: user_group_id,
            operation: operation
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/user/usergroup/update/list';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USERS LIST USER GROUP [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users user group..', e);
        }
    };
};

export const updateFoldersListUserGroup = (folder_ids, user_group_id, operation, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            folder_ids: folder_ids,
            user_group_id: user_group_id,
            operation: operation
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/usergroup/update/list';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE FOLDERS LIST USER GROUP [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating folder user group..', e);
        }
    };
};

export const updateFileUserGroup = (file_id, user_group_id, operation, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
            user_group_id: user_group_id,
            operation: operation
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/usergroup/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE FILE USER GROUP [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating file user group..', e);
        }
    };
};


export const downloadFile = (user_id, file_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/download';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                responseType: "blob",
                body: postdata
            });
            console.log(response);
            let fileName;
            let mimeType;
            let resData;

            let headers = response.headers.get('content-disposition')
            fileName = headers.substring(headers.indexOf('filename=') + 'filename='.length).trim().replace(/^"(.+(?="$))"$/, '$1'); // remove quotes at the ends
            fileName = parseMapCaretChars(fileName)

            mimeType = response.headers.get('content-type');
            console.log(headers, response.headers.get('content-disposition'), fileName, mimeType)

            //let blob = await response.blob();

            const contentLength = response.headers.get('content-length');
            const total = parseInt(contentLength, 10);
            let loaded = 0;
            let percentage = 0;

            const res = new Response(new ReadableStream({
                async start(controller) {
                    const reader = response.body.getReader();
                    let loadFilePercentage = 0;

                    dispatch(setDownloadProgress(file_id, loadFilePercentage));

                    for (; ;) {
                        const {done, value} = await reader.read();
                        if (done) break;
                        loaded += value.byteLength;
                        //progress({loaded, total})
                        loadFilePercentage = ((loaded / total) * 100).toFixed(0);

                        controller.enqueue(value);

                        dispatch(setDownloadProgress(file_id, loadFilePercentage));

                        console.log(loaded, total, loadFilePercentage);
                    }
                    controller.close();
                },
            }));
            const blob = await res.blob();

            console.log('download completed')


            /*
            let resData = await response.json();
            console.log(resData);

            const str = JSON.stringify(resData);
            const bytes = new TextEncoder().encode(resData);
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });
            console.log(blob);

             */

            /*
            let downloadFile = fs.createWriteStream(fileName, {'flags': 'a'});
            let dlprogress = '';
            response.addListener('data', function (chunk) {
                dlprogress += chunk.length;
                downloadFile.write(chunk, 'binary');
            });
            response.addListener("end", function() {
                downloadFile.end();

                let FileSaver = require('file-saver');
                //let file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob, fileName)

                console.log("Finished downloading " + fileName);
            });
             */


            /*
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName); //with any browser supported extension
            document.body.appendChild(link);
            link.click();
             */

            let FileSaver = require('file-saver');
            //let file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
            console.log(fileName);
            FileSaver.saveAs(blob, fileName)

            console.log("FILE DOWNLOAD [RESPONSE]: " + JSON.stringify("File downloaded successfully..", null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(fileName), () => onErrorErrorFetchingData(fileName), onErrorUnauthorized);

            await dispatch(createNewFileUserHistory(file_id, user_id, "PRENOS", (res) => {
                console.log("Users file interacted / created successfully..", res);
            }, () => {
                console.warn("Error loading users..");
            }));
        } catch (e) {
            console.warn('Failed downloading file', e);
        }
    };
};

export const previewFile = (file_id, user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/download';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                responseType: "blob",
                body: postdata
            });
            console.log(response);
            let fileName;
            let mimeType;
            let resData;

            let headers = response.headers.get('content-disposition')
            fileName = headers.substring(headers.indexOf('filename=') + 'filename='.length).trim().replace(/^"(.+(?="$))"$/, '$1'); // remove quotes at the ends

            mimeType = response.headers.get('content-type');
            console.log(headers, response.headers.get('content-disposition'), fileName, mimeType)


            const contentLength = response.headers.get('content-length');
            const total = parseInt(contentLength, 10);
            let loaded = 0;
            let percentage = 0;

            const res = new Response(new ReadableStream({
                async start(controller) {
                    const reader = response.body.getReader();
                    let loadFilePercentage = 0;

                    dispatch(setDownloadProgress(file_id, loadFilePercentage));

                    for (; ;) {
                        const {done, value} = await reader.read();
                        if (done) break;
                        loaded += value.byteLength;
                        //progress({loaded, total})
                        loadFilePercentage = ((loaded / total) * 100).toFixed(0);

                        controller.enqueue(value);

                        dispatch(setDownloadProgress(file_id, loadFilePercentage));

                        console.log(loaded, total, loadFilePercentage);
                    }
                    controller.close();
                },
            }));
            const blob = await res.blob();

            console.log('download completed')


            // let blob = await response.blob();

            /*
            let resData = await response.json();
            console.log(resData);

            const str = JSON.stringify(resData);
            const bytes = new TextEncoder().encode(resData);
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });
            console.log(blob);

             */
            let parsedMime = mimeType;
            if (parsedMime.includes('pdf')) {
                parsedMime = "application/pdf";
            } else if (parsedMime.includes('gif')) {
                parsedMime = "image/gif";
            } else if (parsedMime.includes('png')) {
                parsedMime = "image/png";
            } else if (parsedMime.includes('jpg') || parsedMime.includes('jpeg')) {
                parsedMime = "image/jpeg";
            } else if (parsedMime.includes('mp4')) {
                parsedMime = "video/mp4";
            } else if (parsedMime.includes('avi')) {
                parsedMime = "video/avi";
            } else if (parsedMime.includes('mov')) {
                parsedMime = "video/quicktime";
            } else if (parsedMime.includes('ogg')) {
                parsedMime = "video/ogg";
            } else if (parsedMime.includes('wmv')) {
                parsedMime = "video/x-ms-wmv";
            } else if (parsedMime.includes('wmv')) {
                parsedMime = "video/x-ms-wmv";
            } else if (parsedMime.includes('webm')) {
                parsedMime = "video/webm";
            } else if (parsedMime.includes('mp3')) {
                parsedMime = "video/mpeg";
            } else if (parsedMime.includes('doc')) {
                parsedMime = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            } else if (parsedMime.includes('rtf')) {
                parsedMime = "text/rtf";
            } else if (parsedMime.includes('webp')) {
                parsedMime = "image/webp";
            } else if (parsedMime.includes('svg')) {
                parsedMime = "image/svg+xml";
            } else if (parsedMime.includes('tiff')) {
                parsedMime = "image/tiff";
            } else if (parsedMime.includes('ico')) {
                parsedMime = "image/x-icon";
            } else if (parsedMime.includes('xml')) {
                parsedMime = "text/xml";
            } else if (parsedMime.includes('html')) {
                parsedMime = "text/html";
            } else if (parsedMime.includes('url')) {
                parsedMime = "url";
            }

            if (parsedMime === "url") {
                let urlFile = await blob.text();
                urlFile = urlFile.trim();
                let url = urlFile.substring(urlFile.lastIndexOf('URL=') + 4);
                url = url.trim();
                console.log("RAW FILE:", urlFile, "URL:", url);

                const openInNewTab = (url) => {
                    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
                    if (newWindow) newWindow.opener = null
                }
                openInNewTab(url);

            } else {
                //Create a Blob from the PDF Stream
                const file = new Blob([blob], {type: parsedMime});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;

                /*
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //with any browser supported extension
                document.body.appendChild(link);
                link.click();
                */
            }

            console.log("FILE PREVIEW [RESPONSE]: " + JSON.stringify("File (preview) successfully..", null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

            await dispatch(createNewFileUserHistory(file_id, user_id, "PREDOGLED", (res) => {
                console.log("Users file interacted / created successfully..", res);
            }, () => {
                console.warn("Error loading users..");
            }));
        } catch (e) {
            console.warn('Failed previewing file', e);
        }
    };
};

export const sendFileToEmail = (file_id, user_id, username, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
            username: username,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/send';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("SEND FILE [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

            await dispatch(createNewFileUserHistory(file_id, user_id, "POSLANO NA EMAIL", (res) => {
                console.log("Users file interacted / created successfully..", res);
            }, () => {
                console.warn("Error loading users..");
            }));

        } catch (e) {
            console.warn('Failed sending file..', e);
        }
    };
};

export const loadFolder = (user_id, path, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
            path: path,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLDER [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading folder..', e);
        }
    };
};

export const loadFile = (user_id, path, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
            path: path,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FILE [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading file..', e);
        }
    };
};

export const loadFolderByFolderId = (user_id, folder_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
            folder_id: folder_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/id';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLDER [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading folder..', e);
        }
    };
};

export const updateFoldersSortIds = (folderSortIdPairs, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            folder_sort_id_pairs: folderSortIdPairs,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/folder/update/sort';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLDERS SORT [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating folders sort ids..', e);
        }
    };
};

export const loadUsersSharepoint = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET USERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionUsers(JSON.stringify(resData));
                //dispatch(setUsers(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const loadUserGroups = (userGroupIds, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_group_ids: userGroupIds,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/usergroup/ids';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET PARSED USER GROUPS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionUsers(JSON.stringify(resData));
                //dispatch(setUsers(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};


export const userLoginSharepoint = (email, password, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            password: password
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/login';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                }
            });
            const resData = await response.json();
            console.log("GET USER LOGIN: " + JSON.stringify(resData, null, 4));

            if (resData && !resData.user_groups) {
                onErrorErrorFetchingData()
            } else {
                const onDispatchData = async () => {
                    setSessionUser(JSON.stringify(resData));
                    setSessionUserToken(resData["user_id"]);
                    dispatch(setCredentialsSharepoint(email, password));
                    dispatch(setUserData(resData));
                    dispatch(setUserToken(resData["user_id"]));

                    let parsedUserRole = parseUserRole(resData["type"]);
                    if (parsedUserRole === USER_ROLES.ADMIN || parsedUserRole === USER_ROLES.CARETAKER) {
                        await dispatch(loadCaretakerByUserId(resData["user_id"], parsedUserRole, () => {
                            console.log("Caretaker successfully loaded..");
                        }, () => {
                            console.warn("Error loading caretaker user..")
                        }));
                    }

                    await dispatch(createNewUserHistory(resData['user_id'], "PRIJAVA", (res) => {
                        console.log("Users login interacted / created successfully..", res);
                    }, () => {
                        console.warn("Error loading users..");
                    }));

                    await dispatch(loadUsers(resData['user_id'], () => {
                        console.log("Users successfully loaded..");
                    }, () => {
                        console.warn("Error loading users..");
                    }));

                };
                parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);
            }

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};

export const loadUserByUserId = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/user/id';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET USER DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);
                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};

export const sendEmailToRecipients = (recipients, content, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            recipients: recipients,
            content: content
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/email/send';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET EMAIL SENT RESPONSE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
}


export const createNewUserHistory = (user_id, action_type, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
            action_type: action_type
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/user/stats/create/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const createNewFileUserHistory = (file_id, user_id, action_type, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
            user_id: user_id,
            action_type: action_type
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/stats/create/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};


export const loadAllUsersLoginHistory = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/user/stats/login/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadAllUsersFilesHistory = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/user/stats/files/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadAllUsersWithAccessToFile = (file_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/stats/users/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadAllUsersWithAccessToFileNotInteracted = (file_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/stats/notinteracted/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadAllUsersWithAccessToFileInteracted = (file_id, distinct, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
            distinct: distinct
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/sharepoint/file/stats/interacted/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};



export const createNewStat = (bar_id, ad_id, type, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: bar_id,
            ad_id: ad_id,
            type: type
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/create/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};


export const loadDailyQRScans = (period, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            period: period
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/scans/daily/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadHourlyQRScans = (period, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            period: period
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/scans/hourly/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadHourlyQRScansByBars = (period, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            period: period
        };
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/scans/bars/hourly/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                // Dispatch actions here if needed, for example:
                // dispatch(loadHourlyStatsByBars(resData));
            };

            // Assume parseRestApiResponse is a utility function you've created for handling the API response
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};


export const loadRegionallyQRScans = (user_id, period, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: user_id,
            period: period
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/scans/regionally/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};


export const loadAddImpressions = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: user_id
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/adsimpressions/all/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadAddImpressionsRegionally = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: user_id
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/adsimpressions/regionally/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};

export const loadAvgPrices = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: user_id
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/stats/avgprices/';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("GET ALL USER GROUPS [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);
        } catch (e) {
            console.warn('Failed obtaining data..', e);
        }
    };
};