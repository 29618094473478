import React, {useState, useEffect, createRef, useCallback, useRef} from "react";
import Header from "../components/js/Header";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"

import {useDispatch, useSelector} from "react-redux";
import {
    displayAlert, displayDate, displayDateHour,
    isSessionValid,
    isUserAdmin,
    isUserBarRepresentative,
    isUserOwner,
    mapCaretChars, parseMapCaretChars
} from "../helpers/helpers";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    loadAllUsersFilesHistory,
    loadAllUsersLoginHistory,
    resetUserPassword,
    setSidebarWidth,
    updateUser
} from "../store/actions/actions";
import {useHistory} from "react-router-dom";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import Button from "../components/js/Button";

import background from "../assets/img/blur-background.png"
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import ModalInfo from "../components/js/ModalInfo";


function SharepointUser(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    const user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);
    let activeEditUser = useSelector(state => state.reducer.activeEditUser);

    const [fullName, setFullName] = useState(null);
    const [externalId, setExternalId] = useState(null);
    const [email, setEmail] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [type, setType] = useState(null);

    const [userHistory, setUserHistory] = useState([]);
    const [userFileHistory, setUserFileHistory] = useState([]);

    const [requestPasswordReset, setRequestPasswordReset] = useState(false);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        if (activeEditUser) {
            console.log("EDIT USER:", activeEditUser)
            setExternalId(activeEditUser.external_id);
            setFullName(activeEditUser.full_name);
            setEmail(activeEditUser.username);
            setTelephone(activeEditUser.telephone);
            setType(activeEditUser.type);

            loadUserHistory(activeEditUser.user_id);
            loadUserFileHistory(activeEditUser.user_id);
        }
    }, [activeEditUser]);

    useEffect(() => {
        if (requestPasswordReset) {
            dispatch(resetUserPassword(activeEditUser, (res) => {
                console.log("User password successfully updated!", res);
                setModalValues(true)
            }, () => {
                console.warn("Error resetting user password..")
                setModalValues(false)
            }));
            setRequestPasswordReset(false);
        }
    }, [requestPasswordReset]);


    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (success) => {
        setDisplayModalInfo(true);

        if (success) {
            setModalInfoTitle(``);
            setModalInfoText('Povezava za ponastavitev gesla uspešno posredovana na uporabnikov e-mail.');
            setModalInfoAction(() => {
                return async () => {
                    setDisplayModalInfo(false);
                }
            })
        } else {
            setModalInfoTitle(`Napaka`);
            setModalInfoText(`Napaka pri ponastavitvi gesla. Prosimo poskusite znova..`);
            setModalInfoAction(() => {
                return async () => {
                    setDisplayModalInfo(false);
                }
            })
        }

    };

    const loadUserHistory = (user_id) => {
        dispatch(loadAllUsersLoginHistory(user_id, (res) => {
            console.log("User history successfully obtained!", res);

            setUserHistory(res);
        }, () => {
            console.warn("Error obtaining data..")
        }));
    }

    const loadUserFileHistory = (user_id) => {
        dispatch(loadAllUsersFilesHistory(user_id, (res) => {
            console.log("User files history successfully obtained!", res);

            setUserFileHistory(res);
        }, () => {
            //  userFileHistory([]);
            console.warn("Error obtaining data..")
        }));
    }


    const updateUserDispatch = (user) => {
        dispatch(updateUser(user, (res) => {
            console.log("User successfully updated!", res);
        }, () => {
            console.warn("Error updating user..")
        }));
    }


    let inputStyle = {marginBottom: '48px'};

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);
    useEffect(() => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, 250)
    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    function FileHistoryItem(props) {
        // console.log(props.value);
        const item = props.value;
        /*
        if (!isUserAdmin(user)) {
            return null;
        }

         */

        return (
            <li onClick={() => {
            }} className={'row'} >
                <div className={'row-date'}>{displayDateHour(item.date_create)}</div>
                <div className={'row-path'}>{parseMapCaretChars(item.path)}</div>
                <div className={'row-dimension'}>{parseMapCaretChars(item.action_type)}</div>
            </li>
        );
    }

    function renderTableFilesHistory(items) {
        const header = () => {
            return (
                <div className={'row dynamic-header'} style={{left: `${sidebarWidth + 50}px`}}>
                    <div className={'row-date'}>Datum</div>
                    <div className={'row-path'}>Datoteka</div>
                    <div className={'row-dimension'}>Interkacija</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list dynamic'} style={{overflowY: 'scroll', overflowX: 'auto'}}>
                    {items.map((item, index) => <FileHistoryItem key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    function UserHistoryItem(props) {
        // console.log(props.value);
        const item = props.value;
        /*
        if (isUserBarRepresentative(item) || isUserOwner(item)) {
            return null;
        }
         */

        return (
            <li onClick={() => {
            }} className={'row'} >
                <div className={'row-date'}>{displayDateHour(item.date_create)}</div>
                <div className={'row-action'}>{parseMapCaretChars(item.action_type)}</div>
            </li>
        );
    }

    function renderTableLoginHistory(items) {
        const header = () => {
            return (
                <div className={'row dynamic-header'} >
                    <div className={'row-date'}>Datum</div>
                    <div className={'row-action'}>Vpis v sistem</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list dynamic'} style={{overflowY: 'scroll', overflowX: 'auto'}}>
                    {items.map((item, index) => <UserHistoryItem key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    const renderUserHistory = () => {
        return (
            <div className={'user-history-container'}>
                <p className={"p-title-small"} style={{marginTop: "-42px"}}>Zgodovina prijav</p>
                {renderTableLoginHistory(userHistory)}
            </div>
        );
    }

    const renderUserFileHistory = () => {
        return (
            <div className={'user-history-container'}>
                <p className={"p-title-small"} style={{marginTop: "-42px"}}>Zgodovina interakcij z datotekami</p>
                {renderTableFilesHistory(userFileHistory)}
            </div>
        );
    }


    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div ref={ref}>
            <div className={'background-blurred'} style={{backgroundImage: `url(${background})`}}/>
            <Page sharepoint relative className={'sharepoint main'} style={{height: `${mainHeight}px`}}
                  onToggleSidebar={(shown) => {
                      if (shown) {
                          dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                      } else {
                          dispatch(setSidebarWidth(0))
                      }
                  }}>

                <div className={'body'} style={{/*marginTop: '190px', */paddingLeft: `${sidebarWidth + 50}px`}}>
                    <Header left={`${sidebarWidth + 50}px`} title={`PREGLED UPORABNIKOV / ${fullName}`}/>
                    <div className={'container-column-2'} style={{paddingBottom: "100px"}}>
                        <div className={'column-2'}>
                            <div className={'container-row-2'}>
                                <div className={'row-2'}>
                                    <Input white loadUser externalId onChange={setExternalId}
                                           placeholder={!externalId ? 'External ID' : externalId}
                                           label={'External ID'}
                                           style={inputStyle}
                                           disabled
                                    />
                                    <Input white loadUser type onChange={setType}
                                           placeholder={!type ? 'Tip uporabnika' : type}
                                           label={'Tip uporabnika'}
                                           style={inputStyle}
                                           disabled
                                    />
                                </div>
                            </div>
                            <Input white shadow loadUser fullName onChange={setFullName} placeholder={'Polno ime'}
                                   label={'Polno ime'}
                                   style={inputStyle}
                                   onSubmit={(e) => {
                                   }}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (activeEditUser && fullName) {
                                           activeEditUser.full_name = mapCaretChars(fullName);
                                           updateUserDispatch(activeEditUser);
                                       }
                                   }}
                            />
                            <Input white loadUser telephone onChange={setTelephone} placeholder={'Telefon'}
                                   label={'Telefon'}
                                   style={inputStyle}
                                   onSubmit={(e) => {
                                   }}
                                   onBlur={(e) => {
                                       console.log('Triggered because this input lost focus', e.target.value);
                                       if (activeEditUser && telephone) {
                                           activeEditUser.telephone = mapCaretChars(telephone);
                                           updateUserDispatch(activeEditUser);
                                       }
                                   }}/>
                            <div className={'container-row-2'}>
                                <div className={'row-2'}>
                                    <Input white loadUser email onChange={setEmail} placeholder={'E-mail'}
                                           label={'E-mail'}
                                           style={inputStyle}
                                           onSubmit={(e) => {
                                           }}
                                           onBlur={(e) => {
                                               console.log('Triggered because this input lost focus', e.target.value);
                                               if (activeEditUser && email) {
                                                   activeEditUser.username = mapCaretChars(email);
                                                   updateUserDispatch(activeEditUser);
                                               }
                                           }}/>
                                    <Button text={'PONASTAVI GESLO'} confirm action={() => {
                                        setRequestPasswordReset(true);
                                    }}
                                            style={{
                                                marginTop: '-15px'
                                            }}
                                            textStyle={{
                                                fontSize: '14px'
                                            }}/>
                                </div>
                            </div>
                        </div>

                    </div>


                    {user?.username.includes("petra.ojstersek") || user?.username.includes("plu.ceniki@gmail.com") || user?.username.includes("katarina.zuzek@heineken.com") || user?.username.includes("eva.becan2@heineken.com")?
                        <div className={'dynamic container-column-2'} style={{}}>
                            <div className={'column-2'} >
                                <div className={'container-row-2'}>
                                    <div className={'row-2'}>
                                        {renderUserHistory()}
                                    </div>
                                </div>
                            </div>
                            <div className={'column-2'} >
                                <div className={'container-row-2'}>
                                    <div className={'row-2'}>
                                        {renderUserFileHistory()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        : <></>
                    }

                </div>
            </Page>
            {renderModalInfo()}
        </div>
}

export default SharepointUser;