import React, {useState, useEffect, createRef, useCallback} from "react";
import Sidebar from "../components/js/Sidebar";
import Header from "../components/js/Header";
import FileUpload from "../components/js/FileUpload";
import Input from "../components/js/Input";

import "../css/ProductBarEdit.css"
import * as CgIcons from "react-icons/cg";

import ButtonGroupSelection, {LEADING_BRAND} from "../components/js/ButtonGroupSelection";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit.svg";
import {useDispatch, useSelector} from "react-redux";
import {
    createNewUser, loadCaretaker,
    loadMenus,
    setActiveBar, setActiveBarOwner,
    setActiveBarRepresentative, setSidebarWidth,
    updateBar, updateBarActiveStatus, updateBarOwner, updateBarUser,
    updateUserTelephone
} from "../store/actions/actions";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import {
    displayAlert,
    displayDate,
    isSessionValid, isUserAdmin, isUserBarRepresentative,
    isUserCaretaker, isUserOwner, mapCaretChars,
    parseUserRole,
    printDimensions
} from "../helpers/helpers";
import {useHistory} from "react-router-dom";
import {USER_ROLES} from "../constants/enums";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import Footer from "../components/js/Footer";
import ModalInfo from "../components/js/ModalInfo";
import Button from "../components/js/Button";

function Bar(props) {
    let dispatch = useDispatch();

    let token = useSelector(state => state.reducer.userToken);
    let user = useSelector(state => state.reducer.user);
    let userCaretaker = useSelector(state => state.reducer.userCaretaker);
    let activeBar = useSelector(state => state.reducer.activeBar);
    let activeBarRepresentative = useSelector(state => state.reducer.activeBarRepresentative);
    let activeBarCaretaker = useSelector(state => state.reducer.activeBarCaretaker);
    let lastCaretakerCreated = useSelector(state => state.reducer.lastCaretakerCreated);
    let activeBarOwner = useSelector(state => state.reducer.activeBarOwner);
    let menus = useSelector(state => state.reducer.menusHistory);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    const [image, setImage] = useState(activeBar?.logo);
    const [name, setName] = useState(activeBar?.name);
    const [address, setAddress] = useState(null);
    const [taxNumber, setTaxNumber] = useState(null);
    const [legalEntity, setLegalEntity] = useState(null);

    const [telephone, setTelephone] = useState(null);
    const [email, setEmail] = useState(null);
    const [telephoneOwner, setTelephoneOwner] = useState(null);
    const [emailOwner, setEmailOwner] = useState(null);
    const [leadingBrand, setLeadingBrand] = useState(activeBar?.main_brand);
    const [autosaveTimeout, setAutosaveTimeout] = useState(null);

    const [newUserCreated, setCreateNewUser] = useState(false);
    const [newOwnerCreated, setCreateNewOwner] = useState(false);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    console.log("BAR OWNER", activeBarOwner);
    console.log("BAR", activeBar);

    useEffect(() => {
        console.log("LEADING BRAND:", leadingBrand);

    }, [leadingBrand]);

    useEffect(() => {
        if (menus) {
            console.log(menus)
        }
    }, [menus]);

    useEffect(() => {
        if (activeBar && activeBar.bar_owner_id) {
            dispatch(loadCaretaker(activeBar.bar_owner_id, USER_ROLES.OWNER, () => {
                console.log("Caretaker owner loaded..");
                //dispatchLoadCaretaker();
            }, () => {
                dispatch(loadCaretaker(activeBar.bar_owner_id, USER_ROLES.OWNER, () => {
                    console.log("Caretaker owner loaded..");
                    //dispatchLoadCaretaker();
                }, () => {


                    //dispatchLoadCaretaker();
                    dispatch(setActiveBarOwner(null));
                    console.log("Caretaker owner loading failed..")
                }));

                //dispatchLoadCaretaker();
                dispatch(setActiveBarOwner(null));
                console.log("Caretaker owner loading failed..")
            }));
        }
        if (activeBar && activeBar.user_id) {
            dispatch(loadCaretaker(activeBar.user_id, USER_ROLES.BAR_REPRESENTATIVE, () => {
                console.log("Caretaker bar rep loaded..");
                //dispatchLoadCaretaker();
            }, () => {
                dispatch(loadCaretaker(activeBar.user_id, USER_ROLES.BAR_REPRESENTATIVE, () => {
                    console.log("Caretaker bar rep loaded..");
                    //dispatchLoadCaretaker();
                }, () => {


                    //dispatchLoadCaretaker();
                    dispatch(setActiveBarRepresentative(null));
                    console.log("Caretaker bar rep loading failed..")
                }));

                //dispatchLoadCaretaker();
                dispatch(setActiveBarRepresentative(null));
                console.log("Caretaker bar rep loading failed..")
            }));
        }

    }, [activeBar]);

    useEffect(() => {
        if (activeBar && image) {
            if (image === 'REMOVE') {
                activeBar.logo = null;
                setImage(null);
            } else {
                activeBar.logo = image;
            }
            console.log("PRODUCT DATA LOADED..:", activeBar);
            updateBarDispatch(activeBar);
        }
    }, [image]);


    useEffect(() => {
        if (activeBar && newUserCreated) {
            activeBar.user_id = activeBarRepresentative.user_id;
            console.log("BAR REP added to BAR", activeBar);
            dispatchUpdateBarUser(activeBar);

            setCreateNewUser(false);
        }
    }, [newUserCreated]);

    useEffect(() => {
        if (activeBar && newOwnerCreated && lastCaretakerCreated) {
            activeBar.bar_owner_id = lastCaretakerCreated.caretaker_id;
            console.log("BAR OWNER caretaker:", lastCaretakerCreated);
            console.log("BAR OWNER added to BAR:", activeBar);

            dispatch(updateBarOwner(activeBar, () => {
                console.log("Bar owner successfully updated!");
            }, () => {
                console.warn("Error updating bar..")
            }));

            setCreateNewOwner(false);
        }
    }, [newOwnerCreated, lastCaretakerCreated]);

    const dispatchUpdateBarUser = (bar) => {
        dispatch(updateBarUser(bar, () => {
            console.log("Bar user successfully updated!");
        }, () => {
            console.warn("Error updating bar..")
        }));
    }

    const dispatchUpdateBarOwner = (bar) => {
        dispatch(updateBarOwner(bar, () => {
            console.log("Bar owner successfully updated!");
        }, () => {
            console.warn("Error updating bar..")
        }));
    }

    const updateBarDispatch = (bar) => {
        dispatch(updateBar(bar, () => {
            console.log("Bar successfully updated!");
        }, () => {
            console.warn("Error updating bar..")
        }));
    }

    const updateUserBarRepresentativeDispatch = (user) => {
        dispatch(updateUserTelephone(user, () => {
            dispatch(setActiveBarRepresentative(user));
            console.log("User bar representative successfully updated!");
        }, () => {
            console.warn("Error updating user..")
        }));
    }

    const updateUserOwnerDispatch = (user) => {
        dispatch(updateUserTelephone(user, () => {
            dispatch(setActiveBarOwner(user));
            console.log("User bar owner successfully updated!");
        }, () => {
            console.warn("Error updating user..")
        }));
    }

    useEffect(() => {
        if (activeBar) {
            console.log("BAR DATA LOADED..:", activeBar);
            activeBar.logo && setImage(activeBar.logo);
            activeBar.main_brand && setLeadingBrand(activeBar.main_brand);

            dispatch(loadMenus(activeBar['bar_id'], () => {
            }, () => setModalValues(false)));
        }
    }, [activeBar]);

    useEffect(() => {
        if (activeBar && leadingBrand !== null) {
            activeBar.main_brand = leadingBrand;
            updateBarDispatch(activeBar);
        }
    }, [leadingBrand]);


    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (success) => {
        setDisplayModalInfo(true);

        if (success) {
            setModalInfoTitle('');
            setModalInfoText(`Lokal uspešno odstranjen!`);
            setModalInfoAction(() => {
                return () => {
                    console.log("Bar successfully deactivated!");
                    dispatch(setActiveBar(null));

                    setDisplayModalInfo(false);
                    onNavigateTo(PAGES.BARS_LIST);
                }
            })
        } else {
            setModalInfoTitle(`Napaka`);
            setModalInfoText(`Napaka pri pridobivanju seznama lokalov. Prosimo poskusite znova..`);
            setModalInfoAction(() => {
                return () => {
                    setDisplayModalInfo(false);
                }
            })
        }

    };

    const onChangeAutosave = (newValue) => {
        if (newValue === name || newValue === activeBar.name) return
        setName(newValue)
        if (autosaveTimeout) clearTimeout(autosaveTimeout)
        setAutosaveTimeout(setTimeout(() => {
            if (activeBar && newValue) {
                activeBar.name = mapCaretChars(newValue);
                updateBarDispatch(activeBar);
            }
        }, 1000))
    }

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onDeleteBar = () => {
        console.log("DELETE BAR!");
        activeBar.active = false;
        dispatch(updateBarActiveStatus(activeBar, () => {
            setModalValues(true)
        }, () => {
            console.warn("Error deactivating bar..")
        }));
    };

    function Item(props) {
        console.log(props.value);
        const item = props.value;
        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-date'}>{displayDate(item.date_create)}</div>
                <div className={'row-date'}>{displayDate(item.valid_from)}</div>
                <div className={'row-dimension'}>
                    <div style={{borderBottom: '1px solid #707070'}}>{printDimensions(item)}</div>
                </div>
                <div className={'row-edit'}>
                    <a className={'a-icon'}>
                        <IconEdit className={'icon'} width={'21px'} height={'21px'} title={'icon list'}/>
                    </a>
                </div>
            </li>
        );
    }

    function renderTable(items) {
        const header = () => {
            return (
                <div className={'row-header relative'}>
                    <div className={'row-date'}>Datum izdelave</div>
                    <div className={'row-date'}>Datum veljavnosti</div>
                    <div className={'row-dimension'}>Dimenzija</div>
                    <div className={'row-edit'}>Uredi</div>
                </div>
            );
        };
        return (
            <>
                <p className={'p-title-small'}
                   style={{marginTop: isUserBarRepresentative(user) || isUserOwner(user) ? 0 : '50px'}}>Pregled cenikov
                    za lokal</p>
                <div className={'container-item-list'}>
                    {header()}
                    <ul className={'item-list relative'}>
                        {items.map((item, index) => <Item key={index} value={item}/>)}
                    </ul>
                </div>
            </>

        );
    }

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log("load");
                console.log(reader.result);
                setImage(reader.result);
            }, false);
            reader.readAsDataURL(file);
        }
    };

    const previewImage = (base64Data) => {
        base64Data = base64Data === 'REMOVE' ? null : base64Data;
        return <div className={'container-img-upload'}
                    style={{marginLeft: '370px', marginTop: '-150px', marginBottom: '35px', position: 'unset'}}>
            <img className={'img-upload'} src={base64Data} alt="banner" height={'111px'}/>
            <CgIcons.CgCloseO onClick={() => {
                setImage("REMOVE")
            }} className={'icon-close'}/>
        </div>
    };

    const renderFileUpload = (title) => {
        return <><p className={'p-title-small'}>{title}</p>
            <FileUpload comboOffer button={'Izberi in naloži sliko'}
                        handleFile={(file) => handleImageUpload(file)}/>
            {image && previewImage(image)}
        </>
    };

    let inputStyle = {marginBottom: '48px'};

    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    return !isSessionValid(token) ? <Redirect to={PAGES.LOGIN} push={false}/> :
        token && <div><Page relative className={'main'} pageStyle={{height: 'auto'}} onToggleSidebar={(shown) => {
            if (shown) {
                dispatch(setSidebarWidth(SIDEBAR_WIDTH))
            } else {
                dispatch(setSidebarWidth(0))
            }
        }}>
            {/*<Sidebar/>*/}
            {/*<div className={'page relative'}>*/}
            <div className={'body'} style={{marginTop: '140px', paddingLeft: `${sidebarWidth + 50}px`}}>
                <Header left={`${sidebarWidth + 50}px`} title={`PREGLED LOKALOV / ${name}`} interactive delete
                        button={'Deaktiviraj lokal'}
                        action={onDeleteBar}/>
                <div className={'container-column-2'}>
                    <div className={'column-2'}>
                        <Input white shadow loadBar name onChange={onChangeAutosave}
                               placeholder={''}
                               label={'Ime lokala'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (activeBar && name) {
                                       activeBar.name = mapCaretChars(name);
                                       updateBarDispatch(activeBar);
                                   }
                               }}/>
                        <Input white loadBar address disabled={isUserCaretaker(user)} onChange={setAddress}
                               placeholder={''}
                               label={'Naslov lokala'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (activeBar && address) {
                                       activeBar.address = mapCaretChars(address);
                                       updateBarDispatch(activeBar);
                                   }
                               }}/>
                        <Input white loadBar taxNumber disabled={isUserCaretaker(user)} onChange={setTaxNumber}
                               placeholder={''}
                               label={'Davčna številka'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (activeBar) {
                                       activeBar.tax_number = taxNumber;
                                       if (!taxNumber || taxNumber === "") {
                                           activeBar.tax_number = '/';
                                       }
                                       updateBarDispatch(activeBar);
                                   }
                               }}/>
                        <Input white loadBar legalEntity disabled={isUserCaretaker(user)} onChange={setLegalEntity}
                               placeholder={''}
                               label={'Naziv pravne osebe'}
                               style={inputStyle}
                               onBlur={(e) => {
                                   console.log('Triggered because this input lost focus', e.target.value);
                                   if (activeBar) {
                                       activeBar.legal_entity = mapCaretChars(legalEntity);
                                       activeBar.legal_entity = !activeBar.legal_entity ? "" : activeBar.legal_entity;
                                       updateBarDispatch(activeBar);
                                   }
                               }}/>
                        <div className={'container-row-2'}>
                            <div className={'row-2'}>
                                <Input white loadBar phone
                                    //disabled={isUserCaretaker(user) || isUserBarRepresentative(user) || isUserOwner(user) || isUserAdmin(user)}
                                       disabled={!activeBarRepresentative}
                                       onChange={setTelephone}
                                       placeholder={''}
                                       label={'Kontaktni telefon vodja'}
                                       style={inputStyle}
                                       onBlur={(e) => {
                                           console.log('Triggered because this input lost focus', e.target.value);
                                           if (activeBarRepresentative && telephone) {
                                               activeBarRepresentative.telephone = telephone;
                                               updateUserBarRepresentativeDispatch(activeBarRepresentative);
                                           }
                                           if (telephone) {
                                               if (userCaretaker && activeBarRepresentative) {
                                                   activeBarRepresentative.telephone = telephone;
                                                   updateUserBarRepresentativeDispatch(activeBarRepresentative);
                                               }
                                           }
                                       }}/>
                                <Input white loadBar email
                                       disabled={isUserBarRepresentative(user) || isUserOwner(user)}
                                       onChange={setEmail}
                                       placeholder={''}
                                       label={'Kontaktni email vodja'}
                                       style={inputStyle}
                                       onSubmit={() => {
                                       }}
                                       onBlur={async (e) => {
                                           console.log('Triggered because this input lost focus', e.target.value);
                                           if (activeBar && email) {
                                               if (userCaretaker) {
                                                   let user = {
                                                       external_id: null,
                                                       full_name: "Vodja",
                                                       address: null,
                                                       username: email,
                                                       password: null,
                                                       type: 'VODJA',
                                                       telephone: telephone
                                                   };
                                                   await dispatch(createNewUser(userCaretaker.caretaker_id, user, () => {
                                                       console.log("User bar representative successfully created!")
                                                       setCreateNewUser(true);
                                                   }, (res) => {
                                                       if (res && res.error && res.user) {
                                                           console.log(res)

                                                           if (res.user?.user_id) {
                                                               activeBar.user_id = res.user.user_id;
                                                               console.log("BAR representative added to BAR:", activeBar);

                                                               dispatch(updateBarUser(activeBar, () => {
                                                                   console.log("Bar representative successfully updated!");

                                                                   dispatch(setActiveBarRepresentative(res.user)); //user

                                                                   //   dispatch(setActiveBarRepresentative(user));
                                                               }, () => {
                                                                   console.warn("Error updating bar rep...")
                                                               }));
                                                           }
                                                       }
                                                       console.warn("Error creating user bar representative! Updating bar representative successfully instead.")
                                                   }));
                                               } else if (userCaretaker && activeBarRepresentative) {
                                                   //activeBarRepresentative.username = email;
                                                   //updateUserBarRepresentativeDispatch(activeBarRepresentative);
                                               }
                                           }
                                       }}/>
                                <Button text={""} delete style={{marginTop: '-12px'}} action={() => {
                                    if (activeBar) {
                                        activeBar.user_id = null;
                                        console.log("BAR representative added to BAR:", activeBar);

                                        dispatch(updateBarUser(activeBar, () => {

                                        }, () => {
                                            console.warn("Error updating bar rep...")
                                        }));

                                        dispatch(setActiveBarRepresentative(null)); //user
                                        console.log("Bar representative successfully removed!");
                                    }
                                }}/>
                            </div>
                            <div className={'row-2'}>
                                <Input white loadBar phoneOwner
                                    //disabled={isUserCaretaker(user) || isUserBarRepresentative(user) || isUserOwner(user)}
                                       disabled={!activeBarOwner}
                                       onChange={setTelephoneOwner}
                                       placeholder={''}
                                       label={'Kontaktni telefon lastnika'}
                                       style={inputStyle}
                                       onBlur={async (e) => {
                                           console.log('Triggered because this input lost focus', e.target.value);
                                           if (telephoneOwner) {
                                               if (userCaretaker && activeBarOwner) {
                                                   activeBarOwner.telephone = telephoneOwner;
                                                   updateUserOwnerDispatch(activeBarOwner);
                                               }
                                           }
                                       }}/>
                                <Input white loadBar emailOwner
                                       disabled={isUserBarRepresentative(user) || isUserOwner(user)}
                                       onChange={setEmailOwner}
                                       placeholder={''}
                                       label={'Kontaktni email lastnika'}
                                       style={inputStyle}
                                       onSubmit={() => {
                                       }}
                                       onBlur={async (e) => {
                                           console.log('Triggered because this input lost focus', e.target.value);
                                           if (activeBar && emailOwner) {
                                               if (userCaretaker) {
                                                   let user = {
                                                       external_id: null,
                                                       full_name: 'Lastnik', //violates not null constraint
                                                       address: null,
                                                       username: emailOwner,
                                                       password: null,
                                                       type: 'LASTNIK',
                                                       telephone: telephoneOwner
                                                   };

                                                   await dispatch(createNewUser(userCaretaker.caretaker_id, user, () => {
                                                       console.log("User owner successfully created!")
                                                       setCreateNewOwner(true);
                                                   }, (res) => {
                                                       console.log(res)
                                                       if (res && res.error && res.caretaker) {
                                                           console.log(res)

                                                           if (res.caretaker?.caretaker_id) {
                                                               activeBar.bar_owner_id = res.caretaker.caretaker_id;
                                                               console.log("BAR OWNER caretaker created:", lastCaretakerCreated);
                                                               console.log("BAR OWNER added to BAR:", activeBar);

                                                               dispatch(updateBarOwner(activeBar, () => {

                                                                   dispatch(setActiveBarOwner(res.caretaker)); //user
                                                                   console.log("Bar owner successfully updated!");
                                                               }, () => {
                                                                   console.warn("Error updating bar..")
                                                               }));
                                                           }
                                                       }
                                                       console.warn("Error creating user owner! Updating bar owner successfully instead.")
                                                   }));
                                               } else if (userCaretaker && activeBarOwner) {

                                                   //activeBarOwner.username = emailOwner;
                                                   //dispatchUpdateBarOwner(activeBarOwner);
                                               }
                                           }
                                       }}/>

                                <Button text={""} delete style={{marginTop: '-12px'}} action={() => {
                                    if (activeBar) {
                                        activeBar.bar_owner_id = null;
                                        console.log("BAR OWNER caretaker:", null);
                                        console.log("BAR OWNER added to BAR:", activeBar);

                                        dispatch(updateBarOwner(activeBar, () => {
                                            console.log("Bar owner successfully removed!");
                                        }, () => {
                                            console.warn("Error updating bar..")
                                        }));

                                        dispatch(setActiveBarOwner(null)); //user
                                        console.log("Bar owner successfully removed!");
                                    }
                                }}/>
                            </div>
                        </div>
                        {isUserCaretaker(user) || isUserAdmin(user) &&
                            <ButtonGroupSelection leadingBrand loadBar value={leadingBrand} onChange={setLeadingBrand}/>}
                    </div>
                    <div className={'column-2'} style={{paddingRight: '50px'}}>
                        {isUserCaretaker(user) || isUserAdmin(user) && renderFileUpload('Dodajte logotip lokala')}
                        {renderTable(menus)}
                    </div>
                </div>
            </div>
            <Footer
                left={`${sidebarWidth}px`}
                next={'SHRANI'}
                urlNext={PAGES.HOME}
                onAction={() => setTimeout(() => onNavigateTo(PAGES.HOME), 250)}
                progress={0}/>
            {/*</div>*/}
        </Page>
            {renderModalInfo()}
        </div>
}

export default Bar;