import {setCredentials, setUserData, updateImportErrors} from "../store/actions/actions";
import {
    L10N_MENU,
    LOCAL_STORAGE_KEYS,
    USER_ROLES,
    XLSX_BARS_FIELDS,
    XLSX_CATEGORIES_FIELDS,
    XLSX_PRODUCTS_FIELDS,
    XLSX_USERS_FIELDS
} from "../constants/enums";
import {Redirect} from "react-router";
import {PAGES} from "../App";
import React from "react";
import {LEADING_BRAND, LEADING_BRAND_STRING} from "../components/js/ButtonGroupSelection";

export const validateUserInput = (params) => {
    for (let i = 0; i < params.length; i++) {
        console.log(JSON.stringify(params[i], null, 4))
        if (params[i] === null || params[i] === '') {
            return false;
        }
    }
    return true;
};

export function stringToNumber(s){
    if (!s) {
        return s;
    }
    if (!s?.includes(",")) {
        return s;
    }
    return +s.split(',').join('.');
}

export const validateUserInputNumerical = (params) => {
    for (let i = 0; i < params.length; i++) {
        let param = parsePostDecimalValue(params[i])
        console.log(JSON.stringify(param, null, 4))
        if (isNaN(param)) {
            return false;
        }
    }
    return true;
};

export const parseDateForDateInput = (timestamp) => {
    if (!timestamp) {
        return null
    }
    console.log(timestamp)

    let date = new Date(timestamp);
    let day = date.getDate();
    day = day.toString().length < 2 ? `0${day}` : day;

    let month = date.getMonth() + 1;
    month = month.toString().length < 2 ? `0${month}` : month;

    let year = date.getFullYear();

    //console.log(`${day}/${month}/${year}`);
    return `${day}/${month}/${year}`;
    // console.log(`${month}/${day}/${year}`);
    // return `${month}/${day}/${year}`;
};

export const displayDate = (timestamp) => {
    if (!timestamp) {
        return null
    }
    //console.log(timestamp)

    let date = new Date(timestamp);
    let day = date.getDate();
    day = day.toString().length < 2 ? `0${day}` : day;

    let month = date.getMonth() + 1;
    month = month.toString().length < 2 ? `0${month}` : month;

    let year = date.getFullYear();

    //console.log(`${day}.${month}.${year}`);
    return `${day}.${month}.${year}`;
};

export const displayDateUTC = (timestamp) => {
    if (!timestamp) {
        return null;
    }

    const dateParts = timestamp.split("T")[0].split("-");

    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${day}.${month}.${year}`;
};

export const displayDateHour = (timestamp) => {
    if (!timestamp) {
        return null
    }
    //console.log(timestamp)

    let date = new Date(timestamp);
    let day = date.getDate();
    day = day.toString().length < 2 ? `0${day}` : day;

    let month = date.getMonth() + 1;
    month = month.toString().length < 2 ? `0${month}` : month;

    let year = date.getFullYear();

    let hour = date.getHours();
    let minutes = date.getUTCMinutes();
    let seconds = date.getUTCSeconds();

    hour = hour.toString().length < 2 ? `0${hour}` : hour;
    minutes = minutes.toString().length < 2 ? `0${minutes}` : minutes;
    seconds = seconds.toString().length < 2 ? `0${seconds}` : seconds;

    //console.log(`${day}.${month}.${year}`);
    return `${day}.${month}.${year} | ${hour}:${minutes}:${seconds}`;
};


export const parseRestApiResponse = (response, resData, dispatchAction, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    if (Number(response.status) >= 500) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 400) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorUnauthorized) {
            onErrorUnauthorized();
        } else if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 200) {
        //console.log("GET DATA SUCCESS", response.status, resData.error);
        if (dispatchAction) {
            dispatchAction().then(() => {
                if (onSuccessAction) {
                    onSuccessAction();
                }
            });
        }
    }
};

export const displayAlert = (message) => {
    return alert(message);
};

/* EXCEL DATA PARSING */
export const parseUserDataExcel = (row) => {
    let user = {
        external_id: null,
        full_name: "",
        address: null,
        username: null,
        password: null,
        type: null,
        telephone: null
    };
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_USERS_FIELDS.EXTERNAL_ID:
                user.external_id = row[i];
                break;
            case XLSX_USERS_FIELDS.FULL_NAME:
                user.full_name = mapCaretChars(row[i]);
                break;
            case XLSX_USERS_FIELDS.ADDRESS:
                user.address = mapCaretChars(row[i]);
                break;
            case XLSX_USERS_FIELDS.TELEPHONE:
                user.telephone = row[i];
                break;
            case XLSX_USERS_FIELDS.EMAIL:
                user.username = row[i];
                break;
            case XLSX_USERS_FIELDS.USER_ROLE:
                user.type = row[i];
                break;
            default:
                console.warn('Something went wrong.. To many columns found!');
        }
    }
    return user;
};

export const parseBarDataExcel = (row, users, caretakers) => {
    let bar = {
        external_id: null,
        name: null,
        address: null,
        bar_owner_id: null,
        caretaker_id: null,
        tax_number: null,
        user_id: null,
        qr_code: null,
        legal_entity: null
    };
    let street = '';
    let street_nmb = '';
    let post_name = '';
    let post_nmb = '';
    let region = '';
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_BARS_FIELDS.EXTERNAL_ID:
                bar.external_id = row[i] ? row[i].toString() : '';
                break;
            case XLSX_BARS_FIELDS.NAME:
                bar.name = row[i] ? mapCaretChars(row[i].toString()) : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_STREET:
                street = row[i] ? mapCaretChars(row[i]) : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_STREET_NMB:
                street_nmb = row[i] ? mapCaretChars(row[i].toString()) : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_POST_NUMBER:
                post_nmb = row[i] ? mapCaretChars(row[i].toString()) : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_POST:
                post_name = row[i] ? mapCaretChars(row[i]) : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_REGION:
                region = row[i] ? mapCaretChars(row[i]) : '';
                break;
            case XLSX_BARS_FIELDS.TAX_NUMBER:
                bar.tax_number = row[i] ? mapCaretChars(row[i].toString()) : '';
                if (bar.tax_number === "") {
                    throw new Error('Napaka, prazno polje: davčna št.');
                }
                break;
            case XLSX_BARS_FIELDS.LEGAL_ENTITY:
                bar.legal_entity = row[i] ? mapCaretChars(row[i].toString()) : '';
                break;
            case XLSX_BARS_FIELDS.EMAIL_CARETAKER:
                let user = findItem("username", row[i].toLowerCase(), users)
                try {
                    bar.caretaker_id = findItem("user_id", user.user_id, caretakers).caretaker_id;
                } catch (e) {
                    if (e.toString().includes('TypeError: Cannot read properties of null')) {
                        throw new Error('Napaka, potnik s tem e-mailom ne obstaja..');
                    }
                }
                break;
            case XLSX_BARS_FIELDS.EMAIL_PERSONNEL:
                try {
                    bar.user_id = row[i] && row[i] !== '' ? findItem("username", row[i], users).user_id : null;
                } catch (e) {
                    if (e.toString().includes('TypeError: Cannot read properties of null')) {
                        throw new Error('Napaka, vodja s tem e-mailom ne obstaja..');
                    }
                }
                break;
            case XLSX_BARS_FIELDS.EMAIL_OWNER:
                try {
                    if (!row[i] || row[i] === '' || row[i] === "") {
                        bar.bar_owner_id = null;
                    } else {
                        let user = findItem("username", row[i].toLowerCase(), users);
                        bar.bar_owner_id = row[i] && row[i] !== '' ? findItem("user_id", user.user_id, caretakers).caretaker_id : null;
                    }
                } catch (e) {
                    if (e.toString().includes('TypeError: Cannot read properties of null')) {
                        throw new Error('Napaka, lastnik s tem e-mailom ne obstaja..');
                    }
                }
                break;
            default:
                console.warn('Something went wrong.. To many columns found!');
        }
    }

    bar.address = street + ' ' + street_nmb + ', ' + post_name + ' ' + post_nmb;
    return bar;
};

export const parseProductDataExcel = (row) => {
    let product = {
        external_id: null,
        name: null,
        initial_price: 0,
        bar_id: null,
        picture: null,
        slo_descr: null,
        eng_descr: null,
        eng_name: null,
        de_descr: null,
        de_name: null,
        it_descr: null,
        it_name: null,
        hr_descr: null,
        hr_name: null,
        packaging: null,
        measure_unit: null,
        allergenes: null,
        alcohol_percent: null,
        combo_offer: null,
        category_label: null,
        own_product: null
    };
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_PRODUCTS_FIELDS.EXTERNAL_ID:
                product.external_id = row[i]; //.toString();
                break;
            case XLSX_PRODUCTS_FIELDS.NAME:
                product.name = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.CATEGORY_LABEL:
                product.category_label = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.PACKAGING:
                product.packaging = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.MEASURE_UNIT:
                product.measure_unit = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.ALERGENS:
                product.allergenes = row[i];
                console.log("ALGNS..", row[i])
                break;
            case XLSX_PRODUCTS_FIELDS.ALCO_PERCENTAGE:
                product.alcohol_percent = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.COMBO_OFFER:
                product.combo_offer = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.SLO_DESC:
                product.slo_descr = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.EN_NAME:
                product.eng_name = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.EN_DESC:
                product.eng_descr = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.DE_NAME:
                product.de_name = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.DE_DESC:
                product.de_descr = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.IT_NAME:
                product.it_name = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.IT_DESC:
                product.it_descr = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.HR_NAME:
                product.hr_name = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.HR_DESC:
                product.hr_descr = mapCaretChars(row[i]);
                break;
            case XLSX_PRODUCTS_FIELDS.OWN_PRODUCT:
                product.own_product = row[i];
                break;
            default:
                console.warn(`Skipping parsing of column ${i}..`);
        }
    }
    return product;
};

export const parseCategoryDataExcel = (row) => {
    let category = {
        name: null,
        position: null,
        eng_name: null,
        de_name: null,
        it_name: null,
        hr_name: null
    };
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_CATEGORIES_FIELDS.NAME:
                category.name = mapCaretChars(row[i]);
                break;
            case XLSX_CATEGORIES_FIELDS.POSITION:
                category.position = row[i];
                break;
            case XLSX_CATEGORIES_FIELDS.EN_NAME:
                category.eng_name = mapCaretChars(row[i]);
                break;
            case XLSX_CATEGORIES_FIELDS.DE_NAME:
                category.de_name = mapCaretChars(row[i]);
                break;
            case XLSX_CATEGORIES_FIELDS.IT_NAME:
                category.it_name = mapCaretChars(row[i]);
                break;
            case XLSX_CATEGORIES_FIELDS.HR_NAME:
                category.hr_name = mapCaretChars(row[i]);
                break;
            default:
                console.warn('Something went wrong.. To many columns found!');
        }
    }
    return category;
};

export const getCaretaker = (username, array) => {
    if (!array) {
        return null
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i]["username"] === username) {
            return array[i];
        }
    }
    return null;
};

export const getBarRepresentativeId = (email, users) => {
    if (!users) {
        return null
    }
    for (let i = 0; i < users.length; i++) {
        if (users[i]["username"] === email) {
            return users[i]["user_id"];
        }
    }
    return null;
};

export const hasItem = (field, returnField, value, array) => {
    if (!array) {
        return false
    }

    for (let i = 0; i < array.length; i++) {
        if (array[i][field]?.toString() === value?.toString()) {
            return array[i][returnField];
        }
    }


    return null;
};

export const findItem = (field, value, array) => {
    if (!array) {
        return null
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return array[i];
        }
    }
    return null;
};

export const getIndexOf = (field, value, array) => {
    if (!array) {
        return -1
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return i;
        }
    }
    return -1;
};

export const getIndexForNthCharOccurrence = (string, subString, index) => {
    if (!string) {
        return -1
    }
    return string.split(subString, index).join(subString).length;
}

export const parseSelectedObjectValues = (list, searchField) => {
    if (!list || list.length === 0) {
        return null;
    }
    let arr = []
    for (let i = 0; i < list.length; i++) {
        if (list[i][searchField]) {
            arr.push(list[i][searchField]);
        }
    }
    return arr;
}

export const parseSelectionInvertedObjectValues = (listAll, list, searchField) => {
    let parsed = parseSelectedObjectValues(list, searchField);

    if (!parsed || parsed.length === 0) {
        parsed = [];
    }
    let arr = []
    for (let i = 0; i < listAll.length; i++) {
        let found = false;
        for (let j = 0; j < parsed.length; j++) {
            console.log(parsed[j] === listAll[i][searchField], parsed[j], listAll[i][searchField]);
            if (parsed[j] === listAll[i][searchField]) {
                found = true
                break;
            }
        }
        if (!found) {
            arr.push(listAll[i][searchField]);
        }
    }

    return arr;
}

export const parseUserRole = (userRole) => {
    switch (userRole) {
        case 'ADMIN':
            return USER_ROLES.ADMIN;
        case 'VODJA':
            return USER_ROLES.BAR_REPRESENTATIVE;
        case 'LASTNIK':
            return USER_ROLES.OWNER;
        case 'POTNIK':
            return USER_ROLES.CARETAKER;
        case 'SHAREPOINT':
            return USER_ROLES.SHAREPOINT;
        default:
            console.warn(`Error parsing user role... User role '${userRole}' is Ineligible!`);
            return null;
    }
};

export const parseLeadingBrand = (leadingBrandInt) => {
    switch (Number(leadingBrandInt)) {
        case 0:
        case 5:
            return LEADING_BRAND_STRING.HEINEKEN;
        case 1:
        case 4:
            return LEADING_BRAND_STRING.UNION;
        case 2:
        case 3:
            return LEADING_BRAND_STRING.LASKO;
        case 101:
        case 102:
        case 103:
        case 104:
        case 105:
        case 106:
            return LEADING_BRAND_STRING.BEER_CIDER;
        default:
            console.warn(`Error parsing leading brand... Leading brand '${leadingBrandInt}' is ineligible!`);
            return null;
    }
};


/*
export const buildNewMenu = (menuObject) => {
    const menu = {
        bar_id: menuObject.bar_id,
        name: menuObject.name,
        description: menuObject.description,
        active: false,
        valid_from: menuObject.valid_from,
        comment: menuObject.comment,
        categories: menuObject.categories
    };
    for (let i = 0; i < menuObject.categories.length; i++) {
        for (let j = 0; j < menuObject.categories[i].products.length; j++) {
            let product = menuObject.categories[i].products;
            let p = {
                product_id: product.product_id,
                product['name'],
                product['initial_price'],
                product['bar_id'],
                product['picture'],
                product['slo_descr'],
                product['eng_descr'],
                product['packaging'],
                product['measure_unit'],
                product['allergenes'],
                product['alcohol_percent'],
                product['combo_offer']);
            };
        }
    }

    return menu;
}
 */

export const isSessionValid = (token) => {
    if (!token) {
        console.log('redirect');
        return false;
    }
    return true;
}

export const mapCaretChars = (string) => {
    if (!string) {
        return null;
    }
    let res = string.replace(/[ŽžČčŠšĆćđĐ]/gi, function (c) {
        switch (c) {
            case 'Ž':
                return '_Z';
            case 'ž':
                return '_z';
            case 'Č':
            case 'Ć':
                return '_C';
            case 'č':
            case 'ć':
                return '_c';
            case 'đ':
                return 'd';
            case 'Đ':
                return 'D';
            case 'Š':
                return '_S';
            case 'š':
                return '_s';
        }
    });
    return res;
};

export const parseMapCaretChars = (string) => {
    if (!string) {
        return null;
    }
    let res = string.replace(/_Z|_z|_C|_c|_S|_s/gi, function (c) {
        switch (c) {
            case '_Z':
                return 'Ž';
            case '_z':
                return 'ž';
            case '_C':
                return 'Č';
            case '_c':
                return 'č';
            case '_S':
                return 'Š';
            case '_s':
                return 'š';
        }
    });
    return res;
};
export const parseBeerCiderLabel = (beer_cider_type) => {
    if (!beer_cider_type) {
        return '';
    } else {
        return 'PIVSKA KARTA'
    }
};

export const parsePathToParent = (path) => {
    if (!path) {
        return '';
    }
    return path.substring(0, path.lastIndexOf("/"));
}

export const isNewObjValidCheck = (obj, requiredFields, checkNumeric) => {
    let response = {
        isValid: true,
        invalidFields: [],
        errorMessages: []
    }
    // check for required fields
    for (const requiredField in requiredFields) {
        if (!obj[requiredField] || obj[requiredField] === '') {
            response.isValid = false;
            response.invalidFields.push(requiredField);
            response.errorMessages.push('Manjka vrednost v polju: ' + requiredFields[requiredField]);
        }
    }

    if (checkNumeric) {
        // check for correct numerical input
        if (!validateUserInputNumerical([obj.alcohol_percent])) {
            response.isValid = false;
            response.invalidFields.push('alcohol_percent');
            response.errorMessages.push('Neveljaven vnos podatkov! Stopnja alkohola mora biti decimalno število podano brez enote.');
        }
    }

    return response
}

export const isNewProductValidCheck = (product, requiredFields) => {
    let response = {
        isValid: true,
        invalidFields: [],
        errorMessages: []
    }
    // check for required fields
    for (const requiredField in requiredFields) {
        console.log(requiredField);
        console.log(product[requiredField]);
        if (!product[requiredField] || product[requiredField] === '') {
            response.isValid = false;
            response.invalidFields.push(requiredField);
            response.errorMessages.push('Manjka vrednost v polju: ' + requiredFields[requiredField]);
        }
    }

    // check for correct numerical input
    if (!validateUserInputNumerical([product.alcohol_percent])) {
        response.isValid = false;
        response.invalidFields.push('alcohol_percent');
        response.errorMessages.push('Neveljaven vnos podatkov! Stopnja alkohola mora biti decimalno število podano brez enote.');
    }

    return response;
}

export const isMenuValidPriceCheck = (creatingMenu) => {
    console.log("MENU VALIDATE", creatingMenu);
    for (let i = 0; i < creatingMenu.length; i++) {
        for (let j = 0; j < creatingMenu[i].products.length; j++) {
            console.log("PRODUCT", creatingMenu[i].products[j]);
            if (!creatingMenu[i] || !creatingMenu[i].products || !creatingMenu[i].products[j]) {
                continue;
            }
            let price = creatingMenu[i].products[j].initial_price;
            if (!price || price === '' || isNaN(parseFloat(parsePostDecimalValue(price))) || price === 0) {
                return false;
            }
        }
    }
    return true;
};

export const mapProductsFromStoreToDefaultCategories = (products, isBeerCiderMenu) => {
    let array = [];
    //console.log("PRODUCTS TO BE MAPPED", products);
    for (let i = 0; i < products.length; i++) {
        let idx;
        if (isBeerCiderMenu && products[i].category_label !== 'beer&cider' || !isBeerCiderMenu && products[i].category_label === 'beer&cider') {
            //console.log(products[i].category_label);
            continue;
        }
        if (!hasItem("category", "category", products[i].category ? products[i].category : 'default', array)) {
            let element = {category: 'default', products: [products[i]]};
            array.push(element);
        } else if ((idx = getIndexOf("category", products[i].category ? products[i].category : 'default', array)) > -1) {
            array[idx].products.push(products[i]);
        } else {
            idx = getIndexOf("category", "default", array)
            array[idx].products.push(products[i]);
        }
    }
    console.log("MAPPED PRODUCTS", array);
    return array;
};

export const printDimensions = (item) => {
    let s = '';
    if (item.quantity_qr) {
        s += 'QR, '
    }
    if (item.quantity_a3) {
        s += 'A3, '
    }
    if (item.quantity_a5) {
        s += 'A5, '
    }
    if (item.quantity_b2) {
        s += 'B2, '
    }
    return s.trim().slice(0, -1);
};

export const parseFirstName = (fullName) => {
    if (!fullName) {
        return '';
    }
    return fullName.split(' ')[0]
}

export const parseFolderName = (path) => {
    let idx = path.lastIndexOf("/");
    let name = path.substring(idx, -1);
    console.log("FOLDER NAME", name);
    return name;
}

export const displayDecimalValue = (price) => {
    if (price === null || price === undefined) {
        return '';
    }
    return price.toString().replace(".", ",");
}

export const parsePostDecimalValue = (price) => {
    return price.toString().replace(",", ".");
}

export const displayPrice = (price) => {
    return displayDecimalValue(Number(price).toFixed(2));
}

export const validatePriceUpdateAction = (price, updateProductPriceMenuAction) => {
    let pricePostFormat = parsePostDecimalValue(price);
    if (price && price !== '' && Number(pricePostFormat) >= 0) {
        updateProductPriceMenuAction();
    } else if (price !== '' && (!Number(parsePostDecimalValue(price)) || Number(pricePostFormat) < 0)) {
        displayAlert("Vpiši veljavno ceno v EUR (brez €)!");
    }
}
export const isUserCaretaker = (user) => {
    return user && parseUserRole(user.type) === USER_ROLES.CARETAKER;
}
export const isUserAdmin = (user) => {
    return user && parseUserRole(user.type) === USER_ROLES.ADMIN;
}
export const isUserOwner = (user) => {
    return user && parseUserRole(user.type) === USER_ROLES.OWNER;
}
export const isUserBarRepresentative = (user) => {
    return user && parseUserRole(user.type) === USER_ROLES.BAR_REPRESENTATIVE;
}
export const isUserSharepointOnlyUser = (user) => {
    return user && parseUserRole(user.type) === USER_ROLES.SHAREPOINT;
}

export const isUserSharepointAdmin = (user) => {
    return user && parseUserRole(user.type) === USER_ROLES.ADMIN || user?.username.includes("petra.ojstersek");
}

export const displayValidFromMenuFormat = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    console.log("DATE:", date, `${day}. ${month}. ${year}`)
    return `${day}. ${month}. ${year}`;
};

export const isMainBrand = (brand, compare) => {
    return brand === compare;
}

export const parseBarsToOptionsObject = (bars) => {
    let obj = [];
    for (let i = 0; i < bars.length; i++) {
        let el = {value: JSON.parse(JSON.stringify(bars[i])), label: bars[i].name}
        obj.push(el);
    }
    console.log(obj);
    return obj
};

export const sortCategoriesByLockPosition = (categories) => {
    console.log();

    let len = categories.length;
    for (let i = 0; i < len; i++) {
        for (let j = 0; j < len - 1; j++) {
            if (!categories[j].category.position && !categories[j + 1].category.position) {
                continue;
            }
            if ((!categories[j].category.position && categories[j + 1].category.position) || Number(categories[j].category.position) < Number(categories[j + 1].category.position)) {
                console.log("PAIR:", categories[j].category, categories[j + 1].category);

                let tmp = categories[j];
                categories[j] = categories[j + 1];
                categories[j + 1] = tmp;
            }
        }
    }
    for (let i = 0; i < len; i++) {
        for (let j = 0; j < len - 1; j++) {
            if (!categories[j + 1].category.position || !categories[j].category.position) {
                continue;
            }
            if (Number(categories[j].category.position) > Number(categories[j + 1].category.position)) {
                console.log("PAIR:", categories[j].category, categories[j + 1].category);

                let tmp = categories[j];
                categories[j] = categories[j + 1];
                categories[j + 1] = tmp;
            }
        }
    }

    return [...categories];
};

export let bubbleSort = (categories) => {
    let len = categories.length;
    let swapped;
    do {
        swapped = false;
        for (let i = 0; i < len; i++) {
            if (categories[i] > categories[i + 1]) {
                let tmp = categories[i];
                categories[i] = categories[i + 1];
                categories[i + 1] = tmp;
                swapped = true;
            }
        }
    } while (swapped);
    return categories;
};

export const setLocalStorageObject = (key, obj) => {
    if (!obj) return
    localStorage.setItem(key, JSON.stringify(obj))
}
export const getLocalStorageObject = (key) => {
    const obj = localStorage.getItem(key)
    if (!obj) return obj
    return JSON.parse(obj)
}

export const getUniqueLocalId = (localMenusInEditing) => {
    // generate unique local id
    let uniqueId = generateUniqueId()
    // check if it exists
    if (!localMenusInEditing) localMenusInEditing = getLocalStorageObject(LOCAL_STORAGE_KEYS.LOCAL_MENUS_IN_EDITING)
    if (!localMenusInEditing) localMenusInEditing = {}
    if (localMenusInEditing.hasOwnProperty(uniqueId)) {
        // repeat the process
        return getUniqueLocalId(localMenusInEditing)
    } else {
        return uniqueId
    }
}

export const deleteLocalMenu = (localId) => {
    let localMenusInEditing = getLocalStorageObject(LOCAL_STORAGE_KEYS.LOCAL_MENUS_IN_EDITING)
    if (!localMenusInEditing) return
    if (localMenusInEditing.hasOwnProperty(localId)) {
        delete localMenusInEditing[localId]
        setLocalStorageObject(LOCAL_STORAGE_KEYS.LOCAL_MENUS_IN_EDITING, localMenusInEditing)
    }
}

const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

export const saveToLocalStorage = (createMenuActive) => {
    console.log('saving to local storage ', createMenuActive)
    console.log('saving to local storage localId ', createMenuActive.localId)
    // get current list of menus from local storage
    let localMenusInEditing = getLocalStorageObject(LOCAL_STORAGE_KEYS.LOCAL_MENUS_IN_EDITING)
    if (!localMenusInEditing) localMenusInEditing = {}

    // set current menu to local menus
    localMenusInEditing[createMenuActive.localId] = createMenuActive

    // set local menus to local storage
    setLocalStorageObject(LOCAL_STORAGE_KEYS.LOCAL_MENUS_IN_EDITING, localMenusInEditing)
}

export const getL10NAlergens = (l10n) => {
    if (!l10n) {
        l10n = L10N_MENU.si.value;
    }
    let r = '';
    switch (l10n) {
        case L10N_MENU.si.value:
            r += '1. Mleko in mlečni izdelki \n' +
                '2. Jajca in jajčni izdelki \n' +
                '3. Raki in izdelki iz njih \n' +
                '4. Ribe in izdelki iz njih \n' +
                '5. Oreščki / Nuts \n' +
                '6. Žito, ki vsebuje gluten \n' +
                '7. Arašidi \n' +
                '8. Zrnje soje in izdelki iz njega \n' +
                '9. Listnata zelena in izdelki iz nje \n' +
                '10. Gorčično seme in izdelki iz njega \n' +
                '11. Sezamovo seme in izdelki iz njega \n' +
                '12. Žveplov dioksid in sulfiti \n' +
                '13. Volčji bob \n' +
                '14. Mehkužci';
            break;
        case L10N_MENU.en.value:
            r += '1. MILK AND DAIRY PRODUCTS \n' +
                '2. EGGS AND EGG PRODUCTS \n' +
                '3. CRUSTACEANS AND PRODUCTS THEREOF \n' +
                '4. FISH AND PRODUCTS THEREOF \n' +
                '5. NUTS \n' +
                '6. CEREALS CONTAINING GLUTEN \n' +
                '7. PEANUTS \n' +
                '8. SOYBEANS AND PRODUCTS THEREOF \n' +
                '9. CELERY AND PRODUCTS THEREOF \n' +
                '10. MUSTARD AND PRODUCTS THEREOF \n' +
                '11. SESAME SEEDS AND PRODUCTS THEREOF \n' +
                '12. SULFUR DIOXIDE AND SULPHITES \n' +
                '13. LUPIN \n' +
                '14. MOLLUSCS';
            break;
        case L10N_MENU.de.value:
            r += '1. MILCH UND MILCHPRODUKTE \n' +
                '2. EIER UND EIERPRODUKTE \n' +
                '3. KREBSE UND KREBSPRODUKTE \n' +
                '4. FISCHE UND FISCHPRODUKTE \n' +
                '5. NÜSSE \n' +
                '6. GLUTENHALTIGES GETREIDE \n' +
                '7. ERDNÜSSE \n' +
                '8. SOJABOHNEN UND SOJAPRODUKTE \n' +
                '9. BLATTSELERIE UND SELLERIEPRODUKTE \n' +
                '10. SENFKÖRNER UND SENFPRODUKTE \n' +
                '11. SESAMSAMEN UND SESAMPRODUKTE \n' +
                '12. SCHWEFELDIOXID UND SULFITE \n' +
                '13. LUPINEN \n' +
                '14. WEICHWAREN SULPHITES \n' +
                '13. LUPIN \n' +
                '14. MOLLUSCS';
            break;
        case L10N_MENU.it.value:
            r += '1. LATTE E LATTICINI \n' +
                '2. UOVA E PRODOTTI A BASE DI UOVA \n' +
                '3. CROSTACEI E PRODOTTI A BASE DI CROSTACEI \n' +
                '4. PESCE E PRODOTTI A BASE DI PESCE \n' +
                '5. FRUTTA A GUSCIO 6. GRANO CONTENENTE GLUTINE \n' +
                '7. ARACHIDI \n' +
                '8. SEMI DI SOIA E PRODOTTI A BASE DI SEMI DI SOIA \n' +
                '9. FOGLIE DI SEDANO E PRODOTTI A BASE DI FOGLIE DI SEDANO \n' +
                '10. SEMI DI SENAPE E PRODOTTI A BASE DI SEMI DI SENAPE \n' +
                '11. SEMI DI SESAMO E PRODOTTI A BASE DI SEMI DI SESAMO \n' +
                '12. DIOSSIDO DI ZOLFO E SOLFITI \n' +
                '13. LUPINO \n' +
                '14. MOLLUSCHI';
            break;
        case L10N_MENU.hr.value:
            r += '1. MLIJEKO I MLIJEČNI PROIZVODI \n' +
                '2. JAJA I PROIZVODI OD JAJA \n' +
                '3. RAKOVI I PROIZVODI OD RAKOVA \n' +
                '4. RIBE I RIBLJI PROIZVODI \n' +
                '5. ORAŠČIĆI \n' +
                '6. GLUTENSKE ŽITARICE \n' +
                '7. KIKIRIKI \n' +
                '8. ZNA SOJE I SOJINI PROIZVODI \n' +
                '9. CELER I PROIZVODI OD CELERA \n' +
                '10. SJEMENKE GORUŠICE I PROIZVODI OD SJEMENKI GORUŠICE \n' +
                '11. SJEMENKE SEZAMA I PROIZVODI OD SJEMENKI SEZAMA \n' +
                '12. SUMPOROV OKSID I SULFATI \n' +
                '13. LUPINA \n' +
                '14. MEKUŠCI';
            break;
        default:
            return '/';
    }
    return r;
}

export const getL10NVat = (l10n) => {
    if (!l10n) {
        l10n = L10N_MENU.si.value;
    }
    let r = '';
    switch (l10n) {
        case L10N_MENU.si.value:
            r += 'D.Š.:';
            break;
        case L10N_MENU.en.value:
            r += 'VAT ID:';
            break;
        case L10N_MENU.de.value:
            r += 'MwSt.-Nummer:';
            break;
        case L10N_MENU.it.value:
            r += 'PARTITA IVA:';
            break;
        case L10N_MENU.hr.value:
            r += 'ID ZA PDV:';
            break;
        default:
            return '/';
    }
    return r;
}

export const getL10NMenuValidFrom = (l10n) => {
    if (!l10n) {
        l10n = L10N_MENU.si.value;
    }
    let r = '';
    switch (l10n) {
        case L10N_MENU.si.value:
            r += 'Cene veljajo od:';
            break;
        case L10N_MENU.en.value:
            r += 'PRICE LIST VALID FROM:';
            break;
        case L10N_MENU.de.value:
            r += 'PREISLISTE GÜLTIG AB:';
            break;
        case L10N_MENU.it.value:
            r += 'LISTINO PREZZI VALIDO DAL:';
            break;
        case L10N_MENU.hr.value:
            r += 'CJENIK VRIJEDI OD:';
            break;
        default:
            return '/';
    }
    return r;
}

export const getL10NMenuPricesDisclaimer = (l10n) => {
    if (!l10n) {
        l10n = L10N_MENU.si.value;
    }
    let r = '';
    switch (l10n) {
        case L10N_MENU.si.value:
            r += 'Vse cene so v evrih. V ceno je vključen DDV.';
            break;
        case L10N_MENU.en.value:
            r += 'PRICES ARE IN EUR, VAT IS INCLUDED IN THE PRICE.';
            break;
        case L10N_MENU.de.value:
            r += 'PREISE IN EUR, INKL. MEHRWERTSTEUER.';
            break;
        case L10N_MENU.it.value:
            r += 'I PREZZI SONO IN EURO, IVA INCLUSA NEL PREZZO.';
            break;
        case L10N_MENU.hr.value:
            r += 'CIJENE SU IZRAŽENE U EURIMA, PDV JE URAČUNAT U CIJENU.';
            break;
        default:
            return '/';
    }
    return r;
}

export const getL10NMenuNonTaxDisclaimer = (l10n) => {
    if (!l10n) {
        l10n = L10N_MENU.si.value;
    }
    let r = '';
    switch (l10n) {
        case L10N_MENU.si.value:
            r += 'VSE CENE SO EUR. PRODAJALEC NI ZAVEZANEC ZA DDV';
            break;
        case L10N_MENU.en.value:
            r += 'PRICES ARE IN EUR, THE SELLER IS NOT LIABLE FOR VAT.';
            break;
        case L10N_MENU.de.value:
            r += 'ALLE PREISE IN EURO. DER VERKÄUFER IST NICHT MEHRWERTSTEUERPFLICHTIG.';
            break;
        case L10N_MENU.it.value:
            r += 'TUTTI I PREZZI SONO IN EUR. IL VENDITORE NON È RESPONSABILE PER L\'IVA';
            break;
        case L10N_MENU.hr.value:
            r += 'SVE CIJENE SU EUR. PRODAVAČ NIJE OBVEZNIK PDV-a';
            break;
        default:
            return '/';
    }
    return r;
}

export const isUserPartOfSharepointAdminGroup = (user_group_ids) => {
    if (!user_group_ids) {
        return false
    }
    //console.log("FOUND USER GROUP", foundUserGroup);
    const SHAREPOINT_ADMIN_USER_GROUP_ID = '036450d5-d7d2-4d44-a6da-eab80d2e9164';
    return user_group_ids.includes(SHAREPOINT_ADMIN_USER_GROUP_ID);
}

export const isPasswordValid = (password) => {
    const passwordRegex = /^[a-zA-Z0-9]+$/;
    return passwordRegex.test(password);
}

