import {useHistory} from "react-router-dom";
import React, {useCallback, useEffect} from "react";

import '../css/Card.css'
import '../css/Footer.css';

import * as FaIcons from "react-icons/fa";

import {PAGES} from "../../App";
import Loader from "react-loader-spinner";
import colors from "../../constants/colors";
import Page from "./Page";
import {isRememberMeEnabled, loadCredentials, loadDownloadProgress} from "../../store/actions/actions";
import {findItem, getIndexForNthCharOccurrence, parseMapCaretChars} from "../../helpers/helpers";
import {useSelector} from "react-redux";

function DownloadProgress(props) {

    let downloadProgress = useSelector(state => state.reducer.downloadProgress);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        if (props.onLoading) {
        }
    }, [props.onLoading, props.status, props.progressQueue]);

    const onAction = (url, isNext) => {
        if (props.onActionNext && isNext) {
            if (!props.onActionNext()) {
                return;
            }
        } else if (props.onActionBack && !isNext) {
            if (!props.onActionBack()) {
                return;
            }
        } else {
            if (props.onAction) {
                if (!props.onAction()) {
                    return;
                }
            }
        }
        onNavigateTo(url);
    };

    function Item(props) {

        let item = props.value;
        return (
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', padding: '4px 0', zoom: '0.8'}}>
                <a onClick={() => {}}><FaIcons.FaFileDownload
                    style={{}}/>{item.status}</a>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    <Loader
                        type="TailSpin"
                        color={colors.white}
                        height={32}
                        width={32}
                        style={{paddingRight: '42px', opacity: item.progress !== '100' && item.progress !== '0' ? 1 : 0}}
                    />
                    <a onClick={() => {}}
                       style={{minWidth: 'fit-content'}}>{item.progress + "%"}<FaIcons.FaInfoCircle/></a>
                </div>
            </div>
        );
    }


    return (
        <div className={'footer'} style={{height: 'auto', left: props.left, zIndex: 999, flexDirection: 'column'}}>
            {downloadProgress && downloadProgress.length > 0 && downloadProgress.map((item, index) => <Item key={index} value={item} status={props.status} onloading={props.onLoading}/>)}

        </div>
    );
}

export default DownloadProgress;

