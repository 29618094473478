import React, {useState, useCallback, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import logo from "../../assets/img/logo.jpg";

import '../css/Sidebar.css';

import {ReactComponent as IconUpload} from "../../assets/svg/Icon feather-upload.svg";
import {ReactComponent as IconAdd} from "../../assets/svg/Icon ionic-ios-add-circle-outline.svg";
import {ReactComponent as IconList} from "../../assets/svg/Icon awesome-list-ul.svg";
import {ReactComponent as IconBar} from "../../assets/svg/Icon material-local-bar.svg";
import {ReactComponent as IconDrink} from "../../assets/svg/Icon material-local-drink.svg";
import {ReactComponent as IconNotificationsNew} from "../../assets/svg/Icon feather-material-notifications-none.svg";
import {ReactComponent as IconNotifications} from "../../assets/svg/Icon material-notifications-none.svg";
import {ReactComponent as IconEdit} from "../../assets/svg/Icon feather-edit.svg";
import {ReactComponent as IconUser} from "../../assets/svg/Icon user.svg";
import {ReactComponent as IconScale} from "../../assets/svg/icon-scale.svg";

import {ReactComponent as IconPercentage} from "../../assets/svg/icon-percentage.svg";


import Divider from "./Divider";
import {PAGES} from "../../App";
import {
    setActiveBar,
    setActiveMenu, setComboOffers, setCreateMenuActive,
    setCredentials,
    setIsCreatingMenu, setMenuLastCreated, setProducts, setSessionComboOffers, setSessionProducts,
    setSessionUser,
    setSessionUserToken,
    setUserData,
    setUserToken
} from "../../store/actions/actions";
import {parseUserRole} from "../../helpers/helpers";
import {USER_ROLES} from "../../constants/enums";
import {Redirect} from "react-router";


function Sidebar(props) {
    let dispatch = useDispatch();
    const isCreatingMenu = useSelector(state => state.reducer.isCreatingMenu);
    const user = useSelector(state => state.reducer.user);
    const notificationsAvailable = useSelector(state => state.reducer.notificationsAvailableBool);

    const [userRole, setUserRole] = useState(parseUserRole(user.type))
    const [displaySidebar, setDisplaySidebar] = useState(props.display)

    const onLogout = () => {
        dispatch(setUserData(null));
        dispatch(setUserToken(null));

        setSessionUser(null);
        setSessionUserToken(null);
        dispatch(setCredentials('', ''));

        setSessionProducts(null);
        setSessionComboOffers(null);
        setProducts([]);
        setComboOffers([]);
    };

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onNavigateAction = (path) => {
        dispatch(setIsCreatingMenu(-1));
        onNavigateTo(path)
    };

    const onNavigateActionReset = (path, beer_cider) => {
        window.beer_cider_menu = beer_cider;

        dispatch(setActiveMenu(null));
        dispatch(setActiveBar(null));
        dispatch(setCreateMenuActive(null));
        dispatch(setMenuLastCreated(null));
        onNavigateTo(path)
    };

    useEffect(() => {
        setDisplaySidebar(props.display)
    }, [props.display])

    useEffect(() => {
        if (notificationsAvailable) {
            console.log("NOTIFICATIONS AVAILABLE", notificationsAvailable);
        }
    }, [notificationsAvailable]);


    let classNameLi = '';
    /*
    if (isCreatingMenu > -1) {
        classNameLi = 'collapsed';
    }

     */

    const renderCreateMenuSubmenu = (beer_cider_submenu) => {
        const getClassName = (pageIndex) => {
            let className = 'submenu-item ';
            if (pageIndex < isCreatingMenu) {
                className += 'before';
            } else if (pageIndex === isCreatingMenu) {
                className += 'current';
            } else {
                className += 'after';
            }
            return className;
        };
        if (isCreatingMenu === -1 || beer_cider_submenu !== window.beer_cider_menu) {
            return null;
        }
        return (
            <div style={{marginTop: '16px'}}>
                <a onClick={() => onNavigateTo(PAGES.CREATE_MENU)} className={getClassName(0)}>OSNOVNI PODATKI</a>
                <a style={{cursor: 'default'}} onClick={() => {
                } /* onNavigateTo(PAGES.CREATE_MENU_CHOOSE_BACKGROUND) */} className={getClassName(1)}>IZBERI
                    OZADJE</a>
                <a style={{cursor: 'default'}} onClick={() => {
                } /*onNavigateTo(PAGES.CREATE_MENU_CHOOSE_CATEGORIES)*/} className={getClassName(2)}>IZBERI
                    KATEGORIJE</a>
                <a style={{cursor: 'default'}} onClick={() => {
                } /*onNavigateTo(PAGES.CREATE_MENU_ADD_PRODUCTS)*/} className={getClassName(3)}>IZBERI
                    ARTIKLE</a>
                <a style={{cursor: 'default'}} onClick={() => {
                }/* onNavigateTo(PAGES.CREATE_MENU_CHOOSE_COMBO_OFFER) */} className={getClassName(4)}>POSEBNA
                    PONUDBA</a>
                <a style={{cursor: 'default'}} onClick={() => {
                } /*onNavigateTo(PAGES.CREATE_MENU_PREVIEW) */} className={getClassName(5)}>PREDOGLED</a>
            </div>
        );
    };

    const renderStatistics = (beer_cider_submenu) => {
        if (userRole !== USER_ROLES.ADMIN) {
            return null;
        }

        const getClassName = (pageIndex) => {
            let className = 'submenu-item ';
            if (pageIndex < isCreatingMenu) {
                className += 'before';
            } else if (pageIndex === isCreatingMenu) {
                className += 'current';
            } else {
                className += 'after';
            }
            return className;
        };
        if (!window.statistics_page_open) {
            return null;
        }
        let selectedOpacity = 0.8;
        let defaultOpacity = 0.39;
        return (
            <div style={{marginTop: '16px'}}>
                <a style={{cursor: 'pointer', opacity: window.active_submenu_item_stats === 0 ? selectedOpacity : defaultOpacity}}
                   onClick={() => {
                       onNavigateTo(PAGES.STATS);
                    //   window.active_submenu_item_stats = 0;
                   }} className={getClassName(0)}>OSNOVNI PREGLED</a>
                <a style={{cursor: 'pointer', opacity: window.active_submenu_item_stats === 1 ? selectedOpacity : defaultOpacity}}
                   onClick={() => {
                       onNavigateTo(PAGES.STATS_ADS);
                   //    window.active_submenu_item_stats = 1;
                   }} className={getClassName(1)}>STATISTIKA OGLASOV</a>
                <a style={{cursor: 'pointer', opacity: window.active_submenu_item_stats === 2 ? selectedOpacity : defaultOpacity}}
                   onClick={() => {
                       onNavigateTo(PAGES.STATS_PRODUCTS);
                   //    window.active_submenu_item_stats = 2;
                   }
                   } className={getClassName(2)}>STATISTIKA ARTIKLOV</a>
            </div>
        );
    };

    const renderAdminSidebar = () => {
        if (userRole !== USER_ROLES.ADMIN) {
            return null;
        }

        /* removed from first ul li, add if necessary -> same goes down lower for caretaker menu, add it!! */
        /*                      <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.MENUS_LIST)}>
                            <IconList className={'icon'} width={'24px'} height={'20px'} title={'icon add'}/>OBSTOJEČI
                            CENIKI</a>
                    </li> */

        return (
            <div className={'sidebar'}>
                <div onClick={() => onNavigateAction(PAGES.HOME)} className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" width={'220px'}/>
                </div>
                <Divider style={{marginBottom: '24px'}}/>
                <ul>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.USERS_LIST)}>
                            <IconUser style={{margin: '0 11px'}} className={'icon'} width={'32px'} height={'32px'}
                                      title={'icon users'}/>PREGLED
                            UPORABNIKOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.BARS_LIST)}>
                            <IconBar className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            LOKALOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.PRODUCTS_LIST)}>
                            <IconDrink className={'icon'} width={'22px'} height={'25px'} title={'icon add'}/>PREGLED
                            IZDELKOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.CATEGORIES_LIST)}>
                            <IconList className={'icon'} width={'22px'} height={'25px'} title={'icon add'}/>PREGLED
                            KATEGORIJ</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.NOTIFICATIONS)}>
                            {
                                !notificationsAvailable ?
                                    <IconNotifications className={'icon'} width={'24px'} height={'29px'}
                                                       title={'icon add'}/> :
                                    <IconNotificationsNew className={'icon'} width={'24px'} height={'29px'}
                                                          title={'icon add'}/>
                            }
                            OBVESTILA</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.IMPORT_FILE)}>
                            <IconUpload className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>UVOZI
                            DATOTEKO</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.CREATE_COMBO_OFFER)}>
                            <IconAdd className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>IZDELAVA
                            COMBO
                            PONUDBE</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.CREATE_AD)}>
                            <IconAdd className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>IZDELAVA
                            OGLASA</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.ADS_LIST)}>
                            <IconList className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            OGLASOV</a>
                    </li>
                    <li style={{display: 'flex', flexDirection: 'column'}}>
                        <a onClick={() => onNavigateActionReset(PAGES.STATS, false)}>
                            <IconScale className={'icon icon-stats-sidebar'} width={'24px'} height={'24px'}
                                       title={'icon add'}/>STATISTIKA</a>
                        {renderStatistics(false)}
                    </li>
                    <li style={{display: 'flex', flexDirection: 'column'}}>
                        <a onClick={() => onNavigateAction(PAGES.ANALYTICS)}>
                            <IconPercentage className={'icon icon-stats-sidebar'} width={'24px'} height={'24px'}
                                       title={'icon add'}/>ANALITIKA</a>
                    </li>
                </ul>
                <div className={'btn-logout'} onClick={onLogout}><p>ODJAVA</p></div>
            </div>
        );
    };

    /*
                        <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.CREATE_AD)}>
                            <IconAdd className={'icon'} width={'24px'} height={'24px'}
                                               title={'icon add'}/>IZDELAVA BANNERJA</a>
                    </li>
    * */

    const renderCaretakerSidebar = () => {
        if (userRole !== USER_ROLES.CARETAKER) {
            return null;
        }
        return (
            <div className={'sidebar'}>
                <div onClick={() => onNavigateAction(PAGES.HOME)} className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" width={'220px'}/>
                </div>
                <Divider style={{marginBottom: '25px'}}/>
                <ul>
                    <li style={{
                        display: isCreatingMenu === -1 || isCreatingMenu > -1 && !window.beer_cider_menu ? 'flex' : 'none',
                        flexDirection: 'column'
                    }}>
                        <a onClick={() => onNavigateActionReset(PAGES.CREATE_MENU, false)}>
                            <IconAdd className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>USTVARI CENIK</a>
                        {renderCreateMenuSubmenu(false)}
                    </li>
                    <li style={{
                        display: isCreatingMenu === -1 || isCreatingMenu > -1 && window.beer_cider_menu ? 'flex' : 'none',
                        flexDirection: 'column'
                    }}>
                        <a onClick={() => onNavigateActionReset(PAGES.CREATE_MENU, true)}>
                            <IconAdd className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>USTVARI
                            PIVSKO KARTO</a>
                        {renderCreateMenuSubmenu(true)}
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.BARS_LIST)}>
                            <IconBar className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            LOKALOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.PRODUCTS_LIST)}>
                            <IconDrink className={'icon'} width={'22px'} height={'25px'} title={'icon add'}/>PREGLED
                            IZDELKOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.CATEGORIES_LIST)}>
                            <IconList className={'icon'} width={'22px'} height={'25px'} title={'icon add'}/>PREGLED
                            KATEGORIJ</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.NOTIFICATIONS)}>
                            <IconNotifications className={'icon'} width={'24px'} height={'29px'}
                                               title={'icon add'}/>OBVESTILA</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.MENUS_LIST_LOCAL)}>
                            <IconEdit className={'icon'} width={'24px'} height={'29px'}
                                      title={'icon edit'}/>NEODDANI CENIKI</a>
                    </li>
                </ul>
                <div className={'btn-logout'} onClick={onLogout}><p>ODJAVA</p></div>
            </div>
        );
    };

    const renderBarRepresentativeSidebar = () => {
        if (userRole !== USER_ROLES.BAR_REPRESENTATIVE) {
            return null
        }
        return (
            <div className={'sidebar'}>
                <div onClick={() => onNavigateTo(PAGES.HOME)} className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" width={'220px'}/>
                </div>
                <Divider style={{marginBottom: '24px'}}/>
                <ul>
                    <li style={{
                        display: isCreatingMenu === -1 || isCreatingMenu > -1 && !window.beer_cider_menu ? 'flex' : 'none',
                        flexDirection: 'column'
                    }}>
                        <a onClick={() => onNavigateActionReset(PAGES.CREATE_MENU, false)}>
                            <IconAdd className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>USTVARI CENIK</a>
                        {renderCreateMenuSubmenu(false)}
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.BARS_LIST)}>
                            <IconBar className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            LOKALOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.PRODUCTS_LIST)}>
                            <IconBar className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            UNIKATNIH IZDELKOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.CATEGORIES_LIST)}>
                            <IconBar className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            UNIKATNIH KATEGORIJ</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.EDIT_PUBLISHED_MENU)}>
                            <IconList className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>UREDI
                            DIGITALNI CENIK</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.MENUS_LIST_LOCAL)}>
                            <IconEdit className={'icon'} width={'24px'} height={'29px'}
                                      title={'icon edit'}/>NEODDANI CENIKI</a>
                    </li>
                </ul>
                <div className={'btn-logout'} onClick={onLogout}><p>ODJAVA</p></div>
            </div>
        );
    };

    const renderBarOwnerSidebar = () => {
        if (userRole !== USER_ROLES.OWNER) {
            return null
        }
        return (
            <div className={'sidebar'}>
                <div onClick={() => onNavigateTo(PAGES.HOME)} className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" width={'220px'}/>
                </div>
                <Divider style={{marginBottom: '24px'}}/>
                <ul>
                    <li style={{
                        display: isCreatingMenu === -1 || isCreatingMenu > -1 && !window.beer_cider_menu ? 'flex' : 'none',
                        flexDirection: 'column'
                    }}>
                        <a onClick={() => onNavigateActionReset(PAGES.CREATE_MENU, false)}>
                            <IconAdd className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>USTVARI CENIK</a>
                        {renderCreateMenuSubmenu(false)}
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.BARS_LIST)}>
                            <IconBar className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            LOKALOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.PRODUCTS_LIST)}>
                            <IconBar className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>PREGLED
                            UNIKATNIH IZDELKOV</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.EDIT_PUBLISHED_MENU)}>
                            <IconList className={'icon'} width={'24px'} height={'24px'} title={'icon add'}/>UREDI
                            TRENUTNI CENIK</a>
                    </li>
                    <li className={classNameLi}>
                        <a onClick={() => onNavigateAction(PAGES.MENUS_LIST_LOCAL)}>
                            <IconEdit className={'icon'} width={'24px'} height={'29px'}
                                      title={'icon edit'}/>NEODDANI CENIKI</a>
                    </li>

                </ul>
                <div className={'btn-logout'} onClick={onLogout}><p>ODJAVA</p></div>
            </div>
        );
    };

    /*
    *                     <li className={classNameLi}>
                        <a onClick={() => onNavigateTo(PAGES.EDIT_BAR)}>
                            <IconBar className={'icon'} width={'22px'} height={'25px'} title={'icon add'}/>UREDI
                            BAR</a>
                    </li>
    * */

    const renderSidebar = () => {
        return <>
            {renderAdminSidebar()}
            {renderCaretakerSidebar()}
            {renderBarRepresentativeSidebar()}
            {renderBarOwnerSidebar()}
        </>
    }

    if (!displaySidebar) {
        return <></>;
    }

    return <>
        {renderSidebar()}
    </>;
}

export default Sidebar;