import * as React from "react";
import * as BiIcons from "react-icons/bi";
import {ReactComponent as IconUpload} from "../../assets/svg/Icon feather-upload-cloud.svg";


const FileUploadSharepoint = props => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile && props.handleFile(fileUploaded);

        setTimeout(() => {
            hiddenFileInput.current.value = ""
        }, 250)
    };
    return (
        <>
            <div className={'sharepoint toolbar-button sharepoint-button'} style={props.style} onClick={handleClick}>
                <IconUpload style={{margin: '0px'}} className={'sharepoint icon toolbar-icon upload'} width={'23px'} height={'23px'}/>
            </div>
            <input type="file"
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{...props.style, display: 'none'}}
            />
        </>
    );
};
export default FileUploadSharepoint;