import React, {useState, useEffect, useReducer} from "react";

import '../css/Input.css';
import {useSelector} from "react-redux";
import {displayDecimalValue, parseMapCaretChars} from "../../helpers/helpers";
import {ReactComponent as IconSearch} from "../../assets/svg/Icon feather-search.svg";


function Input(props) {
    const [input, setInput] = useState('');
    const savedCredentials = useSelector(state => state.reducer.savedCredentials);
    const activeBar = useSelector(state => state.reducer.activeBar);
    const activeBarRepresentative = useSelector(state => state.reducer.activeBarRepresentative);
    const activeBarOwner = useSelector(state => state.reducer.activeBarOwner);
    const selectedProduct = useSelector(state => state.reducer.selectedProduct);
    const selectedCategory = useSelector(state => state.reducer.selectedCategory);
    const selectedAd = useSelector(state => state.reducer.selectedAd);
    const activeBarCaretaker = useSelector(state => state.reducer.activeBarCaretaker);
    const createMenuActive = useSelector(state => state.reducer.createMenuActive);
    let activeEditUser = useSelector(state => state.reducer.activeEditUser);

    const savedCredentialsSharepoint = useSelector(state => state.reducer.savedCredentialsSharepoint);

    useEffect(() => {
        if (props.search) {
            console.log(props.value);
            onInputChange(props.value)
        }
    }, [props.value]);

    useEffect(() => {
        if (props.sharepoint_folder_description) {
            onInputChange(props.sharepoint_folder_description)
        }
    }, [props.sharepoint_folder_description]);
    useEffect(() => {
        if (props.loadUser && activeEditUser) {
            if (props.externalId) {
                onInputChange(activeEditUser.external_id)
            } else if (props.type) {
                onInputChange(activeEditUser.type)
            } else if (props.fullName) {
                onInputChange(activeEditUser.full_name)
            } else if (props.email) {
                onInputChange(activeEditUser.username)
            } else if (props.telephone) {
                onInputChange(activeEditUser.telephone)
            }
        }
    }, [activeEditUser]);
    useEffect(() => {
        if (props.loadLoginCredentials) {
            if (props.loadLoginCredentials && props.email && savedCredentials) {
                onInputChange(savedCredentials.email)
            } else if (props.loadLoginCredentials && props.password && savedCredentials) {
                onInputChange(savedCredentials.password)
            }
        }
    }, [savedCredentials]);
    useEffect(() => {
        if (props.loadLoginCredentialsSharepoint) {
            if (props.loadLoginCredentialsSharepoint && props.email && savedCredentialsSharepoint) {
                onInputChange(savedCredentialsSharepoint.email)
            } else if (props.loadLoginCredentialsSharepoint && props.password && savedCredentialsSharepoint) {
                onInputChange(savedCredentialsSharepoint.password)
            }
        }
    }, [savedCredentialsSharepoint]);
    useEffect(() => {
        if (props.loadMenu) {
            if (props.quantity_qr && createMenuActive) {
                onInputChange(createMenuActive.quantity_qr ? createMenuActive.quantity_qr : (createMenuActive?.printQuantities?.qr ? createMenuActive.printQuantities.qr : null))
            } else if (props.quantity_a3 && createMenuActive) {
                onInputChange(createMenuActive.quantity_a3 ? createMenuActive.quantity_a3 : (createMenuActive?.printQuantities?.a3 ? createMenuActive.printQuantities.a3 : null))
            } else if (props.quantity_a4 && createMenuActive) {
                onInputChange(createMenuActive.quantity_a4 ? createMenuActive.quantity_a4 : (createMenuActive?.printQuantities?.a4 ? createMenuActive.printQuantities.a4 : null))
            } else if (props.quantity_a5 && createMenuActive) {
                onInputChange(createMenuActive.quantity_a5 ? createMenuActive.quantity_a5 : (createMenuActive?.printQuantities?.a5 ? createMenuActive.printQuantities.a5 : null))
            } else if (props.quantity_b2 && createMenuActive) {
                onInputChange(createMenuActive.quantity_b2 ? createMenuActive.quantity_b2 : (createMenuActive?.printQuantities?.b2 ? createMenuActive.printQuantities.b2 : null))
            } else if (props.comment && createMenuActive) {
                //onInputChange(createMenuActive.comment)
                onInputChange(parseMapCaretChars(createMenuActive.comment))
            }
        }

        /* didnt work when sorting, here in case we need it in future
        if (props.loadActiveMenuProduct) {
            if (createMenuActive) {
                console.log(`loadActiveMenuProduct ${props.c},${props.p}`, props.product, displayDecimalValue(createMenuActive.categories[props.c]?.products[props.p]?.initial_price));
                onInputChange(displayDecimalValue(createMenuActive.categories[props.c]?.products[props.p]?.initial_price))
            }
        }
         */

    }, [createMenuActive]);
    useEffect(() => {
        if (props.loadBar) {
            if (props.loadBar && props.name && activeBar) {
                onInputChange(parseMapCaretChars(activeBar.name))
            } else if (props.loadBar && props.address && activeBar) {
                onInputChange(parseMapCaretChars(activeBar.address))
            } else if (props.loadBar && props.taxNumber && activeBar) {
                onInputChange(activeBar.tax_number)
            } else if (props.loadBar && props.legalEntity && activeBar) {
                onInputChange(parseMapCaretChars(activeBar.legal_entity))
            }
        }
    }, [activeBar]);
    useEffect(() => {
        if (props.loadBar) {
            if (props.loadBar && props.phone && activeBarRepresentative) {
                onInputChange(activeBarRepresentative.telephone)
            } else if (props.loadBar && props.email) {
                if (activeBarRepresentative) {
                    onInputChange(activeBarRepresentative.username)
                } else {
                    onInputChange("")
                }
                console.log("ALO", activeBarRepresentative)
            } else if (props.loadBar && props.phoneOwner && activeBarOwner) {
                onInputChange(activeBarOwner.telephone)
            } else if (props.loadBar && props.emailOwner) {
                if (activeBarOwner) {
                    onInputChange(activeBarOwner.username)
                } else {
                    onInputChange("")
                }
            }
        }
    }, [activeBarRepresentative, activeBarOwner, props.loadBar]);
    useEffect(() => {
        if (props.loadProduct) {
            if (props.loadProduct && props.name && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.name))
            } else if (props.loadProduct && props.sloDesc && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.slo_descr))

            } else if (props.loadProduct && props.engDesc && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.eng_descr))
            } else if (props.loadProduct && props.engName && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.eng_name))

            } else if (props.loadProduct && props.deDesc && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.de_descr))
            } else if (props.loadProduct && props.deName && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.de_name))

            } else if (props.loadProduct && props.itDesc && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.it_descr))
            } else if (props.loadProduct && props.itName && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.it_name))

            } else if (props.loadProduct && props.hrDesc && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.hr_descr))
            } else if (props.loadProduct && props.hrName && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.hr_name))

            } else if (props.loadProduct && props.unit && selectedProduct) {
                onInputChange(selectedProduct.measure_unit)
            } else if (props.loadProduct && props.unit2 && selectedProduct) {
                onInputChange(selectedProduct.measure_unit_2)
            } else if (props.loadProduct && props.unit3 && selectedProduct) {
                onInputChange(selectedProduct.measure_unit_3)
            } else if (props.loadProduct && props.packaging && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.packaging))
            } else if (props.loadProduct && props.allergens && selectedProduct) {
                onInputChange(selectedProduct.allergenes)
            } else if (props.loadProduct && props.alcoPercentage && selectedProduct) {
                if (selectedProduct.alcohol_percent === 0) {
                    selectedProduct.alcohol_percent = '0.0';
                }
                onInputChange(selectedProduct.alcohol_percent)
            } else if (props.loadProduct && props.category && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.category_label))
            } else if (props.loadProduct && props.nameSec && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.name_secondary))
            } else if (props.loadProduct && props.descSec && selectedProduct) {
                onInputChange(parseMapCaretChars(selectedProduct.desc_secondary))
            }
        }
    }, [selectedProduct]);
    useEffect(() => {
        if (props.loadCategory) {
                console.log("nooooo", props.loadCategory, selectedCategory)
            if (props.loadCategory && props.name && selectedCategory) {
                onInputChange(parseMapCaretChars(selectedCategory.name))
            } else if (props.loadCategory && props.engName && selectedCategory) {
                onInputChange(parseMapCaretChars(selectedCategory.eng_name))
            } else if (props.loadCategory && props.deName && selectedCategory) {
                onInputChange(parseMapCaretChars(selectedCategory.de_name))
            } else if (props.loadCategory && props.itName && selectedCategory) {
                onInputChange(parseMapCaretChars(selectedCategory.it_name))
            } else if (props.loadCategory && props.hrName && selectedCategory) {
                onInputChange(parseMapCaretChars(selectedCategory.hr_name))
            }
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (props.loadAd) {
            if (props.loadAd && props.name && selectedAd) {
                onInputChange(parseMapCaretChars(selectedAd.name))
            } else if (props.loadAd && props.link && selectedAd) {
                onInputChange(selectedAd.link)
            }
        }
    }, [selectedAd]);

    const onActionSubmit = (event) => {
        if (props.onSubmit) {
            event.preventDefault();
            props.onSubmit();
        }
    };

    const onInputChange = (value) => {
        const {onChange} = props;

        setInput(value);
        onChange(value);
    }

    let className = 'input-default';
    if (props.white) {
        className += ' input-white';
    }
    if (props.login) {
        className += ' input-login';
    }
    if (props.shadow) {
        className += ' input-shadow';
    }
    if (props.textarea) {
        className += ' input-textarea';
    }
    if (props.price) {
        className += ' input-price';
    }
    if (props.sharepoint_folder_description) {
        className += ' input-sharepoint_folder_description';
    }

    let type = 'text';
    if (props.password) {
        type = 'password';
    } else if (props.email) {
        type = 'email';
    }
    let inputContent = () => {
        if (props.textarea) {
            return <textarea
                disabled={props.disabled}
                className={className}
                placeholder={props.placeholder}
                style={props.style}
                value={input ? input : ''} onBlur={props.onBlur}
                onChange={(event) => onInputChange(event.target.value)}/>
        } else {
            return <input disabled={props.disabled} type={type} className={className}
                          placeholder={props.placeholder}
                          style={props.style}
                          value={input || input === 0 ? input : ''}
                          onBlur={props.onBlur}
                          name={props.login && props.email ? "email" : ""}
                          /*id={props.login && props.email ? "email" : ""}*/
                          autoComplete={props.login && props.password ? "new-password" : props.login && props.email ? "email" : "off"}
                          onChange={(event) => onInputChange(event.target.value)}/>
        }
    };

    return (
        <form onSubmit={onActionSubmit}>
            <label className={'input-label'}>
                {props.label &&
                <p className={'p-title-small'}>{props.label}</p>
                }
                {inputContent()}
            </label>
            {props.icon ? <IconSearch className={'icon search'} width={'20px'} height={'20px'}/> : <></>}
        </form>
    );
}

export default Input;